/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from "react";

import Field from "Component/Field";
import { FieldType } from "Component/Field/Field.config";
import TextPlaceholder from "Component/TextPlaceholder";
import { TextPlaceHolderLength } from "Component/TextPlaceholder/TextPlaceholder.config";
import { SortDirections } from "Route/CategoryPage/CategoryPage.config";
import { ReactElement } from "Type/Common.type";

import { CategorySortComponentProps } from "./CategorySort.type";
import DrawerBox from "Component/DrawerBox/DrawerBox.component";
import "./CategorySort.style";
import getStore from "Util/Store";

import ChevronIcon from "Component/ChevronIcon";
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';


/**
 * Product Sort
 * @class ProductSort
 * @namespace Component/CategorySort/Component
 */
export class CategorySortComponent extends PureComponent<CategorySortComponentProps> {
    __construct(props: CategorySortComponentProps): void {
        super.__construct?.(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(value: string): void {
        const { onSortChange } = this.props;
        const [direction, ...key] = value.split(" ");

        onSortChange(direction as SortDirections, key);
    }

    renderPlaceholder(): ReactElement {
        return (
            <p block="CategorySort" elem="Placeholder">
                <TextPlaceholder length={TextPlaceHolderLength.SHORT} />
            </p>
        );
    }

    renderSortField(): ReactElement {
        const {
            sortKey,
            sortDirection,
            selectOptions,
            isMatchingInfoFilter,
            isCurrentCategoryLoaded,
        } = this.props;

        // if (!isMatchingInfoFilter || !isCurrentCategoryLoaded) {
        //     return this.renderPlaceholder();
        // }
        // return this.renderPlaceholder();

        return (
            <>
                <Field
                    type={FieldType.SELECT}
                    attr={{
                        id: "category-sort",
                        name: "category-sort",
                        value: `${sortDirection} ${sortKey}`,
                        noPlaceholder: true,
                    }}
                    events={{
                        onChange: this.onChange,
                    }}
                    isSortSelect
                    options={selectOptions}
                    mix={{ block: "CategorySort", elem: "Select" }}
                />
            </>
        );
    }

    render(): ReactElement {

        const state = getStore().getState();
        const isMobile = state.ConfigReducer.device.isMobile;
        const urlParams = new URLSearchParams(window.location.search);
        const isContentSorted = urlParams.get('sortKey')
        const { showLayoutsText } = this.props;

        return (
            <>
                {
                    (showLayoutsText) ? (isMobile) ? "" : <div block="CategoryPage" elem="LayoutsInfo"><span>Layouts:</span></div> : ''
                }
                <div block="CategorySort">
                    {
                        (isContentSorted) ?
                            <svg block="isContentSorted" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#E31620"/>
                            </svg>
                            :
                            ''
                    }
                    {/* { this.renderSortField() } */}
                    {isMobile ? (
                        <DrawerBox
                            wrapperClass="sort-by"
                            position="bottom"
                            buttonTitle="Sort By"
                            heading="Sort By"
                            content={this.renderSortField()}
                            endIcon={<ChevronIcon direction={ Directions.BOTTOM } />}
                        />
                    ) : (
                        this.renderSortField()
                    )}
                </div>
            </>
        );
    }
}

export default CategorySortComponent;
