import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { SignInOptions } from 'Query/MyAccount.type';
import { CheckoutStepUrl } from 'Route/Checkout/Checkout.config';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { NetworkError, ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';
import { FieldData } from 'Util/Form/Form.type';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { getErrorMessage } from 'Util/Request/Error';
import { RootState } from 'Util/Store/Store.type';
import { appendWithStoreCode } from 'Util/Url';
import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';

export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    async onSignInSuccess(form: HTMLFormElement, fields: FieldData[]): Promise<void> {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            totals: { is_virtual },
            isCheckout,
        } = this.props;

        const {
            isSignIn,
        } = this.state;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        if (!isSignIn) {
            this.setState({ isSignIn: true });

            try {
                await signIn(fieldPairs as SignInOptions);
                onSignIn();
            } catch (error) {
                showNotification(NotificationType.ERROR, getErrorMessage(error as NetworkError));
                this.setState({ isSignIn: false });
            } finally {
                setLoadingState(false);
            }
        }

        setLoadingState(false);

        if (window.location.pathname.includes('knowledge-base')) {
            //@ts-ignore
            window.closeCustomPopUp();
            //@ts-ignore
            window.kbRefresh();
            return;
        }

        if (is_virtual && isCheckout) {
            history.push({ pathname: appendWithStoreCode(CheckoutStepUrl.BILLING_URL) });
        } else if (!is_virtual && isCheckout) {
            history.push({ pathname: appendWithStoreCode(CheckoutStepUrl.SHIPPING_URL) });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
