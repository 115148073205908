export const RICH_SNIPPET = 'rich-snippet';
export const PAGE_NUMBER_POSITION_AT_BEGIN = 1;
export const PAGE_NUMBER_POSITION_AT_END = 2;
export const PAGE_NUMBER_POSITION_AT_BEGIN_WITH_FIRST = 3;
export const PAGE_NUMBER_POSITION_AT_END_WITH_FIRST = 4;

const addRichSnippet = (rich_snippet) => {
    const script = document.getElementById(RICH_SNIPPET);
    if (script) {
        script.parentNode.removeChild(script);
    }
    if (!rich_snippet) {
        return false;
    }
    let richSnippedScript = document.createElement('script');
    richSnippedScript.id = RICH_SNIPPET;
    richSnippedScript.type = 'application/ld+json';
    richSnippedScript.text = rich_snippet;
    document.head.appendChild(richSnippedScript);
};

const addTwitterCard = (twitter_card, currentPage = false, meta_title_page_number = false, meta_description_page_number = false) => {
    let twitterMeta = JSON.parse(twitter_card);
    let twitterMetaHtml;
    Object.entries(twitterMeta).reduce((acc, [key, value]) => {
        twitterMetaHtml = document.querySelectorAll('meta[name^="' + key + '"]');
        twitterMetaHtml.forEach(el => el.remove());
        twitterMetaHtml = document.createElement('meta');
        twitterMetaHtml.setAttribute('name', key);
        if (key === 'twitter:title') {
            value = metaPagination(value, currentPage, meta_title_page_number, false);
        }
        if (key === 'twitter:description') {
            value = metaPagination(value, currentPage, false, meta_description_page_number);
        }
        twitterMetaHtml.setAttribute('content', value);
        document.head.appendChild(twitterMetaHtml);
    }, {});
};

const addOpenGraph = (opengraph, currentPage = false, meta_title_page_number = false, meta_description_page_number = false) => {
    let opengraphMeta = JSON.parse(opengraph);
    let opengraphMetaHtml;
    Object.entries(opengraphMeta).reduce((acc, [key, value]) => {
        opengraphMetaHtml = document.querySelectorAll('meta[property^="' + key + '"]');
        opengraphMetaHtml.forEach(el => el.remove());
        opengraphMetaHtml = document.createElement('meta');
        opengraphMetaHtml.setAttribute('property', key)
        if (key === 'og:title') {
            value = metaPagination(value, currentPage, meta_title_page_number, false);
        }
        if (key === "og:description") {
            value = metaPagination(value, currentPage, false, meta_description_page_number);
        }
        opengraphMetaHtml.setAttribute('content', value)
        document.head.appendChild(opengraphMetaHtml);
    }, {});
};

const metaPagination = (value, currentPage, meta_title_page_number = false, meta_description_page_number = false) => {
    if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_BEGIN && currentPage > 1) {
        return 'Page ' + currentPage + ' | ' + value;
    } else if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_BEGIN && currentPage === 1) {
        return value;
    } else if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_END && currentPage > 1) {
        return value + ' | ' + 'Page ' + currentPage;
    } else if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_END && currentPage === 1) {
        return value;
    } else if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_BEGIN_WITH_FIRST || meta_title_page_number === PAGE_NUMBER_POSITION_AT_END_WITH_FIRST) {
        return value.replace(/Page \d+/, 'Page ' + currentPage);
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_BEGIN && currentPage > 1) {
        return 'Page ' + currentPage + ' | ' + value;
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_BEGIN && currentPage === 1) {
        return value;
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_END && currentPage > 1) {
        return value + ' | ' + 'Page ' + currentPage;
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_END && currentPage === 1) {
        return value;
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_BEGIN_WITH_FIRST || meta_description_page_number === PAGE_NUMBER_POSITION_AT_END_WITH_FIRST) {
        return value.replace(/Page \d+/, 'Page ' + currentPage);
    }
    return value;
}

const mapStateToPropsProductPage = (args, callback, instance) => {
    const {
        0: {
            ProductReducer: {
                product: {
                    rich_snippet,
                    twitter_card,
                    opengraph
                }
            }
        }
    } = args;
    if (typeof rich_snippet !== "undefined" && rich_snippet) {
        addRichSnippet(rich_snippet);
    }
    if (typeof twitter_card !== "undefined" && twitter_card) {
        addTwitterCard(twitter_card);
    }
    if (typeof opengraph !== "undefined" && opengraph) {
        addOpenGraph(opengraph);
    }
    return callback.apply(instance, args);

};

const mapStateToPropsCategoryPage = (args, callback, instance) => {
    const {
        0: {
            CategoryReducer: {
                category: {
                    meta_title,
                    meta_description,
                    rich_snippet,
                    twitter_card,
                    opengraph,
                    meta_title_page_number,
                    meta_description_page_number
                }
            },
            ProductListReducer: {
                currentArgs: {
                    currentPage
                }
            }
        }
    } = args;
    if (typeof rich_snippet !== "undefined" && rich_snippet) {
        addRichSnippet(rich_snippet);
    }
    if (typeof twitter_card !== "undefined" && twitter_card) {
        addTwitterCard(twitter_card, currentPage, meta_title_page_number, meta_description_page_number);
    }
    if (typeof opengraph !== "undefined" && opengraph) {
        addOpenGraph(opengraph, currentPage, meta_title_page_number, meta_description_page_number);
    }
    if (typeof meta_title == "undefined" || typeof meta_description == "undefined") {
        return callback.apply(instance, args);
    }

    /**
     * Update Title Pagination
     */

    if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_BEGIN && currentPage > 1) {
        document.querySelectorAll('meta[name^="title"]').forEach(el => {
            el.content = 'Page ' + currentPage + ' | ' + meta_title;
        });
        document.title = 'Page ' + currentPage + ' | ' + meta_title;
    } else if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_BEGIN && currentPage === 1) {
        document.querySelectorAll('meta[name^="title"]').forEach(el => {
            el.content = meta_title;
        });
        document.title = meta_title;
    } else if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_END && currentPage > 1) {
        document.querySelectorAll('meta[name^="title"]').forEach(el => {
            el.content = meta_title + ' | ' + 'Page ' + currentPage;
        });
        document.title = meta_title + ' | ' + 'Page ' + currentPage;
    } else if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_END && currentPage === 1) {
        document.querySelectorAll('meta[name^="title"]').forEach(el => {
            el.content = meta_title;
        });
        document.title = meta_title;
    } else if (meta_title_page_number === PAGE_NUMBER_POSITION_AT_BEGIN_WITH_FIRST || meta_title_page_number === PAGE_NUMBER_POSITION_AT_END_WITH_FIRST) {
        document.querySelectorAll('meta[name^="title"]').forEach(el => {
            el.content = meta_title.replace(/Page \d+/, 'Page ' + currentPage);
        });
        document.title = meta_title.replace(/Page \d+/, 'Page ' + currentPage);
    }

    /**
     * Update Description Pagination
     */

    if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_BEGIN && currentPage > 1) {
        document.querySelectorAll('meta[name^="description"]').forEach(el => {
            el.content = 'Page ' + currentPage + ' | ' + meta_description;
        });
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_BEGIN && currentPage === 1) {
        document.querySelectorAll('meta[name^="description"]').forEach(el => {
            el.content = meta_description;
        });
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_END && currentPage > 1) {
        document.querySelectorAll('meta[name^="description"]').forEach(el => {
            el.content = meta_description + ' | ' + 'Page ' + currentPage;
        });
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_END && currentPage === 1) {
        document.querySelectorAll('meta[name^="description"]').forEach(el => {
            el.content = meta_description;
        });
    } else if (meta_description_page_number === PAGE_NUMBER_POSITION_AT_BEGIN_WITH_FIRST || meta_description_page_number === PAGE_NUMBER_POSITION_AT_END_WITH_FIRST) {
        document.querySelectorAll('meta[name^="description"]').forEach(el => {
            el.content = meta_description.replace(/Page \d+/, 'Page ' + currentPage);
        });
    }

    return callback.apply(instance, args);

};

const containerProps = (args, callback, instance) => {

    const {
        cmsPage: {
            rich_snippet,
            twitter_card,
            opengraph
        } = {}
    } = instance.props;

    if (typeof rich_snippet !== "undefined" && rich_snippet) {
        addRichSnippet(rich_snippet);
    }
    if (typeof twitter_card !== "undefined" && twitter_card) {
        addTwitterCard(twitter_card);
    }
    if (typeof opengraph !== "undefined" && opengraph) {
        addOpenGraph(opengraph);
    }

    return callback.apply(instance, args);

};
const renderProductName = (args, callback, instance) => {
    const {product: {title_h1}} = instance.props;
    if (title_h1) {
        return <h1 itemProp="name" className="CpPp-Title">{title_h1}</h1>
    }
    return callback.apply(instance, args);

};
const renderCategoryName = (args, callback, instance) => {
    const {category: {title_h1}} = instance.props;
    if (title_h1) {
        return <h1 itemProp="name" className="CpPp-Title">{title_h1}</h1>
    }
    return callback.apply(instance, args);

};

export default {
    'Route/ProductPage/Container/mapStateToProps': {
        'function': mapStateToPropsProductPage
    },
    'Route/CategoryPage/Container/mapStateToProps': {
        'function': mapStateToPropsCategoryPage
    },
    'Route/CmsPage/Container': {
        'member-function': {
            containerProps: containerProps
        }
    },
    'Component/Product/Component': {
        'member-function': {
            renderName: renderProductName
        }
    },
    'Component/CategoryDetails/Component': {
        'member-function': {
            renderCategoryName: renderCategoryName
        }
    },

};
