import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    MyAccountNewsletterSubscriptionContainer as SourceMyAccountNewsletterSubscriptionContainer,
} from 'SourceComponent/MyAccountNewsletterSubscription/MyAccountNewsletterSubscription.container';

import { RootState } from 'Util/Store/Store.type'
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

import { Dispatch } from 'redux'
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MyAccountNewsletterSubscriptionContainer extends SourceMyAccountNewsletterSubscriptionContainer {
    setSubscriptionStatus(): void {
        this.setState((state) => ({ isSubscriptionSelected: !state.isSubscriptionSelected }));
        //@ts-ignore
        document.getElementById('updateSubscription').dispatchEvent(new MouseEvent("click",{bubbles: true, cancellable: true}));
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountNewsletterSubscriptionContainer);
