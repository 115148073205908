import FeedBackTab from '../component/FeedBackTab';
import {getStaticReducers} from "./StoreReducer.plugin";

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;

const BEFORE_ITEMS_TYPE = (originalMember) => {
    // const maxPosition = Math.max(
    //     // originalMember.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    // );
    const maxPosition = Math.max.apply(
        Math, originalMember.filter(route => route.position <= MAX_POSITION
        ).map(function (route) {
            return route.position;
        })
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...originalMember,
        {
            component: <FeedBackTab contactUs={false} />,
            position: maxPosition + INCREASED_POSITION
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE
        }
    }
};
