/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';

import './ThumbsDownIcon.style';

export class ThumbsDownIcon extends PureComponent {
    render() {
        return (
            <svg className='thumbs-down-icon' width="22" height="22" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 0.999873H18.67C19.236 0.989864 19.7859 1.18801 20.2154 1.55669C20.645 1.92538 20.9242 2.43893 21 2.99987V9.99987C20.9242 10.5608 20.645 11.0744 20.2154 11.4431C19.7859 11.8117 19.236 12.0099 18.67 11.9999H16M9.00003 13.9999V17.9999C9.00003 18.7955 9.3161 19.5586 9.87871 20.1212C10.4413 20.6838 11.2044 20.9999 12 20.9999L16 11.9999V0.999873H4.72003C4.2377 0.99442 3.76965 1.16347 3.40212 1.47587C3.0346 1.78827 2.79235 2.22297 2.72003 2.69987L1.34003 11.6999C1.29652 11.9865 1.31586 12.2792 1.39669 12.5576C1.47753 12.8361 1.61793 13.0936 1.80817 13.3124C1.99842 13.5311 2.23395 13.7059 2.49846 13.8247C2.76297 13.9434 3.05012 14.0032 3.34003 13.9999H9.00003Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        );
    }
}

export default ThumbsDownIcon;
