/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import AdvancedReviewQuery from './../../query/AdvancedReview';
import {showNotification} from 'Store/Notification/Notification.action';
import {fetchQuery} from 'Util/Request/Query';
import {getErrorMessage} from 'Util/Request/Error';
import {showPopup} from "Store/Popup/Popup.action";
import PopUpWithImages from './PopUpWithImages.component';
import Loader from "Component/Loader/Loader.component";
// import ReactDOMServer from "react-dom/server";

let reviewId;

/** @namespace Component/AdvancedReview/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    amSortingOptions: state.ConfigReducer.am_advanced_review_sorting_options,
    device: state.ConfigReducer.device
});

/** @namespace Component/AdvancedReview/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showInfoNotification: (message) => dispatch(showNotification('info', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    showAdvancedReview: (payload) => dispatch(showPopup('AdvancedReviewPopup', payload))
});

/** @namespace Component/AdvancedReview/Container */
export class PopUpWithImagesContainer extends PureComponent {
    // static propTypes = {
    //     showErrorNotification: PropTypes.func.isRequired,
    //     showInfoNotification: PropTypes.func.isRequired,
    //     showSuccessNotification: PropTypes.func.isRequired
    // };

    state = {
        advancedReview: [],
        isLoading: false,
        firstTimeLoad: true
    };

    containerFunctions = {
        // onReviewImageClick: this.onReviewImageClick.bind(this),
        // handlePaginationLinkClick: this.handleClick.bind(this),
        // onSortChange: this.onSortChange.bind(this),
        // handleVote: this.handleVote.bind(this),
        // submitComment: this.submitComment.bind(this)
    };

    componentDidMount() {
        this.requestDownloadable();
    }

    renderReplyLoader(){
        return (
            <div className="reply-loader">
                <Loader isLoading={true}/>
            </div>
        )
    }

    submitComment(e) {
        let reviewId = e.target.getAttribute('review-id');
        let reviewComment = e.target.getAttribute('review-comment');
        // let html = ReactDOMServer.renderToString(this.renderReplyLoader())
        let html = '<div>LOADER HERE</div>';
        let currentReply = document.getElementById('reply-comment-wrapper-' + reviewId);
        currentReply.insertAdjacentHTML('beforeBegin', html);
        // console.clear();
        // console.log(e);
        // console.log(e.target);
        // console.log(e.target.id);
        let xxx = document.getElementsByClassName(e.target.id);
        for (let i = 0; i < xxx.length; i++) {
            // console.log(xxx[i].parentElement);
            // console.log(xxx[i]);
            // console.log(xxx[i].value);
            xxx[i].parentElement.classList.remove("Field_isValid");
            xxx[i].value = '';
        }
        e.target.style.pointerEvents = 'none';
        e.target.style.opacity = '0.25';

        // console.log(reviewComment);

        // document.querySelectorAll('[class*="reply-loader"]').forEach(n => n.click());
        this.requestDownloadable('', reviewComment);
    }

    onSortChange(e) {
        this.requestDownloadable();
    }

    handleVote(e) {
        let count = parseInt(e.target.getAttribute('count'));
        let vote = e.target.getAttribute('type');
        let reviewId = e.target.getAttribute('review-id');
        // console.clear();
        // console.log(e);
        // console.log(e.target.getAttribute('type'));
        // console.log(e.target.getAttribute('review-id'));
        // console.log(e.target.className);
        // console.log(e.target.classList);
        // console.log(e.target.nextSibling);
        // console.log(e.target.previousSibling);
        // e.target.classList.toggle('voted');
        // try {
        //     if (e.target.nextSibling.classList.contains('voted') && !count) {
        //         e.target.nextSibling.classList.toggle('voted');
        //     }
        // }catch (e) {
        //
        // }
        // try {
        //     if (e.target.previousSibling.classList.contains('voted') && !count) {
        //         e.target.previousSibling.classList.toggle('voted');
        //     }
        // }catch (e) {
        //
        // }



        // e.target.nextSibling.classList.toggle('voted');
        // e.target.previousSibling.classList.toggle('voted');
        this.requestDownloadable(reviewId + ';' + vote);
    }

    handleClick({target}) {
        // console.clear();
        // console.log(target);
        // console.log(target.classList.contains('PaginationLink'));
        // console.log(target.getAttribute('aria-current'));
        if (!target.classList.contains('PaginationLink')) {
            return;
        }
        // console.log(window.location.search);
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') ? urlParams.get('page') : 1;
        // console.log(page);
        // const {history: {location}} = this.props;
        // history.push({pathname: window.location.pathname});
        this.setState({firstTimeLoad:false});
        this.requestDownloadable();
    }

    containerProps = () => {

        const {
            isLoading,
            advancedReview
        } = this.state;

        const {
            areDetailsLoaded,
            device,
            isEnabled,
            product,
            showInfoNotification,
            showAdvancedReview,
            reviewPopupData
        } = this.props;

        return {
            isLoading,
            advancedReview,
            areDetailsLoaded,
            device,
            isEnabled,
            product,
            showInfoNotification,
            showAdvancedReview,
            reviewPopupData
        };
    };

    requestDownloadable() {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') ? urlParams.get('page') : 1;
        const {amSortingOptions, reviewPopupData: {product_id}} = this.props;

        let {product:{id} = false} = this.props;

        if (typeof id === 'undefined') {
            id = parseInt(product_id);
        }

        const {
            advancedReview: {
                config: {
                    filtering_options
                } = {}
            }
        } = this.state;

        let sort;
        try {
            sort = document.getElementById('category-sort').value;
        } catch (e) {
            let firstLoadSorting = amSortingOptions.split(',');
            // console.log(amSortingOptions);
            // console.log(firstLoadSorting[0]);
            sort = 'DESC ' + firstLoadSorting[0];
        }
        let filter = {};
        try {
            filtering_options.map((option, i) => (
                    filter = {
                        ...filter,
                        [option.id]:document.getElementById(`option-${ option.id }`).checked
                    }
                    // filter.push(
                    //     {
                    //         [option.id]:document.getElementById(`option-${ option.id }`).checked
                    //     }
                    // )
                    // console.log(`option-${ option.id }`)
                )
            )
            // console.log(filter);
            filter = JSON.stringify(filter);
        } catch (e) {
            // console.log(e);
            filter = '';
        }
        // console.log(filter);
        this.setState({sort: sort});
        // console.log(this.state);
        // console.log(this.props);
        // console.clear();
        // console.log(id, page, '', '', '', sort, filter, review_id);
        fetchQuery(
            // AdvancedReviewQuery.getAdvancedReview(id, page, '', '', '', sort, filter, review_id)
            AdvancedReviewQuery.getAdvancedReview(
                id,
                page,
                '',
                '',
                '',
                sort,
                filter,
                false,
                true,
                false,
                false,
                false
            )
        ).then(
            /** @namespace Component/AdvancedReview/Container/requestDownloadable/success */
            (
                /** @namespace Component/AdvancedReview/Container/AdvancedReviewContainer/requestDownloadable/fetchQuery/then */
                async (data) => {

                    const {amastyReview: {amasty_review}} = data;
                    // console.log(amasty_review);
                    let advancedReview = JSON.parse(amasty_review);
                    // console.log(advancedReview);
                    this.setState({isLoading: false, advancedReview: advancedReview});
                    // scrollToTop();
                    // document.querySelectorAll('[class*="reply-loader"]').forEach(n => n.remove());

                }),
            /** @namespace Component/AdvancedReview/Container/AdvancedReviewContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                // console.log(err);
                this.setState({isLoading: false});
            }
        );
    }



    render() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        try {
            document.querySelectorAll('[id^=widget-product-id-],[id^=widget-review-id-]').forEach(n => n.classList.remove('loading'));
        }catch (e) {

        }

        // console.log('aaa');
        // return 'asasa'
        return (
            <PopUpWithImages
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpWithImagesContainer);
