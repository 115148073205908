/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
// import PayNowQuery from './../../query/PayNow.query';
import {showNotification} from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import {fetchMutation} from '@scandipwa/scandipwa/src/util/Request/Mutation';
import {fetchQuery} from '@scandipwa/scandipwa/src/util/Request/Query';
import {getErrorMessage} from '@scandipwa/scandipwa/src/util/Request/Error';
import PaymentSystemQuery from "./../../query/PaymentSystem.query";
import PayNow from './PayNow.component';

let orderIncrementId = '';

/** @namespace Component/PayNow/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Component/PayNow/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('info', message))
});


/* Start client-defined Callback Handler Functions */
function onOpenHandler (response) {
    // alert('Payments Modal is Opened');
    console.log('onOpenHandler Response', response);
}

function onCloseHandler (response) {
    // alert('Payments Modal is Closed');
    console.clear();
    console.log(orderIncrementId);
    console.log('onCloseHandler Response', response);
}

function onPaymentSuccessHandler (response) {
    // alert('Payment Success');
    console.log('Payment Success Response', response);
}

function onPaymentFailureHandler (response) {
    // alert('Payment Failure');

    const {paymentId, status} = response;
    console.log('Payment Failure Response', response);
    console.log(incrementId);
}
/* End client-defined Callback Handler Functions */

/** @namespace Component/PayNow/Container */
export class PayNowContainer extends PureComponent {

    containerFunctions = {
        payNow: this._payNow.bind(this),
    };

    _payNow (e) {

    e.stopPropagation();
    console.clear();
    console.log(e);
    console.log(window);
    console.log(this.state);

        const {paymentLink, paymentMethod, incrementId} = this.state;

        orderIncrementId = incrementId;

        switch (paymentMethod) {
            case 'razorpay':
                window.open(paymentLink, '_blank');
            break;
            case 'instamojo':
                window.Instamojo.configure({
                    handlers: {
                        onOpen: async (response) => {
                            // console.clear();
                            console.log('Payment Opened');
                            console.log(response);
                            console.log(orderIncrementId);
                            await fetchMutation(PaymentSystemQuery.getPayNow(orderIncrementId));
                            // console.log('await completed');
                        },
                        onClose: (response) => {
                            // console.clear();
                            console.log('Payment Closed');
                            console.log(response);
                            console.log(orderIncrementId);
                        },
                        onSuccess: async (response) => {
                            // console.clear();
                            console.log('Payment Success');
                            console.log(response);
                            console.log(orderIncrementId);
                            await fetchMutation(PaymentSystemQuery.getPayNow(orderIncrementId));
                            console.log('await completed');
                        },
                        onFailure: async (response) => {
                            // console.clear();
                            console.log('Payment Failure');
                            console.log(response);
                            console.log(orderIncrementId);
                            await fetchMutation(PaymentSystemQuery.getPayNow(orderIncrementId));
                            console.log('await completed');
                        },
                    }
                });
                window.Instamojo.open(paymentLink);
            break;
        }

    // if (paymentMethod === 'instamojo') {
    //     window.Instamojo.configure({
    //         handlers: {
    //             onOpen: async (response) => {
    //                 // console.clear();
    //                 console.log('Payment Opened');
    //                 console.log(response);
    //                 console.log(orderIncrementId);
    //                 await fetchMutation(PaymentSystemQuery.getPayNow(orderIncrementId));
    //                 // console.log('await completed');
    //             },
    //             onClose: (response) => {
    //                 // console.clear();
    //                 console.log('Payment Closed');
    //                 console.log(response);
    //                 console.log(orderIncrementId);
    //             },
    //             onSuccess: async (response) => {
    //                 // console.clear();
    //                 console.log('Payment Success');
    //                 console.log(response);
    //                 console.log(orderIncrementId);
    //                 await fetchMutation(PaymentSystemQuery.getPayNow(orderIncrementId));
    //                 console.log('await completed');
    //             },
    //             onFailure: async (response) => {
    //                 // console.clear();
    //                 console.log('Payment Failure');
    //                 console.log(response);
    //                 console.log(orderIncrementId);
    //                 await fetchMutation(PaymentSystemQuery.getPayNow(orderIncrementId));
    //                 console.log('await completed');
    //             },
    //         }
    //     });
    //     window.Instamojo.open(paymentLink);
    // }

}

    componentDidMount() {

    }

    containerProps = () => {

        const {payment_link, payment_method, increment_id} = this.props;

        this.setState({
            paymentLink: payment_link,
            paymentMethod: payment_method,
            incrementId: increment_id
        })

        return {
            payment_link, payment_method
        };
    };

    requestDownloadable(email, telephone, orderNumber) {
        const {showSuccessNotification, showErrorNotification} = this.props;

        this.setState({isLoading: true});

        fetchQuery(
            PayNowQuery.getOrder(telephone, email, orderNumber)
        ).then(
            /** @namespace Component/PayNow/Container/requestDownloadable/success */
            (
                /** @namespace Component/PayNow/Container/PayNowContainer/requestDownloadable/fetchQuery/then */
                async (data) => {
                    const {magenearPayNow: {magenear_trackorder}} = data;
                    let items = JSON.parse(magenear_trackorder);
                    if (magenear_trackorder === null) {
                        this.setState({isLoading: false, directTrack: false});
                        showErrorNotification('Could not find order matching that email and/or mobile number and order id.');
                    } else {
                        this.setState({items: items.items, orderInfo: items.orderInfo, isLoading: false});
                    }
                }),
            /** @namespace Component/PayNow/Container/PayNowContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                showErrorNotification(getErrorMessage(err));
                this.setState({isLoading: false});
            }
        );
    }

    render() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        return (
            <PayNow
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayNowContainer);
