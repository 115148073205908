/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { fetchMutation } from 'Util/Request/Mutation';
import { fetchQuery } from 'Util/Request/Query';
import PaymentSystemQuery from "../query/PaymentSystem.query";

const requestOrderDetails = async (args, callback, instance) => {

    await fetchMutation(PaymentSystemQuery.getPayNow(window.location.pathname));
    return callback(...args);

};

export default {
    'Component/MyAccountOrder/Container': {
        'member-function': {
            requestOrderDetails
        }
    }
};
