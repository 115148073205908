import {withStoreRegex} from "Component/Router/Router.component";
import {Route} from 'react-router-dom';
import getStore from "Util/Store";
import {
    lazy,
} from 'react';

export const StoreLocatorPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ '../route/StoreLocatorPage'));

export const INCREASED_POSITION = 1;

const SWITCH_ITEMS_TYPE = (originalMember) => {

    const state = getStore().getState();
    let {
        magenear_storelocator_general_enabled
    } = state.ConfigReducer;

    if (magenear_storelocator_general_enabled === "0") {
        return originalMember;
    }

    const {
        mpgooglemaps_map_setting_api_key,
    } = getStore().getState().ConfigReducer;

    // console.log(mpgooglemaps_map_setting_api_key);
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://maps.googleapis.com/maps/api/js?key=" + mpgooglemaps_map_setting_api_key + "&language=en";
    // script.onload = () => this.scriptLoaded();

    // "https://maps.googleapis.com/maps/api/js?key=<?= /** @noEscape */ $mapApi ?>&language=en"

    //For head
    document.head.appendChild(script);

    const maxPosition = Math.max.apply(
        Math, originalMember.filter(route => route.name !== 'URL_REWRITES'
        ).map(function (route) {
            return route.position;
        }));

    return [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/store-locator') } render={ (props) => <StoreLocatorPage { ...props } /> } />,
            position: maxPosition + INCREASED_POSITION,
            name: 'STORE_LOCATOR'
        }
    ];
};

const stateMap = (member) => {

    member['STORE_LOCATOR'] = {
            title: true,
            back: true
    };
    return member;
};

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: SWITCH_ITEMS_TYPE
        }
    },
    'Component/Header/Component': {
        'member-property': {
            stateMap: stateMap
        }
    }
};
