import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
} from 'SourceComponent/ExpandableContent/ExpandableContent.container';
import ExpandableContent from './ExpandableContent.component';
import { RootState } from 'Util/Store/Store.type'
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

import { Dispatch } from 'redux'
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(),
    // TODO extend mapDispatchToProps
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableContent);
