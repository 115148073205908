import ClickOutside from "Component/ClickOutside";
import getStore from 'Util/Store';


const handleClickOutside = (args, callback, instance) => {

    const { id } = instance.props;

    if (id === 'REVIEW_POPUP_ID' || id === 'AdvancedReviewPopup') {
        return;
    }

    return callback(...args);

};

const renderContent = (args, callback, instance) => {
    const { id, children, contentMix, productName } = instance.props;
    // console.clear();
    // console.log(id);
    if (id !== 'REVIEW_POPUP_ID') {
        return callback(...args);
    }

    const isVisible = instance.getIsVisible();
    // console.clear();
    const state = getStore().getState();
    // console.log(state);
    // console.log(children);
    // console.log(contentMix);
    // console.log(args);
    // console.log(instance.props);
    if (!isVisible) {
        return null;
    }

    return (
        <ClickOutside onClick={ instance.handleClickOutside }>
            <div data-name={`You are reviewing: ${productName}`} block="Popup" elem="Content" mix={ contentMix }>
                <header block="Popup" elem="Header">
                    { instance.renderTitle() }
                    { instance.renderCloseButton() }
                </header>
                { instance.renderNotifications() }
                { children }
            </div>
            <div className="Popup-Back"></div>
        </ClickOutside>
    );

    return callback(...args);

};

export default {
    'Component/Popup/Component': {
        'member-function': {
            handleClickOutside,
            renderContent
        }
    }
};
