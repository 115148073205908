const _getLabel = (args, callback, instance) => {
    const {source = {}} = instance.props;
    if (source !== 'knowledgeBase') {
        return callback(...args);
    }

    const { id, label: pureLabel } = args[0];

    // eslint-disable-next-line fp/no-let
    let [label] = pureLabel.split(' ');

    label = label.charAt(0).toUpperCase() + label.slice(1);

    switch (id) {
        case 'position':
            return {
                asc: __('Best match')
            };
        case 'created_at':
            return {
                asc: __('%s: Old to Newest', label),
                desc: __('%s: Newest to Old', label)
            };
        case 'rating':
            return {
                asc: __('%s: Low to High', label),
                desc: __('%s: High to Low', label)
            };
        case 'votes_sum':
            return {
                asc: __('%s: Low to High', label),
                desc: __('%s: High to Low', label)
            };
        default:
            return {
                asc: __('%s: Ascending', label),
                desc: __('%s: Descending', label)
            };
    }
};

export default {
    'Component/CategorySort/Container': {
        'member-function': {
            _getLabel: _getLabel
        }
    }
};
