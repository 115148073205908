/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent, Suspense } from 'react';

import Loader from './../../component/Loader';
import ProductListPage from './../../component/ProductListPage';
import { ReactElement } from 'Type/Common.type';
import { scrollToTop } from 'Util/Browser';
import { noopFn } from 'Util/Common';
import CSS from 'Util/CSS';
import { IndexedProduct } from './../../util/Product/Product.type';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import { OBSERVER_THRESHOLD } from './ProductList.config';
import { PageProps, ProductListComponentProps } from './ProductList.type';

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination as SwiperPagination, FreeMode} from "swiper";
// Import Swiper styles
// import 'swiper/swiper-bundle.css';
import Decoration from "Component/Decoration/Decoration.component";
import ChevronIcon from "Component/ChevronIcon";
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Link from "Component/Link/Link.component";
import history from 'Util/History';
import Html from "Component/Html";
import ProductCard from 'Component/ProductCard';

import './ProductList.style';
import {Feedback} from "@material-ui/icons";

export const Pagination = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays-misc" */ 'Component/Pagination'
));

/**
 * List of category products
 * @class ProductList
 * @namespace Component/ProductList/Component
 */
export class ProductListComponent extends PureComponent<ProductListComponentProps> {
    static defaultProps: Partial<ProductListComponentProps> = {
        mix: {},
        title: '',
        isInfiniteLoaderEnabled: false,
        isPaginationEnabled: false,
        selectedFilters: {},
        isLoading: false,
        updatePage: noopFn,
        totalPages: 1,
        loadPage: noopFn,
        loadPrevPage: noopFn,
        currentPage: 1,
        isShowLoading: false,
        isVisible: true,
        isWidget: false,
    };

    observer: IntersectionObserver | null = null;

    nodes: Record<string, HTMLElement | null> = {};

    observedNodes: HTMLElement[] = [];

    pagesIntersecting: number[] = [];

    componentDidUpdate(prevProps: ProductListComponentProps): void {
        const { isWidget, currentPage, device } = this.props;
        const { currentPage: prevCurrentPage } = prevProps;

        // Scroll up on page change, ignore widgets
        if (prevCurrentPage !== currentPage && !isWidget && !device.isMobile) {
            scrollToTop({ behavior: 'smooth' });
        }

        const { isInfiniteLoaderEnabled } = this.props;

        if (isInfiniteLoaderEnabled) {
            this.observePageChange();
        }
    }

    componentWillUnmount(): void {
        if (this.observer && this.observer.disconnect) {
            this.observer.disconnect();
        }

        this.observer = null;
    }

    observePageChange(): void {
        const { updatePage, isLoading } = this.props;

        if (isLoading) {
            this.pagesIntersecting = [];
        }

        if (!this.observer && 'IntersectionObserver' in window) {
            const threshold = this._getThreshold();

            this.observer = new IntersectionObserver((entries) => {
                const { currentPage } = this.props;

                entries.forEach(({ target, isIntersecting }) => {
                    const page = +(Object.keys(this.nodes).find((node) => this.nodes[ node ] === target) || 0);
                    const index = this.pagesIntersecting.indexOf(page);

                    if (isIntersecting && index === -1) {
                        this.pagesIntersecting.push(page);
                    }

                    if (!isIntersecting && index > -1) {
                        this.pagesIntersecting.splice(index, 1);
                    }
                });

                const minPage = Math.min(...this.pagesIntersecting);

                if (minPage < Infinity && minPage !== currentPage) {
                    updatePage(minPage);
                }
            }, {
                rootMargin: '0px',
                threshold,
            });
        }

        this.updateObserver();
    }

    updateObserver(): void {
        const currentNodes = Object.values(this.nodes);

        if (!this.observer || currentNodes.length <= 0) {
            return;
        }

        currentNodes.forEach((node) => {
            if (node && !this.observedNodes.includes(node)) {
                this.observer?.observe(node);
                this.observedNodes.push(node);
            }
        });

        this.observedNodes = this.observedNodes.reduce((acc: HTMLElement[], node) => {
            if (!currentNodes.includes(node)) {
                this.observer?.unobserve(node);
            } else {
                acc.push(node);
            }

            return acc;
        }, []);
    }

    _getThreshold(): number[] {
        const hundredPercent = 100;

        return Array.from(
            { length: (hundredPercent / OBSERVER_THRESHOLD) + 1 },
            (_, i) => i * (OBSERVER_THRESHOLD / hundredPercent),
        );
    }

    renderLoadButton(): ReactElement {
        const {
            isShowLoading,
            isInfiniteLoaderEnabled,
            loadPrevPage,
        } = this.props;

        if (!isShowLoading || !isInfiniteLoaderEnabled) {
            return null;
        }

        return (
            <div
              block="ProductList"
              elem="LoadButton"
              role="button"
              tabIndex={ 0 }
              onKeyUp={ loadPrevPage }
              onClick={ loadPrevPage }
            >
                { __('Load previous') }
            </div>
        );
    }

    renderNoProducts(): ReactElement {
        const {selectedFilters, totalPages} = this.props;
        let noProducts;

        if (Object.keys(selectedFilters).length && !totalPages) {
            noProducts = __('Sorry, there are no products. Try using different filters');
        }else {
            noProducts = __('Sorry, there are no products.');
        }

        return (
            <div block="ProductList">
                <div
                    block="ProductList"
                    elem="ProductsMissing"
                >
                    <svg width="90" height="70" viewBox="0 0 90 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.305 -0.0195312H4.19375C2.33389 -0.0195312 0.845703 1.46864 0.845703 3.32851V24.4398C0.845703 26.2996 2.33387 27.7878 4.19375 27.7878H25.305C27.1649 27.7878 28.6531 26.2997 28.6531 24.4398V3.42151C28.7458 1.56099 27.1649 -0.0195312 25.305 -0.0195312ZM21.957 21.0917H7.63446V6.76989H21.9563L21.957 21.0917Z" fill="#A10101"/>
                        <path d="M43.2542 6.76972H85.8487C87.7086 6.76972 89.1968 5.28155 89.1968 3.42167C89.1968 1.56181 87.7086 0.0736304 85.8487 0.0736304H43.2542C41.3943 0.0736304 39.9062 1.5618 39.9062 3.42167C39.9062 5.28153 41.3943 6.76972 43.2542 6.76972Z" fill="#A10101"/>
                        <path d="M43.2542 27.881H74.1306C75.9904 27.881 77.4786 26.3928 77.4786 24.5329C77.4786 22.6731 75.9905 21.0921 74.1306 21.0921H43.2542C41.3943 21.0921 39.9062 22.5802 39.9062 24.4401C39.9062 26.3 41.3943 27.881 43.2542 27.881Z" fill="#A10101"/>
                        <path d="M25.305 42.2032H4.19375C2.33389 42.2032 0.845703 43.6913 0.845703 45.5512V66.6625C0.845703 68.5223 2.33387 70.0105 4.19375 70.0105H25.305C27.1649 70.0105 28.6531 68.5224 28.6531 66.6625V45.5512C28.7458 43.6907 27.1649 42.2032 25.305 42.2032ZM21.957 63.3144H7.63446V48.9926H21.9563L21.957 63.3144Z" fill="#A10101"/>
                        <path d="M85.8487 42.2032H43.2542C41.3943 42.2032 39.9062 43.6913 39.9062 45.5512C39.9062 47.4111 41.3943 48.8993 43.2542 48.8993H85.8487C87.7086 48.8993 89.1968 47.4111 89.1968 45.5512C89.1968 43.6907 87.7086 42.2032 85.8487 42.2032Z" fill="#A10101"/>
                        <path d="M74.1306 63.3144H43.2542C41.3943 63.3144 39.9062 64.8026 39.9062 66.6625C39.9062 68.5223 41.3943 70.0105 43.2542 70.0105H74.1306C75.9904 70.0105 77.4786 68.5224 77.4786 66.6625C77.4786 64.8026 75.9905 63.3144 74.1306 63.3144Z" fill="#A10101"/>
                        <path d="M25.305 -0.0195312H4.19375C2.33389 -0.0195312 0.845703 1.46864 0.845703 3.32851V24.4398C0.845703 26.2996 2.33387 27.7878 4.19375 27.7878H25.305C27.1649 27.7878 28.6531 26.2997 28.6531 24.4398V3.42151C28.7458 1.56099 27.1649 -0.0195312 25.305 -0.0195312ZM21.957 21.0917H7.63446V6.76989H21.9563L21.957 21.0917Z" stroke="#FBFBFB" stroke-width="2"/>
                        <path d="M43.2542 6.76972H85.8487C87.7086 6.76972 89.1968 5.28155 89.1968 3.42167C89.1968 1.56181 87.7086 0.0736304 85.8487 0.0736304H43.2542C41.3943 0.0736304 39.9062 1.5618 39.9062 3.42167C39.9062 5.28153 41.3943 6.76972 43.2542 6.76972Z" stroke="#FBFBFB" stroke-width="2"/>
                        <path d="M43.2542 27.881H74.1306C75.9904 27.881 77.4786 26.3928 77.4786 24.5329C77.4786 22.6731 75.9905 21.0921 74.1306 21.0921H43.2542C41.3943 21.0921 39.9062 22.5802 39.9062 24.4401C39.9062 26.3 41.3943 27.881 43.2542 27.881Z" stroke="#FBFBFB" stroke-width="2"/>
                        <path d="M25.305 42.2032H4.19375C2.33389 42.2032 0.845703 43.6913 0.845703 45.5512V66.6625C0.845703 68.5223 2.33387 70.0105 4.19375 70.0105H25.305C27.1649 70.0105 28.6531 68.5224 28.6531 66.6625V45.5512C28.7458 43.6907 27.1649 42.2032 25.305 42.2032ZM21.957 63.3144H7.63446V48.9926H21.9563L21.957 63.3144Z" stroke="#FBFBFB" stroke-width="2"/>
                        <path d="M85.8487 42.2032H43.2542C41.3943 42.2032 39.9062 43.6913 39.9062 45.5512C39.9062 47.4111 41.3943 48.8993 43.2542 48.8993H85.8487C87.7086 48.8993 89.1968 47.4111 89.1968 45.5512C89.1968 43.6907 87.7086 42.2032 85.8487 42.2032Z" stroke="#FBFBFB" stroke-width="2"/>
                        <path d="M74.1306 63.3144H43.2542C41.3943 63.3144 39.9062 64.8026 39.9062 66.6625C39.9062 68.5223 41.3943 70.0105 43.2542 70.0105H74.1306C75.9904 70.0105 77.4786 68.5224 77.4786 66.6625C77.4786 64.8026 75.9905 63.3144 74.1306 63.3144Z" stroke="#FBFBFB" stroke-width="2"/>
                    </svg>

                    <p>{ __('No Results Found') }</p>
                    <p>{ noProducts }</p>
                </div>
            </div>
        );
    }

    renderPages(): ReactElement {
        const {
            pages,
            isVisible,
            isLoading,
            isInfiniteLoaderEnabled,
            isWidget
        } = this.props;

        if (isLoading) {
            return this.renderPage();
        }

        if (isWidget) {
            let items = pages[1];
            return this.renderItemsSlider(items, 2, 'Products-Widget');
        }

        const pageRenders = Object.entries(pages).map(this.renderProductPage.bind(this));

        if (isVisible && isInfiniteLoaderEnabled) { // add placeholders to the end of pages if needed
            const key = Math.max(...(Object.keys(pages) as unknown as number[])) + 1; // the key should match next page key

            pageRenders.push(this.renderPage({ key }));
        }

        return pageRenders;
    }

    renderItemsSlider(items: any[], mobileSlidesPerView = 4, wrapper = ''): ReactElement {
        const { device: {isMobile}} = this.props;
        let urlRewrite;
        let categoryUrl;
        let arrowRight = <Html content='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 12H19" stroke="#6B2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 5L19 12L12 19" stroke="#6B2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' />;

        return (
            <>
                {/*<Decoration direction="left" angle="topLeft" type="type1"/>*/}
                <Swiper
                    className={(items.length > mobileSlidesPerView) ? wrapper + ' WithPager' : wrapper}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    // modules={[Autoplay, Navigation, SwiperPagination]}
                    modules={[Autoplay, Navigation]}
                    spaceBetween={(isMobile) ? 12:24}
                    breakpoints={{
                        // 320: {
                        //     slidesPerView: mobileSlidesPerView,
                        // },
                        0: {
                            slidesPerView: 1,
                        },
                        375: {
                            slidesPerView: 2,
                        },
                        580: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        811: {
                            slidesPerView: 3,
                        },
                        1180: {
                            slidesPerView: 3,
                        },
                        1440: {
                            slidesPerView: 4,
                        },
                    }}
                >

                    {
                        items.map((item, index) => {

                            urlRewrite = item.url_rewrites;

                            return <SwiperSlide>
                                <ProductCard
                                    block="ProductLinks"
                                    elem="Card"
                                    product={item}
                                    key={index}
                                />
                            </SwiperSlide>
                        })
                    }
                </Swiper>
                {/*{*/}
                {/*    //@ts-ignore*/}
                {/*    urlRewrite?.map((item: { url: string; }, index: any) => {*/}
                {/*        categoryUrl = item.url.split('/');*/}
                {/*        if (categoryUrl.length === 2) {*/}
                {/*            categoryUrl = categoryUrl[0];*/}
                {/*            this.setState({currentUrl: categoryUrl});*/}
                {/*        }*/}
                {/*    })*/}
                {/*}*/}
                {/*<Link*/}
                {/*    to={categoryUrl}*/}
                {/*    className="Unique-Link"*/}
                {/*    // id={ item_id }*/}
                {/*    // onMouseEnter={ handleCategoryHover }*/}
                {/*    // onMouseLeave={ handleLinkLeave }*/}
                {/*    // mods={ { isHovered } }*/}
                {/*    onClick={this.onItemClick.bind(this)}*/}
                {/*>*/}
                {/*    See All {*/}
                {/*    (isMobile)*/}
                {/*        ?*/}
                {/*        arrowRight*/}
                {/*        :*/}
                {/*        <ChevronIcon direction={Directions.RIGHT}/>*/}

                {/*}*/}
                {/*</Link>*/}
            </>
        )
    }

    onItemClick() {
        // @ts-ignore
        const {currentUrl} = this.state;
        history.push(currentUrl);
        history.replace(currentUrl);
    }

    _processProps(props: Partial<PageProps>): Partial<PageProps> {
        const { isInfiniteLoaderEnabled } = this.props;

        if (isInfiniteLoaderEnabled) {
            return props;
        }

        // there must be no more then one page per screen
        // if the "isInfiniteLoaderEnabled" is false
        const { key, ...restProps } = props;

        return { ...restProps, key: 0 };
    }

    renderPage(props: Partial<PageProps> = {}): ReactElement {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            mix,
            isPlp,
        } = this.props;
        const {
            items = [],
            // keys = [],
            pageNumber = 0,
            selectedFilters = {},
            wrapperRef,
            key,
        } = this._processProps(props);

        return (
            <ProductListPage
              key={ key }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              items={ items }
              pageNumber={ pageNumber }
              selectedFilters={ selectedFilters }
              wrapperRef={ wrapperRef }
              isPlp={ isPlp }
            />
        );
    }

    renderProductPage([k, items = []]: [k: string, items: IndexedProduct[]]): ReactElement {
        const { selectedFilters } = this.props;
        const key = Number(k);

        const pageNumber = key;

        return this.renderPage({
            selectedFilters,
            pageNumber,
            items,
            key,
            wrapperRef: (node: HTMLElement | null) => {
                this.nodes[ pageNumber ] = node;
            },
        });
    }

    renderPagination(): ReactElement {
        const {
            isLoading,
            totalPages,
            isPaginationEnabled,
        } = this.props;

        if (!isPaginationEnabled || totalPages === 1) {
            return null;
        }

        return (
            <Suspense fallback={ <div /> }>
                <Pagination
                  isLoading={ isLoading }
                  totalPages={ totalPages }
                />
            </Suspense>
        );
    }

    renderTitle(): ReactElement {
        const { title, isWidget } = this.props;

        if (!title) {
            return null;
        }

        if (isWidget) {
            return (
                <h1 className="CmsPage-Heading">{ title }</h1>
            );
        }

        return (
            <h2>{ title }</h2>
        );
    }

    renderLayoutChangingLoader(): ReactElement {
        const {
            productListLoaderRef,
        } = this.props;

        const width = productListLoaderRef?.current?.offsetWidth;

        if (productListLoaderRef?.current) {
            CSS.setVariable(productListLoaderRef, 'product-list-loader-width', `${width}px`);
        }

        return (
            <div block="ProductList" elem="LayoutChangingLoader" ref={ productListLoaderRef }>
                <div block="ProductList" elem="LayoutChangingLoaderWrapper">
                    <Loader />
                </div>
            </div>
        );
    }

    render(): ReactElement {
        const {
            totalPages,
            isLoading,
            mix,
        } = this.props;

        if (!isLoading && totalPages === 0) {
            setLoadedFlag();

            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
            >
                { this.renderLayoutChangingLoader() }
                { this.renderTitle() }
                { this.renderLoadButton() }
                { this.renderPages() }
                { this.renderPagination() }
            </div>
        );
    }
}

export default ProductListComponent;
