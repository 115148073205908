import {
    FieldComponent as SourceFieldComponent,
} from 'SourceComponent/Field/Field.component';
import { ReactElement } from 'Type/Common.type';
import { FieldType } from './Field.config';
import './Field.style';

export class FieldComponent extends SourceFieldComponent {
    render(): ReactElement {
        const {
            attr: {name},
            type, validationResponse, mix,
        } = this.props;
        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);
        const { mods: { hasError = false } = {} } = mix;

        return (
            <div block="Field" elem="Wrapper" mods={ { type } } mix={{block:name}}>
                <div
                    block="Field"
                    mods={ {
                        type,
                        isValid: !hasError && validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                    } }
                    mix={ mix }
                >
                    { type !== FieldType.CHECKBOX && type !== FieldType.RADIO && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
};

export default FieldComponent;
