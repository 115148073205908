import {withStoreRegex} from "Component/Router/Router.component";
import {Route} from 'react-router-dom';
import {
    lazy,
} from 'react';

import getStore from 'Util/Store';
import {renderColumns} from "./ComponentFooterComponent.plugin";

export const KbCategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ '../route/KbCategoryPage'));

export const INCREASED_POSITION = 1;

const SWITCH_ITEMS_TYPE = (originalMember) => {

    const state = getStore().getState();
    let {
        kb_general_base_url
    } = state.ConfigReducer;

    // console.log(kb_general_base_url);

    if (typeof kb_general_base_url === 'undefined') {
        kb_general_base_url = 'knowledge-base';
    }

    // return originalMember;
    const maxPosition = Math.max.apply(
        Math, originalMember.filter(route => route.name !== 'URL_REWRITES'
        ).map(function (route) {
            return route.position;
        }));

    return [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/' + kb_general_base_url) } render={ (props) => <KbCategoryPage { ...props } /> } />,
            position: maxPosition + INCREASED_POSITION,
            name: 'KB_CATEGORIES'
        }
    ];
};

const stateMap = (member) => {

    member['KB_CATEGORIES'] = {
            title: true,
            back: true
    };
    return member;
};

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: SWITCH_ITEMS_TYPE
        }
    },
    'Component/Header/Component': {
        'member-property': {
            stateMap: stateMap
        }
    }
};
