/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from "react";
import { ReactElement } from "Type/Common.type";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { unstable_ClassNameGenerator as ClassNameGenerator } from "@mui/material/className";
import { DrawerBoxComponentProps } from "./DrawerBox.type";
import { DrawerBoxComponentState } from "./DrawerBox.type";
import getStore from "Util/Store";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// ClassNameGenerator.configure((componentName) =>
//     componentName.replace("Mui", "DrawerBox")
// );

import "./DrawerBox.style";
import CloseIcon from "Component/CloseIcon";
import SearchIconDesktop from "Component/SearchIconDesktop";
import ChevronIcon from "Component/ChevronIcon";
import { Directions } from "Component/ChevronIcon/ChevronIcon.config";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    const state = getStore().getState();
    const isMobile = state.ConfigReducer.device.isMobile;
    // console.log(isMobile);
    return <Slide direction={isMobile ? "up" : "right"} ref={ref} {...props} />;
});

type Anchor = "top" | "left" | "bottom" | "right";

// const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide direction="right" ref={ref} {...props} />);
// const Transition = forwardRef<unknown, TransitionProps>((props, ref) => <Slide direction="right" ref={ref} {...props} />);

/** @namespace Component/DrawerBox/Component */
export class DrawerBox extends PureComponent<
    DrawerBoxComponentProps,
    DrawerBoxComponentState
> {
    static defaultProps: Partial<DrawerBoxComponentProps> = {
        isContentFiltered: false,
        wrapperClass: "",
        buttonTitle: "Button",
        heading: "PopUp Heading",
        content: "",
        position: "left",
        startIcon: "",
        endIcon: ""
    };

    __construct(props: DrawerBoxComponentProps): void {
        super.__construct?.(props);
        this.handleDrawerBoxClick = this.handleDrawerBoxClick.bind(this);

        this.state = {
            isOpened: false,
        };
    }

    handleDrawerBoxClick(): void {
        // const { isExpanding } = this.state;

        // if (!isExpanding) {
        this.setState(({ isOpened }) => ({ isOpened: !isOpened }));
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);

        // const { isOpen } = this.state;

        // console.log(isOpen);
        // }
    }

    render() {

        let {
            isContentFiltered,
            wrapperClass,
            buttonTitle,
            content,
            heading,
            position,
            startIcon,
            endIcon,
        } = this.props;

        const { isOpened } = this.state;

        if (buttonTitle === 'searchButton') {
            if (!isOpened) {
                // document.body.classList.remove('isOpened')
                buttonTitle =  <SearchIconDesktop />
            }else {
                // document.body.classList.add('isOpened')
                buttonTitle =  <CloseIcon />
            }
        }

        if (!isOpened) {
            document.body.classList.remove('isOpened')
        }else {
            document.body.classList.add('isOpened')
        }

        return (
            <>
                <React.Fragment key={position}>
                    <Button
                        classes={{
                            root: wrapperClass,
                        }}
                        onClick={this.handleDrawerBoxClick}
                        startIcon={startIcon}
                        endIcon={endIcon}
                    >
                        <span>{buttonTitle}</span>
                        {isContentFiltered ? (
                            <svg
                                block="isContentFiltered"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="6" cy="6" r="6" fill="#E31620" />
                            </svg>
                        ) : (
                            ""
                        )}
                    </Button>

                    <Drawer
                        classes={{
                            root: wrapperClass,
                            modal: wrapperClass,
                        }}
                        transitionDuration={300}
                        anchor={position}
                        open={isOpened}
                        onClose={this.handleDrawerBoxClick}
                    >
                        <div className="MuiDrawer-Heading">
                            <DialogTitle>
                                {heading}
                                {/* <Button onClick={this.handleCustomPopUpClick}>Close</Button> */}
                            </DialogTitle>
                            <div
                                id="DrawerBox-Close"
                                className="MuiDrawer-Close"
                                onClick={this.handleDrawerBoxClick}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        {content}
                    </Drawer>
                </React.Fragment>
            </>
        );
    }
}

export default DrawerBox;
