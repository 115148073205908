import {
    MyAccountSignInComponent as SourceMyAccountSignInComponent,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';

import './MyAccountSignIn.style';
import {ReactElement} from "Type/Common.type";
import Form from "Component/Form";
import Field from "Component/Field";
import {FieldType} from "Component/Field/Field.config";
import {ValidationInputType} from "Util/Validator/Config";
import Loader from "Component/Loader";

export class MyAccountSignInComponent extends SourceMyAccountSignInComponent {
    renderSignInForm(): ReactElement {
        const {
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            isCheckout,
            handleEmailInput,
            isLoading,
        } = this.props;

        return (
            <Form
                key="sign-in"
                onSubmit={ onSignInSuccess }
                onError={ onFormError }
            >
                <Field
                    label={ __('Email') }
                    type={ FieldType.EMAIL }
                    attr={ {
                        id: 'email',
                        name: 'email',
                        placeholder: __('Your email address'),
                        defaultValue: emailValue,
                        autoComplete: isCheckout ? 'off' : 'email',
                    } }
                    validateOn={ ['onChange'] }
                    validationRule={ {
                        isRequired: true,
                        inputType: ValidationInputType.EMAIL,
                    } }
                    events={ { onChange: handleEmailInput } }
                    addRequiredTag
                />
                <div>
                <Field
                    label={ __('Password') }
                    type={ FieldType.PASSWORD }
                    attr={ {
                        id: 'password',
                        name: 'password',
                        placeholder: __('Enter your password'),
                        autoComplete: 'current-password',
                    } }
                    validateOn={ isCheckout ? ['onSubmit'] : ['onChange'] }
                    validationRule={ {
                        isRequired: true,
                        inputType: ValidationInputType.PASSWORD,
                    } }
                    addRequiredTag
                />
                <button
                    type="button"
                    block="Button"
                    mods={ { likeLink: true } }
                    mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                    onClick={ handleForgotPassword }
                >
                    { __('Forgot password?') }
                </button>
                </div>
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{ __('Sign In') }</button>
                </div>
                <Loader isLoading={ isLoading } />
            </Form>
        );
    }

    renderAdditionalField(): ReactElement {
        const {
            isCheckout,
            handleCreateAccount,
            state,
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                <section className="dont-have-an-account">
                    <span id="forgot-password-label">{ __("Don't have an account?") }</span>
                    <button
                        block="Button"
                        mods={ { likeLink: true } }
                        onClick={ handleCreateAccount }
                    >
                        { __('Create an account') }
                    </button>
                </section>
            </article>
        );
    }
};

export default MyAccountSignInComponent;
