/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {noopFn} from "Util/Common";

const renderCheckboxOrRadio = (args, callback, instance) => {
    const {addRequiredTag, attr: {helpdeskCustomField} = {}} = instance.props;

    if (!helpdeskCustomField) {
        return callback(...args);
    }
    const {
        type,
        setRef,
        attr: {defaultChecked = false, ...newAttr} = {},
        events: {onChange},
        events,
        isDisabled,
        label
    } = instance.props;
    const {id = '', checked, value = ''} = newAttr;
    const elem = type.charAt(0).toUpperCase() + type.slice(1);
    const inputEvents = {
        ...events,
        onChange: onChange || noopFn
    };
    // if button value is "none" do not disable
    const isButtonDisabled = (!value.match('none') && isDisabled);
    const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

    return (
        <label htmlFor={id} block="Field" elem={`${elem}Label`} mods={{isDisabled}}>
            <input
                ref={(elem) => setRef(elem)}
                disabled={isButtonDisabled ? isDisabled : false}
                type={type}
                {...newAttr}
                {...inputEvents}
                // shipping options have checked attr assigned so prioritize its value
                defaultChecked={isChecked}
            />
            <div block="input-control" disabled={isDisabled}/>
            {label}
            {(addRequiredTag) ? <span className="Field-Label Field-Label_isRequired"> *</span> : ''}
        </label>
    );

};

export default {
    'Component/Field/Component': {
        'member-function': {
            renderCheckboxOrRadio
        }
    }
};
