import PriceAlert from "./../component/PriceAlert";
import StockAlert from "./../component/StockAlert";

export const PRICE_ALERT = 'price-alert';
export const STOCK_ALERT = 'stock-alert';

const renderMap = (member) => {
    member[PRICE_ALERT] = PriceAlert
    member[STOCK_ALERT] = StockAlert
    return member;
};

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: renderMap
        }
    }
};
