
const _prepareOptions = (args, callback, instance) => {
    console.clear();
    console.log(args);
    console.log(callback);
    console.log(instance);
    return callback(...args);

};

const _getLabel = (args, callback, instance) => {
    const { id, label: pureLabel } = args[0];
// eslint-disable-next-line fp/no-let
    let [label] = pureLabel.split(' ');
    label = label.charAt(0).toUpperCase() + label.slice(1);

    switch (id) {
        case 'name':
            return {
                asc: __('Name: A to Z', label),
                desc: __('Name: Z to A', label)
            };
        case 'position':
            return {
                asc: __('Relevance')
            };
        case 'price':
            return {
                asc: __('%s: Low to High', label),
                desc: __('%s: High to Low', label)
            };
        case 'none':
            return {
                asc: __('Relevance')
            };
        default:
            return {
                asc: __('%s: Ascending', label),
                desc: __('%s: Descending', label)
            };
    }

};
export default {
    'Component/CategorySort/Container': {
        'member-function': {
            // _prepareOptions
            // _getLabel
        }
    },
};
