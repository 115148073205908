import {MouseEvent, PureComponent} from 'react';
import PropTypes from 'prop-types';
import Loader from "Component/Loader/Loader.component";
import './Tracking.style';
import Field from "Component/Field";
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { ValidationInputType } from "Util/Validator/Config";
import TextPlaceholder from "Component/TextPlaceholder";
import Html from "Component/Html";
import {Tab, TabList, Tabs, TabPanel } from 'react-web-tabs';
import MyAccountOrderTableRowExpandable from './../MyAccountOrderTableRowExpandable';
import MyAccountOrder from 'Component/MyAccountOrder/MyAccountOrder.component';
import Image from "Component/Image";
import PayNow from "magenear-paymentsystem/src/component/PayNow";
import CustomPopUp from './../../../../../src/component/CustomPopUp/CustomPopUp.component';
import CustomRequestPopup from "./../../../../mirasvit-helpdesk/src/component/CustomRequestPopup";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Slider from "@mui/material/Slider";
// const payNow = function (e) {
//
//     e.stopPropagation();
//     console.clear();
//     console.log(e);
//     // console.log(e.target.getAttribute('payment_link'));
//     // window.open(e.target.getAttribute('payment_link'), '_blank');
//
// }

export class Tracking extends PureComponent {




    render () {
        // return 'tracking bar'
console.log(this.state);
console.log(this.props);
        let {isLoading, statuses, statusBarProgress} = this.props;
        // return 'tracking bar'

        if (isLoading) {
            // return <Loader isLoading={true} />
            statuses = 'Please wait, getting updated information.';
        }

        if (!statuses.length) {
            statuses = 'Status will be shown here as soon as the shipment starts moving.';
        }


        const marks = [
            {
                value: 0,
                label: 'Placed',
            },
            {
                value: 1,
                label: 'Processing',
            },
            {
                value: 2,
                // label: 'Shipped',
                label:  (statusBarProgress === 2) ? <Tooltip title={<Html content={statuses} /> } placement="top">
                    <span>Shipped</span>
                </Tooltip> : 'Shipped',
            },
            {
                value: 3,
                label: 'Delivered',
            },
        ];

       return (
           <Box>
               <Slider
                   aria-label="Custom marks"
                   // getAriaValueText={valuetext}
                   // valueLabelFormat={valuetext}
                   step={1}
                   max={3}
                   // valueLabelDisplay="auto"
                   marks={marks}
                   defaultValue={statusBarProgress}
                   value={statusBarProgress}
               />
           </Box>
       )
    }

}

export default Tracking;
