import MyReviews from "./../component/MyReviews";
// import MyAccountTicket from '../component/MyAccountTicket';
export const MY_REVIEWS = 'my-reviews';


const tabMap = (member) => {

    // console.clear();
    // console.log(member);
    member[MY_REVIEWS] = {
        url: '/customer/reviews',
        tabName: __('My Reviews'),
        section: 1,
        isFullUrl: true
    };
    return member;
};

const renderMap = (member) => {
    member[MY_REVIEWS] = MyReviews
    return member;
};

// const routeMap = (member) => {
//     member['/customer/reviews'] = {name: 'ACCOUNT_TAB'};
//     return member;
// };

// export const getTabContent = (args, callback, instance) => {
//     const { activeTab, location: { pathname } } = instance.props;
//     // const { changeTabName, setTabSubheading } = instance.props;
//     if (activeTab === MY_REVIEWS && pathname.includes('customer/reviews/view')) {
//         return 'MyAccountTicket';
//     }
//     return callback(...args);
// };

// export const changeTabName = (args, callback, instance) => {
//     // console.clear();
//     console.log(args);
//     return callback(...args);
//
// };
export default {
    'Component/NavigationTabs/Container': {
        // 'member-property': {
        //     routeMap: routeMap
        // },
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: tabMap
        },
        // 'member-function': {
        //     changeTabName: changeTabName
        // }
    },
    'Route/MyAccount/Component': {
        'static-member': {
            // tabMap: tabMap
        },
        'member-property': {
            renderMap: renderMap
        },
        // 'member-function': {
        //     getTabContent: getTabContent
        // }
    }
};
