/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import AddIcon from 'Component/AddIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import MinusIcon from 'Component/MinusIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ChildrenType, MixType, ModsType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import { isCrawler, isSSR } from 'Util/Browser';
import { getFixedElementHeight } from 'Util/CSS';
import Tooltip from "@material-ui/core/Tooltip";
import './MyAccountOrderTableRowExpandable.style';
import {GRID_LAYOUT} from "Route/CategoryPage/CategoryPage.config";
import GridIcon from "Component/GridIcon";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: "rgba(64, 25, 25, 1)",
        fontSize: '12px',
        lineHeight: '12px',
        borderRadius: '20px',
        padding: '8px 12px'
    },
    customArrow: {
        color: "rgba(64, 25, 25, 1)"
    }
}));
const RenderTooltip = (content) => {
    const classes = useStyles();
    const {title, placement, children} = content;

    return (
        <>
            <Tooltip
                TransitionProps={{ timeout: 300 }}
                classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                }}
                title={title}
                arrow
                placement={placement}
            >
                { children }
            </Tooltip>
        </>
    );
};

/** @namespace Component/MyAccountOrderTableRowExpandable/Component */
export class MyAccountOrderTableRowExpandable extends PureComponent {
    static propTypes = {
        isContentExpanded: PropTypes.bool,
        isArrow: PropTypes.bool,
        heading: PropTypes.string,
        children: ChildrenType,
        mix: MixType.isRequired,
        mods: ModsType,
        device: DeviceType.isRequired,
        onClick: (props, propName, componentName) => {
            const propValue = props[propName];

            if (propValue === null) {
                return;
            }

            if (typeof propValue === 'function') {
                return;
            }

            throw new Error(`${componentName} only accepts null or string`);
        }
    };

    static defaultProps = {
        heading: '',
        isContentExpanded: false,
        onClick: null,
        children: [],
        isArrow: false,
        mods: {}
    };

    MyAccountOrderTableRowExpandableRef = createRef();

    toggleExpand = this.toggleExpand.bind(this);

    __construct(props) {
        super.__construct(props);
        const { isContentExpanded } = this.props;

        const isForceExpanded = isSSR() || isCrawler();

        this.state = {
            isContentExpanded: isForceExpanded || isContentExpanded,
            // eslint-disable-next-line react/no-unused-state
            prevIsContentExpanded: isContentExpanded
        };
    }

    static getDerivedStateFromProps({ isContentExpanded }, { prevIsContentExpanded }) {
        if (isContentExpanded !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isContentExpanded,
                isContentExpanded
            };
        }

        return null;
    }

    scrollToExpandedContent() {
        const { isContentExpanded } = this.state;
        const elem = this.MyAccountOrderTableRowExpandableRef && this.MyAccountOrderTableRowExpandableRef.current;

        if (isContentExpanded && !elem) {
            return;
        }

        const elemToWindowTopDist = elem.getBoundingClientRect().top;
        const windowToPageTopDist = document.body.getBoundingClientRect().top;
        const topToElemDistance = elemToWindowTopDist - windowToPageTopDist;
        const {
            total: totalFixedElementHeight,
            bottom: bottomFixedElementHeight
        } = getFixedElementHeight();

        const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
            ? elem.offsetHeight
            : screen.height - totalFixedElementHeight;
        const scrollTo = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

        // checking if button is in a view-port
        if (-windowToPageTopDist >= scrollTo) {
            return;
        }

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }

    toggleExpand() {
        const { onClick } = this.props;

        if (onClick) {
            onClick();

            return;
        }
        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => this.scrollToExpandedContent()
        );
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, mix, order_id, order_date, order_delivery_date, order_status, order_items } = this.props;

        if (typeof order_id === 'undefined') {
            return null;
        }
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        // console.log(JSON.parse(order_items));
        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="MyAccountOrderTableRowExpandable"
              elem="Button"
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'MyAccountOrderTableRowExpandableButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                <div
                  block="MyAccountOrderTableRowExpandable"
                  elem="Heading"
                  mix={ { ...mix, elem: 'MyAccountOrderTableRowExpandableHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <>
                            <div block="MyAccountOrderTableRowExpandableHeading" elem="OrderID">
                        <TextPlaceholder content={ 'Order ID: ' + order_id } length="block" />
                            </div>
                            <div block="MyAccountOrderTableRowExpandableHeading" elem="OrderedOn">
                        <TextPlaceholder content={ 'Ordered on ' + order_date } length="medium" />
                            </div>
                        </>
                    ) : (
                        heading
                    ) }
                </div>
                <div
                    block="MyAccountOrderTableRowExpandable"
                    elem="DeliveryExpected"
                >
                    <RenderTooltip title={ "Delivery expected by " + order_delivery_date } placement="top" children={
                        <div><span>Delivery expected by {order_delivery_date}</span></div>
                    }/>
                    {/*<span>Delivery expected by {order_date}</span>*/}
                </div>
                <div
                    block="MyAccountOrderTableRowExpandable"
                    elem="OrderStatus"
                >
                    <RenderTooltip title={ "Status: " + order_status } placement="top" children={
                        <div>Status: <span>{order_status}</span></div>
                    }/>
                    {/*<span>Status: </span>{order_status}*/}
                </div>
                { this.renderButtonIcon() }
            </div>
        );
    }

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile } } = this.props;

        // if (!isMobile) {
        //     return null;
        // }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? Directions.TOP : Directions.BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <div block="ChevronIconWrapper"><ChevronIcon direction={Directions.TOP} /></div>;
        }

        return <div block="ChevronIconWrapper"><ChevronIcon direction={Directions.BOTTOM} /></div>;
    }

    renderContent() {
        const { children, mix } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };

        return (
            <div
              block="MyAccountOrderTableRowExpandable"
              elem="Content"
              mods={ mods }
              mix={ { ...mix, elem: 'MyAccountOrderTableRowExpandableContent', mods } }
            >
                { children }
            </div>
        );
    }

    render() {
        const { mix, mods } = this.props;

        return (
            <article
              block="MyAccountOrderTableRowExpandable"
              mix={ mix }
              mods={ mods }
              ref={ this.MyAccountOrderTableRowExpandableRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}
export default MyAccountOrderTableRowExpandable;
