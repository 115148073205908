import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    MyAccountInformationContainer as SourceMyAccountInformationContainer,
} from 'SourceComponent/MyAccountInformation/MyAccountInformation.container';

import { RootState } from 'Util/Store/Store.type'
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

import { Dispatch } from 'redux'
import {
    MyAccountInformationComponentProps,
    MyAccountInformationContainerFunctions, MyAccountInformationContainerPropsKeys
} from "Component/MyAccountInformation/MyAccountInformation.type";
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MyAccountInformationContainer extends SourceMyAccountInformationContainer {

    containerFunctions: MyAccountInformationContainerFunctions = {
        onCustomerSave: this.onCustomerSave.bind(this),
        handleChangeEmailCheckbox: this.handleChangeEmailCheckbox.bind(this),
        handleChangePasswordCheckbox: this.handleChangePasswordCheckbox.bind(this),
        //@ts-ignore
        handleChangePasswordCheckboxHide: this.handleChangePasswordCheckboxHide.bind(this),
        //@ts-ignore
        handleChangeEmailCheckboxHide: this.handleChangeEmailCheckboxHide.bind(this),
        //@ts-ignore
        handleChangePasswordCheckboxShow: this.handleChangePasswordCheckboxShow.bind(this),
        //@ts-ignore
        handleChangeEmailCheckboxShow: this.handleChangeEmailCheckboxShow.bind(this),
    };

    containerProps(): Pick<
        MyAccountInformationComponentProps,
        MyAccountInformationContainerPropsKeys
    > {
        const { customer, isLoading } = this.props;
        const { showEmailChangeField, showPasswordChangeField } = this.state;
        if (isLoading) {
            document.body.classList.add('loading');
        }else {
            document.body.classList.remove('loading');
        }
        return {
            isLoading,
            customer,
            showEmailChangeField,
            showPasswordChangeField,
        };
    }

    handleChangePasswordCheckboxShow(): void {
        console.log('show password');
        this.setState({showPasswordChangeField: true});
    }

    handleChangeEmailCheckboxShow(): void {
        console.log('show edit');
        this.setState({showEmailChangeField: true});
    }

    handleChangePasswordCheckboxHide(): void {
        this.setState({showPasswordChangeField: false});
    }

    handleChangeEmailCheckboxHide(): void {
        this.setState({showEmailChangeField: false});
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountInformationContainer);
