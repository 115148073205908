/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';
import CmsBlock from 'Component/CmsBlock';
import Popup from 'Component/Popup';

/** @namespace Component/AccountTermsAndConditionsPopup/Component */
export class AccountTermsAndConditionsPopup extends PureComponent {

    render() {
        return (
            <Popup id="AccountTermsAndConditionsPopup"
                   mix={ { block: 'AccountTermsAndConditionsPopup' } }
            >
                <CmsBlock identifier="terms-conditions"/>
            </Popup>
        );
    }
}

export default AccountTermsAndConditionsPopup;
