import {
    ExpandableContentComponent as SourceExpandableContentComponent,
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';
import { createRef, PureComponent, ReactElement } from 'react';
import AddIcon from 'Component/AddIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import MinusIcon from 'Component/MinusIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { isCrawler, isSSR } from 'Util/Browser';
import { getFixedElementHeight } from 'Util/CSS';
import {ExpandableContentComponentProps} from "Component/ExpandableContent/ExpandableContent.type";

export class ExpandableContentComponent extends SourceExpandableContentComponent {
    static defaultProps: Partial<ExpandableContentComponentProps> = {
        heading: '',
        isContentExpanded: false,
        onClick: undefined,
        children: [],
        isArrow: true,
        mods: {},
    };

    __construct(props: ExpandableContentComponentProps): void {
        super.__construct?.(props);
        const { isContentExpanded } = this.props;

        const isForceExpanded = isSSR() || isCrawler();

        this.toggleExpand = this.toggleExpand.bind(this);
        //@ts-ignore
        window.productPageToggleExpand = this.scrollToExpandedOnlyContent.bind(this);

        this.state = {
            isContentExpanded: isForceExpanded || isContentExpanded,
            // eslint-disable-next-line react/no-unused-state
            prevIsContentExpanded: isContentExpanded,
        };
    }

    renderButtonIcon(): ReactElement | null {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile } } = this.props;

        // if (!isMobile) {
        //     return null;
        // }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? Directions.TOP : Directions.BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderContent(): ReactElement {
        //@ts-ignore
        let { label = false, heading, children, mix } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };

        if (label) {
            heading = label;
        }

        let tabId = heading?.replace(/\s/g, '');
        if (!!(mix.block)) {
            tabId += mix.block;
        }
        if (!!(mix.elem)) {
            tabId += mix.elem;
        }

        return (
            <div
                block="ExpandableContent"
                elem="Content"
                mods={ mods }
                mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                <div id={tabId + '-ExpandableContentContent'}
                     block="ExpandableContent"
                     elem="ContentWrapper">
                    { children }
                </div>
            </div>
        );
    }

    scrollToExpandedOnlyContent(): void {

        const { device: {isMobile}} = this.props;


        // const expandableElement = document.getElementById('ProductInformationProductAttributesContent-ExpandableContentContent');
        const expandableElement = document.querySelectorAll('.ProductTabs-Wrapper .ExpandableContent-ContentWrapper');

        if (!expandableElement) {
            return;
        }

        expandableElement.forEach((element) => {
            // @ts-ignore
            element.classList.add('tabActivated');

            // @ts-ignore
            const expandElement = element?.parentElement;
            // if (isContentExpanded) {
            if (expandElement?.className.includes('isContentExpanded')) {
                const elementPadding = (isMobile) ? 16 : 24;
                // @ts-ignore
                expandElement.style.paddingBottom = element?.clientHeight + elementPadding + 'px';
            }else {
                // @ts-ignore
                // expandElement.style.paddingBottom = "0";
            }
        });



    }

    scrollToExpandedContentBundle(elem:any): void {
        // const { isContentExpanded } = this.state;
        //@ts-ignore
        let { label = false, heading, device: {isMobile}, children, mix } = this.props;

        // console.clear();
        // console.log(elem);
        // console.log(elem.lastChild);
        const bundleElement = elem.lastChild.lastChild;

        // console.log(bundleElement);
        // console.log(bundleElement.getBoundingClientRect());


        let bundledExpaned = false;
        const CheckoutOrderSummaryContent = document.querySelector(".CheckoutOrderSummary-OrderItems .ExpandableContent-Content_isContentExpanded");

        if (elem?.className.includes('Bundle')) {
            bundledExpaned = true;
        }
        let isContentExpanded = false;
        if (elem?.lastChild.className.includes('isContentExpanded')) {
            isContentExpanded = true;
        }

        //@ts-ignore
        let mainPadding = parseInt(CheckoutOrderSummaryContent?.style.paddingBottom);
        //@ts-ignore
        let bundlePadding = parseInt(bundleElement?.clientHeight);
// console.log(isContentExpanded);
        let newPadding = 0;
        if (isContentExpanded) {
            newPadding = mainPadding + bundlePadding;
            // @ts-ignore
            elem.lastChild.style.paddingBottom = bundlePadding + 'px';
        }else {
            newPadding = mainPadding - bundlePadding;
            // @ts-ignore
            elem.lastChild.style.paddingBottom = '0px';
        }


        // console.log(mainPadding);
        // console.log(bundlePadding);
        // console.log(newPadding);

        try {
            // @ts-ignore
            CheckoutOrderSummaryContent.style.paddingBottom = newPadding + 'px';
        }catch (e) {}


        return;


        // @ts-ignore
        const expandElement = elem;
        // console.log(expandableElement);
        // console.log(expandElement);
        if (isContentExpanded) {
            const elementPadding = (isMobile) ? 16 : 24;
            // @ts-ignore
            expandElement.style.paddingBottom = expandableElement?.clientHeight + elementPadding + 'px';

            if (bundledExpaned) {
                console.log(expandElement);
                console.log(elem);
                console.log(elem?.lastChild);
                //@ts-ignore
                let mainPadding = parseInt(CheckoutOrderSummaryContent?.style.paddingBottom);
                //@ts-ignore
                let bundlePadding = parseInt(expandElement?.style.paddingBottom);
                let newPadding = mainPadding + bundlePadding;

                // console.log(CheckoutOrderSummaryContent);
                //@ts-ignore
                // console.log(CheckoutOrderSummaryContent?.style.paddingBottom);
                // console.log(expandElement);
                // console.log(expandElement?.style.paddingBottom);
                // console.log(newPadding);
                //@ts-ignore
                CheckoutOrderSummaryContent.style.paddingBottom = newPadding + 'px';
                //@ts-ignore
                // console.log(CheckoutOrderSummaryContent.style.paddingBottom);

            }

        }else {


            if (bundledExpaned) {
                //@ts-ignore
                let mainPadding = parseInt(CheckoutOrderSummaryContent?.style.paddingBottom);
                //@ts-ignore
                let bundlePadding = parseInt(expandElement?.style.paddingBottom);
                let newPadding = mainPadding - bundlePadding;
                // console.log(mainPadding);
                // console.log(bundlePadding);

                // console.log(CheckoutOrderSummaryContent);
                //@ts-ignore
                // console.log(CheckoutOrderSummaryContent?.style.paddingBottom);
                // console.log(expandElement);
                // console.log(expandElement?.style.paddingBottom);
                // console.log(newPadding);
                //@ts-ignore
                CheckoutOrderSummaryContent.style.paddingBottom = newPadding + 'px';
                //@ts-ignore
                // console.log(CheckoutOrderSummaryContent.style.paddingBottom);

            }

            // @ts-ignore
            expandElement.style.paddingBottom = "0";
        }
        const elemToWindowTopDist: number = elem.getBoundingClientRect().top;
        const windowToPageTopDist: number = document.body.getBoundingClientRect().top;
        const topToElemDistance: number = elemToWindowTopDist - windowToPageTopDist;
        const {
            total: totalFixedElementHeight,
            bottom: bottomFixedElementHeight,
        } = getFixedElementHeight();

        const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
            ? elem.offsetHeight
            : screen.height - totalFixedElementHeight;
        const scrollTo = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

        // checking if button is in a view-port
        if (-windowToPageTopDist >= scrollTo) {
            return;
        }

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }

    scrollToExpandedContent(): void {
        const { isContentExpanded } = this.state;
        //@ts-ignore
        let { label = false, heading, device: {isMobile}, children, mix } = this.props;
        let elem = this.expandableContentRef && this.expandableContentRef.current;

        if (elem?.className.includes('Bundle')) {
            this.scrollToExpandedContentBundle(elem);
            return;
        }

        if (label) {
            heading = label;
        }

        let tabId = heading?.replace(/\s/g, '');
        if (!!(mix.block)) {
            tabId += mix.block;
        }
        if (!!(mix.elem)) {
            tabId += mix.elem;
        }

        const expandableElement = document.getElementById(tabId + '-ExpandableContentContent');

        if (!elem) {
            // @ts-ignore
            elem = expandableElement?.parentElement?.parentElement;
        }

        if ((isContentExpanded && !elem) || !elem) {
            return;
        }

        elem.classList.add('buttonTabActivated');

        // @ts-ignore
        expandableElement.classList.add('tabActivated');

        // @ts-ignore
        const expandElement = expandableElement?.parentElement;
        // console.log(expandableElement);
        // console.log(expandElement);
        if (isContentExpanded) {
            const elementPadding = (isMobile) ? 16 : 24;
            // @ts-ignore
            expandElement.style.paddingBottom = expandableElement?.clientHeight + elementPadding + 'px';
        }else {
            // @ts-ignore
            expandElement.style.paddingBottom = "0";
        }
        const elemToWindowTopDist: number = elem.getBoundingClientRect().top;
        const windowToPageTopDist: number = document.body.getBoundingClientRect().top;
        const topToElemDistance: number = elemToWindowTopDist - windowToPageTopDist;
        const {
            total: totalFixedElementHeight,
            bottom: bottomFixedElementHeight,
        } = getFixedElementHeight();

        const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
            ? elem.offsetHeight
            : screen.height - totalFixedElementHeight;
        const scrollTo = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

        // checking if button is in a view-port
        if (-windowToPageTopDist >= scrollTo) {
            return;
        }

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }

    toggleExpand(): void {
        const { onClick } = this.props;

        if (onClick) {
            onClick();

            return;
        }
        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => this.scrollToExpandedContent(),
        );
    }

    render(): ReactElement {
        const { mix, mods } = this.props;

        return (
            <article
                block="ExpandableContent"
                mix={ mix }
                mods={ mods }
                ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
};

export default ExpandableContentComponent;
