/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import MyReviewsQuery from './../../query/MyReviews';
import {showNotification} from 'Store/Notification/Notification.action';
import {fetchQuery} from 'Util/Request/Query';
import {getErrorMessage} from 'Util/Request/Error';
import {showPopup} from "Store/Popup/Popup.action";
import MyReviews from './MyReviews.component';
import Loader from "Component/Loader/Loader.component";
import { scrollToTop } from 'Util/Browser';
// import ReactDOMServer from "react-dom/server";
import {
    appendWithStoreCode,
    getQueryParam,
    setQueryParams
} from 'Util/Url';

import history from 'Util/History';


let reviewId;

/** @namespace Component/MyReviews/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    amSortingOptions: state.ConfigReducer.am_advanced_review_sorting_options,
    device: state.ConfigReducer.device,
});

/** @namespace Component/MyReviews/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showInfoNotification: (message) => dispatch(showNotification('info', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    showMyReviews: (payload) => dispatch(showPopup('MyReviewsPopup', payload))
});

/** @namespace Component/MyReviews/Container */
export class MyReviewsContainer extends PureComponent {
    // static propTypes = {
    //     showErrorNotification: PropTypes.func.isRequired,
    //     showInfoNotification: PropTypes.func.isRequired,
    //     showSuccessNotification: PropTypes.func.isRequired
    // };

    state = {
        MyReviews: [],
        isLoading: false,
        firstTimeLoad: true
    };

    containerFunctions = {
        onReviewImageClick: this.onReviewImageClick.bind(this),
        handlePaginationLinkClick: this.handleClick.bind(this),
        onSortChange: this.onSortChange.bind(this),
        handleVote: this.handleVote.bind(this),
        submitComment: this.submitComment.bind(this)
    };

    componentDidMount() {
        this.requestDownloadable();
    }

    renderReplyLoader(){
        return (
            <div className="reply-loader">
                <Loader isLoading={true}/>
            </div>
        )
    }

    submitComment(e) {
        let reviewId = e.target.getAttribute('review-id');
        let reviewComment = e.target.getAttribute('review-comment');
        // let html = ReactDOMServer.renderToString(this.renderReplyLoader())
        let html = '<div>LOADER HERE</div>';
        let currentReply = document.getElementById('reply-comment-wrapper-' + reviewId);
        currentReply.insertAdjacentHTML('beforeBegin', html);
        // console.clear();
        // console.log(e);
        // console.log(e.target);
        // console.log(e.target.id);
        let xxx = document.getElementsByClassName(e.target.id);
        for (let i = 0; i < xxx.length; i++) {
            // console.log(xxx[i].parentElement);
            // console.log(xxx[i]);
            // console.log(xxx[i].value);
            xxx[i].parentElement.classList.remove("Field_isValid");
            xxx[i].value = '';
        }
        e.target.style.pointerEvents = 'none';
        e.target.style.opacity = '0.25';

        // console.log(reviewComment);

        // document.querySelectorAll('[class*="reply-loader"]').forEach(n => n.click());
        this.requestDownloadable('', reviewComment);
    }

    onSortChange(e) {
        this.requestDownloadable();
    }

    handleVote(e) {
        let count = parseInt(e.target.getAttribute('count'));
        let vote = e.target.getAttribute('type');
        let reviewId = e.target.getAttribute('review-id');
        // console.clear();
        // console.log(e);
        // console.log(e.target.getAttribute('type'));
        // console.log(e.target.getAttribute('review-id'));
        // console.log(e.target.className);
        // console.log(e.target.classList);
        // console.log(e.target.nextSibling);
        // console.log(e.target.previousSibling);
        // e.target.classList.toggle('voted');
        // try {
        //     if (e.target.nextSibling.classList.contains('voted') && !count) {
        //         e.target.nextSibling.classList.toggle('voted');
        //     }
        // }catch (e) {
        //
        // }
        // try {
        //     if (e.target.previousSibling.classList.contains('voted') && !count) {
        //         e.target.previousSibling.classList.toggle('voted');
        //     }
        // }catch (e) {
        //
        // }



        // e.target.nextSibling.classList.toggle('voted');
        // e.target.previousSibling.classList.toggle('voted');
        this.requestDownloadable(reviewId + ';' + vote);
    }

    handleClick({target}) {
        // console.clear();
        // console.log(target);
        // console.log(target.classList.contains('PaginationLink'));
        // console.log(target.getAttribute('aria-current'));
        if (!target.classList.contains('PaginationLink')) {
            return;
        }
        // console.log(window.location.search);
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') ? urlParams.get('page') : 1;
        // console.log(page);
        // const {history: {location}} = this.props;
        // history.push({pathname: window.location.pathname});
        this.setState({firstTimeLoad:false});
        this.requestDownloadable();
    }

    containerProps = () => {

        const {
            isLoading,
            MyReviews
        } = this.state;

        const {
            areDetailsLoaded,
            device,
            isEnabled,
            product,
            showInfoNotification,
            showMyReviews
        } = this.props;

        return {
            isLoading,
            MyReviews,
            areDetailsLoaded,
            device,
            isEnabled,
            product,
            showInfoNotification,
            showMyReviews
        };
    };

    requestDownloadable(vote = '', comment = '') {
        console.clear();
        console.log(this.state);
        console.log(this.props);

        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') ? urlParams.get('page') : 1;
        console.log(page);
        const {showSuccessNotification, showErrorNotification, showInfoNotification} = this.props;
        scrollToTop({ behavior: 'smooth' });
        this.setState({isLoading: true});
        fetchQuery(
            MyReviewsQuery.getMyReviews(true, page, 'action')
        ).then(
            /** @namespace Component/MyReviews/Container/requestDownloadable/success */
            (
                /** @namespace Component/MyReviews/Container/MyReviewsContainer/requestDownloadable/fetchQuery/then */
                async (data) => {

                    console.log(data);
                    try {
                        const {amastyReview: {amasty_review}} = data;
                        // console.log(amasty_review);
                        let MyReviews = JSON.parse(amasty_review);
                        console.log(MyReviews);
                        const {config:{is_comment_approved}} = MyReviews;
                        if (comment && is_comment_approved) {
                            showSuccessNotification('Your comment has been submitted.');
                        }else if (comment && !is_comment_approved) {
                            showInfoNotification('You submitted your comment for moderation.');
                        }
                        if (vote) {
                            showSuccessNotification('Your vote has been submitted.');
                        }
                        this.setState({isLoading: false, MyReviews: MyReviews});

                        document.querySelector(".my-reviews .Loader").forEach(n => n.remove());
                        document.getElementById('AmreviewWrapper').classList.remove('loading');
                    }catch(e) {
                        console.log(e);
                    }

                }),
            /** @namespace Component/MyReviews/Container/MyReviewsContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                showErrorNotification(getErrorMessage(err));
                this.setState({isLoading: false});
                document.querySelectorAll('[class*="reply-loader"]').forEach(n => n.remove());
                document.getElementById('AmreviewWrapper').classList.remove('loading');
            }
        );
    }

    onReviewImageClick(e) {
        // console.clear();
        // console.log(e);
        // console.log(this.state);
        // console.log(this.props);
        const { review_id } = e;
        const { product:{name}, showMyReviews } = this.props;
        const { MyReviews: {reviews_list} } = this.state;
        // console.log(review_id);
        // console.log(reviews_list);
        // const { items } = this.state;
        showMyReviews({
            title: name
        });

    }

    render() {
        // console.log(this.state);
        // console.log(this.props);
        return (
            <MyReviews
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyReviewsContainer);
