import MyAccountMyTickets from "./../component/MyAccountMyTickets";
import MyAccountTicket from '../component/MyAccountTicket';
export const MY_TICKETS = 'my-tickets';
export const MY_TICKET = 'my-ticket';
export const HELP_DESK = 'helpdesk';

const tabMap = (member) => {

    member[MY_TICKETS] = {
        url: '/helpdesk/tickets',
        tabName: __('My Support Tickets'),
        section: 1,
        isFullUrl: true
    };
    return member;
};

const renderMap = (member) => {
    member[MY_TICKETS] = MyAccountMyTickets
    member[MY_TICKET] = MyAccountTicket
    return member;
};

const routeMap = (member) => {
    // member[MY_TICKETS] = MyAccountMyTickets
    member['/helpdesk/tickets'] = {name: 'ACCOUNT_TAB'};
    member['/helpdesk/tickets/view'] = {name: 'ACCOUNT_TAB'};
    return member;
};

export const getTabContent = (args, callback, instance) => {
    const { activeTab, location: { pathname } } = instance.props;
    // const { changeTabName, setTabSubheading } = instance.props;
    if (activeTab === MY_TICKETS && pathname.includes('helpdesk/tickets/view')) {
        return MyAccountTicket;
    }
    return callback(...args);
};

export const changeTabName = (args, callback, instance) => {
    // console.clear();
    console.log(args);
    return callback(...args);

};
export default {
    'Component/NavigationTabs/Container': {
        'member-property': {
            routeMap: routeMap
        },
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: tabMap
        },
        'member-function': {
            changeTabName: changeTabName
        }
    },
    'Route/MyAccount/Component': {
        'static-member': {
            // tabMap: tabMap
        },
        'member-property': {
            renderMap: renderMap
        },
        'member-function': {
            getTabContent: getTabContent
        }
    }
};
