/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from 'Util/Query';

export class AdvancedReviewQuery {
    getAdvancedReview(
        productId = false,
        page = false,
        comment = '',
        vote = '',
        guest = false,
        sort='',
        filter='',
        viewWithImages = false,
        popup = false,
        widget = false,
        reviewType = false,
        reviewsCount = false,
    ) {
        return new Field('amastyReview')
            .addFieldList([
                'amasty_review'
            ])
            .addArgument('productId', 'Int', productId)
            .addArgument('page', 'Int', page)
            .addArgument('comment', 'String', comment)
            .addArgument('vote', 'String', vote)
            .addArgument('guest', 'String', guest)
            .addArgument('sort', 'String', sort)
            .addArgument('filter', 'String', filter)
            .addArgument('viewWithImages', 'Int', viewWithImages)
            .addArgument('popup', 'Int', popup)
            .addArgument('widget', 'Int', widget)
            .addArgument('reviewType', 'Int', reviewType)
            .addArgument('reviewsCount', 'Int', reviewsCount)
    }

}

export default new AdvancedReviewQuery();
