/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from 'Util/Query';
import { Mutation } from '@tilework/opus';

/** @namespace Query/ProductAlerts/Query */
export class xNotifyQuery {
    getSubscriptions(alertType, alertAction, productId) {
        return new Field('amastyXnotify').addFieldList([
            'image',
            'title',
            'subscribedOn',
            'stockStatus',
            'price',
            'notificationStatus',
            'productUrl',
            'productId'
        ]).addArgument('alert_type', 'String!', alertType)
            .addArgument('alert_action', 'Int!', alertAction)
            .addArgument('product_id', 'ID', productId)
    }

    getProductAlertSubscribeMutation(productId, type, guestEmail){
        return new Mutation('productAlertSubscribe')
            .addArgument('productId', 'ID!', productId)
            .addArgument('type', 'String!', type)
            .addArgument('guestEmail', 'String', guestEmail)
    }
}

export default new xNotifyQuery();
