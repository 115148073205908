/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import {PureComponent} from 'react';

import './GridIcon.style';

/** @namespace Component/GridIcon/Component */
export class GridIcon extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };


    render() {
        const {isActive, gridLayout, isMobile} = this.props;

        if (isMobile && gridLayout === 'grid') {
            return (
                <svg block="GridIcon"
                     className={gridLayout}
                     mods={{isActive}}
                     width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="4" fill="white"/>
                    <rect className='GridIcon-Line' x="4" y="4" width="7" height="16" rx="1.5" fill="black" fill-opacity="0.4"/>
                    <rect className='GridIcon-Line' x="13" y="4" width="7" height="16" rx="1.5" fill="black" fill-opacity="0.4"/>
                    <rect className='GridIcon-Stroke' x="0.25" y="0.25" width="23.5" height="23.5" rx="3.75" stroke="black" stroke-opacity="0.4" stroke-width="0.5"/>
                </svg>

            );
        }

        if (isMobile && gridLayout === 'grid5') {
            return (
                <svg
                    block="GridIcon"
                    mods={{ isActive }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="3.5"
                        fill="white"
                    />
                    <rect
                        className="GridIcon-Line"
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        rx="1.5"
                        fill="black"
                        fill-opacity="0.4"
                    />
                    <rect
                        className="GridIcon-Stroke"
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="3.5"
                        stroke="black"
                        stroke-width="0.5"
                        stroke-opacity="0.4"
                    />
                </svg>

            );
        }

        switch (gridLayout) {
            case 'grid5':
                return (
                    <svg block="GridIcon"
                         className={gridLayout}
                         mods={{isActive}}
                         width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="36" height="36" rx="4" fill="white"/>
                        <rect className='GridIcon-Line' x="4" y="4" width="4" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="10" y="4" width="4" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="16" y="4" width="4" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="22" y="4" width="4" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="28" y="4" width="4" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Stroke' x="0.25" y="0.25" width="35.5" height="35.5" rx="3.75" stroke="black" stroke-opacity="0.4"
                              stroke-width="0.5"/>
                    </svg>
                );
            case 'grid4':
                return (
                    <svg block="GridIcon"
                         className={gridLayout}
                         mods={{isActive}}
                         width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" fill="white"/>
                        <rect className='GridIcon-Line' x="4" y="4" width="5.5" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="11.5" y="4" width="5.5" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="19" y="4" width="5.5" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="26.5" y="4" width="5.5" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Stroke' x="0.5" y="0.5" width="35" height="35" rx="3.5" stroke="black" stroke-opacity="0.4"
                              stroke-width="0.5"/>
                    </svg>
                );
            default:
                return (
                    <svg block="GridIcon"
                         className={gridLayout}
                         mods={{isActive}}
                         width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="36" height="36" rx="4" fill="white"/>
                        <rect className='GridIcon-Line' x="4" y="4" width="8" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="14" y="4" width="8" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        <rect className='GridIcon-Line' x="24" y="4" width="8" height="28" rx="1.5" fill="black"
                              fill-opacity="0.4"/>
                        {/*<rect className='GridIcon-Line' x="4" y="4" width="4" height="28" rx="1.5" fill="black"*/}
                        {/*      fill-opacity="0.4"/>*/}
                        {/*<rect className='GridIcon-Line' x="9" y="4" width="4" height="28" rx="1.5" fill="black"*/}
                        {/*      fill-opacity="0.4"/>*/}
                        {/*<rect className='GridIcon-Line' x="14" y="4" width="4" height="28" rx="1.5" fill="black"*/}
                        {/*      fill-opacity="0.4"/>*/}
                        {/*<rect className='GridIcon-Line' x="19" y="4" width="4" height="28" rx="1.5" fill="black"*/}
                        {/*      fill-opacity="0.4"/>*/}
                        {/*<rect className='GridIcon-Line' x="24" y="4" width="4" height="28" rx="1.5" fill="black"*/}
                        {/*      fill-opacity="0.4"/>*/}
                        {/*<rect className='GridIcon-Line' x="29" y="4" width="4" height="28" rx="1.5" fill="black"*/}
                        {/*      fill-opacity="0.4"/>*/}
                        <rect className='GridIcon-Stroke' x="0.25" y="0.25" width="35.5" height="35.5" rx="3.75" stroke="black" stroke-opacity="0.4"
                              stroke-width="0.5"/>
                    </svg>
                )
        }
    }
}

export default GridIcon;
