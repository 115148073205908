/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';
import {connect} from 'react-redux';
import FeedBackTabQuery from './../../query/FeedBackTab.query';
import {showNotification} from 'Store/Notification/Notification.action';
import {fetchQuery} from 'Util/Request/Query';
import {showPopup} from "Store/Popup/Popup.action";

import FeedBackTab from './FeedBackTab.component';
import {TicketDispatcher} from "../MyAccountMyTickets/MyAccountMyTickets.container";
import browserHistory from 'Util/History';

/** @namespace Component/FeedBackTab/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    kb_general_base_url: state.ConfigReducer.kb_general_base_url
});

/** @namespace Component/FeedBackTab/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getTicketList: (page) => TicketDispatcher.then(
        ({default: dispatcher}) => dispatcher.requestTickets(dispatch, page)
    ),
    showContactUs: (payload) => dispatch(showPopup('NewContactUsPopup', payload)),
    showCustomRequest: (payload) => dispatch(showPopup('NewCustomRequestPopup', payload))
});

let articlePreviewLength = 300;

/** @namespace Component/FeedBackTab/Container */
export class FeedBackTabContainer extends PureComponent {
    // static propTypes = {
    //     showErrorNotification: PropTypes.func.isRequired,
    //     showSuccessNotification: PropTypes.func.isRequired,
    // };

    state = {
        items: [],
        isLoading: false,
    };

    containerFunctions = {
        onButtonClick: this.onButtonClick.bind(this),
        onQuestionChange: this.onQuestionChange.bind(this),
        goToContactUsForm: this.goToContactUsForm.bind(this),
    };

    componentDidMount() {
        if (window.location.pathname.includes('/contact')) {
            document.body.classList.add("helpdesk-contact");
        }
        browserHistory.listen( location =>  {
            // console.clear();
            // console.log(location.pathname);
            if (location.pathname.includes('/contact')) {
                document.body.classList.add("helpdesk-contact");
            }else {
                document.body.classList.remove("helpdesk-contact");
            }
            // console.log('feedback tab route changes');
        });
        this.requestDownloadable();
    }

    containerProps = () => {
        const {isLoading} = this.state;
        const {kb_general_base_url, showContactUs, showCustomRequest, getTicketList, showNotification, contactUs, onlyAddNewTicket = false} = this.props;
        // console.clear();
        // console.log(this.state);
        let items = this._prepareDownloadableProps();

        if (onlyAddNewTicket) {
            items.total_required = onlyAddNewTicket.total_required;
        }
        // console.log(this.props);
        // console.log(items);
        return {
            kb_general_base_url,
            isLoading,
            items:items,
            showContactUs,
            showCustomRequest,
            getTicketList,
            showNotification,
            contactUs,
            onlyAddNewTicket
        };
    };

    _prepareDownloadableProps() {
        // console.log(this.state);
        // console.log(this.props);
        const {items} = this.state;

        // if (!items?.length) {
        //     return [];
        // }
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        return items;

    }

    goToContactUsForm() {
        const contactForm = document.getElementById('ContactFormWrapper').getBoundingClientRect().top;
        window.scrollTo({ behavior: 'smooth', top: contactForm + window.scrollY - 132 });
    }

    onQuestionChange(e) {
        this.searchInKB();
    }

    searchKbNoResults() {
        return (
            <div block="ProductList">
                <div
                    block="ProductList"
                    elem="ProductsMissing"
                >
                    <svg width="90" height="70" viewBox="0 0 90 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.3031 -0.0200195H4.19179C2.33194 -0.0200195 0.84375 1.46815 0.84375 3.32802V24.4393C0.84375 26.2991 2.33192 27.7873 4.19179 27.7873H25.3031C27.1629 27.7873 28.6511 26.2992 28.6511 24.4393V3.42102C28.7439 1.5605 27.1629 -0.0200195 25.3031 -0.0200195ZM21.955 21.0912H7.6325V6.7694H21.9544L21.955 21.0912Z" fill="#6B2A2A"/>
                        <path d="M43.2522 6.76923H85.8468C87.7066 6.76923 89.1948 5.28106 89.1948 3.42118C89.1948 1.56133 87.7067 0.0731422 85.8468 0.0731422H43.2522C41.3924 0.0731422 39.9042 1.56131 39.9042 3.42118C39.9042 5.28104 41.3924 6.76923 43.2522 6.76923Z" fill="#6B2A2A"/>
                        <path d="M43.2522 27.8805H74.1286C75.9885 27.8805 77.4767 26.3923 77.4767 24.5325C77.4767 22.6726 75.9885 21.0916 74.1286 21.0916H43.2522C41.3924 21.0916 39.9042 22.5797 39.9042 24.4396C39.9042 26.2995 41.3924 27.8805 43.2522 27.8805Z" fill="#6B2A2A"/>
                        <path d="M25.3031 42.2027H4.19179C2.33194 42.2027 0.84375 43.6909 0.84375 45.5507V66.662C0.84375 68.5219 2.33192 70.01 4.19179 70.01H25.3031C27.1629 70.01 28.6511 68.5219 28.6511 66.662V45.5507C28.7439 43.6902 27.1629 42.2027 25.3031 42.2027ZM21.955 63.314H7.6325V48.9921H21.9544L21.955 63.314Z" fill="#6B2A2A"/>
                        <path d="M85.8468 42.2027H43.2522C41.3924 42.2027 39.9042 43.6908 39.9042 45.5507C39.9042 47.4106 41.3924 48.8988 43.2522 48.8988H85.8468C87.7066 48.8988 89.1948 47.4106 89.1948 45.5507C89.1948 43.6902 87.7067 42.2027 85.8468 42.2027Z" fill="#6B2A2A"/>
                        <path d="M74.1286 63.314H43.2522C41.3924 63.314 39.9042 64.8021 39.9042 66.662C39.9042 68.5219 41.3924 70.01 43.2522 70.01H74.1286C75.9885 70.01 77.4767 68.5219 77.4767 66.662C77.4767 64.8021 75.9885 63.314 74.1286 63.314Z" fill="#6B2A2A"/>
                        <path d="M25.3031 -0.0200195H4.19179C2.33194 -0.0200195 0.84375 1.46815 0.84375 3.32802V24.4393C0.84375 26.2991 2.33192 27.7873 4.19179 27.7873H25.3031C27.1629 27.7873 28.6511 26.2992 28.6511 24.4393V3.42102C28.7439 1.5605 27.1629 -0.0200195 25.3031 -0.0200195ZM21.955 21.0912H7.6325V6.7694H21.9544L21.955 21.0912Z" stroke="white" stroke-width="2"/>
                        <path d="M43.2522 6.76923H85.8468C87.7066 6.76923 89.1948 5.28106 89.1948 3.42118C89.1948 1.56133 87.7067 0.0731422 85.8468 0.0731422H43.2522C41.3924 0.0731422 39.9042 1.56131 39.9042 3.42118C39.9042 5.28104 41.3924 6.76923 43.2522 6.76923Z" stroke="white" stroke-width="2"/>
                        <path d="M43.2522 27.8805H74.1286C75.9885 27.8805 77.4767 26.3923 77.4767 24.5325C77.4767 22.6726 75.9885 21.0916 74.1286 21.0916H43.2522C41.3924 21.0916 39.9042 22.5797 39.9042 24.4396C39.9042 26.2995 41.3924 27.8805 43.2522 27.8805Z" stroke="white" stroke-width="2"/>
                        <path d="M25.3031 42.2027H4.19179C2.33194 42.2027 0.84375 43.6909 0.84375 45.5507V66.662C0.84375 68.5219 2.33192 70.01 4.19179 70.01H25.3031C27.1629 70.01 28.6511 68.5219 28.6511 66.662V45.5507C28.7439 43.6902 27.1629 42.2027 25.3031 42.2027ZM21.955 63.314H7.6325V48.9921H21.9544L21.955 63.314Z" stroke="white" stroke-width="2"/>
                        <path d="M85.8468 42.2027H43.2522C41.3924 42.2027 39.9042 43.6908 39.9042 45.5507C39.9042 47.4106 41.3924 48.8988 43.2522 48.8988H85.8468C87.7066 48.8988 89.1948 47.4106 89.1948 45.5507C89.1948 43.6902 87.7067 42.2027 85.8468 42.2027Z" stroke="white" stroke-width="2"/>
                        <path d="M74.1286 63.314H43.2522C41.3924 63.314 39.9042 64.8021 39.9042 66.662C39.9042 68.5219 41.3924 70.01 43.2522 70.01H74.1286C75.9885 70.01 77.4767 68.5219 77.4767 66.662C77.4767 64.8021 75.9885 63.314 74.1286 63.314Z" stroke="white" stroke-width="2"/>
                    </svg>
                    <p>{ __('No Results Found') }</p>
                    <p>{ noProducts }</p>
                </div>
            </div>
        )
    }

    async searchInKB(action = false, productId = 0) {


        const {showSuccessNotification, showErrorNotification, contactUs} = this.props;

        // this.setState({isLoading: true});

        const contactUsNote = (contactUs) ? '<p class="contact-form-route">Fill this question in contact form, and we’ll be in touch with you.</p>\n' : '';
        const contactFormButton = (contactUs) ? 'Go to Contact Form' : 'Contact Us';

        const searchKB = document.getElementById('what-is-your-question').value;

        await fetchQuery(
            FeedBackTabQuery.getFeedBackTab(searchKB)
        ).then(
            /** @namespace Component/FeedBackTab/Container/requestDownloadable/success */
            (
                /** @namespace Component/FeedBackTab/Container/FeedBackTabContainer/requestDownloadable/fetchQuery/then */
                    (data) => {


                    // const items = data.ticketsList;
                    // console.clear();
                    // console.log(data);
                    const items = JSON.parse(data.ticketsList.tickets_list);

                    // console.log(items);
                    // console.log(contactUs);

                    const searchKBResult = document.getElementById('FeedBackTabActiveKbResult');
                    searchKBResult.innerHTML = '';
                    const node = document.createElement("div");

                    document.getElementById('new-ticket-subject').value = document.getElementById('what-is-your-question').value;
                    if (items === 'empty') {
                        // node.setAttribute("class", "empty");
                        // const textnode = document.createTextNode("We have not found any helpful topics.");
                        // node.appendChild(textnode);
                        // searchKBResult.appendChild(node);
                    }else if (items.results_count === 0) {
                        searchKBResult.insertAdjacentHTML('beforeend', '<div class="KBSearchResults">\n' +
                            '                    <svg width="90" height="70" viewBox="0 0 90 70" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                            '                        <path d="M25.3031 -0.0200195H4.19179C2.33194 -0.0200195 0.84375 1.46815 0.84375 3.32802V24.4393C0.84375 26.2991 2.33192 27.7873 4.19179 27.7873H25.3031C27.1629 27.7873 28.6511 26.2992 28.6511 24.4393V3.42102C28.7439 1.5605 27.1629 -0.0200195 25.3031 -0.0200195ZM21.955 21.0912H7.6325V6.7694H21.9544L21.955 21.0912Z" fill="#6B2A2A"/>\n' +
                            '                        <path d="M43.2522 6.76923H85.8468C87.7066 6.76923 89.1948 5.28106 89.1948 3.42118C89.1948 1.56133 87.7067 0.0731422 85.8468 0.0731422H43.2522C41.3924 0.0731422 39.9042 1.56131 39.9042 3.42118C39.9042 5.28104 41.3924 6.76923 43.2522 6.76923Z" fill="#6B2A2A"/>\n' +
                            '                        <path d="M43.2522 27.8805H74.1286C75.9885 27.8805 77.4767 26.3923 77.4767 24.5325C77.4767 22.6726 75.9885 21.0916 74.1286 21.0916H43.2522C41.3924 21.0916 39.9042 22.5797 39.9042 24.4396C39.9042 26.2995 41.3924 27.8805 43.2522 27.8805Z" fill="#6B2A2A"/>\n' +
                            '                        <path d="M25.3031 42.2027H4.19179C2.33194 42.2027 0.84375 43.6909 0.84375 45.5507V66.662C0.84375 68.5219 2.33192 70.01 4.19179 70.01H25.3031C27.1629 70.01 28.6511 68.5219 28.6511 66.662V45.5507C28.7439 43.6902 27.1629 42.2027 25.3031 42.2027ZM21.955 63.314H7.6325V48.9921H21.9544L21.955 63.314Z" fill="#6B2A2A"/>\n' +
                            '                        <path d="M85.8468 42.2027H43.2522C41.3924 42.2027 39.9042 43.6908 39.9042 45.5507C39.9042 47.4106 41.3924 48.8988 43.2522 48.8988H85.8468C87.7066 48.8988 89.1948 47.4106 89.1948 45.5507C89.1948 43.6902 87.7067 42.2027 85.8468 42.2027Z" fill="#6B2A2A"/>\n' +
                            '                        <path d="M74.1286 63.314H43.2522C41.3924 63.314 39.9042 64.8021 39.9042 66.662C39.9042 68.5219 41.3924 70.01 43.2522 70.01H74.1286C75.9885 70.01 77.4767 68.5219 77.4767 66.662C77.4767 64.8021 75.9885 63.314 74.1286 63.314Z" fill="#6B2A2A"/>\n' +
                            '                        <path d="M25.3031 -0.0200195H4.19179C2.33194 -0.0200195 0.84375 1.46815 0.84375 3.32802V24.4393C0.84375 26.2991 2.33192 27.7873 4.19179 27.7873H25.3031C27.1629 27.7873 28.6511 26.2992 28.6511 24.4393V3.42102C28.7439 1.5605 27.1629 -0.0200195 25.3031 -0.0200195ZM21.955 21.0912H7.6325V6.7694H21.9544L21.955 21.0912Z" stroke="white" stroke-width="2"/>\n' +
                            '                        <path d="M43.2522 6.76923H85.8468C87.7066 6.76923 89.1948 5.28106 89.1948 3.42118C89.1948 1.56133 87.7067 0.0731422 85.8468 0.0731422H43.2522C41.3924 0.0731422 39.9042 1.56131 39.9042 3.42118C39.9042 5.28104 41.3924 6.76923 43.2522 6.76923Z" stroke="white" stroke-width="2"/>\n' +
                            '                        <path d="M43.2522 27.8805H74.1286C75.9885 27.8805 77.4767 26.3923 77.4767 24.5325C77.4767 22.6726 75.9885 21.0916 74.1286 21.0916H43.2522C41.3924 21.0916 39.9042 22.5797 39.9042 24.4396C39.9042 26.2995 41.3924 27.8805 43.2522 27.8805Z" stroke="white" stroke-width="2"/>\n' +
                            '                        <path d="M25.3031 42.2027H4.19179C2.33194 42.2027 0.84375 43.6909 0.84375 45.5507V66.662C0.84375 68.5219 2.33192 70.01 4.19179 70.01H25.3031C27.1629 70.01 28.6511 68.5219 28.6511 66.662V45.5507C28.7439 43.6902 27.1629 42.2027 25.3031 42.2027ZM21.955 63.314H7.6325V48.9921H21.9544L21.955 63.314Z" stroke="white" stroke-width="2"/>\n' +
                            '                        <path d="M85.8468 42.2027H43.2522C41.3924 42.2027 39.9042 43.6908 39.9042 45.5507C39.9042 47.4106 41.3924 48.8988 43.2522 48.8988H85.8468C87.7066 48.8988 89.1948 47.4106 89.1948 45.5507C89.1948 43.6902 87.7067 42.2027 85.8468 42.2027Z" stroke="white" stroke-width="2"/>\n' +
                            '                        <path d="M74.1286 63.314H43.2522C41.3924 63.314 39.9042 64.8021 39.9042 66.662C39.9042 68.5219 41.3924 70.01 43.2522 70.01H74.1286C75.9885 70.01 77.4767 68.5219 77.4767 66.662C77.4767 64.8021 75.9885 63.314 74.1286 63.314Z" stroke="white" stroke-width="2"/>\n' +
                            '                    </svg>\n' +
                            '                    <p>No Results Found</p>\n' + contactUsNote +
                            '                   <button id=\'none-of-these-are-helpful\' class=\'Button\'>'+contactFormButton+'</button>\n' +
                            '            </div>');
                        // searchKBResult.appendChild(node);
                    } else {
                        let results = "<span class='not-empty'>We found the following topics that might be helpful:</span>";
                        items.results.forEach((article) => {
                           // console.log(article);
                            let more = (article.text.replace(/<[^>]+>/g, '').length > articlePreviewLength) ? '...' : '';
                            results += "<div class='wrapper'><a class='name' href='/" + items.kb_base_url + "/" + article.url_key +"'>" + article.name + "</a><span class='text'>" + article.text.replace(/<[^>]+>/g, '').substring(0, articlePreviewLength) + more + "</span><div class='categories'>Categories: "+ article.categories +"</div></div>"
                            // results += article.article_id;
                            // return {...acc, filter}
                        }, {});

                        results += "<button id='none-of-these-are-helpful' class='Button'>None of these are helpful - " + contactFormButton + "</button>"

                        // new-ticket-subject
                        // console.log(results);
                        // const textnode = document.createTextNode("results here.");
                        // node.appendChild(textnode);
                        searchKBResult.insertAdjacentHTML('beforeend', '<div class="FeedBackTabActiveKbResultsWrapper">' + results + '</div>');



                        // searchKBResult.appendChild(node);
                    }


                    // if (contactUs) {


                        const contactForm = document.getElementById('ContactFormWrapper').getBoundingClientRect().top;
                        const goToContactUsForm = document.getElementById('goToContactUsForm');
                        // console.clear();
                        // console.log(contactForm);
                    // }

                    try {
                        const noneOfTheseAreHelpful = document.getElementById('none-of-these-are-helpful');
                        if (noneOfTheseAreHelpful.getAttribute('listener') !== 'true') {
                            noneOfTheseAreHelpful.addEventListener("click", function () {
                                if (contactUs) {
                                    window.scrollTo({ behavior: 'smooth', top: contactForm - 132 });
                                }else {
                                    document.getElementById('FeedBackTabActiveKb').hidden = true;
                                    document.getElementById('FeedBackTabForm').classList.remove('hidden');
                                }
                                console.log(contactForm);
                                console.log(contactForm - 132);
                                console.log(this);
                            });
                            noneOfTheseAreHelpful.setAttribute('listener', 'true');

                            // goToContactUsForm.addEventListener("click", function () {
                            //     if (contactUs) {
                            //         window.scrollTo({ behavior: 'smooth', top: contactForm - 132 });
                            //     }else {
                            //         // document.getElementById('FeedBackTabActiveKb').hidden = true;
                            //         // document.getElementById('FeedBackTabForm').hidden = false;
                            //     }
                            //     goToContactUsForm.setAttribute('listener', 'true');
                            //     console.log(contactForm);
                            //     console.log(contactForm - 132);
                            //     console.log(this);
                            // });

                        }
                    }catch (e) {
                        // console.clear();
                        // console.log(e)
                    }
                    // this.setState({items, isLoading: false});
                    // if (action === 1) {
                    //     showSuccessNotification('You will no longer receive price alerts for this product.');
                    // }
                }),
            /** @namespace Component/FeedBackTab/Container/FeedBackTabContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                console.log(err);
                // showErrorNotification(getErrorMessage(err));
                // this.setState({isLoading: false});
            }
        );
    }

    async requestDownloadable(action = false, productId = 0) {


        const {showSuccessNotification, showErrorNotification} = this.props;

        this.setState({isLoading: true});

        await fetchQuery(
            FeedBackTabQuery.getFeedBackTab('showFeedbackTabIfEnabled')
        ).then(
            /** @namespace Component/FeedBackTab/Container/requestDownloadable/success */
            (
                /** @namespace Component/FeedBackTab/Container/FeedBackTabContainer/requestDownloadable/fetchQuery/then */
                (data) => {


                    // const items = data.ticketsList;
                    // console.clear();
                    // console.log(data);
                    let items = JSON.parse(data.ticketsList.tickets_list);
                    // console.log(items);

                    document.body.classList.remove('helpdeskHQ');
                    if (items.schedule_is_open) {
                        document.body.classList.add('helpdeskHQ');
                    }

                    this.setState({items, isLoading: false});
                    // if (action === 1) {
                    //     showSuccessNotification('You will no longer receive price alerts for this product.');
                    // }
                }),
            /** @namespace Component/FeedBackTab/Container/FeedBackTabContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                console.log(err);
                // showErrorNotification(getErrorMessage(err));
                this.setState({isLoading: false});
            }
        );
    }

    onButtonClick(e) {
        // console.clear();
        // console.log(e);
        // console.log(this.state);
        // console.log(this.props);
        const { showContactUs, showCustomRequest, onlyAddNewTicket } = this.props;
        const { items } = this.state;

        if (onlyAddNewTicket) {
            showCustomRequest({
                // action: ADD_ADDRESS,
                title: onlyAddNewTicket.ticketTitle
                // address: {}
            });
        }else {
            showContactUs({
                // action: ADD_ADDRESS,
                title: items.form_title,
                // address: {}
            });
        }



    }

    render() {
        return (
            <FeedBackTab
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedBackTabContainer);
