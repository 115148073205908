import { connect } from 'react-redux';
import {
    ComponentType,
    MouseEvent,
    PureComponent,
} from 'react';
import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';

import { RootState } from 'Util/Store/Store.type'
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

import { Dispatch } from 'redux'
import {
    MyAccountOverlayContainerFunctions,
    MyAccountOverlayContainerProps
} from "Component/MyAccountOverlay/MyAccountOverlay.type";
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {

    containerFunctions: MyAccountOverlayContainerFunctions = {
        onFormError: this.onFormError.bind(this),
        handleForgotPassword: this.handleForgotPassword.bind(this),
        handleSignIn: this.handleSignIn.bind(this),
        handleCreateAccount: this.handleCreateAccount.bind(this),
        onVisible: this.onVisible.bind(this),
        setSignInState: this.setSignInState.bind(this),
        setLoadingState: this.setLoadingState.bind(this),
        //@ts-ignore
        showTermsAndConditionsPopup: this.showTermsAndConditionsPopup.bind(this),
        showPrivacyPolicyPopup: this.showPrivacyPolicyPopup.bind(this),
    };

    showTermsAndConditionsPopup() {
        //@ts-ignore
        const {showTermsAndConditionsPopup} = this.props;
        return showTermsAndConditionsPopup();
    }

    showPrivacyPolicyPopup() {
        //@ts-ignore
        const {showPrivacyPolicyPopup} = this.props;
        return showPrivacyPolicyPopup();
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(
    MyAccountOverlayContainer as unknown as ComponentType<MyAccountOverlayContainerProps>,
);
