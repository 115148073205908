import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './../AdvancedReview/AdvancedReview.style';
import './AdvancedReviewWidget.style';
import AdvancedReviewWidgetFrame from './review-widget-frame.png';
import {showNewReviewPopup} from 'Util/Product';
import ProductReviewRating from "./../ProductReviewRating";
import PopUpWithImages from "./../PopUpWithImages";
import Html from "Component/Html";
import Popup from "Component/Popup";
import Link from 'Component/Link';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, FreeMode, Scrollbar, Mousewheel, Pagination as SwiperPagination} from "swiper";
// Import Swiper styles
// import 'swiper/swiper-bundle.css';

import Pagination from 'Component/Pagination';
import Image from 'Component/Image';
import Field from "Component/Field";
import { FieldType } from 'Component/Field/Field.config';
import CategorySort from 'Component/CategorySort';
import history from 'Util/History';

import ThumbsUpIcon from "../ThumbsUpIcon";
import ThumbsDownIcon from "../ThumbsDownIcon";
import CommentsIcon from "../CommentsIcon";
import AmExpandableContent from "../AmExpandableContent";
import { ValidationInputType } from "Util/Validator/Config";
import Loader from "Component/Loader/Loader.component";
import { wordEnding } from '../../../../../src/util/WordEnding';
import Decoration from "Component/Decoration/Decoration.component";
import Logo from 'Component/Logo';
import frameLogo from 'Style/icons/logos/mainLogo.svg';

import ChevronIcon from "Component/ChevronIcon";
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';


let reviewPopupData = {};
let totalReplyFields = 1;
let replyToValidate = {};
let reviewToReply = null;

export class AdvancedReviewWidget extends PureComponent {

    // static propTypes = {
    //     onFormError: PropTypes.func.isRequired
    // };

    state = {
        with_images: false,
        verified_buyer: false,
        is_recommended: false,
        reply_to_validate: 1
    }

    // handlePaginationLinkClick = this.handleClick.bind(this);
    //
    // handleClick({target}) {
    //     // console.clear();
    //     console.log(target);
    //     // const {history: {location}} = this.props;
    //     // history.push({pathname: window.location.pathname});
    //     // this.requestDownloadable();
    // }

    componentDidMount() {
        // document.addEventListener('click', this.handleClick);
        // browserHistory.listen( location =>  {
        //     // console.clear();
        //     console.log('advanced review changes');
        // });
        // const Anchors = document.getElementsByClassName('Pagination-ListItem')
        //
        // for (let i = 0; i < Anchors.length; i++) {
        //     console.log(Anchors[i]);
        //     Anchors[i].addEventListener("click",
        //         this.handlePaginationLinkClick.bind(this),
        //         false);
        // }
    }

        handleCheckboxChange = this._handleCheckboxChange.bind(this);

    _handleCheckboxChange(event, option) {

        const { onSortChange } = this.props;
        this.setState({[option.id]: false})
        // console.clear();
        onSortChange();
        // console.clear();
        // console.log(option);
        // console.log(this.state);
        // console.log(this.props);
        // this.renderHorizontalLogic2();

    }

    componentWillMount() {
        const {handlePaginationLinkClick} = this.props;
        document.addEventListener('click', handlePaginationLinkClick);
    }

    componentWillUnmount() {
        const {handlePaginationLinkClick} = this.props;
        document.removeEventListener('click', handlePaginationLinkClick);
    }

    // componentDidUpdate() {
    //     console.log('update');
    //
    // }

    handlePrevReview = this._handlePrevReview.bind(this);

    _handlePrevReview(event) {
        document.getElementById('swiper-button-prev-review-popup-placeholder').click();
        // console.clear();
        // console.log(event);
        // console.log(this.state);
        // console.log(this.props);
        // this.forceUpdate();

    }

    handleNextReview = this._handleNextReview.bind(this);

    _handleNextReview(event) {
        document.getElementById('swiper-button-next-review-popup-placeholder').click();
        // console.clear();
        // console.log(event);
        // console.log(this.state);
        // console.log(this.props);
        // this.forceUpdate();

    }

    renderButton() {
        return (
            <button
                block="ProductReviews"
                elem="Button"
                mix={{block: 'Button', mods: {amReview: true}}}
                onClick={showNewReviewPopup}
            >
                {__('Write a Review')}
            </button>
        );
    }

    renderRatingSchema(percent, reviewCount, singleReview = false) {

        if (singleReview) {
            return (
                <>
                    <meta itemProp="ratingValue" content={percent}/>
                    <meta itemProp="worstRating" content={0}/>
                    <meta itemProp="bestRating" content={100}/>
                </>
            );
        }

        return (
            <>
                <meta itemProp="ratingValue" content={percent}/>
                <meta itemProp="worstRating" content={0}/>
                <meta itemProp="bestRating" content={100}/>
                <meta itemProp="reviewCount" content={reviewCount}/>
            </>
        );
    }

    renderRatingData(ratingSummary, reviewCount) {

        const STARS_COUNT = 5;
        const PERCENT = 100;

        // eslint-disable-next-line no-mixed-operators
        const percent = parseFloat(STARS_COUNT * (ratingSummary || 0) / PERCENT).toFixed(2);

        return (
            <>
                {this.renderRatingSchema(ratingSummary, reviewCount)}
                <ProductReviewRating
                    mix={{block: 'ProductReviews', elem: 'SummaryRating'}}
                    summary={ratingSummary}
                />
            </>
        );
    }

    renderNoRating() {
        const {device: {isMobile}} = this.props;

        if (isMobile) {
            return (
                <span className='ampercent-no-rating-yet'>
                    {__('There are no reviews yet! Click button below to submit one!')}
                </span>
            );
        }

        return (
            <span className='ampercent-no-rating-yet'>
                {__('There are no reviews yet! Click button on the right to submit one!')}
            </span>
        );
    }

    renderSummary(ratingSummary, reviewCount) {

        return (
            <>
                <div
                    block="ProductReviews"
                    elem="Summary"
                    itemType={reviewCount ? 'http://schema.org/AggregateRating' : null}
                    itemProp={reviewCount ? 'aggregateRating' : null}
                    itemScope={reviewCount ? true : null}
                >
                    {this.renderRatingData(ratingSummary, reviewCount)}
                    {/*{ this.renderButton() }*/}
                </div>
                {/*<span className='amreview-count'>*/}
                {/*    /!*{review_count + ' ' + this.trueWordForm(review_count, 'Review', 'Reviews', 'Reviews')}*!/*/}
                {/*    {reviewCount + ' ' + wordEnding(reviewCount, 'Review', 'Reviews', 'Reviews')}*/}
                {/*</span>*/}
            </>
        );
    }

    detailedSummary() {

        const {isLoading, advancedReview} = this.props;

        if (isLoading) {
            return null;
        }

        const {
            review_count,
            detailed_summary = {}
        } = advancedReview;
        // console.log(detailed_summary);

        let bar;
        let barNumber;
        return Object.entries(detailed_summary).map((key) => {
            // console.log(key);
            barNumber = Math.round((key[1] / review_count * 100));
            bar = (review_count) ? barNumber + '%' : 0 + '%';
            return (
                <>
                    <div className='summary-details-wrapper'>
                    <span className="amreview-stars">
                        {key[0]}
                        {(key[0]) === '1' ? __(' star') : __(' stars')}
                    </span>
                        <div className='amreview-bar-wrapper'>
                            <div className="amreview-bar">
                                <div className="amreview-bar -active"
                                     style={{width: bar}}></div>
                            </div>

                        </div>
                        <span className="amreview-text">
                        {bar} ({key[1]})
                    </span>
                    </div>
                </>
            )
        })
    }

    reviewsList() {
        // console.clear();
        // console.log(this.props);
        // console.log(this.state);
        const {device: {isMobile}, advancedReview: {reviews_list}} = this.props;
        // console.log(reviews_list);
        if (typeof reviews_list === 'undefined') {
            return null;
            return 'reviews_list'
        }
        const {
            advancedReview:
                {
                    config: {
                        is_admin_answer_account_only,
                        guest_email,
                        is_guest_can_comment,
                        is_comments_enabled,
                        gdpr_enabled,
                        gdpr_text
                    }, customer_id
                }
        } = this.props;

        if (!customer_id && guest_email) {
            this.setState({reply_to_validate: 3})
            totalReplyFields = 3;
        } else if (!customer_id && !guest_email) {
            this.setState({reply_to_validate: 2})
            totalReplyFields = 2;
        }

        let gdpr = (gdpr_enabled) ? gdpr_text : false;
        // console.log(customer_id);
        // console.log(is_guest_can_comment);
        return Object.values(reviews_list).map((review) => {
            return (
                <>
                    <div className='item review-item amreview-review-wrapper'>
                        {
                            (isMobile) ?
                                <>
                                <div className="review-details amreview-author-details">
                                    <div className='amreview-mobile-author'>
                                    <p className="amreview-author">
                                        {review.nickname}
                                    </p>
                                    <Html content={review.verified_buyer_html}/>
                                    </div>
                                    <time className='amreview-date' dateTime={review.created_at}>
                                        {review.created_at}
                                        <br/>
                                        {review.time_ago}
                                    </time>
                                </div>
                                <Html content={review.recommended_html}/>
                                </>
                                :
                                <div className="review-details amreview-author-details">
                                    <p className="amreview-author">
                                        {review.nickname}
                                    </p>
                                    <Html content={review.verified_buyer_html}/>
                                    <time className='amreview-date' dateTime={review.created_at}>
                                        {review.created_at}
                                        <br/>
                                        {review.time_ago}
                                    </time>
                                    <Html content={review.recommended_html}/>
                                </div>
                        }

                        <div className="amreview-description-wrap">
                            <div className="amrev-desc-title">
                            <span className="amreview-title-review">
                                {review.title}
                            </span>
                                <span className="amrev-voteqty">
                                {review.helpful_html}
                            </span>
                            </div>
                            {Object.values(review.rating_votes).map((vote) => {
                                return (
                                    <div className='amstars-rating-container'>
                                        {this.renderRatingSchema(vote.percent, 1)}
                                        <div className='rating-code'>{vote.rating_code}</div>
                                        <ProductReviewRating
                                            mix={{block: 'ProductReviews', elem: 'SummaryRating'}}
                                            summary={vote.percent}
                                        />
                                    </div>
                                )
                            })}
                            <span className='amreview-text'>{review.detail}</span>

                            {
                                (review.like_about || review.not_like_about) ?
                                    <div className='pros-cons-wrapper'>{this.renderProsAndCons(review)}</div> : ''
                            }


                            {(review.review_images.length) ? this.renderReviewImages(review.review_images) : ''}
                            {
                                (!is_admin_answer_account_only && review.answer) ?
                                    <>
                                        <div className='amreview-response-wrapper'>
                                            <div className='amreview-response'>
                                                <span className='amreview-text'>Response from Divah</span>
                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_138_5087)">
                                                        <path
                                                            d="M13.9627 3.28873C12.9555 3.01993 11.9716 2.67066 11.0205 2.24428C10.0846 1.83833 9.18127 1.36139 8.31823 0.817615L8.07378 0.666504L7.83378 0.822059C6.97074 1.36583 6.06737 1.84278 5.13156 2.24873C4.1788 2.67381 3.19341 3.0216 2.1849 3.28873L1.85156 3.37317V7.07984C1.85156 13.0309 7.8649 15.2576 7.92267 15.2798L8.07378 15.3332L8.2249 15.2798C8.28712 15.2798 14.296 13.0354 14.296 7.07984V3.37317L13.9627 3.28873ZM11.9316 6.24428L6.96712 11.0665L4.15823 8.25317C4.04035 8.1353 3.97413 7.97542 3.97413 7.80873C3.97413 7.64203 4.04035 7.48216 4.15823 7.36428C4.2761 7.24641 4.43597 7.18019 4.60267 7.18019C4.76937 7.18019 4.92924 7.24641 5.04712 7.36428L6.98045 9.33317L11.0649 5.33317C11.1233 5.27481 11.1926 5.22851 11.2688 5.19692C11.3451 5.16533 11.4268 5.14908 11.5093 5.14908C11.5919 5.14908 11.6736 5.16533 11.7499 5.19692C11.8261 5.22851 11.8954 5.27481 11.9538 5.33317C12.0121 5.39154 12.0584 5.46083 12.09 5.53708C12.1216 5.61334 12.1379 5.69507 12.1379 5.77761C12.1379 5.86016 12.1216 5.94189 12.09 6.01815C12.0584 6.0944 12.0121 6.16369 11.9538 6.22206L11.9316 6.24428Z"
                                                            fill="#6B2A2A"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_138_5087">
                                                            <rect width="16" height="16" fill="white"
                                                                  transform="translate(0.0742188)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </div>
                                            <span className='amreview-text'>{review.answer}</span>
                                        </div>

                                    </> : ''
                            }
                            {
                                (customer_id && is_comments_enabled || !customer_id && is_guest_can_comment && is_comments_enabled) ?
                                    this.renderCommentActions(review.review_id, customer_id, review.comments, review.vote_count, guest_email, gdpr)
                                    : ''
                            }
                        </div>
                    </div>

                </>
            )
        })
    }

    renderProsAndCons(review) {
        const {advancedReview: {config: {is_pros_cons_enabled}}} = this.props;
        if (!is_pros_cons_enabled) {
            return null;
        }
        // console.log(review);
        // console.log(review.like_about);
        return (
            <>
                {
                    (review.like_about) ? <div className='review-pros'>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_138_5103)">
                                <path
                                    d="M9.07422 16.5C13.2164 16.5 16.5742 13.1421 16.5742 9C16.5742 4.85786 13.2164 1.5 9.07422 1.5C4.93208 1.5 1.57422 4.85786 1.57422 9C1.57422 13.1421 4.93208 16.5 9.07422 16.5Z"
                                    stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.07422 6V12" stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M6.07422 9H12.0742" stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_138_5103">
                                    <rect width="18" height="18" fill="white" transform="translate(0.0742188)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span>{review.like_about}</span>
                    </div> : ''
                }

                {
                    (review.not_like_about) ? <div className='review-cons'>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_138_5106)">
                                <path
                                    d="M9.07422 16.5C13.2164 16.5 16.5742 13.1421 16.5742 9C16.5742 4.85786 13.2164 1.5 9.07422 1.5C4.93208 1.5 1.57422 4.85786 1.57422 9C1.57422 13.1421 4.93208 16.5 9.07422 16.5Z"
                                    stroke="#E31620" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.07422 9H12.0742" stroke="#E31620" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_138_5106">
                                    <rect width="18" height="18" fill="white" transform="translate(0.0742188)"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <span>{review.not_like_about}</span>
                    </div> : ''
                }

            </>
        )
    }

    renderReviewImages(index, reviewImages) {

        const {device: {isMobile}, advancedReview: {config: {slides_to_show}}} = this.props;

        return (
            <div>

            <Swiper
                // className={(items.length > 4) ? 'WithPager' : ''}
                // effect={"fade"}

                // freeMode={true}
                // navigation={true}
                navigation={{
                    prevEl: '#prevImage'+index,
                    nextEl: '#nextImage'+index
                }}
                // pagination={{
                //     clickable: true,
                // }}
                loop={false}
                // loopFillGroupWithBlank={true}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                modules={[Autoplay, Navigation, SwiperPagination]}
                // className="mySwiper"
                spaceBetween={12}
                // spaceBetween={(isMobile) ? 12 : 24}
                // slidesPerView={(isMobile) ? 2 : 4}
                slidesPerView='auto'
                // breakpoints={{
                //     0: {
                //         slidesPerView: 4,
                //     },
                //     1440: {
                //         slidesPerView: 8,
                //     },
                //
                // }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
            >
                {/*<SwiperSlide>1</SwiperSlide>*/}
                {/*<SwiperSlide>2</SwiperSlide>*/}
                {/*<SwiperSlide>3</SwiperSlide>*/}
                {
                    // this.renderMobileSlider()
                    reviewImages.map((image, index) => {
                        let imageThumb = 'url(' + image.thumb + ')';
                        return (
                            <SwiperSlide className='review-image-container' style={{backgroundImage: imageThumb}}>
                                {this.renderImageInContainer(image)}
                            </SwiperSlide>)
                    })
                }


            </Swiper>
                <div className="swiper-button-prev review-images" id={'prevImage' + index}></div>
                <div className="swiper-button-next review-images" id={'nextImage' + index}></div>
            </div>
        );

    }

    renderImageInContainer(image) {
        const {onReviewImageClick} = this.props;
        return (
            <img className="amreview-image"
                 src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAMAAABcxfTLAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAAtJREFUeNpjGOQAAACgAAHz2+p5AAAAAElFTkSuQmCC'
                 // title='Review image'
                 // alt='Review image'
                 data-review-id={image.review_id}
                 data-image-id={image.image_id}
                 onClick={this.ShowPopup.bind(this, image)}
            />
        )
    }

    reviewsPopup(changeReview = false) {
        // console.clear();
        // console.log(reviewPopupData);
        // console.log(Object.keys(reviewPopupData).length);
        if (!Object.keys(reviewPopupData).length) {
            return null;
        }

        // console.clear();
        // console.log(this.props);
        const {advancedReview: {reviews_list}} = this.props;
        // console.log(reviews_list);
        // console.log(reviews_list[0].review_images[0]);
        if (changeReview) {
            // console.log('review should be changed');
            reviewPopupData = reviews_list[0].review_images[0];
        }

        if (typeof reviews_list === 'undefined') {
            return null;
            return 'reviews_list'
        }
        // console.log(reviewPopupData);
        const {
            product: {name, thumbnail, small_image, image},
            advancedReview: {config: {is_admin_answer_account_only}}
        } = this.props;
        return (
            <>
                <div id="amreview-popup-wrapper">
                    <div className='left-side'>{
                        Object.values(reviews_list).map((review) => {
                            if (review.review_id !== reviewPopupData.review_id) {
                                return null;
                            }
                            // console.clear();
                            // console.log(reviewPopupData);
                            // console.log(review.review_images);
                            // console.log(this.state);
                            // console.log(this.props);
                            let clickedImage;
                            review.review_images.map((image, index) => {
                                if (reviewPopupData.image_id === image.image_id) {
                                    clickedImage = index;
                                }
                            })
                            // console.log(clickedImage);
                            return (
                                <>
                                    <>
                                        <Swiper
                                            loop={true}
                                            centeredSlides={true}
                                            navigation={true}
                                            pagination={{
                                                type: "fraction",
                                            }}
                                            modules={[Autoplay, Navigation, SwiperPagination]}
                                            slidesPerView={1}
                                            initialSlide={clickedImage}
                                            // breakpoints={{
                                            //     320: {
                                            //         slidesPerView: 2,
                                            //     },
                                            //     640: {
                                            //         slidesPerView: 3,
                                            //     },
                                            //     768: {
                                            //         slidesPerView: 4,
                                            //     },
                                            // }}
                                            onSlideChange={(swiper) => {
                                                // console.clear();
                                                // console.log(review);
                                                // console.log(reviewPopupData);
                                                // console.log(reviews_list);
                                                // console.log(swiper);
                                                let {realIndex, previousIndex, isBeginning, isEnd} = swiper;
                                                realIndex++;
                                                // console.log('isBeginning ' + isBeginning);
                                                // console.log('isEnd ' + isEnd);
                                                // console.log('real index' + realIndex);
                                                // console.log('prev index' + previousIndex);

                                                if (isBeginning) {
                                                    // console.log('switch to prev review');
                                                    // this.reviewsPopup();
                                                }

                                                if (isEnd) {
                                                    // console.log('switch to next review');
                                                    // this.reviewsPopup(true);
                                                }
                                            }}
                                            // onSwiper={(swiper) => console.log(swiper)}
                                        >


                                            {
                                                review.review_images.map((image, index) => {
                                                    // console.log(image);
                                                    let imageThumb = 'url(' + image.thumb + ')';
                                                    return (
                                                        <SwiperSlide>
                                                            <Image
                                                                src={image.path}
                                                                // mix={ {
                                                                //     block: 'CartItem',
                                                                //     elem: 'Picture',
                                                                //     mods: {
                                                                //         isNotAvailable, isMobileLayout
                                                                //     }
                                                                // } }
                                                                // ratio="custom"
                                                                // alt={ `Product ${name} thumbnail.` }
                                                            />
                                                        </SwiperSlide>)
                                                })
                                            }
                                        </Swiper>
                                    </>
                                    {/*{(review.review_images.length) ? this.renderReviewImages(review.review_images) : ''}*/}


                                </>
                            )
                        })

                    }
                        <div className='reviewed-product-popup'>
                            <Image
                                src={thumbnail.url}
                                // mix={ {
                                //     block: 'CartItem',
                                //     elem: 'Picture',
                                //     mods: {
                                //         isNotAvailable, isMobileLayout
                                //     }
                                // } }
                                // ratio="custom"
                                alt={name}
                                // alt={ `Product ${name} thumbnail.` }
                            />
                            <span>{name}</span>
                        </div>
                    </div>
                    <div className='right-side'>{
                        Object.values(reviews_list).map((review) => {
                            if (review.review_id !== reviewPopupData.review_id) {
                                return null;
                            }
                            return (
                                <>
                                    <div className='item review-item amreview-review-wrapper'>
                                        <div className="amreview-description-wrap">
                                            <div className="amrev-desc-title">
                            <span className="amreview-title-review">
                                {review.title}
                            </span>

                                            </div>
                                            {Object.values(review.rating_votes).map((vote) => {
                                                return (
                                                    <div className='amstars-rating-container'>
                                                        {this.renderRatingSchema(vote.percent, 1)}
                                                        <div className='rating-code'>{vote.rating_code}</div>
                                                        <ProductReviewRating
                                                            mix={{block: 'ProductReviews', elem: 'SummaryRating'}}
                                                            summary={vote.percent}
                                                        />
                                                    </div>
                                                )
                                            })}
                                            <span className='amreview-text'>{review.detail}</span>


                                            {
                                                (review.like_about || review.not_like_about) ? <div
                                                    className='pros-cons-wrapper'>{this.renderProsAndCons(review)}</div> : ''
                                            }


                                        </div>

                                    </div>

                                    <div className='recommended-helpful'>
                                        {
                                            (review.recommended_html) ?
                                                <span className="amreview-author">
                                                {review.nickname} recommends this product
                                            </span>
                                                : ''
                                        }
                                        <span className="amrev-voteqty">{review.helpful_html}</span>
                                    </div>
                                    <div className="review-details amreview-author-details">
                                        {/*<p>*/}
                                        {/*    {review.review_id}*/}
                                        {/*</p>*/}
                                        <p className="amreview-author">
                                            {review.nickname}
                                        </p>
                                        <Html content={review.verified_buyer_html}/>
                                        <time className='amreview-date' dateTime={review.created_at}>
                                            {review.time_ago}
                                        </time>

                                    </div>

                                </>
                            )
                        })

                    }

                    </div>
                </div>
            </>
        )
    }



    ShowPopup(clickedImage) {
        // console.clear();
        const {onReviewImageClick} = this.props;
        this.forceUpdate();
        reviewPopupData = clickedImage;
        // console.log(clickedImage);
        const {product_id} = clickedImage;
        let productId = 'widget-product-id-' + product_id;

        try {
            document.getElementById(productId).classList.add('loading');
        }catch (e) {

        }
        // console.log(productId)
        onReviewImageClick(clickedImage);
        // console.log(this.props);
        // return 1
    }

    renderLoadingSkeleton() {
        return (
            <>
                <section aria-label="Linked products" className="ProductLinks-MostViewed">
                    <div className="Uniques-Wrapper">
                        <div block="CmsPage" elem="SectionPlaceholder" mix={ { block: 'Title loading' } }>heading</div>
                        <div className="skeletonWrapper">
                            {Array.apply(0, Array(4)).map(function (x, i) {
                                return (
                                    <span key={i} block="CmsPage" elem="SectionPlaceholder">
                            </span>
                                )
                            })}
                        </div>
                    </div>
                </section>
            </>
        );
    }

    render() {

        const { isLoading, device: {isMobile}, title, advancedReview: {reviews_list} } = this.props;
        // console.log(reviews_list);


        if (isLoading || typeof reviews_list === 'undefined') {
            // return <div block="CmsPage" elem="SectionPlaceholder"/>;
            // return null;
            // return 'loading';
            return this.renderLoadingSkeleton();
        }
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        let {
            advancedReview: {
                config:{
                    total_reviews, total_summary, total_summary_value, total_summary_satisfaction
                }
            },
            product,
            amAdvancedReviewBaseUrl
        } = this.props;

        if (typeof amAdvancedReviewBaseUrl === 'undefined') {
            amAdvancedReviewBaseUrl = 'all-reviews';
        }

        return (
            <>
                <div id="AdvancedReviewWidget" block="AdvancedReviewWidget" elem="Wrapper">
                    {/*<Decoration direction="left" angle="topLeft" type="type2"/>*/}
                    <h1 block="CmsPage" elem="Heading">{title}</h1>
                    <div block="AdvancedReviewWidget" elem="Parts">
                        <div block="AdvancedReviewWidget" elem="Summary">
                            <div block="AdvancedReviewWidget" elem="Info">
                                <div block="AdvancedReviewWidget" elem="Text">
                                    <span>{total_summary_satisfaction}</span>
                                    <div className='amreview-stars'>
                                        {this.renderSummary(total_summary, total_reviews)}
                                    </div>
                                    <div className='amreview-numbers'>
                                        <span>{total_summary_value} average</span>
                                        <span>{total_reviews} verified reviews</span>
                                    </div>
                                </div>
                                <Logo src={frameLogo} />
                            </div>
                            <Image
                                src={AdvancedReviewWidgetFrame}
                                // mix={ {
                                //     block: 'CartItem',
                                //     elem: 'Picture',
                                //     mods: {
                                //         isNotAvailable, isMobileLayout
                                //     }
                                // } }
                                // ratio="custom"
                                // alt={ `Product ${name} thumbnail.` }
                            />
                        </div>
                        <div block="AdvancedReviewWidget" elem="List">
                            <Swiper
                                // effect={"fade"}
                                navigation={{
                                    prevEl: "#swiper-button-prev-review",
                                    nextEl: "#swiper-button-next-review",
                                }}
                                pagination={{
                                    el: '.hp-reviews-bullets',
                                    clickable: true
                                }}
                                // loop={true}
                                // loopFillGroupWithBlank={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                modules={[Autoplay, Navigation, SwiperPagination, FreeMode]}
                                freeMode={true}
                                // className="mySwiper"
                                spaceBetween={24}
                                slidesPerView={1}
                                // onSlideChange={() => console.log('slide change')}
                                // onSwiper={(swiper) => console.log(swiper)}
                                breakpoints={
                                    {
                                        580: {
                                            slidesPerView: 2,
                                            // navigation: {
                                            //     enabled: true
                                            // }
                                        },
                                        811: {
                                            slidesPerView: 1,
                                            // navigation: {
                                            //     enabled: true
                                            // }
                                        },
                                        1039: {
                                            slidesPerView: 2,
                                            // navigation: {
                                            //     enabled: true
                                            // }
                                        },
                                        1279: {
                                            slidesPerView: 3,
                                            // navigation: {
                                            //     enabled: true
                                            // }
                                        }
                                    }
                                }
                            >

                                {/*{Array.apply(0, Array(100)).map(function (x, i) {*/}
                                {/*    return (*/}
                                {/*        <SwiperSlide key={i} block="CmsPage" elem="SectionPlaceholder">*/}
                                {/*            <div className="reviewContent">*/}
                                {/*                {i}*/}
                                {/*            </div>*/}
                                {/*        </SwiperSlide>*/}
                                {/*    )*/}
                                {/*})}*/}

                                {
                                    Object.entries(reviews_list).map(([index, review]) => {

                                        // console.log(index);
                                        // console.log(review);
                                        let reviewKey = Object.keys(review)[0];
                                        let productId = 'widget-product-id-' + reviewKey;
                                        // console.log(Object.keys(review));
                                        // console.log(Object.values(review));
                                        // console.log(review[reviewKey]);
                                        // review.map((product, index) => {
                                        //     // product.options = (product.options) ? product.options : [];
                                        //     console.log(product);
                                        //     if (typeof product === 'undefined') {
                                        //         return null;
                                        //     }
                                        //
                                        //     // return <SwiperSlide><div className="reviewContent">{index}</div></SwiperSlide>
                                        // })
                                        return <SwiperSlide>
                                            <div id={productId} className="reviewContent" mix={{block: (review[reviewKey].review_images.length) ? '':'no-images'}}>
                                                <Loader isLoading={true} />
                                                <div className='reviewContentInfo'>
                                                    <svg className='quote' width="101" height="63" viewBox="0 0 101 63"
                                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M29.7383 -2.07303L29.3631 -4C21.4899 -4 14.1432 0.56173 7.31244 9.29131C0.454395 18.0557 -3 27.5912 -3.00001 37.8739C-3.00001 44.9053 -0.798934 50.7219 3.65248 55.2337C7.74607 59.7435 12.9758 62 19.2632 62C25.2129 62 30.42 59.7329 34.8433 55.2646C39.3007 51.1231 41.5263 45.8374 41.5263 39.4874C41.5263 33.472 39.285 28.204 34.8586 23.7256C30.432 19.2471 25.2198 16.9748 19.2632 16.9748C19.0842 16.9748 19.0025 16.9326 18.9588 16.9004C18.9092 16.8639 18.8219 16.7753 18.739 16.5516C18.559 16.066 18.4888 15.1877 18.6609 13.7949L18.6684 13.7338L18.6684 13.6723C18.6684 11.052 19.6405 8.01032 21.7059 4.52211C23.7205 1.47283 26.3918 -0.718707 29.7383 -2.07303ZM88.212 -2.07303L87.8368 -3.99999C79.9636 -3.99999 72.6169 0.561735 65.7861 9.29131C58.9281 18.0557 55.4737 27.5912 55.4737 37.8739C55.4737 44.9053 57.6748 50.722 62.1262 55.2337C66.2198 59.7435 71.4495 62 77.7368 62C83.6866 62 88.8938 59.7329 93.317 55.2646C97.7744 51.123 100 45.8374 100 39.4874C100 33.472 97.7587 28.204 93.3323 23.7256C88.9057 19.2471 83.6935 16.9748 77.7368 16.9748C77.5579 16.9748 77.4762 16.9326 77.4325 16.9004C77.3829 16.8639 77.2956 16.7753 77.2127 16.5516C77.0327 16.066 76.9625 15.1877 77.1346 13.7949L77.1421 13.7338L77.1421 13.6723C77.1421 11.0521 78.1142 8.01037 80.1796 4.5222C82.1941 1.47287 84.8654 -0.718686 88.212 -2.07303Z"
                                                            stroke="#FFA800" stroke-opacity="0.08" stroke-width="2"/>
                                                    </svg>

                                                    <div className="amreview-author-details">
                                                        <div className='author'>
                                                            {review[reviewKey].nickname}
                                                            <Html content={review[reviewKey].verified_buyer_html}/>
                                                        </div>
                                                        <div className='time-ago'>
                                                            {review[reviewKey].time_ago}
                                                        </div>
                                                    </div>
                                                    {/*<div className='amrating'><span className='amrating-number'>{review[reviewKey].rating_summary_value}</span>*/}
                                                    <div className='amrating'>
                                                        <div className='amreview-stars'>
                                                            {this.renderSummary(review[reviewKey].rating_summary, review[reviewKey].review_count)}
                                                        </div>
                                                    </div>
                                                    <div className='amreview-title-review'>
                                                        {review[reviewKey].title}
                                                    </div>
                                                    <div className="amreview-author-review"
                                                         mix={{block: (review[reviewKey].review_images.length) ? '' : 'no-images'}}>
                                                        {review[reviewKey].detail}
                                                    </div>
                                                </div>

                                                {
                                                    (review[reviewKey].review_images.length && !review[reviewKey].review_images[0].path.includes('data:image/png;base64')) ?
                                                        <div className='amreview-images'>
                                                            { this.renderReviewImages(index,review[reviewKey].review_images)}
                                                        </div>
                                                        :
                                                        <div className="amReadMoreWrapper">
                                                            <span className='amReadMore' onClick={this.ShowPopup.bind(this, review[reviewKey].review_images[0])}>Read More</span>
                                                        </div>
                                                }


                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                            {/*<div className="swiper-button-prev" id="swiper-button-prev-review"></div>*/}
                            {/*<div className="swiper-button-next" id="swiper-button-next-review"></div>*/}
                        </div>

                    </div>
                    {/*<Decoration direction="right" angle="bottomRight" type="type2"/>*/}
                    <div block="AdvancedReviewWidget" elem="Controls">
                        <div className="hp-reviews-switcher" id="swiper-button-prev-review">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18L9 12L15 6" stroke="#4B4848" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className="hp-reviews-bullets" id="hp-reviews-bullets"></div>
                        <div className="hp-reviews-switcher" id="swiper-button-next-review">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L15 12L9 6" stroke="#4B4848" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <Link
                        // to={kb_general_base_url}
                        to={amAdvancedReviewBaseUrl}
                        className="More-Reviews-Link"
                        // id={ item_id }
                        // onMouseEnter={ handleCategoryHover }
                        // onMouseLeave={ handleLinkLeave }
                        // mods={ { isHovered } }
                        // onClick={this.onItemClick.bind(this)}
                    >
                        More Reviews
                        {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*    <path d="M5 12H19" stroke="#6B2A2A" stroke-width="2" stroke-linecap="round"*/}
                        {/*          stroke-linejoin="round"/>*/}
                        {/*    <path d="M12 5L19 12L12 19" stroke="#6B2A2A" stroke-width="2" stroke-linecap="round"*/}
                        {/*          stroke-linejoin="round"/>*/}
                        {/*</svg>*/}
                    </Link>

                </div>
                <Popup
                    id="AdvancedReviewPopup"
                    mix={{block: 'AdvancedReviewPopup'}}
                    // onClose={this.closeNewTicketPopup.bind(this)}
                >
                    <div id='amreview-popup'>
                        <PopUpWithImages product={product} reviewPopupData={reviewPopupData}/>
                    </div>

                    {
                        (isMobile) ? '' :
                            <div className="reviews-control-wrapper">
                                <div id="reviews-control">
                                    <div className="swiper-button-prev" id="swiper-button-prev-review-popup" onClick={this.handlePrevReview}>
                                        <ChevronIcon direction={ Directions.LEFT } />
                                    </div>
                                    <span id="reviews-control-current"></span>
                                    <div className="swiper-button-next" id="swiper-button-next-review-popup" onClick={this.handleNextReview}>
                                        <ChevronIcon direction={ Directions.RIGHT } />
                                    </div>
                                </div>
                            </div>
                    }
                </Popup>
            </>
        )
    }

}

export default AdvancedReviewWidget;
