/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {PureComponent} from 'react';

import {ReactElement} from 'Type/Common.type';

import {AddIconComponentProps} from './AddIcon.type';

import './AddIcon.style';

/** @namespace Component/AddIcon/Component */
export class AddIconComponent extends PureComponent<AddIconComponentProps> {
    static defaultProps: Partial<AddIconComponentProps> = {
        isPrimary: false,
    };

    render(): ReactElement {
        const {isPrimary} = this.props;

        return (
            <svg block="AddIcon"
                 mods={{isPrimary}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/*<path d="M6 0.75V11.25M0.75 6H11.25" stroke="#C3C1C1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
                <path d="M6 0.75V11.25M0.75 6H11.25" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
    );
    }
}

export default AddIconComponent;
