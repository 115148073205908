import {
    MyAccountTabListItemComponent as SourceMyAccountTabListItemComponent,
} from 'SourceComponent/MyAccountTabListItem/MyAccountTabListItem.component';

import './MyAccountTabListItem.style';
import {ReactElement} from "Type/Common.type";
import ChevronIcon from "Component/ChevronIcon";

export class MyAccountTabListItemComponent extends SourceMyAccountTabListItemComponent {

    menuIcon(icon: any): ReactElement {

        switch (icon) {
            case 'my-account':
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.62344 13.0794C4.94672 12.3442 6.43093 11.9333 7.9987 11.9333V10.7333C6.21694 10.7333 4.53319 11.2011 3.04062 12.0304L3.62344 13.0794ZM7.9987 11.9333C9.56646 11.9333 11.0507 12.3442 12.374 13.0794L12.9568 12.0304C11.4642 11.2011 9.78045 10.7333 7.9987 10.7333V11.9333ZM10.0654 6.31127C10.0654 7.45266 9.14009 8.37794 7.9987 8.37794V9.57794C9.80283 9.57794 11.2654 8.1154 11.2654 6.31127H10.0654ZM7.9987 8.37794C6.85731 8.37794 5.93203 7.45266 5.93203 6.31127H4.73203C4.73203 8.1154 6.19457 9.57794 7.9987 9.57794V8.37794ZM5.93203 6.31127C5.93203 5.16988 6.85731 4.2446 7.9987 4.2446V3.0446C6.19457 3.0446 4.73203 4.50714 4.73203 6.31127H5.93203ZM7.9987 4.2446C9.14009 4.2446 10.0654 5.16988 10.0654 6.31127H11.2654C11.2654 4.50714 9.80283 3.0446 7.9987 3.0446V4.2446ZM14.0654 8C14.0654 11.3505 11.3492 14.0667 7.9987 14.0667V15.2667C12.012 15.2667 15.2654 12.0133 15.2654 8H14.0654ZM7.9987 14.0667C4.64817 14.0667 1.93203 11.3505 1.93203 8H0.732031C0.732031 12.0133 3.98543 15.2667 7.9987 15.2667V14.0667ZM1.93203 8C1.93203 4.64947 4.64817 1.93333 7.9987 1.93333V0.733328C3.98543 0.733328 0.732031 3.98673 0.732031 8H1.93203ZM7.9987 1.93333C11.3492 1.93333 14.0654 4.64947 14.0654 8H15.2654C15.2654 3.98673 12.012 0.733328 7.9987 0.733328V1.93333Z" fill="black"/>
                    </svg>
                )
            case 'my-orders':
                return(
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.6666 4.85181L7.99998 7.99996M7.99998 7.99996L2.33331 4.85181M7.99998 7.99996L8 14.3333M14 10.7057V5.29427C14 5.06585 14 4.95163 13.9663 4.84977C13.9366 4.75965 13.8879 4.67693 13.8236 4.60713C13.7509 4.52824 13.651 4.47277 13.4514 4.36184L8.51802 1.6211C8.32895 1.51606 8.23442 1.46354 8.1343 1.44295C8.0457 1.42473 7.95431 1.42473 7.8657 1.44295C7.76559 1.46354 7.67105 1.51606 7.48198 1.6211L2.54865 4.36184C2.34896 4.47278 2.24912 4.52824 2.17642 4.60713C2.11211 4.67693 2.06343 4.75965 2.03366 4.84977C2 4.95163 2 5.06585 2 5.29428V10.7057C2 10.9341 2 11.0483 2.03366 11.1502C2.06343 11.2403 2.11211 11.323 2.17642 11.3928C2.24912 11.4717 2.34897 11.5272 2.54865 11.6381L7.48198 14.3789C7.67105 14.4839 7.76559 14.5364 7.8657 14.557C7.95431 14.5752 8.0457 14.5752 8.1343 14.557C8.23442 14.5364 8.32895 14.4839 8.51802 14.3789L13.4514 11.6381C13.651 11.5272 13.7509 11.4717 13.8236 11.3928C13.8879 11.323 13.9366 11.2403 13.9663 11.1502C14 11.0483 14 10.9341 14 10.7057Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 6.33333L5 3" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                )
            case 'my-wishlist':
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7394 2C13.0876 2 14.6654 4.235 14.6654 6.32C14.6654 10.5425 8.11722 14 7.9987 14C7.88018 14 1.33203 10.5425 1.33203 6.32C1.33203 4.235 2.90981 2 5.25796 2C6.60611 2 7.48759 2.6825 7.9987 3.2825C8.50981 2.6825 9.39129 2 10.7394 2Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                )
            case 'address':
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.9987 14L7.93199 13.8999C7.4689 13.2053 7.23735 12.858 6.93143 12.6065C6.6606 12.3839 6.34854 12.2169 6.0131 12.1151C5.6342 12 5.21677 12 4.38191 12H3.46536C2.71863 12 2.34526 12 2.06004 11.8547C1.80916 11.7268 1.60519 11.5229 1.47736 11.272C1.33203 10.9868 1.33203 10.6134 1.33203 9.86667V4.13333C1.33203 3.3866 1.33203 3.01323 1.47736 2.72801C1.60519 2.47713 1.80916 2.27316 2.06004 2.14532C2.34526 2 2.71863 2 3.46536 2H3.73203C5.2255 2 5.97224 2 6.54267 2.29065C7.04444 2.54631 7.45239 2.95426 7.70805 3.45603C7.9987 4.02646 7.9987 4.77319 7.9987 6.26667M7.9987 14V6.26667M7.9987 14L8.0654 13.8999C8.5285 13.2053 8.76004 12.858 9.06596 12.6065C9.33679 12.3839 9.64886 12.2169 9.9843 12.1151C10.3632 12 10.7806 12 11.6155 12H12.532C13.2788 12 13.6521 12 13.9374 11.8547C14.1882 11.7268 14.3922 11.5229 14.52 11.272C14.6654 10.9868 14.6654 10.6134 14.6654 9.86667V4.13333C14.6654 3.3866 14.6654 3.01323 14.52 2.72801C14.3922 2.47713 14.1882 2.27316 13.9374 2.14532C13.6521 2 13.2788 2 12.532 2H12.2654C10.7719 2 10.0252 2 9.45472 2.29065C8.95296 2.54631 8.54501 2.95426 8.28935 3.45603C7.9987 4.02646 7.9987 4.77319 7.9987 6.26667" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                )
            case 'my-tickets':
                // @ts-ignore
                window.helpdeskMyAccountMyTicketsNoTicketsNote = {
                    'svg': <svg width="81" height="62" viewBox="0 0 81 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M51.2148 45.6888L44.8284 44.7608L41.9724 38.9738C41.8369 38.6993 41.6273 38.4682 41.3673 38.3066C41.1073 38.145 40.8073 38.0593 40.5012 38.0593C40.1951 38.0593 39.895 38.145 39.635 38.3066C39.3751 38.4682 39.1654 38.6993 39.0299 38.9738L36.174 44.7608L29.7876 45.6888C29.4846 45.7328 29.2001 45.8607 28.966 46.058C28.7319 46.2553 28.5577 46.5142 28.4631 46.8053C28.3685 47.0965 28.3573 47.4083 28.4306 47.7055C28.504 48.0027 28.659 48.2735 28.8782 48.4872L33.4995 52.9919L32.4087 59.3525C32.3569 59.6542 32.3906 59.9644 32.506 60.248C32.6213 60.5316 32.8137 60.7772 33.0614 60.9572C33.309 61.1371 33.6021 61.2442 33.9074 61.2662C34.2128 61.2883 34.5182 61.2245 34.7892 61.082L40.5012 58.0786L46.2135 61.0817C46.4845 61.2243 46.7899 61.2881 47.0953 61.2661C47.4006 61.244 47.6937 61.1369 47.9413 60.9569C48.189 60.7769 48.3814 60.5313 48.4967 60.2477C48.6121 59.9641 48.6458 59.6539 48.594 59.3522L47.5031 52.9916L52.1243 48.487C52.3435 48.2733 52.4985 48.0026 52.5718 47.7054C52.6452 47.4081 52.6339 47.0963 52.5393 46.8052C52.4446 46.5141 52.2704 46.2552 52.0364 46.0579C51.8023 45.8606 51.5177 45.7327 51.2148 45.6888Z" fill="#F4A509"/>
                        <path d="M40.5012 38.0591C40.1951 38.0591 39.895 38.1447 39.635 38.3064C39.375 38.468 39.1654 38.6991 39.0299 38.9736L36.174 44.7606L29.7876 45.6886C29.4846 45.7327 29.2001 45.8606 28.966 46.0579C28.7319 46.2552 28.5577 46.514 28.4631 46.8052C28.3685 47.0963 28.3573 47.4081 28.4306 47.7054C28.504 48.0026 28.659 48.2733 28.8782 48.487L33.4995 52.9917L32.4087 59.3524C32.3569 59.6541 32.3906 59.9643 32.506 60.2479C32.6213 60.5314 32.8137 60.7771 33.0614 60.957C33.309 61.137 33.6021 61.244 33.9074 61.2661C34.2128 61.2882 34.5182 61.2243 34.7892 61.0819L40.5012 58.0786V38.0591Z" fill="#F8BE4A"/>
                        <path d="M24.6796 45.6888L18.2932 44.7608L15.4373 38.9738C15.3018 38.6993 15.0922 38.4682 14.8322 38.3066C14.5722 38.145 14.2721 38.0593 13.966 38.0593C13.6599 38.0593 13.3599 38.145 13.0999 38.3066C12.8399 38.4682 12.6303 38.6993 12.4948 38.9738L9.63884 44.7608L3.25243 45.6888C2.94949 45.7328 2.6649 45.8607 2.43083 46.058C2.19677 46.2553 2.02256 46.5142 1.9279 46.8053C1.83335 47.0965 1.82211 47.4083 1.89548 47.7055C1.96884 48.0027 2.12388 48.2735 2.34306 48.4872L6.96431 52.9919L5.87353 59.3525C5.82177 59.6542 5.85547 59.9644 5.97081 60.248C6.08615 60.5316 6.27853 60.7772 6.52619 60.9572C6.77386 61.1371 7.06693 61.2442 7.37227 61.2662C7.67761 61.2883 7.98303 61.2245 8.254 61.082L13.9662 58.0789L19.6785 61.082C19.9495 61.2246 20.2549 61.2884 20.5603 61.2664C20.8656 61.2443 21.1587 61.1372 21.4063 60.9572C21.654 60.7772 21.8464 60.5316 21.9617 60.248C22.0771 59.9644 22.1108 59.6542 22.059 59.3525L20.9681 52.9919L25.5893 48.4873C25.8086 48.2736 25.9637 48.0028 26.0371 47.7056C26.1104 47.4083 26.0992 47.0964 26.0045 46.8052C25.9099 46.514 25.7356 46.2551 25.5015 46.0578C25.2673 45.8605 24.9826 45.7327 24.6796 45.6888Z" fill="#F4A509"/>
                        <path d="M13.966 38.0591C13.6599 38.0591 13.3599 38.1447 13.0999 38.3064C12.8399 38.468 12.6303 38.6991 12.4948 38.9736L9.63884 44.7606L3.25243 45.6886C2.94949 45.7327 2.6649 45.8606 2.43083 46.0579C2.19677 46.2552 2.02256 46.514 1.9279 46.8052C1.83335 47.0963 1.82211 47.4081 1.89548 47.7054C1.96884 48.0026 2.12388 48.2733 2.34306 48.487L6.96431 52.9917L5.87353 59.3524C5.82177 59.6541 5.85547 59.9643 5.97081 60.2479C6.08615 60.5314 6.27853 60.7771 6.52619 60.957C6.77386 61.137 7.06693 61.244 7.37227 61.2661C7.67761 61.2882 7.98303 61.2243 8.254 61.0819L13.9662 58.0788L13.966 38.0591Z" fill="#F8BE4A"/>
                        <path d="M77.746 45.6888L71.3596 44.7608L68.5037 38.9738C68.3682 38.6993 68.1586 38.4682 67.8986 38.3066C67.6386 38.145 67.3386 38.0593 67.0324 38.0593C66.7263 38.0593 66.4263 38.145 66.1663 38.3066C65.9063 38.4682 65.6967 38.6993 65.5612 38.9738L62.7052 44.7608L56.3188 45.6888C56.0159 45.7328 55.7313 45.8607 55.4972 46.058C55.2632 46.2553 55.089 46.5142 54.9943 46.8053C54.8998 47.0965 54.8885 47.4083 54.9619 47.7055C55.0352 48.0027 55.1903 48.2735 55.4095 48.4872L60.0307 52.9919L58.9399 59.3525C58.8882 59.6542 58.9219 59.9644 59.0372 60.248C59.1526 60.5316 59.3449 60.7772 59.5926 60.9572C59.8403 61.1371 60.1333 61.2442 60.4387 61.2662C60.744 61.2883 61.0494 61.2245 61.3204 61.082L67.0326 58.0789L72.7449 61.082C73.0159 61.2246 73.3213 61.2884 73.6267 61.2664C73.932 61.2443 74.2251 61.1372 74.4727 60.9572C74.7204 60.7772 74.9128 60.5316 75.0281 60.248C75.1435 59.9644 75.1772 59.6542 75.1254 59.3525L74.0345 52.9919L78.6557 48.4873C78.875 48.2736 79.0301 48.0028 79.1035 47.7056C79.1768 47.4083 79.1656 47.0964 79.0709 46.8052C78.9763 46.514 78.802 46.2551 78.5679 46.0578C78.3337 45.8605 78.049 45.7327 77.746 45.6888Z" fill="#F4A509"/>
                        <path d="M67.0324 38.0591C66.7263 38.0591 66.4263 38.1447 66.1663 38.3064C65.9063 38.468 65.6967 38.6991 65.5612 38.9736L62.7052 44.7606L56.3188 45.6886C56.0159 45.7327 55.7313 45.8606 55.4972 46.0579C55.2632 46.2552 55.089 46.514 54.9943 46.8052C54.8998 47.0963 54.8885 47.4081 54.9619 47.7054C55.0352 48.0026 55.1903 48.2733 55.4095 48.487L60.0307 52.9917L58.9399 59.3524C58.8882 59.6541 58.9219 59.9643 59.0372 60.2479C59.1526 60.5314 59.3449 60.7771 59.5926 60.957C59.8403 61.137 60.1333 61.244 60.4387 61.2661C60.744 61.2882 61.0494 61.2243 61.3204 61.0819L67.0326 58.0788V38.0591H67.0324Z" fill="#F8BE4A"/>
                        <path d="M69.2325 0.729736H11.7675C5.55469 0.729736 0.5 5.78427 0.5 11.9972V19.6002C0.5 25.401 4.90563 30.1919 10.547 30.8019L12.9833 34.0477C13.7864 35.1177 15.0141 35.7433 16.3516 35.7641L16.4205 35.7646C17.7319 35.7646 18.95 35.18 19.7716 34.1538L22.403 30.8675H69.2325C75.4455 30.8675 80.5 25.813 80.5 19.6V11.9971C80.5 5.78427 75.4453 0.729736 69.2325 0.729736Z" fill="#FE5252"/>
                        <path d="M40.5 0.729736H11.7675C5.55469 0.729736 0.5 5.78427 0.5 11.9972V19.6002C0.5 25.401 4.90563 30.1919 10.547 30.8019L12.9833 34.0477C13.7864 35.1177 15.0141 35.7433 16.3516 35.7641L16.4205 35.7646C17.7319 35.7646 18.95 35.18 19.7716 34.1538L22.403 30.8675H40.5V0.729736Z" fill="#FE8484"/>
                        <path d="M66.8606 8.64771H14.1406C12.8462 8.64771 11.7969 9.69708 11.7969 10.9915C11.7969 12.2858 12.8462 13.3352 14.1406 13.3352H66.8606C68.155 13.3352 69.2044 12.2858 69.2044 10.9915C69.2044 9.69708 68.155 8.64771 66.8606 8.64771Z" fill="#FE0909"/>
                        <path d="M40.5006 8.64771H14.1406C12.8462 8.64771 11.7969 9.69708 11.7969 10.9915C11.7969 12.2858 12.8462 13.3352 14.1406 13.3352H40.5006V8.64771ZM35.4744 18.0715H14.1406C12.8462 18.0715 11.7969 19.1208 11.7969 20.4152C11.7969 21.7096 12.8462 22.759 14.1406 22.759H35.4744C36.7688 22.759 37.8181 21.7096 37.8181 20.4152C37.8181 19.1208 36.7688 18.0715 35.4744 18.0715Z" fill="#FE5252"/>
                    </svg>,
                    'title': 'No Tickets Yet',
                    'note': 'Got a question? Don’t hesitate. Ask us'
                }
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2102_45602)">
                            <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12Z" fill="black"/>
                            <path d="M8 9V8.5C8.34612 8.5 8.68446 8.39736 8.97225 8.20507C9.26003 8.01278 9.48434 7.73947 9.61679 7.4197C9.74924 7.09993 9.7839 6.74806 9.71637 6.40859C9.64885 6.06913 9.48218 5.75731 9.23744 5.51256C8.9927 5.26782 8.68087 5.10115 8.34141 5.03363C8.00194 4.9661 7.65007 5.00076 7.3303 5.13321C7.01053 5.26566 6.73722 5.48997 6.54493 5.77775C6.35264 6.06554 6.25 6.40388 6.25 6.75" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2102_45602">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                )
            case 'my-subscriptions':
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 6.66667H2M10.6667 1.33333V4M5.33333 1.33333V4M5.2 14.6667H10.8C11.9201 14.6667 12.4802 14.6667 12.908 14.4487C13.2843 14.2569 13.5903 13.951 13.782 13.5746C14 13.1468 14 12.5868 14 11.4667V5.86667C14 4.74656 14 4.18651 13.782 3.75868C13.5903 3.38236 13.2843 3.0764 12.908 2.88465C12.4802 2.66667 11.9201 2.66667 10.8 2.66667H5.2C4.0799 2.66667 3.51984 2.66667 3.09202 2.88465C2.71569 3.0764 2.40973 3.38236 2.21799 3.75868C2 4.18651 2 4.74656 2 5.86667V11.4667C2 12.5868 2 13.1468 2.21799 13.5746C2.40973 13.951 2.71569 14.2569 3.09202 14.4487C3.51984 14.6667 4.0799 14.6667 5.2 14.6667Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                )
            case 'my-reviews':
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1201_4970)">
                            <path d="M6.66536 10L4.61519 12.0758C4.32922 12.3653 4.18624 12.5101 4.06333 12.5203C3.95671 12.5292 3.85231 12.4863 3.78267 12.4051C3.7024 12.3115 3.7024 12.108 3.7024 11.701V10.6611C3.7024 10.296 3.40341 10.0318 3.04217 9.97889V9.97889C2.16786 9.85082 1.48122 9.16417 1.35315 8.28987C1.33203 8.1457 1.33203 7.97368 1.33203 7.62963V4.53334C1.33203 3.41323 1.33203 2.85318 1.55002 2.42535C1.74176 2.04903 2.04773 1.74307 2.42405 1.55132C2.85187 1.33334 3.41193 1.33334 4.53203 1.33334H9.46537C10.5855 1.33334 11.1455 1.33334 11.5733 1.55132C11.9497 1.74307 12.2556 2.04903 12.4474 2.42535C12.6654 2.85318 12.6654 3.41323 12.6654 4.53334V7.33334M12.6654 14.6667L11.2144 13.6579C11.0105 13.5161 10.9085 13.4452 10.7975 13.395C10.699 13.3503 10.5954 13.3179 10.4891 13.2983C10.3693 13.2762 10.2451 13.2762 9.99664 13.2762H8.7987C8.05196 13.2762 7.67859 13.2762 7.39338 13.1309C7.14249 13.003 6.93852 12.7991 6.81069 12.5482C6.66536 12.263 6.66536 11.8896 6.66536 11.1429V9.46667C6.66536 8.71993 6.66536 8.34656 6.81069 8.06135C6.93852 7.81047 7.14249 7.60649 7.39338 7.47866C7.67859 7.33334 8.05196 7.33334 8.7987 7.33334H12.532C13.2788 7.33334 13.6521 7.33334 13.9374 7.47866C14.1882 7.60649 14.3922 7.81047 14.52 8.06135C14.6654 8.34656 14.6654 8.71993 14.6654 9.46667V11.2762C14.6654 11.8974 14.6654 12.2081 14.5639 12.4531C14.4285 12.7798 14.169 13.0394 13.8423 13.1747C13.5972 13.2762 13.2866 13.2762 12.6654 13.2762V14.6667Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1201_4970">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                )
            default:
                return null;
        }
    }

    render(): ReactElement {

        let { children, tabEntry: [, { tabName }], isActive, tabEntry } = this.props;

        if (tabEntry[0] === 'address') {
            tabName = 'My Address Book';
        }

        if (tabEntry[0] === 'my-downloadable' || tabEntry[0] === 'edit') {
            return null;
        }


        // console.log(tabEntry);
        return (
            <li
                block="MyAccountTabListItem"
                mods={ { isActive } }
            >
                <button
                    block="MyAccountTabListItem"
                    elem="Button"
                    onClick={ this.changeActiveTab }
                    role="link"
                >
                    <div className="item-icon">
                        { this.menuIcon(tabEntry[0]) }
                        { tabName }
                    </div>
                    <ChevronIcon />
                </button>
                { children }
            </li>
        );
    }
};

export default MyAccountTabListItemComponent;
