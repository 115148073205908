/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { showNotification } from 'Store/Notification/Notification.action';
import { MatchType } from 'Type/Router.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { noopFn } from 'Util/Common';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import Popup from 'Component/Popup';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {showPopup} from "Store/Popup/Popup.action";

import MyAccountTicket from './MyAccountTicket.component';
import HelpDeskQuery from "../../query/HelpDesk.query";
import {fetchMutation} from 'Util/Request/Mutation';
import {fetchQuery} from 'Util/Request/Query';
import {getErrorMessage} from 'Util/Request/Error';

import FieldFile from 'Component/FieldFile';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Ticket/Ticket.dispatcher'
);

/** @namespace Component/MyAccountTicket/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Component/MyAccountTicket/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getOrderById: (orderId) => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getOrderById(dispatch, orderId)
    ),
    changeHeaderState: (state) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(NavigationType.BOTTOM_NAVIGATION_TYPE)),
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showRatePopup: (payload) => dispatch(showPopup('RatePopup', payload))
});

/** @namespace Component/MyAccountTicket/Container */
export class MyAccountTicketContainer extends PureComponent {
    callBack = (childState) => {
        // console.log(childState);
        // console.log('container callback');
        const { files, preparedFiles } = childState;
        this.setState({
            files: files,
            preparedFiles: preparedFiles
        })
    }

    static propTypes = {
        match: MatchType.isRequired,
        showNotification: PropTypes.func.isRequired,
        getOrderById: PropTypes.func.isRequired,
        changeTabName: PropTypes.func,
        setTabSubheading: PropTypes.func,
        changeHeaderState: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        changeTabName: noopFn,
        setTabSubheading: noopFn
    };

    state = {
        order: {},
        isLoading: true,
        rate: '',
        uid: '',
        isClosed: '',
        files: [],
        preparedFiles: []
    };

    containerFunctions = {
        onRateClick: this.onRateClick.bind(this),
        processRate: this.processRate.bind(this),
        closeTicket: this.closeTicket.bind(this),
        submitMessage: this.submitMessage.bind(this)
        // handleReorder: this.handleReorder.bind(this),
        // handleChangeActiveTab: this.handleChangeActiveTab.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        const { match: { params: { orderId } } } = this.props;
        // console.log(this.state);
        // console.log(this.props);
        if (orderId) {
            this.requestOrderDetails();
        }
    }

    componentWillUnmount() {
        const { changeTabName, setTabSubheading } = this.props;

        changeTabName();
        setTabSubheading();
    }

    containerProps() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        const { order: stateOrder, isLoading } = this.state;
        const {
            setTabSubheading,
            isMobile
        } = this.props;

        return {
            isLoading,
            setTabSubheading,
            isMobile,
            order: {
                ...stateOrder
            }
        };
    }

    onRateClick(e) {
// console.clear();
        this.setState({
            rate:e.currentTarget.attributes.getNamedItem('rate').value,
            uid:e.currentTarget.attributes.getNamedItem('uid').value
        })

        const { showRatePopup } = this.props;
        // console.log(this.state);
        // console.log(this.props);
        showRatePopup({
            action: e.currentTarget.attributes.getNamedItem('rate').value,
            title: __('Provide Feedback'),
        });
        // showPopup();

        // console.log(e.currentTarget.attributes.getNamedItem('rate').value);
        // const productId = e.currentTarget.attributes.getNamedItem('data-productId').value;
        // this.requestDownloadable(1, productId);

    }

    processRate(e) {

        const { hideActiveOverlay, showNotification } = this.props;

        let comment = document.getElementById("rate-comment").value;

        const {rate, uid} = this.state;

        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        // console.log(comment);
        this.requestDownloadable(uid, rate, comment, false, false, false, false);
        // this.setState({ isLoading: true });
        hideActiveOverlay();
        // showNotification('success', __('Your rate has been submitted.'));
        // this.requestOrderDetails();
        // this.forceUpdate();
        // console.log(e.currentTarget.attributes.getNamedItem('rate').value);

    }

    closeTicket(e) {

        const { hideActiveOverlay, showNotification } = this.props;

        // let reply = document.getElementById("helpdesk-reply").value;

        const {rate, uid} = this.state;

        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        // console.log(reply);
        // this.requestDownloadable(uid, rate, comment);
        // this.setState({ isLoading: true });
        // hideActiveOverlay();
        // showNotification('success', __('Your rate has been submitted.'));
        // this.requestOrderDetails();
        // this.forceUpdate();
        // console.log(e.currentTarget.attributes.getNamedItem('rate').value);

    }

    reopenTicket(e) {

        const { hideActiveOverlay, showNotification } = this.props;

        // let reply = document.getElementById("helpdesk-reply").value;

        const {rate, uid} = this.state;

        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        // console.log(reply);
        // this.requestDownloadable(uid, rate, comment);
        // this.setState({ isLoading: true });
        // hideActiveOverlay();
        // showNotification('success', __('Your rate has been submitted.'));
        // this.requestOrderDetails();
        // this.forceUpdate();
        // console.log(e.currentTarget.attributes.getNamedItem('rate').value);

    }

    submitMessage(e) {

        // console.clear();
        // console.log(this.state);
        // console.log(this.props);



        // console.log(FieldFile.props);
        // let attachments = document.getElementById("helpdesk-attachments").value;
        // console.log(attachments);
        // return;

        const { hideActiveOverlay, showNotification } = this.props;
        const {preparedFiles, rate, uid, order: {id, is_closed}} = this.state;

        let reply = document.getElementById("helpdesk-reply").value;

        let action = e.currentTarget.attributes.getNamedItem('id').value;
        if (action === 'submit-message' && !is_closed) {
            action = 'submit-message';
        }else if (action === 'submit-message' && is_closed) {
            action = 'reopen-and-submit'
        }else {
            action = 'close-and-submit'
        }



        // console.clear();
        // console.log(e);
        // console.log(this.state);
        // console.log(this.props);
        // console.log(reply);
        // console.log(action);
        // console.log(preparedFiles);
        // console.log(JSON.stringify(preparedFiles));

        // return;
        this.requestDownloadable(false, false, false, id, reply, action, JSON.stringify(preparedFiles));
        // this.setState({ isLoading: true });
        // hideActiveOverlay();
        // showNotification('success', __('Your rate has been submitted.'));
        // this.requestOrderDetails();
        // this.forceUpdate();

    }

    handleChangeHeaderState() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: 'customer_ticket',
            title: __('Ticket'),
            onBackClick: () => history.goBack()
        });
    }

    requestDownloadable(uId, rate, comment, id, reply, action, attachments) {

        const {showNotification} = this.props;
        this.setState({isLoading: true});

        let notificationMessage;
        if (action === 'close-and-submit') {
            notificationMessage = 'Your ticket has been closed.';
        }else if (action === 'reopen-and-submit') {
            notificationMessage = 'Your ticket has been reopened.';
        }else if (action === 'submit-message') {
            notificationMessage = 'Your message has been submitted.';
        }else {
            notificationMessage = 'Your rate has been submitted.';
        }


        fetchQuery(
            HelpDeskQuery.processRate(uId, rate, comment, id, reply, action, attachments)
        ).then(
            (
                (data) => {

                    // const items = data.ticketsList;
                    // console.log(data);

                    showNotification('success', notificationMessage);
                    this.requestOrderDetails();
                    // let items = JSON.parse(data.ticketsList.tickets_list);
                    // console.log(items);
                    // this.setState({items, isLoading: false});
                    // if (action === 1) {
                    //     showSuccessNotification('You will no longer receive price alerts for this product.');
                    // }
                }),
            (err) => {
                showNotification('error', getErrorMessage(err));
                this.setState({isLoading: false});
            }
        );
    }

    async requestOrderDetails() {
        const {
            match: {
                params: {
                    orderId
                }
            },
            getOrderById,
            changeTabName,
        } = this.props;

        if (!isSignedIn()) {
            return;
        }

        const order = await getOrderById(orderId);
// console.clear();
// console.log(order);
        if (!order) {
            history.replace(appendWithStoreCode(`helpdesk/tickets`));

            return;
        }

        const { subject } = order;

// console.log(this.state);
// console.log(this.props);
// console.log(order);
// console.log(changeTabName);
        changeTabName(subject);
        // setTabSubheading(status);
        this.handleChangeHeaderState();
        this.setState({ order, isLoading: false });
    }

    render() {
        // console.clear();
        // console.log(this.state);
        return (
            <MyAccountTicket
                {...this.state}
              { ...this.containerFunctions }
              { ...this.containerProps() }
                callBack={ this.callBack }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTicketContainer);
