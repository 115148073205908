/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import AdvancedReviewQuery from './../../query/AdvancedReview';
import {showNotification} from 'Store/Notification/Notification.action';
import {fetchQuery} from 'Util/Request/Query';
import {getErrorMessage} from 'Util/Request/Error';
import {showPopup} from "Store/Popup/Popup.action";
import AdvancedReviewWidget from './AdvancedReviewWidget.component';
import Loader from "Component/Loader/Loader.component";
import ProductListQuery from "Query/ProductList.query";
// import ReactDOMServer from "react-dom/server";
import {
    appendWithStoreCode,
    getQueryParam,
    setQueryParams
} from 'Util/Url';

import history from 'Util/History';


let reviewId;

/** @namespace Component/AdvancedReview/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    amSortingOptions: state.ConfigReducer.am_advanced_review_sorting_options,
    device: state.ConfigReducer.device,
    amAdvancedReviewBaseUrl: state.ConfigReducer.am_advanced_review_base_url
});

/** @namespace Component/AdvancedReview/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showInfoNotification: (message) => dispatch(showNotification('info', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    showAdvancedReview: (payload) => dispatch(showPopup('AdvancedReviewPopup', payload))
});

/** @namespace Component/AdvancedReview/Container */
export class AdvancedReviewWidgetContainer extends PureComponent {
    // static propTypes = {
    //     showErrorNotification: PropTypes.func.isRequired,
    //     showInfoNotification: PropTypes.func.isRequired,
    //     showSuccessNotification: PropTypes.func.isRequired
    // };

    state = {
        advancedReview: [],
        isLoading: false,
        firstTimeLoad: true
    };

    containerFunctions = {
        onReviewImageClick: this.onReviewImageClick.bind(this),
        handlePaginationLinkClick: this.handleClick.bind(this),
        onSortChange: this.onSortChange.bind(this),
        handleVote: this.handleVote.bind(this),
        submitComment: this.submitComment.bind(this)
    };

    componentDidMount() {
        this.requestDownloadable();
    }

    renderReplyLoader(){
        return (
            <div className="reply-loader">
                <Loader isLoading={true}/>
            </div>
        )
    }

    submitComment(e) {
        let reviewId = e.target.getAttribute('review-id');
        let reviewComment = e.target.getAttribute('review-comment');
        // let html = ReactDOMServer.renderToString(this.renderReplyLoader())
        let html = '<div>LOADER HERE</div>';
        let currentReply = document.getElementById('reply-comment-wrapper-' + reviewId);
        currentReply.insertAdjacentHTML('beforeBegin', html);
        // console.clear();
        // console.log(e);
        // console.log(e.target);
        // console.log(e.target.id);
        let xxx = document.getElementsByClassName(e.target.id);
        for (let i = 0; i < xxx.length; i++) {
            // console.log(xxx[i].parentElement);
            // console.log(xxx[i]);
            // console.log(xxx[i].value);
            xxx[i].parentElement.classList.remove("Field_isValid");
            xxx[i].value = '';
        }
        e.target.style.pointerEvents = 'none';
        e.target.style.opacity = '0.25';

        // console.log(reviewComment);

        // document.querySelectorAll('[class*="reply-loader"]').forEach(n => n.click());
        this.requestDownloadable('', reviewComment);
    }

    onSortChange(e) {
        this.requestDownloadable();
    }

    handleVote(e) {
        let count = parseInt(e.target.getAttribute('count'));
        let vote = e.target.getAttribute('type');
        let reviewId = e.target.getAttribute('review-id');
        // console.clear();
        // console.log(e);
        // console.log(e.target.getAttribute('type'));
        // console.log(e.target.getAttribute('review-id'));
        // console.log(e.target.className);
        // console.log(e.target.classList);
        // console.log(e.target.nextSibling);
        // console.log(e.target.previousSibling);
        // e.target.classList.toggle('voted');
        // try {
        //     if (e.target.nextSibling.classList.contains('voted') && !count) {
        //         e.target.nextSibling.classList.toggle('voted');
        //     }
        // }catch (e) {
        //
        // }
        // try {
        //     if (e.target.previousSibling.classList.contains('voted') && !count) {
        //         e.target.previousSibling.classList.toggle('voted');
        //     }
        // }catch (e) {
        //
        // }



        // e.target.nextSibling.classList.toggle('voted');
        // e.target.previousSibling.classList.toggle('voted');
        this.requestDownloadable(reviewId + ';' + vote);
    }

    handleClick({target}) {
        // console.clear();
        // console.log(target);
        // console.log(target.classList.contains('PaginationLink'));
        // console.log(target.getAttribute('aria-current'));
        if (!target.classList.contains('PaginationLink')) {
            return;
        }
        // console.log(window.location.search);
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') ? urlParams.get('page') : 1;
        // console.log(page);
        // const {history: {location}} = this.props;
        // history.push({pathname: window.location.pathname});
        this.setState({firstTimeLoad:false});
        this.requestDownloadable();
    }

    containerProps = () => {

        const {
            isLoading,
            advancedReview,
            product
        } = this.state;

        const {
            areDetailsLoaded,
            device,
            isEnabled,
            showInfoNotification,
            showAdvancedReview,
            reviewType,
            reviewsCount,
            title,
            amAdvancedReviewBaseUrl
        } = this.props;

        return {
            isLoading,
            advancedReview,
            areDetailsLoaded,
            device,
            isEnabled,
            product,
            showInfoNotification,
            showAdvancedReview,
            reviewType,
            reviewsCount,
            title,
            amAdvancedReviewBaseUrl
        };
    };

    requestDownloadable() {

        const {reviewType, reviewsCount} = this.props;
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        // console.log(vote);
        // document.getElementById('AdvancedReviewWidget').classList.add('loading');


        fetchQuery(
            AdvancedReviewQuery.getAdvancedReview(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                reviewType,
                reviewsCount
            )
        ).then(
            /** @namespace Component/AdvancedReview/Container/requestDownloadable/success */
            (
                /** @namespace Component/AdvancedReview/Container/AdvancedReviewContainer/requestDownloadable/fetchQuery/then */
                async (data) => {

                    try {

                        // console.clear();
                        // console.log(data);
                        const {amastyReview: {amasty_review}} = data;
                        // console.log(JSON.stringify(amasty_review));
                        let advancedReview = JSON.parse(amasty_review);
                        // console.log(advancedReview);
                        // document.getElementById('AdvancedReviewWidget').classList.remove('loading');
                        this.setState({isLoading: false, advancedReview: advancedReview});
                    }catch(e) {
                        // console.log(e);
                    }

                }),
            /** @namespace Component/AdvancedReview/Container/AdvancedReviewContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                showErrorNotification(getErrorMessage(err));
                // this.setState({isLoading: false});
                // document.querySelectorAll('[class*="reply-loader"]').forEach(n => n.remove());
                // document.getElementById('AdvancedReviewWidget').classList.remove('loading');
            }
        );
    }

    async onReviewImageClick(clickedImage) {
        // console.clear();
        // console.log(clickedImage);
        // console.log(this.state);
        // console.log(this.props);
        let {review_id, sku, parent_sku} = clickedImage;
        const {showAdvancedReview} = this.props;
        const {product_name} = clickedImage;
        const {advancedReview: {reviews_list}} = this.state;

        let requestSku = sku;

        if (parent_sku) {
            requestSku = parent_sku;
        }

        const { products: {items}} = await this.requestProduct(requestSku);

        if (items.length && parent_sku) {
            const { variants } = items[0];
            const childProduct = variants.filter(variant => {
                return (variant.product.sku === sku);
            });
            if (childProduct.length) {
                this.setState({product: childProduct[0].product});
            }
        }else if (items.length) {
            this.setState({product: items[0]});
        }else {
            this.setState({product: false});
        }

        showAdvancedReview({
            title: product_name
        });

    }

    async requestProduct(sku) {
        const query = ProductListQuery.getQuery({
            args: {
                filter: {
                    productsSkuArray: [sku]
                }
            },
            notRequireInfo: false,
            noVariants: false,
            noVariantAttributes: false
        })
        return  await fetchQuery(query);

    }

    render() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        // return 12345
        return (
            <AdvancedReviewWidget
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedReviewWidgetContainer);
