import {PureComponent} from 'react';
import Loader from "./../../../../../src/component/Loader/Loader.component";

import './CustomRequestPopup.style';
import Popup from "./../../../../../src/component/Popup";
import Field from "./../../../../../src/component/Field";
import { FieldType } from './../../../../../src/component/Field/Field.config';
import { ValidationInputType } from '@scandipwa/scandipwa/src/util/Validator/Config';
import { fetchMutation } from '@scandipwa/scandipwa/src/util/Request/Mutation';
import TicketQuery from "../../query/Ticket.query";
import {DropzoneArea} from "material-ui-dropzone";
import UploadIcon from './../../../../../src/component/UploadIcon';
import TimeCountDown from "../CountDown/TimeCountDown.component";
import Html from "@scandipwa/scandipwa/src/component/Html";
import Link from '@scandipwa/scandipwa/src/component/Link';

const handlePreviewIcon = (fileObject, classes) => {

    const {data, file: {name, type}, file} = fileObject;

    let iconType = name.split('.');
    if (iconType.length === 1) {
        iconType = 'blank';
    } else {
        iconType = name.split('.').pop();
    }

    let attachmentClass = 'fiv-cla fiv-icon-' + iconType;

    return <span className={attachmentClass}></span>;

}

let preparedTicketData = {};
let preparedTicketDataCopy = {};

export class CustomRequestPopup extends PureComponent {

    state = {
        files: [],
        preparedFiles: []
    };

    closeCustomTicketDataPopup = this._closeCustomTicketDataPopup.bind(this);

    _closeCustomTicketDataPopup() {
        console.clear();
        console.log(this.props);
        const { showCustomRequest } = this.props;
        showCustomRequest();
        this.closeNewTicketPopup();
    }

    closeNewTicketPopup() {
        let {onlyAddNewTicket} = this.props;

        preparedTicketData = {};
        preparedTicketDataCopy = {};

        if (onlyAddNewTicket) {
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
        }

    }

    goToTickets = this._goToTickets.bind(this);

    _goToTickets() {
        const {contactUs, getTicketList, showContactUs, showCustomRequest, showNotification, onlyAddNewTicket} = this.props;

        if (onlyAddNewTicket) {
            showCustomRequest();
        }else {
            showContactUs();
        }
    }

    componentDidMount() {
        this.closeNewTicketPopup();
    }

    renderContent() {
        const {items, contactUs, onlyAddNewTicket} = this.props;

        let messageTitle = items.description_title;
        let messagePlaceholder = items.description_placeholder;

        let subjectTitle = items.subject_title;

        let ticketSubjectAttr = {
            id: `new-ticket-subject`,
            name: `subject`,
            placeholder: items.subject_placeholder,
            isRequired: true,
        }

        let submitTitle = __('Submit Ticket');
        let showCancel = false;

        if (onlyAddNewTicket) {
            subjectTitle = 'Subject';
            submitTitle = onlyAddNewTicket.submitTitle;
            showCancel = onlyAddNewTicket.showCancel;
            messageTitle = onlyAddNewTicket.messageTitle;
            messagePlaceholder = onlyAddNewTicket.messagePlaceholder;
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
            ticketSubjectAttr.value = onlyAddNewTicket.ticketSubject;
            ticketSubjectAttr.disabled = !!(onlyAddNewTicket && typeof onlyAddNewTicket.ticketSubject !== 'undefined');
        }

        // console.log(this.props);
        return (
            <>
                {(contactUs && !onlyAddNewTicket) ?
                    <h3 block="Popup" elem="Heading">
                        {items.form_title}
                    </h3>
                    : ''
                }
                {(items.help_text && !onlyAddNewTicket) ?
                    <div>
                        <p className="cms-content-important">{items.help_text}</p>
                    </div>
                    : ''}
                {(contactUs && items.schedule_in_contact_us && !onlyAddNewTicket) ? this.renderSchedule() : ''}
                {(!contactUs && items.schedule_in_popup && !onlyAddNewTicket) ? this.renderSchedule() : ''}
                {(!onlyAddNewTicket) ? this.renderWorkingHours() : ''}
                <Field
                    addRequiredTag={true}
                    label={subjectTitle}
                    type={FieldType.TEXT}
                    attr={ticketSubjectAttr}
                    events={{
                        onChange: this.handleFieldChange.bind(this)
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            if (!(value)) {
                                delete preparedTicketData['subject'];
                                document.getElementById('submit-new-ticket').classList.add('disabled');
                            }
                            return !!(value);

                        },
                        customErrorMessages: {
                            onMatchFail: 'This field is required!'
                        }
                    }}
                />
                <Field
                    addRequiredTag={true}
                    label={messageTitle}
                    type={FieldType.TEXTAREA}
                    attr={{
                        id: `helpdesk-new-message`,
                        name: `message`,
                        placeholder: messagePlaceholder,
                        isRequired: true
                    }}
                    events={{
                        onChange: this.handleFieldChange.bind(this)
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            if (!(value)) {
                                delete preparedTicketData['message'];
                                document.getElementById('submit-new-ticket').classList.add('disabled');
                            }
                            return !!(value);

                        },
                        customErrorMessages: {
                            onMatchFail: 'This field is required!'
                        }
                    }}
                />

                {!items.customer ?
                    <>
                        <Field
                            addRequiredTag={true}
                            label={__('Name')}
                            type={FieldType.TEXT}
                            attr={{
                                id: `new-ticket-customer-name`,
                                name: `customer_name`,
                                placeholder: __('Your Name'),
                                isRequired: true
                            }}
                            events={{
                                onChange: this.handleFieldChange.bind(this)
                            }}
                            validateOn={['onChange']}
                            validationRule={{
                                match: (value) => {
                                    if (!(value)) {
                                        delete preparedTicketData['customer-name'];
                                        document.getElementById('submit-new-ticket').classList.add('disabled');
                                    }
                                    return !!(value);

                                },
                                customErrorMessages: {
                                    onMatchFail: 'This field is required!'
                                }
                            }}
                        />
                        <Field
                            addRequiredTag={true}
                            label={__('Email')}
                            type={FieldType.TEXT}
                            attr={{
                                id: `new-ticket-customer-email`,
                                name: `customer_email`,
                                placeholder: __('Your Email'),
                                isRequired: true
                            }}
                            events={{
                                onChange: this.handleFieldChange.bind(this)
                            }}
                            validateOn={['onChange']}
                            validationRule={{
                                inputType: ValidationInputType.EMAIL,
                                match: (value) => {
                                    let valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    if (!valid.test(value)) {
                                        delete preparedTicketData['customer-email'];
                                        document.getElementById('submit-new-ticket').classList.add('disabled');
                                        return true;
                                    }
                                    return !!(value);
                                },
                                customErrorMessages: {
                                    onMatchFail: 'This field is required!'
                                }
                            }}
                        />
                    </>
                    : ''}

                <div block="OptionsWrapper">
                {(!onlyAddNewTicket) ? this.renderDropDowns('Priority', 'priority_id', items.priority) : ''}
                {(!onlyAddNewTicket) ? this.renderDropDowns('Department', 'department_id', items.department) : ''}

                {(items.is_custom_fields && !onlyAddNewTicket) ?
                    items.is_custom_fields.map((item, i) => {
                        let fieldType;
                        if (item.type === 'text') {
                            fieldType = FieldType.TEXT;
                        }
                        if (item.type === 'textarea') {
                            fieldType = FieldType.TEXTAREA;
                        }
                        if (item.type === 'select') {
                            fieldType = FieldType.SELECT;
                        }
                        if (item.type === 'checkbox') {
                            fieldType = FieldType.CHECKBOX;
                        }
                        if (item.type === 'date') {
                            fieldType = FieldType.DATE;
                        }
                        let options;
                        if (item.values) {
                            options = item.values.map(({id, name}) => ({id, label: name, value: id}));
                        } else {
                            options = false;
                        }
                        return (
                            <Field
                                addRequiredTag={item.is_required}
                                label={item.name}
                                type={fieldType}
                                options={options}
                                attr={{
                                    id: `helpdesk-new-message-${item.code}`,
                                    name: item.code,
                                    placeholder: __('Your Ticket') + ' ' + item.name,
                                    isRequired: item.is_required,
                                    helpdeskCustomField: true
                                }}
                                events={{
                                    onChange: this.handleFieldChange.bind(this)
                                }}
                                validateOn={['onChange']}
                                validationRule={{
                                    match: (value) => {
                                        if (!item.is_required) {
                                            delete preparedTicketData[item.code];
                                            return true;
                                        }
                                        if (!(value)) {
                                            delete preparedTicketData[item.code];
                                            document.getElementById('submit-new-ticket').classList.add('disabled');
                                        }
                                        return !!(value);
                                    },
                                    customErrorMessages: {
                                        onMatchFail: 'This field is required!'
                                    }
                                }}
                            />
                        )
                    }) : ''
                }
                </div>
                <div className='helpdesk-popup-bottom'>
                    {(!onlyAddNewTicket) ? this.renderAttachments() : ''}
                    <div block="SubmitNewTicket" elem="Actions">
                        {
                            (showCancel) ?
                                <button
                                    id="submitnewticket-cancel"
                                    className="SubmitNewTicket-Cancel"
                                    type="button"
                                    block="Button"
                                    mix={ { block: 'CancelButton', elem: 'Cancel' } }
                                    onClick={this.closeCustomTicketDataPopup}
                                >
                                    { __('Cancel') }
                                </button>
                                : ''
                        }
                    <button
                        // disabled
                        id="submit-new-ticket"
                        block="Button disabled"
                        onClick={this.submitNewTicket.bind(this)}
                    >
                        {(contactUs && !onlyAddNewTicket) ? __('Submit') : submitTitle }
                    </button>
                    </div>
                </div>
                <Loader isLoading={true}/>
            </>
        )
    }

    renderFeedBackButton() {

        const {isLoading, items, onButtonClick, onlyAddNewTicket} = this.props;
        if (isLoading) {
            return null;
        }

        if (onlyAddNewTicket) {

            let mix = (typeof onlyAddNewTicket.mix !== 'undefined') ? onlyAddNewTicket.mix : {block: 'NewTicketPopup'};

            let customPopUpId = 'NewCustomRequestPopup';

            if (typeof onlyAddNewTicket.customPopUpId !== 'undefined') {
                customPopUpId = customPopUpId + '-' + onlyAddNewTicket.customPopUpId;
            }

            return (
                <>
                    <>
                        <span
                            block="RequestCancellation"
                            // mix={{block: 'NewTicket', elem: 'Button'}}
                            // mods={{isHollow: true}}
                            onClick={onButtonClick}
                        >
                            {onlyAddNewTicket.buttonTitle}
                        </span>
                        <Popup
                            // id="NewCustomRequestPopup"
                            id={customPopUpId}
                            mix={mix}
                            onClose={this.closeNewTicketPopup.bind(this)}
                            clickOutside={false}
                            hasFooter={true}
                        >
                            <Loader isLoading={true}/>
                            <div>
                                {this.ticketNote()}
                                {this.renderContent()}
                            </div>
                            {this.renderSchedule(true)}

                        </Popup>
                    </>
                </>
            )
        }

        if (!items.is_enabled) {
            return null;
        }

        return (
            <>
                <div id="hdmx__contact-button"
                     className={'hdmx__contact-button hdmx__contact-button-' + items.position}
                     style={{backgroundColor: items.button_color}}
                     onClick={onButtonClick}
                >
                    <span>{items.title}</span>
                </div>
                <Popup
                    id="NewContactUsPopup"
                    mix={{block: 'NewContactUsPopup'}}
                    onClose={this.closeNewTicketPopup.bind(this)}
                >
                    {this.renderContent()}
                </Popup>
            </>

        );
    }

    ticketNote() {
        const {onlyAddNewTicket} = this.props;
        if (onlyAddNewTicket) {
            return (
                <div block="NewTicketPopup" elem="Note">
                    <span>Note:</span>&nbsp;
                    <Html content={onlyAddNewTicket.ticketNote} />
                </div>
            )
        }
    }

    renderSchedule(responseTime = false) {
        const {items: {schedule_is_open, open_message, open_in, closed_message, closed_in}} = this.props;
        if (schedule_is_open) {
            return (
                <div className="hdmx__schedule-status status-open">
                    {/*{open_message}*/}
                    <TimeCountDown messages={open_message.split('|')} source='opened' countDown={closed_in} />
                    {
                        (responseTime) ?
                            <div className='status-content response-time'>
                                Avg response time: 1 hr
                            </div>
                            : ''
                    }

                </div>
            )
        }

        if (closed_message) {
            return (
                <div className="hdmx__schedule-status status-close">
                    {/*{closed_message}*/}
                    <TimeCountDown messages={closed_message.split('|')} source='closed' countDown={open_in} />
                </div>
            )
        }

        return null;

    }

    renderWorkingHours() {
        const {items: {current_schedule_title, upcoming_schedule_title, working_hours}} = this.props;

        if (!working_hours) {
            return null;
        }

        if (working_hours.current_schedule && working_hours.upcoming_schedule) {
            return (
                <div block="ContactPage" elem="WorkingHoursColumnWrapper">
                    <div block="ContactPage" elem="Column">
                        {this.renderWorkingHoursTable(current_schedule_title, working_hours.current_schedule)}
                    </div>
                    <div block="ContactPage" elem="Column">
                        {this.renderWorkingHoursTable(upcoming_schedule_title, working_hours.upcoming_schedule)}
                    </div>
                </div>
            )
        }

        return this.renderWorkingHoursTable(current_schedule_title, working_hours.current_schedule);
    }

    renderWorkingHoursTable(title, content) {
        return (
            <>
                <h3 block="WorkingHours" elem="Heading">
                    {title}
                </h3>
                {Object.entries(content).map(([key, value]) => {
                    return (
                        <div className="WorkingHoursTable">
                            {!!(value.day) ? <span>{(value.day)}</span> : ''}
                            {!!(value.day) ? <span>{(value.time)}</span> : <h4>{(value.time)}</h4>}
                        </div>
                    );
                })}
            </>
        )
    }

    handleFieldChange(event, field) {

        let {items: {total_required, customer}, onlyAddNewTicket} = this.props;

        if (!customer) {
            total_required++;
            total_required++;
        }

        let {isRequired, value = ''} = field;

        if (onlyAddNewTicket) {
            // preparedTicketData = {};
            // preparedTicketDataCopy = {};
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
        }

        if (field.name.includes('checkbox') && typeof preparedTicketDataCopy[field.name] !== 'undefined') {
            value = preparedTicketDataCopy[field.name] = !preparedTicketDataCopy[field.name];
        }


        if (isRequired) {
            preparedTicketData[field.name] = value;
        }

        preparedTicketDataCopy[field.name] = value;


        if (!value) {
            delete preparedTicketData[field.name];
            delete preparedTicketDataCopy[field.name];
        }


        let filedFields = Object.keys(preparedTicketData).length;

        if (total_required === filedFields) {
            document.getElementById('submit-new-ticket').classList.remove('disabled');
        }
    }

    renderAttachments() {

        const {items: {attachment_is_allowed, attachment_size}} = this.props;

        if (!attachment_is_allowed) {
            return null;
        }

        return (
            <>
                <div className="max-attachment-size">
                    <label className="Field-Label">Attach Files</label>
                </div>
                <DropzoneArea
                    onChange={this.handleChange.bind(this)}
                    getPreviewIcon={handlePreviewIcon}
                    Icon={UploadIcon}
                    filesLimit={100}
                    showFileNames={true}
                    showAlerts={false}
                    dropzoneText={"Upload file (max file " + attachment_size + " Mb)"}
                />
            </>
        )


    }

    handleChange(files) {
        let preparedFiles = [];
        const {callBack} = this.props
        files.map((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                let dropZoneFile = {
                    name: file.name,
                    size: file.size,
                    type: !!(file.type) ? file.type : 'unknown',
                    data: reader.result,
                }
                preparedFiles.push(dropZoneFile);
            };

        })

        this.setState({files: files, preparedFiles: preparedFiles});
    }

    renderDropDowns(option, optionCode, source) {
        if (!source) {
            return null;
        }

        return (
            <Field
                addRequiredTag={(option !== 'Order')}
                label={option}
                type={FieldType.SELECT}
                options={source.map(({id, name}) => ({id, label: name, value: id}))}
                attr={{
                    id: `helpdesk-new-message-${option}`,
                    name: optionCode,
                    isRequired: (option !== 'Order')
                }}
                events={{
                    onChange: this.handleFieldChange.bind(this)
                }}
                validateOn={['onChange']}
                validationRule={{
                    match: (value) => {
                        if (option === 'Order') {
                            delete preparedTicketData[`option-helpdesk-new-message-${option}`];
                            return true;
                        }
                        if (!(value)) {
                            delete preparedTicketData[`option-helpdesk-new-message-${option}`];
                            document.getElementById('submit-new-ticket').classList.add('disabled');
                        }
                        return !!(value);
                    },
                    customErrorMessages: {
                        onMatchFail: 'This field is required!'
                    }
                }}
            />
        )
    }

    async submitNewTicket() {

        const {contactUs, getTicketList, showContactUs, showCustomRequest, showNotification, onlyAddNewTicket} = this.props;
        const {preparedFiles} = this.state;
        let ticketData = {
            'ticketFields': preparedTicketDataCopy,
            'attachments': preparedFiles
        }
        // document.querySelectorAll(
        //     '.NewContactUsPopup .Loader, .ContactPage .Loader, .NewTicketPopup .Loader'
        // ).forEach(n => n.style.display = "block");

        document.querySelectorAll(
            '.NewContactUsPopup .Loader, .ContactPage .Loader, .NewTicketPopup .Loader'
        ).forEach(function (element, index) {
            if (index === 0) {
                element.style.display = "block";
            }
        });

        const newTicketResponse = await fetchMutation(TicketQuery.createNewTicket(JSON.stringify(ticketData)));
        if (!contactUs) {
            if (onlyAddNewTicket) {
                showCustomRequest();
            }else {
                showContactUs();
            }

        } else {
            document.querySelectorAll(
                '.ContactPage .Loader'
            ).forEach(n => n.style.display = "none");
        }

        const {createNewTicket: {tickets_list}} = newTicketResponse;
        let result = JSON.parse(tickets_list);
        if (result.wrong) {
            showNotification('error', __('Something went wrong. Please refresh the page.'));
        } else {
            if (result.success) {
                if (contactUs) {
                    showNotification('success', __('Your ticket has been created.'));
                }
                if (onlyAddNewTicket) {
                    let customPopUpId = 'NewCustomRequestPopup';

                    if (typeof onlyAddNewTicket.customPopUpId !== 'undefined') {
                        customPopUpId = customPopUpId + '-' + onlyAddNewTicket.customPopUpId;
                    }
                    showCustomRequest({
                        customPopUpId: customPopUpId,
                        title: 'Submission Received',
                        successPopupContent: this.submissionRecieved(result.ticket_id)
                    });
                }else {
                    showContactUs({
                        title: 'Submission Received',
                        successPopupContent: this.submissionRecieved(result.ticket_id)
                    });
                }

            } else {
                showNotification('error', __('Your ticket has not been created.'));
            }
            if (result.customer) {
                getTicketList(1);
            }
        }

        this.closeNewTicketPopup();

    }

    submissionRecieved(ticketId) {
        return (
            <div className='SubmissionRecieved'>
                <h3>Ticket ID: #{ticketId}</h3>
                <p>Save this Ticket ID for reference. Our support team will be in contact with you shortly through email.</p>
                <Link
                    onClick={this.goToTickets}
                    to='/helpdesk/tickets'
                >
                    My Support Tickets
                </Link>
            </div>
        )
    }

    render() {

        const {isLoading} = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <div
                block="MyAccountFeedBackTab"
            >
                {this.renderFeedBackButton()}
            </div>
        );
    }
}

export default CustomRequestPopup;
