/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from "react";

import { ReactElement } from "Type/Common.type";

import { StartFill } from "./StarIcon.config";
import { StarIconComponentProps } from "./StarIcon.type";

import "./StarIcon.style";

/** @namespace Component/StarIcon/Component */
export class StarIconComponent extends PureComponent<StarIconComponentProps> {
    getStarPath(): ReactElement {
        const { starFill } = this.props;

        if (starFill === StartFill.EMPTY) {
            return (
                <path
                    stroke="#F4A509"
                    d="m12 0 3.1035 7.72839 8.3092.56341-6.3911 5.3398 2.0318 8.0766L12 17.28l-7.05342 4.4282 2.03184-8.0766L.587322 8.2918l8.309168-.56341L12 0Z"
                />
            );
        }

        if (starFill === StartFill.HALF_FULL) {
            return (
                <>
                    <defs>
                        <linearGradient id="star_half_full">
                            <stop offset="50%" stop-color="#F4A509" />
                            <stop offset="50%" stop-color="transparent" />
                        </linearGradient>
                    </defs>
                    <path
                        fill="url(#star_half_full)"
                        d="m12 0 3.1035 7.72839 8.3092.56341-6.3911 5.3398 2.0318 8.0766L12 17.28l-7.05342 4.4282 2.03184-8.0766L.587322 8.2918l8.309168-.56341L12 0Z"
                        stroke="#F4A509"
                    />
                </>
            );
        }

        if (starFill === StartFill.FULL) {
            return (
                <path
                    fill="#F4A509"
                    d="m12 0 3.1035 7.72839 8.3092.56341-6.3911 5.3398 2.0318 8.0766L12 17.28l-7.05342 4.4282 2.03184-8.0766L.587322 8.2918l8.309168-.56341L12 0Z"
                />
            );
        }

        return null;
    }

    render(): ReactElement {
        const { starFill } = this.props;

        return (
            <svg
                block="StarIcon"
                mix={{ block: starFill === StartFill.FULL ? "" : "not-full" }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {this.getStarPath()}
            </svg>
        );
    }
}

export default StarIconComponent;
