/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';
import {connect} from 'react-redux';
import TrackingQuery from './../../query/TrackOrder.query';
import {showNotification} from 'Store/Notification/Notification.action';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchQuery } from 'Util/Request/Query';
import Tracking from './Tracking.component';



/** @namespace Component/Tracking/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Component/Tracking/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('info', message)),
});

/** @namespace Component/Tracking/Container */
export class TrackingContainer extends PureComponent {
    state = {
        isLoading: false,
        statuses: '',
    };


    // __construct(props) {
    //     super.__construct(props);
    //     console.clear();
    //
    //     const { orderId } = this.props;
    //     console.log(orderId)
    //
    //     if (orderId) {
    //         this.requestDownloadable(orderId);
    //     }
    // }

    componentDidMount() {
        const { orderId } = this.props;
        console.log(orderId)

        if (orderId) {
            this.requestDownloadable(orderId);
        }
    }


    containerProps = () => {

        const {
            isLoading,
            statuses

        } = this.state;

        const {
            isMobile,
            statusBarProgress
        } = this.props;

        return {
            isLoading,
            isMobile,
            statuses,
            statusBarProgress
        };
    };


    requestDownloadable(orderId) {
        const {showSuccessNotification, showErrorNotification} = this.props;

        this.setState({isLoading: true});
        // return;

        fetchQuery(
            TrackingQuery.getTracking(orderId)
        ).then(
            /** @namespace Component/Tracking/Container/requestDownloadable/success */
            (
                /** @namespace Component/Tracking/Container/TrackingContainer/requestDownloadable/fetchQuery/then */
                async (data) => {

                    const {magenearTrackOrder: {magenear_trackorder}} = data;
                    let statuses = JSON.parse(magenear_trackorder);
                    console.log(statuses);
                    this.setState({statuses: statuses});
                    this.setState({isLoading: false});

                }),
            /** @namespace Component/Tracking/Container/TrackingContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                console.clear();
                console.log(err);
                showErrorNotification(getErrorMessage(err));
                this.setState({isLoading: false});
            }
        );
    }



    render() {
        console.log(this.state);
        console.log(this.props);
        return (
            <Tracking
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingContainer);
