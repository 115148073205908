import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Loader from "./../../../../../src/component/Loader/Loader.component";
import './PayNow.style';
import Field from "./../../../../../src/component/Field/Field.container";
import FIELD_TYPE from './../../../../../src/component/Field/Field.config';
import Form from '@scandipwa/scandipwa/src/component/Form';
import {VALIDATION_INPUT_TYPE} from '@scandipwa/scandipwa/src/util/Validator/Config';
import TextPlaceholder from "@scandipwa/scandipwa/src/component/TextPlaceholder";
import Html from "./../../../../../src/component/Html";

export class PayNow extends PureComponent {

    render() {

        const { payNow } = this.props;
        // const {
        //     isLoading,
        //     onFormError,
        //     getOrderData,
        //     trackAnotherOrder,
        //     items,
        //     orderInfo,
        //     directTrack,
        //     page: {
        //         title, content_heading, content
        //     }
        // } = this.props;

console.log(this.props);



        return (
            <span className='pay-now Button Button_isHollow' onClick={payNow}>Pay Now</span>
        )
    }

}

export default PayNow;
