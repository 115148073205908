import './_uniques.scss';
import UniquesCategoryProductsWidget from "./../component/UniquesCategoryProductsWidget";
import RenderWhenVisible from "Component/RenderWhenVisible";
import { Suspense } from 'react';

const renderContent = (args, callback, instance) => {

    const {
        type
    } = instance.props;

    if (type !== 'UniquesCategoryProducts') {
        return callback.apply(instance, args);
    }

    instance.renderMap['UniquesCategoryProducts'] = {
        component: UniquesCategoryProductsWidget
    }

    const {
        sliderId = 0,
        blockId = 0,
        displayType,
        productsCount,
        showPager,
        storeId,
        title,
        conditionsEncoded,
        onLoad,
    } = instance.props;

    const {
        component: Widget,
        fallback = () => <div />,
    } = instance.renderMap[type] || {};

    if (Widget !== undefined) {
        return (
            <Suspense fallback={ fallback() }>
                <RenderWhenVisible>
                    <Widget
                      sliderId={ sliderId }
                      blockId={ blockId }
                      displayType={ displayType }
                      productsCount={ productsCount }
                      showPager={ !!showPager }
                      storeId={ storeId }
                      title={ title }
                      conditionsEncoded={ conditionsEncoded }
                      onLoad={ onLoad }
                    />
                </RenderWhenVisible>
            </Suspense>
        );
    }

    return callback.apply(instance, args);
};

export default {
    'Component/WidgetFactory/Component': {
        'member-function': {
            renderContent
        }
    }
};
