/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';
import {connect} from 'react-redux';
import ShippingSystemQuery from './../../query/ShippingSystem.query';
import {showNotification} from 'Store/Notification/Notification.action';
import {fetchQuery} from 'Util/Request/Query';

import PinChecker from './PinChecker.component';
import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace Component/PinChecker/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    shippingCharge: state.ConfigReducer.shippingsystem_shipping_charge,
    shippingMode: state.ConfigReducer.shippingsystem_shipping_mode,
    courierNotice: state.ConfigReducer.shippingsystem_courier_notice,
});

/** @namespace Component/PinChecker/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('info', message))
});

/** @namespace Component/PinChecker/Container */
export class PinCheckerContainer extends PureComponent {

    state = {
        items: [],
        isLoading: false,
        couriers: [],
    };

    componentDidMount() {
        window.selectDeliveryLocationPinChecker = this.requestDownloadable.bind(this);
        let pinCode = BrowserDatabase.getItem('selected_delivery_pincode');
        if (!pinCode) {
            return;
        }
        this.requestDownloadable();
    }

    // componentDidUpdate(prevProps) {
    //     const {zip:prevZip} = prevProps;
    //     const {zip} = this.props;
    //     if (prevZip !== zip) {
    //         this.requestDownloadable();
    //     }
    // }

    containerFunctions = {
        onCheckPinCode: this.onCheckPinCode.bind(this),
    };

    onCheckPinCode(pinCode) {
        BrowserDatabase.setItem(pinCode, 'selected_delivery_pincode');
        this.requestDownloadable();
    }

    containerProps = () => {
        const {
            isLoading,
            couriers,
            checkedPinCode,
            checkedPinCodeEta
        } = this.state;

        const {isMobile, shippingCharge, shippingMode, courierNotice} = this.props;

        return {
            couriers,
            isLoading,
            isMobile,
            shippingCharge,
            shippingMode,
            courierNotice,
            checkedPinCode,
            checkedPinCodeEta,
            items: this._prepareDownloadableProps(),
        };
    };

    _prepareDownloadableProps() {
        const {items} = this.state;
        return items;
    }

    requestDownloadable(fromHeader = false) {
        // console.log(fromHeader);
        let zip = BrowserDatabase.getItem('selected_delivery_pincode');


        if (!zip) {
            return;
        }

        // console.log(zip);
        // const {showSuccessNotification, showErrorNotification} = this.props;

        const selectedDeliveryCity = BrowserDatabase.getItem('selected_delivery_city');
        const selectedDeliveryPincode = BrowserDatabase.getItem('selected_delivery_pincode');
        const selectedDeliveryEta = BrowserDatabase.getItem('selected_delivery_eta');
        const selectedDeliverySku = BrowserDatabase.getItem('selected_delivery_sku');
        // console.log(selectedDeliveryCity);
        // console.log(!selectedDeliveryPincode);
        // console.log(selectedDeliveryEta);
        // console.log(selectedDeliverySku);
        // console.log(window.checkedPinCode);
        // document.getElementById("CheckoutDeliveryHolder")?.classList.add('loading');

        const cart = BrowserDatabase.getItem('cart_totals');
        // const cartId = BrowserDatabase.getItem('cart_id');
        const {items} = cart;
        // console.log(cartId);
        // console.log(items);
        // console.log(window.localStorage);
        // console.log(JSON.parse(window.localStorage.cart_totals));

        // let zip = document.querySelector('input[name=postcode]').value;
        // console.log(zip);
        // document.getElementById("ProceedToBilling").setAttribute("disabled", true);
        // if (typeof window.checkedPinCode !== 'undefined') {
        if (!!selectedDeliveryEta) {
            this.setState({items, checkedPinCode: selectedDeliveryPincode, checkedPinCodeEta:selectedDeliveryEta});
            document.getElementById("pinCodeNote")?.classList.add('pinCodeChecked');
            return;
        }
        this.setState({isLoading: true});
        fetchQuery(
            ShippingSystemQuery.getShippingSystem(selectedDeliverySku, zip, '',true)
            // ShippingSystemQuery.getShippingSystem(window.localStorage.cart_totals)
        ).then(
            /** @namespace Component/PinChecker/Container/requestDownloadable/success */
            (
                /** @namespace Component/PinChecker/Container/PinCheckerContainer/requestDownloadable/fetchQuery/then */
                async (data) => {
                    // console.clear();
                    // console.log(data);
                    let couriers = JSON.parse(data.shippingSystem.shippingsystem_data);
                    // console.log(couriers);

                    this.setState({couriers, items, isLoading: false, checkedPinCode: zip});

                    console.log('request form header: ' + fromHeader);
                    if (!fromHeader && typeof window.selectDeliveryLocationFindPinCode !== 'undefined') {
                        window.selectDeliveryLocationFindPinCode(zip);
                        window.selectDeliveryLocationFindPinCode(zip);
                    }

                    if (!!selectedDeliveryCity && selectedDeliveryPincode !== zip) {
                        //@ts-ignore
                        BrowserDatabase.setItem('testtesttesttesttesttesttesttesttest', 'selected_delivery_city');
                    }

                    //@ts-ignore
                    BrowserDatabase.setItem(zip, 'selected_delivery_pincode');

                    if (couriers.length) {
                        const recommended = couriers[0];
                        // console.log(recommended);
                        // const dispatchEvent = function(element, eventName) {
                        //     if ('createEvent' in document) {
                        //         var event = document.createEvent('HTMLEvents');
                        //         event.initEvent(eventName, true, true);
                        //         element.dispatchEvent(event);
                        //     } else {
                        //         element.fireEvent(eventName); // only for backward compatibility (older browsers)
                        //     }
                        // };
                        this.setState({checkedPinCodeEta:recommended});

                        BrowserDatabase.setItem(recommended, 'selected_delivery_eta');

                        // window.pinCodeChecker = false;
                        // document.getElementById("pinCode").value = '';
                        // document.getElementById("ResultPinCode").value = zip;

                        // dispatchEvent(document.getElementById("pinCode"), 'change');
                        // dispatchEvent(document.getElementById("ResultPinCode"), 'change');
                    }else {

                        if (typeof window.selectDeliveryLocationFindPinCode !== 'undefined') {
                            window.selectDeliveryLocationFindPinCode(zip);
                        }

                        //@ts-ignore
                        BrowserDatabase.setItem(null, 'selected_delivery_city');
                        //@ts-ignore
                        BrowserDatabase.setItem(null, 'selected_delivery_pincode');

                        if (typeof couriers.status !== 'undefined') {
                            if (couriers.status === 404) {
                                this.setState({checkedPinCodeEta:couriers.message});
                                BrowserDatabase.setItem(couriers.message, 'selected_delivery_eta');
                            }
                        }else {
                            this.setState({checkedPinCodeEta:'Sorry, we don’t service this pincode'});
                            BrowserDatabase.setItem('Sorry, we don’t service this pincode', 'selected_delivery_eta');
                        }

                    }

                    document.getElementById("pinCodeNote")?.classList.add('pinCodeChecked');
                }),
            /** @namespace Component/PinChecker/Container/PinCheckerContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                // showErrorNotification(getErrorMessage(err));
                this.setState({isLoading: false});
            }
        );
    }

    render() {
        return (
            <PinChecker
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PinCheckerContainer);
