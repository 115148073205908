import {createRef, PureComponent} from 'react';
import './PopUpWithImages.style';
import ProductReviewRating from "./../ProductReviewRating";
import Html from "Component/Html";
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {
    Autoplay,
    EffectCreative,
    EffectFade,
    FreeMode,
    Navigation,
    Pagination as SwiperPagination,
    Thumbs
} from "swiper";
// Import Swiper styles
// import 'swiper/swiper-bundle.css';
import Image from 'Component/Image';
import Loader from "Component/Loader/Loader.component";
import {Scrollbars} from 'react-custom-scrollbars';
import ChevronIcon from "Component/ChevronIcon";
import {Directions} from 'Component/ChevronIcon/ChevronIcon.config';
// import SwipeToNext from './../SwipeToNext';
import Draggable from 'Component/Draggable';
// import {SwipeToNextComponentProps} from "../SwipeToNext/SwipeToNext.type";
import CloseIcon from 'Component/CloseIcon';
import getStore from "Util/Store";
import Link from 'Component/Link';
let reviewPopupData = false;
let totalReplyFields = 1;
let replyToValidate = {};
let reviewToReply = null;

let lastX, lastY, currentDrag, sidePosition;

let swiperWasRendered = false;

const resetControls = () => {
    // document.getElementById(currentDrag).classList.remove('switchNext', 'switchPrev')
    lastX = false;
    lastY = false;
    currentDrag = false;
    // document.getElementById('prevReviewMobile').style = 'transform: translateX(0px);';
    // document.getElementById('nextReviewMobile').style = 'transform: translateX(0px);';
}

const getPageWidth = () => {
    const bodyMax = document.body
        ? Math.max(document.body.scrollWidth, document.body.offsetWidth)
        : 0;

    const docElementMax = document.documentElement
        ? Math.max(
            document.documentElement.scrollWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        )
        : 0;

    return Math.max(bodyMax, docElementMax);
};

export class PopUpWithImages extends PureComponent {

    state = {
        isLoaded: false,
        currentReview: 0,
        galleryThumbsSwiper: undefined
    }


    reviewScrolledContent = createRef();

    handlePrevReview = this.previousReview.bind(this);
    handleNextReview = this.nextReview.bind(this);
    handleClosePopup = this.closePopup.bind(this);
    handleCloseTip = this.closeTip.bind(this);

    __construct() {
        super.__construct?.(props);

        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
    }


    componentWillMount() {
        document.body.classList.add('reviewPopUp', 'reviewPopUpTip');
        const {handlePaginationLinkClick} = this.props;
        document.addEventListener('click', handlePaginationLinkClick);
    }

    componentWillUnmount() {
        document.body.classList.remove('reviewPopUp');
        const {handlePaginationLinkClick} = this.props;
        document.removeEventListener('click', handlePaginationLinkClick);
    }

    previousReview() {
        let {currentReview} = this.state;
        let {advancedReview: {reviews_list}} = this.props;
        let totalReviewsWithImages = reviews_list.length;
        totalReviewsWithImages--;
        currentReview--;
        if (currentReview === -1) {
            currentReview = totalReviewsWithImages;
        }
        let prevReview = reviews_list[currentReview].review_images.length;
        prevReview--;
        this.setState({
            currentReview: currentReview,
            reviewPopupData: reviews_list[currentReview].review_images[prevReview]
        })
        this.fadeIn();
    }

    nextReview() {
        let {currentReview} = this.state;
        let {advancedReview: {reviews_list}} = this.props;
        let totalReviewsWithImages = reviews_list.length;
        totalReviewsWithImages--;
        currentReview++;
        if (currentReview > totalReviewsWithImages) {
            currentReview = 0;
        }
        this.setState({
            currentReview: currentReview,
            reviewPopupData: reviews_list[currentReview].review_images[0]
        })
        this.fadeIn();
    }

    closePopup() {
        const closeButton = document.getElementsByClassName('Header-Button_type_close');
        closeButton[0].click();
    }

    closeTip() {
        document.body.classList.remove('reviewPopUpTip');
    }

    handleDrag(event) {

        const {clientX, originalX, clientY, originalY, translateX, translateY} = event;

        if (clientX < 0 || clientX > getPageWidth()) {
            return;
        }

        if (!lastX) {
            lastX = originalX;
        }


        if (clientY < 470 && !document.body.classList.contains('amreview-popup-no-images')) {
            return;
        }


        // if (clientY > lastY) {
        //     console.log('move down');
        // } else if (clientY < lastY) {
        //     console.log('move up');
        // }

        lastY = clientY;

        let direction;
        if (clientX > lastX && !currentDrag) {
            currentDrag = 'prevReviewMobile';
            direction = 'right';
        } else if (clientX < lastX && !currentDrag) {
            currentDrag = 'nextReviewMobile';
            direction = 'left';
        }

        lastX = clientX;

        let review = document.getElementById(currentDrag);

        if (!review) {
            return;
        }

        let sidePosition = Math.abs(translateX);

        let detecor = getPageWidth() / 2;

        if (clientX >= getPageWidth()) {
            review.classList.add('switched');
            review.style = 'transform: translateX(' + getPageWidth() + 'px);';
            return;
        }
        if (clientX < 0) {
            review.classList.add('switched');
            review.style = 'transform: translateX(-' + getPageWidth() + 'px);';
            return;
        }

        review.style = 'transform: translateX(' + translateX + 'px);';

    }

    handleDragStart(event) {
        document.getElementById('prevReviewMobile').classList.remove('appearance', 'switchPrev', 'switchNext');
        document.getElementById('nextReviewMobile').classList.remove('appearance', 'switchPrev', 'switchNext');
        lastX = false;
        lastY = false;
        currentDrag = false;
        document.body.classList.add('controlled');
    }

    handleDragEnd(event) {

        const {clientX, originalX, clientY, originalY, translateX, translateY} = event;

        document.body.classList.remove('controlled');
        let review = document.getElementById('prevReviewMobile');

        if (!review) {
            return;
        }

        sidePosition = Math.abs(translateX);

        let detecor = getPageWidth() / 2;

        let switchReview = document.getElementById(currentDrag);

        if (sidePosition >= detecor && currentDrag === 'nextReviewMobile') {

            switchReview.style = 'transform: translateX(-' + getPageWidth() + 'px);';
            switchReview.classList.add('switchNext');

            switchReview.ontransitionend = (l) => {
                if (sidePosition >= detecor && currentDrag === 'nextReviewMobile') {
                    switchReview.classList.remove('switchNext');
                    resetControls();
                    window.handleNextReview();
                    switchReview.style = 'transform: translateX(0px);';
                }

            }

            return;

        } else if (sidePosition >= detecor && currentDrag === 'prevReviewMobile') {

            switchReview.style = 'transform: translateX(' + getPageWidth() + 'px);';
            switchReview.classList.add('switchPrev');

            switchReview.ontransitionend = (l) => {
                if (sidePosition >= detecor && currentDrag === 'prevReviewMobile') {
                    switchReview.classList.remove('switchPrev');
                    resetControls();
                    window.handlePrevReview();
                    switchReview.style = 'transform: translateX(-' + getPageWidth() + 'px);';
                }

            }

            return;

        }



        review.style = 'transform: translateX(0px);';
        review.classList.add('appearance');
        review = document.getElementById('nextReviewMobile');

        if (!review) {
            return;
        }

        review.style = 'transform: translateX(0px);';
        review.classList.add('appearance');

        lastX = false;
        lastY = false;
        currentDrag = false;

    }

    fadeIn() {
        this.reviewScrolledContent.current.scrollTop();
        swiperWasRendered = true;
        const {device: {isMobile}} = this.props;

        if (isMobile) {
            return;
        }
        return;

        const {isLoaded} = this.state;

        if (isLoaded) {
            document.getElementById('amreview-popup').classList.remove('firsttime');
        }

        document.querySelectorAll('[class*="amreview-cloned"]').forEach(n => n.remove());
        const node = document.getElementById("amreview-popup");
        const clonedNode = document.getElementById("amreview-popup-wrapper");
        const clone = clonedNode.cloneNode(true);
        clone.classList.add('amreview-cloned');

        if (!clone.classList.contains('no-images')) {
            if (node.classList.contains('switch')) {
                document.getElementById('amreview-popup').classList.remove('switch');
            }
            node.append(clone);
            document.getElementById('amreview-popup-wrapper').setAttribute('amreview', 'show-review');
            const animated = document.querySelector('[amreview*="show-review"]');
            animated.onanimationend = (l) => {
                document.getElementById('amreview-popup-wrapper').removeAttribute('amreview');
            }

            const animatedClone = document.querySelector('[class*="amreview-cloned"]');
            animatedClone.onanimationend = (l) => {
                document.querySelectorAll('[class*="amreview-cloned"]').forEach(n => n.remove());
            }

        } else {
            document.getElementById('amreview-popup').classList.add('switch');
        }

    }

    renderCloseButton() {
        const { device: { isMobile } } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <button
                key="close"
                block="AmastyPopup"
                elem="Close"
                onClick={ this.handleClosePopup }
                aria-label="Close"
            >
                <CloseIcon />
            </button>
        );
    }

    renderRatingSchema(percent, reviewCount, singleReview = false) {

        if (singleReview) {
            return (
                <>
                    <meta itemProp="ratingValue" content={percent}/>
                    <meta itemProp="worstRating" content={0}/>
                    <meta itemProp="bestRating" content={100}/>
                </>
            );
        }

        return (
            <>
                <meta itemProp="ratingValue" content={percent}/>
                <meta itemProp="worstRating" content={0}/>
                <meta itemProp="bestRating" content={100}/>
                <meta itemProp="reviewCount" content={reviewCount}/>
            </>
        );
    }


    renderProsAndCons(review) {

        const {advancedReview: {config: {is_pros_cons_enabled}}} = this.props;
        if (!is_pros_cons_enabled) {
            return null;
        }

        return (
            <>
                {
                    (review.like_about) ? <div className='review-pros'>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_138_5103)">
                                <path
                                    d="M9.07422 16.5C13.2164 16.5 16.5742 13.1421 16.5742 9C16.5742 4.85786 13.2164 1.5 9.07422 1.5C4.93208 1.5 1.57422 4.85786 1.57422 9C1.57422 13.1421 4.93208 16.5 9.07422 16.5Z"
                                    stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.07422 6V12" stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M6.07422 9H12.0742" stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_138_5103">
                                    <rect width="18" height="18" fill="white" transform="translate(0.0742188)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span>{review.like_about}</span>
                    </div> : ''
                }

                {
                    (review.not_like_about) ? <div className='review-cons'>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_138_5106)">
                                <path
                                    d="M9.07422 16.5C13.2164 16.5 16.5742 13.1421 16.5742 9C16.5742 4.85786 13.2164 1.5 9.07422 1.5C4.93208 1.5 1.57422 4.85786 1.57422 9C1.57422 13.1421 4.93208 16.5 9.07422 16.5Z"
                                    stroke="#E31620" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.07422 9H12.0742" stroke="#E31620" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_138_5106">
                                    <rect width="18" height="18" fill="white" transform="translate(0.0742188)"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <span>{review.not_like_about}</span>
                    </div> : ''
                }

            </>
        )
    }


    reviewPopupClone(current) {

        let {isLoaded, currentReview} = this.state;

        if (!isLoaded) {
            return;
        }

        const {product: {name, thumbnail, small_image, image}, device: {isMobile, safari}, advancedReview: {reviews_list}} = this.props;

        if (typeof reviews_list === 'undefined') {
            return <Loader isLoading={true}/>;
        }

        reviews_list.sort((a, b) => a.review_id - b.review_id)

        let totalReviews = reviews_list.length;
        totalReviews--

        if (current === 'next') {
            currentReview++;
            if (currentReview > totalReviews) {
                currentReview = 0;
            }
        }
        if (current === 'prev') {
            currentReview--;
            if (currentReview < 0) {
                currentReview = totalReviews;
            }

        }

        const review = reviews_list[currentReview];
        const reviewImages = review.review_images[0]

        let noImagesInPopup = '';

        if (reviewImages.path.includes('data:image/png;base64')) {
            noImagesInPopup = 'no-images';
        }

        // this.setState({galleryThumbsSwiperprev: undefined, galleryThumbsSwipernext: undefined});

        const thumbsSwiper = <Swiper
            onSwiper={(swiper) => {
                this.setState({[`galleryThumbsSwiper${current}`]: swiper});
            }}
            spaceBetween={12}
            slidesPerView={4}
            freeMode={true}
            navigation={{
                prevEl: "#swiper-button-prev-thumb-popup-image",
                nextEl: "#swiper-button-next-thumb-popup-image",
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className={"thumbs-images long_" + review.review_images.length}
        >
            {
                review.review_images.map((image, index) => {
                    return (
                        <SwiperSlide>
                            <Image
                                src={image.path}
                            />
                        </SwiperSlide>)
                })
            }

        </Swiper>;
        const mainSwiper = <Swiper
            speed={500}
            effect={"fade"}
            fadeEffect={{
                crossFade: true
            }}
            loop={true}
            centeredSlides={true}
            navigation={{
                prevEl: "#swiper-button-prev-review-popup-image",
                nextEl: "#swiper-button-next-review-popup-image",
            }}
            pagination={{
                type: "fraction",
            }}
            modules={[Autoplay, Navigation, SwiperPagination, FreeMode, Thumbs, EffectFade, EffectCreative]}
            slidesPerView={1}
            thumbs={{swiper: this.state[`galleryThumbsSwiper${current}`]}}
        >
            {
                review.review_images.map((image, index) => {
                    return (
                        <SwiperSlide>
                            <Image
                                src={image.path}
                            />
                        </SwiperSlide>)
                })
            }
        </Swiper>;

        let reviewNumber = currentReview;
        reviewNumber++;
        let ending = '';
        if (reviews_list.length > 1) {
            ending = 's';
        }
        const currentReviewInfo = reviewNumber + ' of ' + reviews_list.length + ' review' + ending;

        return (
            <>
                <div className="reviews-control-wrapper" mix={{block: noImagesInPopup}}>
                        <span id="reviews-control-current-side">{currentReviewInfo}</span>
                    {this.renderCloseButton()}
                </div>
                <div id="amreview-popup-wrapper" className="sides"
                     mix={{block: noImagesInPopup, mods: {backfaceVisibility: (safari) ? 'hidden' : ''}}}>
                    <div className='left-side'>
                            {mainSwiper}
                            {
                                (isMobile) ?
                                    <>
                                        <div id="thumbsWrapper">
                                            {thumbsSwiper}
                                            <div className="swiper-button-prev"
                                                 id="swiper-button-prev-thumb-popup-image">
                                                <ChevronIcon direction={Directions.LEFT}/>
                                            </div>

                                            <div className="swiper-button-prev"
                                                 id="swiper-button-next-thumb-popup-image">
                                                <ChevronIcon direction={Directions.RIGHT}/>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }

                            <div className="swiper-button-prev"
                                 id="swiper-button-prev-review-popup-image"></div>
                            <div className="swiper-button-next"
                                 id="swiper-button-next-review-popup-image"></div>
                            <div id="swiper-button-prev-review-popup-placeholder"
                                 onClick={this.handlePrevReview}>prev
                            </div>
                            <div id="swiper-button-next-review-popup-placeholder"
                                 onClick={this.handleNextReview}>next
                            </div>
                    </div>
                    <div className='right-side'>
                        {
                            (isMobile) ?
                                <div id="swipeTip">
                                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.40839 11.4324L6.97645 6.91976C6.74156 6.17947 5.95101 5.76983 5.21072 6.00472C4.47042 6.23961 4.06078 7.03015 4.29567 7.77045L6.56409 14.9192" stroke="#6B2A2A" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.3029 11.2555L11.1463 10.762C10.9114 10.0217 10.1209 9.61205 9.38058 9.84694C8.64028 10.0818 8.23064 10.8724 8.46553 11.6127L8.62214 12.1062M14.1311 10.8461L13.9898 10.4007C13.7549 9.66042 12.9643 9.25078 12.224 9.48567C11.4837 9.72056 11.0741 10.5111 11.309 11.2514L11.4503 11.6968" stroke="#6B2A2A" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.06027 16.4828L5.99696 13.1318C4.51852 13.601 3.70355 15.183 4.17985 16.6591L4.6561 18.135C5.24752 19.9755 6.81305 21.3344 8.71828 21.6614C9.10978 22.8952 10.4273 23.5779 11.6611 23.1864L16.3838 21.6879C17.6176 21.2964 18.3003 19.9788 17.9088 18.745L17.768 18.3013C18.1154 17.7259 18.3361 17.0831 18.4152 16.4157C18.4943 15.7483 18.4301 15.0717 18.2268 14.4311L16.819 9.99445C16.5841 9.25416 15.7936 8.84452 15.0533 9.07941C14.313 9.3143 13.9033 10.1049 14.1382 10.8452L14.2806 11.2939M1.61293 2.11678H8.63007M3.00507 0.703125L1.55194 2.11678L3.00507 3.51562" stroke="#6B2A2A" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8 3.51562L9.45313 2.10197L8 0.703125" stroke="#6B2A2A" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>Swipe left/right to change the review</span>
                                    <button
                                        id="tipClose"
                                        key="close"
                                        block="AmastyPopup"
                                        elem="Close"
                                        onClick={ this.handleCloseTip }
                                        aria-label="Close"
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                                : ''
                        }
                        <Scrollbars
                            className="scrolledContent"
                            style={{height: 'calc(100% - 89px)'}}
                        >

                            <div className='item review-item amreview-review-wrapper'>
                                <div className="amreview-description-wrap">
                                    <div className="amrev-desc-title">
                            <span className="amreview-title-review">
                                {review.title}
                            </span>
                                    </div>
                                    {Object.values(review.rating_votes).map((vote) => {
                                        return (
                                            <div className='amstars-rating-container'>
                                                {this.renderRatingSchema(vote.percent, 1)}
                                                <div className='rating-code'>{vote.rating_code}</div>
                                                <ProductReviewRating
                                                    mix={{
                                                        block: 'ProductReviews',
                                                        elem: 'SummaryRating'
                                                    }}
                                                    summary={vote.percent}
                                                />
                                            </div>
                                        )
                                    })}
                                    <span className='amreview-text'>{review.detail}</span>


                                    {
                                        (review.like_about || review.not_like_about) ? <div
                                            className='pros-cons-wrapper'>{this.renderProsAndCons(review)}</div> : ''
                                    }


                                </div>


                                <div className='recommended-helpful'>
                                    {
                                        (review.recommended_html) ?
                                            <span className="amreview-author">
                                                {/*{review.nickname} recommends this product*/}
                                                {review.nickname} <Html
                                                content={review.recommended_html.replace('I recommend', 'recommends').replace('I not recommend', 'not recommends')}/>
                                            </span>
                                            : ''
                                    }
                                    <span className="amrev-voteqty">{review.helpful_html}</span>
                                </div>
                                <div className="review-details amreview-author-details">
                                    <p className="amreview-author">
                                        {review.nickname}
                                    </p>
                                    <Html content={review.verified_buyer_html}/>
                                    <time className='amreview-date' dateTime={review.created_at}>
                                        {review.time_ago}
                                    </time>

                                </div>
                            </div>

                        </Scrollbars>
                    </div>
                    <div className='reviewed-product-popup'>
                        <Image
                            src={thumbnail.url}
                            alt={name}
                        />
                        <span>{name}</span>
                    </div>
                </div>
            </>
        )
        return current;

    }

    reviewsPopup(current = '') {

        let {isLoaded, currentReview, reviewPopupData} = this.state;
        if (typeof reviewPopupData === 'undefined') {
            reviewPopupData = this.props.reviewPopupData;
        }
        if (!Object.keys(reviewPopupData).length) {
            return <Loader isLoading={true}/>;
        }
        let {device: {isMobile, safari}, advancedReview: {reviews_list}} = this.props;
        if (typeof reviews_list === 'undefined') {
            return <Loader isLoading={true}/>;
        }

        this.setState({isLoaded: true});
        window.handleNextReview = this.handleNextReview;
        window.handlePrevReview = this.handlePrevReview;
        reviews_list.sort((a, b) => a.review_id - b.review_id)

        Object.entries(reviews_list).reduce((acc, [key, review]) => {
            if (review.review_id === reviewPopupData.review_id) {
                this.setState({currentReview: key});
                currentReview = key;
            }

        }, {});

        let {
            product: {name, thumbnail, small_image, image}
        } = this.props;
        setTimeout(() => {
            document.getElementById("amreview-popup-wrapper")?.classList.remove("loading");
            document.getElementById('amreview-popup').classList.add('loaded');
            if (!isLoaded) {
                document.getElementById('amreview-popup').classList.add('firsttime');
            }
        }, 100);

        let noImagesInPopup = '';

        if (reviewPopupData.path.includes('data:image/png;base64')) {
            noImagesInPopup = 'no-images';
        }

        document.body.classList.remove('amreview-popup-', 'amreview-popup-no-images');
        document.body.classList.add('amreview-popup-' + noImagesInPopup);

        let thumbsSwiper;
        // this.setState({galleryThumbsSwiper: undefined});

        if (current === 'prev') {
            return this.reviewPopupClone('prev');
        }
        if (current === 'next') {
            return this.reviewPopupClone('next');
        }


            let reviewNumber = currentReview;
            reviewNumber++;
            let ending = '';
            if (reviews_list.length > 1) {
                ending = 's';
            }
            const currentReviewInfo = reviewNumber + ' of ' + reviews_list.length + ' review' + ending;
        if (!isMobile) {
            document.getElementById('reviews-control-current').innerHTML = currentReviewInfo;
        }

        let tipActived = document.body.classList.contains('reviewPopUpTip');

        const state = getStore().getState();
        const config = state.ConfigReducer;
        let href = reviewPopupData.product_url;
        let {base_url} = config;
        base_url = base_url.replace("https", "http");
        base_url = base_url.replace("http", "https");
        href = href.replace(base_url, '');
        href = href.replace(window.location.origin, '');
        thumbnail.url = reviewPopupData.product_image;

        return (
            <>
                {
                    (isMobile) ?
                        <div className="reviews-control-wrapper" mix={{block: noImagesInPopup}}>
                            <span id="reviews-control-current">{currentReviewInfo}</span>
                            {this.renderCloseButton()}
                        </div>
                        : ''
                }
                <div id="amreview-popup-wrapper" className="loading"
                     mix={{block: noImagesInPopup, mods: {backfaceVisibility: (safari) ? 'hidden' : ''}}}>

                    <div className='left-side'>{
                        Object.values(reviews_list).map((review, index) => {
                            if (review.review_id !== reviewPopupData.review_id) {
                                return '';
                            }
                            if (currentReview === 0) {
                                currentReview = index;
                                this.setState({
                                    currentReview: index,
                                })
                            }


                            let clickedImage;
                            review.review_images.map((image, index) => {
                                if (reviewPopupData.image_id === image.image_id) {
                                    clickedImage = index;
                                }
                            })
                            thumbsSwiper = <Swiper
                                onSwiper={(swiper) => {
                                    this.setState({galleryThumbsSwiper: swiper});
                                }}
                                spaceBetween={12}
                                slidesPerView={4}
                                freeMode={true}
                                navigation={{
                                    prevEl: "#swiper-button-prev-thumb-popup-image",
                                    nextEl: "#swiper-button-next-thumb-popup-image",
                                }}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className={"thumbs-images long_" + review.review_images.length}
                            >
                                {
                                    review.review_images.map((image, index) => {
                                        return (
                                            <SwiperSlide>
                                                <Image
                                                    src={image.path}
                                                />
                                            </SwiperSlide>)
                                    })
                                }

                            </Swiper>;
                            const mainSwiper = <Swiper
                                speed={500}
                                effect={"fade"}
                                fadeEffect={{
                                    crossFade: true
                                }}
                                loop={true}
                                centeredSlides={true}
                                navigation={{
                                    prevEl: "#swiper-button-prev-review-popup-image",
                                    nextEl: "#swiper-button-next-review-popup-image",
                                }}
                                pagination={{
                                    type: "fraction",
                                }}
                                modules={[Autoplay, Navigation, SwiperPagination, FreeMode, Thumbs, EffectFade, EffectCreative]}
                                slidesPerView={1}
                                initialSlide={clickedImage}
                                thumbs={{swiper: this.state.galleryThumbsSwiper}}
                                // onSlideChange={(swiper) => {
                                //     console.log(swiper);
                                // }}
                                onUpdate={(swiper) => {
                                    if (swiperWasRendered) {
                                        swiper.slideTo(1,0);
                                    }
                                    try {
                                        setTimeout(() => {
                                            document.querySelectorAll('#swiper-button-next-thumb-popup-image').forEach(n => n.classList.remove('swiper-button-disabled'));
                                        }, 10);
                                    }catch (e) {

                                    }
                                }}

                            >
                                {
                                    review.review_images.map((image, index) => {
                                        return (
                                            <SwiperSlide>
                                                <Link
                                                    to={ window.location.origin + '/' + href }
                                                    // onClick={ this.handleLinkClick }
                                                    // mix={ mix }
                                                >
                                                    <Image
                                                        src={image.path}
                                                    />
                                                </Link>

                                            </SwiperSlide>)
                                    })
                                }
                            </Swiper>;
                            return (
                                <>
                                    <>
                                        {mainSwiper}
                                        {
                                            (isMobile) ?
                                                <>
                                                    <div id="thumbsWrapper">
                                                        {thumbsSwiper}
                                                        <div className="swiper-button-prev"
                                                             id="swiper-button-prev-thumb-popup-image">
                                                            <ChevronIcon direction={Directions.LEFT}/>
                                                        </div>

                                                        <div className="swiper-button-prev"
                                                             id="swiper-button-next-thumb-popup-image">
                                                            <ChevronIcon direction={Directions.RIGHT}/>
                                                        </div>
                                                    </div>
                                                </>
                                                : ''
                                        }

                                        <div className="swiper-button-prev"
                                             id="swiper-button-prev-review-popup-image"></div>
                                        <div className="swiper-button-next"
                                             id="swiper-button-next-review-popup-image"></div>
                                        <div id="swiper-button-prev-review-popup-placeholder"
                                             onClick={this.handlePrevReview}>prev
                                        </div>
                                        <div id="swiper-button-next-review-popup-placeholder"
                                             onClick={this.handleNextReview}>next
                                        </div>
                                    </>
                                </>
                            )
                        })

                    }
                    </div>
                    <div className='right-side'>
                        {
                            (isMobile) ?
                                <div id="swipeTip">
                                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.40839 11.4324L6.97645 6.91976C6.74156 6.17947 5.95101 5.76983 5.21072 6.00472C4.47042 6.23961 4.06078 7.03015 4.29567 7.77045L6.56409 14.9192" stroke="#6B2A2A" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.3029 11.2555L11.1463 10.762C10.9114 10.0217 10.1209 9.61205 9.38058 9.84694C8.64028 10.0818 8.23064 10.8724 8.46553 11.6127L8.62214 12.1062M14.1311 10.8461L13.9898 10.4007C13.7549 9.66042 12.9643 9.25078 12.224 9.48567C11.4837 9.72056 11.0741 10.5111 11.309 11.2514L11.4503 11.6968" stroke="#6B2A2A" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.06027 16.4828L5.99696 13.1318C4.51852 13.601 3.70355 15.183 4.17985 16.6591L4.6561 18.135C5.24752 19.9755 6.81305 21.3344 8.71828 21.6614C9.10978 22.8952 10.4273 23.5779 11.6611 23.1864L16.3838 21.6879C17.6176 21.2964 18.3003 19.9788 17.9088 18.745L17.768 18.3013C18.1154 17.7259 18.3361 17.0831 18.4152 16.4157C18.4943 15.7483 18.4301 15.0717 18.2268 14.4311L16.819 9.99445C16.5841 9.25416 15.7936 8.84452 15.0533 9.07941C14.313 9.3143 13.9033 10.1049 14.1382 10.8452L14.2806 11.2939M1.61293 2.11678H8.63007M3.00507 0.703125L1.55194 2.11678L3.00507 3.51562" stroke="#6B2A2A" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8 3.51562L9.45313 2.10197L8 0.703125" stroke="#6B2A2A" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>Swipe left/right to change the review</span>
                                    <button
                                        id="tipClose"
                                        key="close"
                                        block="AmastyPopup"
                                        elem="Close"
                                        onClick={ this.handleCloseTip }
                                        aria-label="Close"
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                                : ''
                        }
                        <Scrollbars
                            ref={this.reviewScrolledContent}
                            className="scrolledContent"
                            style={{height: (isMobile) ? 'calc(100% - 136px)' : 'calc(100% - 89px)'}}
                        >
                            {
                                Object.values(reviews_list).map((review) => {
                                    if (review.review_id !== reviewPopupData.review_id) {
                                        return null;
                                    }
                                    return (
                                        <>
                                            <div className='item review-item amreview-review-wrapper'>
                                                <div className="amreview-description-wrap">
                                                    <div className="amrev-desc-title">
                            <span className="amreview-title-review">
                                {review.title}
                            </span>
                                                    </div>
                                                    {Object.values(review.rating_votes).map((vote) => {
                                                        return (
                                                            <div className='amstars-rating-container'>
                                                                {this.renderRatingSchema(vote.percent, 1)}
                                                                <div className='rating-code'>{vote.rating_code}</div>
                                                                <ProductReviewRating
                                                                    mix={{
                                                                        block: 'ProductReviews',
                                                                        elem: 'SummaryRating'
                                                                    }}
                                                                    summary={vote.percent}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                    <span className='amreview-text'>{review.detail}</span>


                                                    {
                                                        (review.like_about || review.not_like_about) ? <div
                                                            className='pros-cons-wrapper'>{this.renderProsAndCons(review)}</div> : ''
                                                    }


                                                </div>


                                                <div className='recommended-helpful'>
                                                    {
                                                        (review.recommended_html) ?
                                                            <span className="amreview-author">
                                                {/*{review.nickname} recommends this product*/}
                                                                {review.nickname} <Html
                                                                content={review.recommended_html.replace('I recommend', 'recommends').replace('I not recommend', 'not recommends')}/>
                                            </span>
                                                            : ''
                                                    }
                                                    <span className="amrev-voteqty">{review.helpful_html}</span>
                                                </div>
                                                <div className="review-details amreview-author-details">
                                                    <p className="amreview-author">
                                                        {review.nickname}
                                                    </p>
                                                    <Html content={review.verified_buyer_html}/>
                                                    <time className='amreview-date' dateTime={review.created_at}>
                                                        {review.time_ago}
                                                    </time>

                                                </div>
                                            </div>

                                        </>
                                    )
                                })

                            }
                        </Scrollbars>
                    </div>
                    <div className='reviewed-product-popup'>
                        <Link
                            block="AllReviews"
                            elem="Link"
                            to={ window.location.origin + '/' + href }
                            // onClick={ this.handleLinkClick }
                            // mix={ mix }
                        >
                            <Image
                                src={thumbnail.url}
                                alt={name}
                            />
                            <span>{name}</span>
                        </Link>
                    </div>
                </div>
            </>
        )
    }

    render() {

        this.setState({galleryThumbsSwiper: undefined, galleryThumbsSwiperprev: undefined, galleryThumbsSwipernext: undefined});

        const {device: {isMobile}, reviewPopupData} = this.props;

        if (!isMobile) {
            return this.reviewsPopup();
        }

        return (
            <>
                <div id="prevReviewMobile" className="mobileReview">
                    <Draggable
                        onDrag={this.handleDrag}
                        onDragStart={this.handleDragStart}
                        onDragEnd={this.handleDragEnd}
                    >
                        {this.reviewsPopup('prev')}
                    </Draggable>
                </div>
                <div id="currentReviewMobile">
                    <Draggable
                        onDrag={this.handleDrag}
                        onDragStart={this.handleDragStart}
                        onDragEnd={this.handleDragEnd}
                    >
                        {this.reviewsPopup('current')}
                    </Draggable>
                </div>
                <div id="nextReviewMobile" className="mobileReview">
                    <Draggable
                        onDrag={this.handleDrag}
                        onDragStart={this.handleDragStart}
                        onDragEnd={this.handleDragEnd}
                    >
                        {this.reviewsPopup('next')}
                    </Draggable>
                </div>
            </>
        )

    }


}

export default PopUpWithImages;
