import SearchIcon from "@scandipwa/scandipwa/src/component/SearchIcon";
// import SearchOverlay from "@scandipwa/scandipwa/src/component/SearchOverlay";

import {lazy} from "react";
export const SearchOverlay = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ './../../../../src/component/SearchOverlay'));


import {fetchQuery} from 'Util/Request/Query';
import searchQuery from './../query/SearchQuery';
import ProductListQuery from "Query/ProductList.query";
import { clearSearchResults, updateLoadStatus, updateSearchBar } from './../../../../src/store/SearchBar/SearchBar.action';

let searchDispatch;
let searchData;

class MiraSearchPlugin {

    prepareRequest = async (args) => {
        // console.clear();
        // console.log(args);
        searchDispatch = args[1];
        const searchQuery = args[0].args.search;
        searchDispatch(updateLoadStatus(false));

        // console.log(searchQuery);
        // console.log(searchQuery.length);
        if (searchQuery.length < 3) {
            return;
        }
        searchDispatch(updateLoadStatus(true));
        // dispatch(updateSearchBar(data));
        this.makeRequest(searchQuery);
    }

    onSuccess =  (args) => {
        // console.clear();
        // console.log(args);
        const data = args[0];
        searchData = data;
        const dispatch = args[1];
        const logQuery = args[2];
        // console.log(logQuery.args.search);
        searchDispatch = dispatch;
        dispatch(updateLoadStatus(false));
        // console.log(data);
        dispatch(updateSearchBar(data));
        // console.log(window.location.href);
        // const mirasResult = await this.makeRequest(args[2].args.search);
        // console.log(mirasResult);

        fetchQuery(
            searchQuery.logSearch(logQuery.args.search, window.location.href)
        ).then(
            (
                (data) => {
                    // console.clear();
                    // console.log(data);
                }),
            (err) => {
                // console.log(err)
            }
        );
    }

    makeRequest(searchCriteria) {

        // console.clear();
        // console.log(this.state);
        console.log(searchCriteria);
        fetchQuery(
            searchQuery.doSearch(searchCriteria)
        ).then(
            (
                async (data) => {
                    // console.clear();
                    console.log(data);

                    const {searchResult: {search_result}} = data;
                    const result = JSON.parse(search_result);
                    console.log(result);

                    const {indexes: {magento_catalog_product, magento_catalog_product: {items}} = {}} = result;
                    console.log(magento_catalog_product);
                    console.log(items);

                    // return;

                    // const {search: {magento_catalog_product: {items}}} = data;
                    // console.log(searchCriteria)
                    // console.log(items)

                    const arrayColumn = (array, column) => {
                        return array.map(item => item[column]);
                    };
                    const names = arrayColumn(items, 'sku');
                    console.log(names);

                    const query = ProductListQuery.getQuery({
                        args: {
                            filter: {
                                // productsSkuArray: []
                                productsSkuArray: names
                            }
                        },
                        notRequireInfo: false,
                        noVariants: false,
                        noVariantAttributes: false
                    })
                    const products = await fetchQuery(query);
                    searchDispatch(updateLoadStatus(false));
                    searchDispatch(updateSearchBar(products));
                    console.log(products);


                }),
            (err) => {
                console.log(err)
            }
        );

    }


    _getLabel = (args, callback, instance) => {
        const {id} = args[0];
        if (id === 'none' && window.location.pathname.includes('/search')) {
            return {
                asc: __('Relevance'),
            };
        }
        return callback(...args);
    }

    renderSearch = (args, callback, instance) => {


        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device,
        } = instance.props;

        return (
            <>
            <input
                // onChange={ this.makeRequest.bind(this, searchCriteria) }
                onChange={event => {
                    this.makeRequest(event.target.value);
                }}
            />
            </>
        );
        return (
            <div
                block="SearchField"
                elem="SearchInnerWrapper"
            >
                <SearchIcon />
                <input
                    id="search-field"
                    // ref={ instance.searchBarRef }
                    block="SearchField"
                    elem="Input"
                    // onFocus={ onSearchBarFocus }
                    // onChange={ instance.handleChange }
                    onChange={ this.makeRequest.bind(this, searchCriteria) }
                    // onKeyDown={ instance.onSearchEnterPress }
                    value={ searchCriteria }
                    mods={ { isActive } }
                    placeholder={ __('Search products, keywords') }
                    // autoComplete="off"
                    // aria-label={ __('Search') }
                />
                { instance.renderSearchIcon() }
                {(!device.isMobile) ? <div className="search-divider">Start typing to see search results!</div> : ''}
                <SearchOverlay
                    isHideOverlay={ !device.isMobile }
                    searchCriteria={ searchCriteria }
                />
            </div>
        );
    };
}

const {
    renderSearch,
    onSuccess,
    // prepareRequest
    _getLabel
} = new MiraSearchPlugin();

export default {
    'Component/SearchField/Component': {
        'member-function': {
            // renderSearch
        }
    },
    'Store/SearchBar/Dispatcher': {
        'member-function': {
            onSuccess
            // prepareRequest
        }
    },
    'Component/CategorySort/Container': {
        'member-function': {
            _getLabel
        }
    }

};
