import {
    MyAccountOrderTotalsComponent as SourceMyAccountOrderTotalsComponent,
} from 'SourceComponent/MyAccountOrderTotals/MyAccountOrderTotals.component';
import {formatPrice} from 'Util/Price';
import './MyAccountOrderTotals.style';
import Html from "Component/Html";
import './MyAccountOrderTotals.style';
import {Discount} from "Query/Order.type";
import {Mix, ReactElement} from "Type/Common.type";
import {GQLCurrencyEnum} from "Type/Graphql.type";

export class MyAccountOrderTotalsComponent extends SourceMyAccountOrderTotalsComponent {

    renderDiscount({ label, amount: { value } }: Discount, index: number): ReactElement {
        const discountLabel = label ? __('Discount (%s)', label) : __('Discount');

        return this.renderPriceLine(discountLabel, -Number(value), undefined, {block: 'MyAccountOrderTotals', elem: 'Discount'}, `discount-${index}`);
    }

    // renderDiscount2({label, amount: {value}}, index) {
    //     const discountLabel = label ? __('Discount (%s)', label) : __('Discount');
    //
    //     const discountMix = {block: 'MyAccountOrderTotals', elem: 'Discount'};
    //
    //     return this.renderPriceLine(discountLabel, -value, null, discountMix, `discount-${index}`);
    // }

    renderContent() {
        let {
            total: {
                subtotal: {
                    value: subtotalPrice
                },
                shipping_handling: {
                    total_amount: {
                        value: shippingHandlingPrice
                    }
                },
                grand_total: {
                    currency,
                    value: grandTotalPrice
                },
                total_tax: {
                    value: totalTaxPrice
                },
                //@ts-ignore
                packing_cost
            }
        } = this.props;
// console.clear();
// console.log(this.state);
// console.log(this.props);
// console.log(packing_cost);
        const grandTotalMix = {block: 'MyAccountOrderTotals', elem: 'GrandTotal'};

        // //@ts-ignore
        // shippingHandlingPrice = shippingHandlingPrice - packing_cost;
        // if (!parseFloat(shippingHandlingPrice as string)) {
        //     shippingHandlingPrice = '<span class="free">FREE</span>';
        // }

        return (
            <>
                {this.renderPriceLine(__('Products Total'), subtotalPrice)}
                {this.renderDiscounts()}
                {this.renderGstTotal()}
                {(packing_cost) ? this.renderPriceLine(__('Packing Cost'), packing_cost) : ''}
                {this.renderPriceLine(__('Shipping Charges'), shippingHandlingPrice)}
                {this.renderShippingGstTotal()}
                {this.renderPriceLine(__('Grand Total'), grandTotalPrice, currency, grandTotalMix)}
            </>
        );
    }

    renderGstTotal() {
        const {
            total: {
                //@ts-ignore
                cgst_charge,
                //@ts-ignore
                sgst_charge,
                //@ts-ignore
                igst_charge,
                //@ts-ignore
                gst_tax_type,
            }
        } = this.props

        return (
            <>
                {(cgst_charge) ? this.renderPriceLine(gst_tax_type + 'CGST', cgst_charge) : ''}
                {(igst_charge) ? this.renderPriceLine(gst_tax_type + 'IGST', igst_charge) : ''}
                {(sgst_charge) ? this.renderPriceLine(gst_tax_type + 'SGST', sgst_charge) : ''}
            </>
        )
    }

    renderShippingGstTotal() {
        const {
            total: {
                //@ts-ignore
                shipping_sgst_charge,
                //@ts-ignore
                shipping_cgst_charge,
                //@ts-ignore
                shipping_igst_charge,
                //@ts-ignore
                gst_shipping_tax_type
            }
        } = this.props

        return (
            <>
                {(shipping_cgst_charge) ? this.renderPriceLine(gst_shipping_tax_type + 'CGST', shipping_cgst_charge) : ''}
                {(shipping_igst_charge) ? this.renderPriceLine(gst_shipping_tax_type + 'IGST', shipping_igst_charge) : ''}
                {(shipping_sgst_charge) ? this.renderPriceLine(gst_shipping_tax_type + 'SGST', shipping_sgst_charge) : ''}
            </>
        )
    }

    renderPriceLine(
        title: string,
        price?: string | number,
        currency?: string,
        mix: Mix = {},
        key?: string | number,
    ): ReactElement {

        if (price !== '<span class="free">FREE</span>') {
            if (typeof price === "string") {
                price = parseFloat(price);
            }
        }

        if (price === 0.00) {
            return null;
        }

        let {
            total: {grand_total: {currency: defaultCurrency}},
            colSpanLabelCount,
            colSpanPriceCount
        } = this.props;
        // console.log(this.props);
        // increase width because we has added GST column
        colSpanLabelCount++;
        // increase width because we has added GST column
// console.clear();
// console.log(this.state);
// console.log(this.props);
        return (
            <tr mix={mix} key={key}>
                <th colSpan={colSpanLabelCount}>{title}</th>
                <td colSpan={colSpanPriceCount}>
                    {(typeof price === 'string') ?
                        //@ts-ignore
                        <Html content={price}/> : formatPrice(price, currency)}
                </td>
            </tr>
        );
    }

};

export default MyAccountOrderTotalsComponent;
