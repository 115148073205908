/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {DeviceType} from '@scandipwa/scandipwa/src/type/Device.type';
import {LocationType} from '@scandipwa/scandipwa/src/type/Router.type';
import {scrollToTop} from '@scandipwa/scandipwa/src/util/Browser';
import {getQueryParam} from '@scandipwa/scandipwa/src/util/Url';

import MyAccountMyTickets from './MyAccountMyTickets.component';
import {showPopup} from "@scandipwa/scandipwa/src/store/Popup/Popup.action";
import {showNotification} from '@scandipwa/scandipwa/src/store/Notification/Notification.action';

export const TicketDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Ticket/Ticket.dispatcher'
    // 'Store/Order/Order.dispatcher'
    );

/** @namespace Component/MyAccountMyTickets/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ticketList: state.TicketReducer.ticketList,
    isLoading: state.TicketReducer.isLoading,
    device: state.ConfigReducer.device
});

/** @namespace Component/MyAccountMyTickets/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getTicketList: (page) => TicketDispatcher.then(
        ({default: dispatcher}) => dispatcher.requestTickets(dispatch, page)
    ),
    showNewTicket: (payload) => dispatch(showPopup('NewTicketPopup', payload))
});

/** @namespace Component/MyAccountMyTickets/Container */
export class MyAccountMyTicketsContainer extends PureComponent {
    static propTypes = {
        getTicketList: PropTypes.func.isRequired,
        // ticketList: OrdersListType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
        location: LocationType.isRequired
    };

    state = {
        newTicketData: []
    };
    containerFunctions = {
        onNewTicket: this.onNewTicket.bind(this)
    };

    componentDidMount() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        const {getTicketList} = this.props;
        getTicketList(this._getPageFromUrl());
    }

    componentDidUpdate(prevProps) {

        // console.clear();
        // console.log(this.props);
        // console.log(this.state);
        const {getTicketList} = this.props;
        const {location: prevLocation} = prevProps;

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();

        if (currentPage !== prevPage) {
            getTicketList(this._getPageFromUrl());
            scrollToTop();
        }
    }

    containerProps() {
        let {ticketList, isLoading, device, getTicketList, showNewTicket, showNotification, onlyAddNewTicket = false} = this.props;

if (onlyAddNewTicket) {
    const {ticketList: {newTicketData}} = this.props;
    if (typeof newTicketData !== 'undefined') {
        let newTicket = JSON.parse(newTicketData);
        // newTicket.attachment_is_allowed = false;
        newTicket.is_custom_fields = false;
        newTicket.show_orders = false;
        newTicket.total_required = onlyAddNewTicket.total_required;
        // newTicket.show_orders = newTicket.show_orders.filter(({ id }) => id === onlyAddNewTicket.orderId);
        // newTicket.show_orders = order;
        // console.log(newTicket);
        // console.log(onlyAddNewTicket);
        // console.log(order);

        // let schedule = JSON.parse(ticketList.schedule);
        // newTicket.closed_message = schedule.closed_message;
        // newTicket.open_message = schedule.open_message;
        // console.log(schedule);
        // console.log(newTicket);

        ticketList.newTicketData = JSON.stringify(newTicket);

        // console.log(JSON.stringify(newTicket));
    }
}
        return {ticketList, isLoading, device, getTicketList, showNewTicket, showNotification, onlyAddNewTicket};
    }

    _getPageFromUrl(url) {
        const {location: currentLocation} = this.props;
        const location = url || currentLocation;

        return +(getQueryParam('page', location) || 1);
    }

    onNewTicket(e) {
        const {showNewTicket, onlyAddNewTicket} = this.props;

        let ticketTitle = 'Create New Ticket';
        if (onlyAddNewTicket) {
            ticketTitle = onlyAddNewTicket.ticketTitle;
        }
        showNewTicket({
            // action: ADD_ADDRESS,
            title:ticketTitle,
            // address: {}
        });

    }


    render() {
        // console.clear();
        // console.log('container render');
        // console.log(this.state);
        // console.log(this.props);

        return (
            <MyAccountMyTickets
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccountMyTicketsContainer));
