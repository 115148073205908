import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import PinChecker from "./../component/PinChecker";

export const renderAddToCartButton = (args, callback, instance) => {
    const {
        getActiveProduct,
        parameters, product: {configurable_options = {}}
    } = instance.props;
    const nativeUnselectedOptions = Object.keys(configurable_options).reduce((accumulator, value) => {
        if (!parameters[value]) {
            accumulator.push(value);
        }
        return accumulator;
    }, []);
    const {sku} = getActiveProduct();
    window.pinCodeCheckerProductSku = sku;
    BrowserDatabase.setItem(sku, 'selected_delivery_sku')
    // window.pinCodeCheckerAllowed = !nativeUnselectedOptions.length;
    return callback(...args);
};

export const renderProductTabs = (args, callback, instance) => {
    // console.clear();
    // console.log(instance.props);
    const {dataSource: {sku}} = instance.props;
    // console.log(sku);
    // const {reviewAlone} = instance.props;
    //
    // if (reviewAlone) {
    //     return callback(...args);
    // }

    return [
        <PinChecker />,
        callback(...args),
    ];
};
export default {
    'Component/Product/Component': {
        'member-function': {
            renderAddToCartButton: renderAddToCartButton
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            // renderRow,
            renderProductTabs
        }
    },
};
