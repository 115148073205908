/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import parser from 'html-react-parser';
import {PureComponent} from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import TextPlaceholder from 'Component/TextPlaceholder';
import {TextPlaceHolderLength} from 'Component/TextPlaceholder/TextPlaceholder.config';
import {UrlRewritePageType} from 'Route/UrlRewrites/UrlRewrites.config';
import {ReactElement} from 'Type/Common.type';

import {FallbackComponentProps} from './Fallback.type';
import CategoryProductList from './../../component/CategoryProductList';

import './Fallback.style';
import BrowserDatabase from "Util/BrowserDatabase";
import {CategoryPageLayout, LAYOUT_KEY} from "Route/CategoryPage/CategoryPage.config";

/** @namespace Component/Fallback/Component */
export class FallbackComponent extends PureComponent<FallbackComponentProps> {
    static defaultProps: Partial<FallbackComponentProps> = {
        type: '',
    };

    fallbackMap: Record<string, ReactElement> = {
        [UrlRewritePageType.CATEGORY]: this.renderCategoryFallback(),
        [UrlRewritePageType.PRODUCT]: this.renderProductFallback(),
    };

    renderCategoryName(): ReactElement {
        const {
            actionName: {
                id,
                name,
            } = {},
        } = window;

        if (id && !name) {
            return null;
        }

        return (
            <h1 block="Fallback" elem="Heading">
                {name}
            </h1>
        );
    }

    renderCategoryDescription(): ReactElement {
        const {
            actionName: {
                id,
                description,
            } = {},
        } = window;

        if (!id) {
            return (
                <p>
                    <TextPlaceholder length={TextPlaceHolderLength.LONG}/>
                </p>
            );
        }

        if (!description) {
            return null;
        }

        return (
            <div block="Fallback" elem="CategoryDescription">
                {parser(description)}
            </div>
        );
    }

    renderCategoryFallback(): ReactElement {
        const {actionName: {type} = {}} = window;

        const {type: skeletonType} = this.props;

        if (skeletonType === UrlRewritePageType.CATEGORY) {
            return this.renderCategoryFallbackSkeleton();
        }

        if (type !== UrlRewritePageType.CATEGORY) {
            return this.renderDefaultFallback();
        }

        return (
            <main
                block="Fallback"
                elem="CategoryPagePlaceholder"
            >
                <ContentWrapper
                    wrapperMix={{block: 'Fallback', elem: 'CategoryWrapper'}}
                    label="Category page"
                >
                    <div
                        block="Fallback"
                        elem="CategoryFilterOverlay"
                        mix={{block: 'Fallback', elem: 'Placeholder'}}
                    />
                    <article block="Fallback" elem="CategoryDetails">
                        <div block="Fallback" elem="CategoryDescriptionWrapper">
                            {this.renderCategoryName()}
                            {this.renderCategoryDescription()}
                        </div>
                        <div/>
                    </article>
                </ContentWrapper>
            </main>
        );
    }

    renderProductFallback(): ReactElement {
        const {actionName: {name, type} = {}} = window;
        const {type: skeletonType} = this.props;

        if (skeletonType === UrlRewritePageType.PRODUCT) {
            document.body.classList.add("product-page");
            return this.renderProductFallbackSkeleton();
        }

        if (type !== UrlRewritePageType.PRODUCT) {
            return this.renderDefaultFallback();
        }

        document.body.classList.add("product-page");

        return (
            <main
                block="Fallback"
                elem="ProductPagePlaceholder"
                aria-label="Product page"
            >
                <ContentWrapper
                    wrapperMix={{block: 'Fallback', elem: 'ProductWrapper'}}
                    label={__('Main product details')}
                >
                    <div block="Fallback" elem="ProductDetails">
                        <h1 block="Fallback" elem="Heading">
                            {name}
                        </h1>
                    </div>
                    <div block="Fallback" elem="ProductGallery">
                        <div block="Fallback" elem="ProductGallerySlider"/>
                    </div>
                    <article block="Fallback" elem="ProductActions">
                        <h1 block="Fallback" elem="Title">
                            {name}
                        </h1>
                    </article>
                </ContentWrapper>
            </main>
        );
    }

    renderCategoryFallbackSkeleton() {
        const storedPlpType = BrowserDatabase.getItem<CategoryPageLayout>(LAYOUT_KEY);
        return (
            <div
                block="Fallback"
                elem="ProductListWrapper"
                mix={{block: 'ContentWrapper'}}
            >
                <p block="CategoryPage" elem="ItemsCount">
                    <TextPlaceholder content="" length={TextPlaceHolderLength.SHORT}/>
                </p>

                <CategoryProductList
                    //@ts-ignore
                    layout={storedPlpType}/>
            </div>
        );
    }

    renderProductFallbackSkeleton(): ReactElement {
        return (
            <main
                block="Fallback"
                elem="ProductPagePlaceholder"
                aria-label="Product page"
            >
                <ContentWrapper
                    wrapperMix={{block: 'Fallback', elem: 'ProductWrapper'}}
                    label={__('Main product details')}
                >
                    {/*<div block="Fallback" elem="ProductDetails">*/}
                    {/*    <h1 block="Fallback" elem="Heading">*/}
                    {/*        name1*/}
                    {/*    </h1>*/}
                    {/*</div>*/}
                    <div block="Fallback" elem="ProductGallery">
                        <div block="Fallback" elem="ProductGallerySlider"/>
                    </div>
                    <article block="Fallback" elem="ProductActions">
                        <div block="ProductPage" elem="Top">
                            <TextPlaceholder content="" length={TextPlaceHolderLength.LONG}/>
                            <TextPlaceholder content="" length={TextPlaceHolderLength.SHORT}/>
                        </div>
                        <TextPlaceholder content="" length={TextPlaceHolderLength.SHORT}/>
                        <section
                            block="ProductActions"
                            elem="Section"
                            mods={{type: 'alerts'}}
                        >
                            <TextPlaceholder content="" length={TextPlaceHolderLength.MEDIUM}/>
                        </section>


                        <div className="TextPlaceholder-AddToCartWrapper">
                            <TextPlaceholder content="" length={TextPlaceHolderLength.LONG}/>
                            <TextPlaceholder content="" length={TextPlaceHolderLength.LONG}/>
                        </div>
                        {/*<div className="TextPlaceholder-InfoIcons">*/}
                        {/*    <TextPlaceholder content="" length={TextPlaceHolderLength.SHORT}/>*/}
                        {/*    <TextPlaceholder content="" length={TextPlaceHolderLength.SHORT}/>*/}
                        {/*    <TextPlaceholder content="" length={TextPlaceHolderLength.SHORT}/>*/}
                        {/*    <TextPlaceholder content="" length={TextPlaceHolderLength.SHORT}/>*/}
                        {/*</div>*/}
                        <div className="TextPlaceholder-ProductTabs">
                            <TextPlaceholder content="" length={TextPlaceHolderLength.LONG}/>
                            <TextPlaceholder content="" length={TextPlaceHolderLength.LONG}/>
                            <TextPlaceholder content="" length={TextPlaceHolderLength.LONG}/>
                            <TextPlaceholder content="" length={TextPlaceHolderLength.LONG}/>
                        </div>
                    </article>
                </ContentWrapper>
            </main>
        );
    }

    renderDefaultFallback(): ReactElement {
        return (
            <main block="Fallback" elem="DefaultPlaceholder"/>
        );
    }

    render(): ReactElement {

        const {type} = this.props;

        return this.fallbackMap[type] || this.renderDefaultFallback();
    }
}

export default FallbackComponent;
