/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const renderOrderIncrementIdAndStatus = (args, callback, instance) => {

    console.log('here');
    alert('here');
    const {setTabSubheading} = instance.props;
    return 'here';
    console.log(args);
    console.log(instance);
    setTabSubheading('test');
    return 'here';

};

export default {
    'Component/MyAccountOrder/Component': {
        'member-function': {
            // renderOrderIncrementIdAndStatus
        }
    }
};
