import {
    ContentWrapperComponent as SourceContentWrapperComponent,
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';

import './ContentWrapper.override.style';

export class ContentWrapperComponent extends SourceContentWrapperComponent {
    // TODO implement logic
};

export default ContentWrapperComponent;
