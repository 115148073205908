/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from '@scandipwa/scandipwa/src/util/Query';

/** @namespace Query/PaymentSystemQuery */
export class PaymentSystemQuery {

    getSetPaymentMethodOnCartMutation(input) {
        return new Field('setPaymentMethodOnCart')
            .addArgument('input', 'SetPaymentMethodOnCartInput!', input)
            .addField(this._getCartField())
            .setAlias('paymentMethod');
    }

    _getCartField() {
        return new Field('cart')
            .addFieldList(this._getCartFieldList());
    }

    _getCartFieldList() {
        return [
            'id'
        ];
    }
    getHandleCallBack(orderId) {
        return new Field('handleCallBack')
            .addFieldList([
                'payment_system'
            ])
            .addArgument('orderId', 'String', orderId);
    }

    getPlaceCashfreeOrder(orderId) {
        return new Field('placeCashfreeOrder')
            .addFieldList([
                'payment_system'
            ])
            .addArgument('orderId', 'String', orderId);
    }

    getPayNow(orderId) {
        return new Field('payNow')
            .addFieldList([
                'payment_system'
            ])
            .addArgument('orderId', 'String', orderId);
    }

    getData(paymentProcessor = '', cartId = '', incrementId = '', action = '') {
        return new Field('paymentSystem').addFieldList([
            'payment_system'
        ])
            .addArgument('paymentProcessor', 'String', paymentProcessor)
            .addArgument('cartId', 'String', cartId)
            .addArgument('incrementId', 'String', incrementId)
            .addArgument('action', 'String', action)
    }

    getPlaceInstamojoOrder(orderId, response = '') {
        return new Field('placeInstamojoOrder')
            .addFieldList([
                'payment_system'
            ])
            .addArgument('response', 'String', response)
            .addArgument('orderId', 'String', orderId);
    }

    getPlaceRazorPayOrder(orderId, response = '') {
        return new Field('placeRazorPayOrder')
            .addFieldList([
                'payment_system'
            ])
            .addArgument('response', 'String', response)
            .addArgument('orderId', 'String', orderId);
    }

}

export default new PaymentSystemQuery();
