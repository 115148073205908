/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import FieldGroup from 'Component//FieldGroup';
import Field from "Component/Field";
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import ReviewStar from 'Component/ReviewStar';
import { RatingItemsType } from 'Type/Rating.type';
import {DropzoneArea} from "material-ui-dropzone";
import UploadIcon from 'Component/UploadIcon';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

import './ProductReviewForm.style';
import { ValidationInputType } from "Util/Validator/Config";

/**
 * ProductReviewForm
 * @class ProductReviewForm
 * @namespace Component/ProductReviewForm/Component
 */
export class ProductReviewForm extends PureComponent {
    static propTypes = {
        reviewRatings: RatingItemsType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        onReviewSubmitSuccess: PropTypes.func.isRequired,
        onReviewError: PropTypes.func.isRequired,
        onStarRatingClick: PropTypes.func.isRequired,
        ratingData: PropTypes.objectOf(PropTypes.string).isRequired,
        reviewData: PropTypes.shape({
            nickname: PropTypes.string,
            summary: PropTypes.string,
            detail: PropTypes.string
        }).isRequired
    };

    ratingTitleMap = {
        1: __('Awful'),
        2: __('Bad'),
        3: __('Average'),
        4: __('Good'),
        5: __('Awesome')
    };

    renderReviewStar(options, rating_id) {
        const { ratingData, onStarRatingClick } = this.props;
        const { option_id, value } = options;
        const isChecked = !!ratingData[rating_id] && ratingData[rating_id] === option_id;

        return (
            <ReviewStar
              key={ option_id }
              name={ rating_id }
              value={ value }
              title={ this.ratingTitleMap[value] }
              isChecked={ isChecked }
              option_id={ option_id }
              rating_id={ rating_id }
              onStarRatingClick={ onStarRatingClick }
            />
        );
    }

    renderReviewRating() {
        const { reviewRatings } = this.props;

        return reviewRatings.map((rating) => {
            const { rating_id, rating_code, rating_options } = rating;

            return (
                <FieldGroup
                  validationRule={ { isRequired: true, selector: '[type="radio"]' } }
                  validateOn={ ['onChange'] }
                  key={ rating_id }
                >
                    <legend block="ProductReviewForm" elem="Legend">
                        { rating_code }
                    </legend>
                    <fieldset block="ProductReviewForm" elem="Rating" key={ rating_id }>
                        { rating_options
                            .sort(({ value }, { value: nextValue }) => nextValue - value)
                            .map((option) => this.renderReviewStar(option, rating_id)) }
                    </fieldset>
                </FieldGroup>
            );
        });
    }

    renderButton() {
        return (
            <button
              block="ProductReviewForm"
              elem="Button"
              type="submit"
              mix={ { block: 'Button' } }
            >
                { __('Submit Review') }
            </button>
        );
    }

    renderReviewRatingWrapper() {
        const { reviewRatings } = this.props;

        if (reviewRatings && reviewRatings.length === 0) {
            return null;
        }

        return (
            <div
              block="ProductReviewForm"
              elem="RatingWrapper"
            >
                <span id="FieldQuestion">What rating you would give?</span>
              { this.renderReviewRating() }
            </div>
        );
    }

    renderReviewFormContent() {
        const {
            reviewData,
            amAdvancedReviewRecommend,
            amAdvancedReviewProsCons,
            amAdvancedReviewGuestEmail,
            amAdvancedReviewImagesAllowUpload,
            amAdvancedReviewImagesIsRequired
        } = this.props;
        const {
            nickname = '',
            summary = '',
            detail = ''
        } = reviewData;

        return (
            <div
              block="ProductReviewForm"
              elem="Wrapper"
            >
               { this.renderReviewRatingWrapper() }
                <div
                  block="ProductReviewForm"
                  elem="Content"
                >
                    <Field
                      type={ FieldType.TEXT }
                      label={ __('Name') }
                      attr={ {
                          id: 'nickname',
                          name: 'nickname',
                          defaultValue: nickname,
                          placeholder: __('Your Name')
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true
                      } }
                      addRequiredTag
                      mix={ { block: 'ProductReviewForm', elem: 'Field' } }
                    />

                    {
                        (!isSignedIn() && amAdvancedReviewGuestEmail === '1') ?
                            <Field
                                addRequiredTag
                                type={FieldType.EMAIL}
                                attr={{
                                    id: 'guest_email',
                                    name: 'guest_email',
                                    placeholder: 'Your email address'
                                }}
                                label='Email'
                                validateOn={['onChange']}
                                validationRule={{
                                    inputType: ValidationInputType.EMAIL,
                                    match: (value) => {

                                        return !!(value);

                                    },
                                    customErrorMessages: {
                                        onMatchFail: 'This field is required!'
                                    }
                                }}
                            /> : ''
                    }
                    <Field
                      type={ FieldType.TEXT }
                      label={ __('Summary') }
                      attr={ {
                          id: 'title',
                          name: 'title',
                          defaultValue: summary,
                          placeholder: __('Summary')
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true
                      } }
                      addRequiredTag
                      mix={ { block: 'ProductReviewForm', elem: 'Field' } }
                    />
                    <Field
                      type={ FieldType.TEXTAREA }
                      label={ __('Review') }
                      attr={ {
                          id: 'detail',
                          name: 'detail',
                          defaultValue: detail,
                          placeholder: __('Review'),
                          rows: 5
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true
                      } }
                      addRequiredTag
                      mix={ { block: 'ProductReviewForm', elem: 'Field' } }
                    />

                    {
                        (amAdvancedReviewProsCons === '1') ?
                            <>
                                <Field
                                    type={ FieldType.TEXTarea }
                                    label={ __('Advantages') }
                                    attr={ {
                                        id: 'like_about',
                                        name: 'like_about',
                                        // defaultValue: detail,
                                        placeholder: __('Advantages'),
                                        rows: 1
                                    } }
                                    validateOn={ ['onChange'] }
                                    validationRule={ {
                                        isRequired: false
                                    } }
                                    // addRequiredTag
                                    mix={ { block: 'ProductReviewForm', elem: 'Field' } }
                                />
                                <Field
                                    type={ FieldType.TEXTarea }
                                    label={ __('Disadvantages') }
                                    attr={ {
                                        id: 'not_like_about',
                                        name: 'not_like_about',
                                        // defaultValue: detail,
                                        placeholder: __('Disadvantages'),
                                        rows: 1
                                    } }
                                    validateOn={ ['onChange'] }
                                    validationRule={ {
                                        isRequired: false
                                    } }
                                    // addRequiredTag
                                    mix={ { block: 'ProductReviewForm', elem: 'Field' } }
                                />
                            </>:''
                    }

                    {
                        (amAdvancedReviewRecommend === '1') ?
                            <Field
                                type={ FieldType.SELECT }
                                label="Do you recommend this product? "
                                attr={ {
                                    selectPlaceholder: __('Please Answer'),
                                    name: `is_recommended`,
                                    // value: 'orange',
                                    // block: 'StyleGuidePage',
                                    elem: 'Select',
                                    // mods: { isValid: true }
                                } }
                                options={ [
                                    { label: 'Yes', value: 1 },
                                    { label: 'No', value: 2 },
                                    // { label: 'Don\'t Know', value: 0 },
                                ] }
                            />
                            // <Field
                            //     type={FIELD_TYPE.checkbox}
                            //     attr={{
                            //         id: `is_recommended`,
                            //         name: `is_recommended`,
                            //         // checked: this.state[option.id]
                            //     }}
                            //     label='I recommend this product'
                            //     // isDisabled={ false }
                            //     validateOn={['onChange']}
                            //     validationRule={{
                            //         match: (value) => {
                            //             console.log(value);
                            //             // this.setState({[option.id]: value})
                            //             // this.setState({comment: !!(value)});
                            //             // this.setState({comment: value});
                            //             // console.log(this.state);
                            //             // onSortChange();
                            //             return true;
                            //
                            //         },
                            //         customErrorMessages: {
                            //             onMatchFail: 'This field is required!'
                            //         }
                            //     }}
                            // />
                            :''
                    }

                    {
                        (amAdvancedReviewImagesAllowUpload === '1') ?
                            <>
                                <div className='Field-Wrapper Field-Wrapper_type_file'>
                                    <div className="Field Field_type_file ProductReviewForm-Field">
                                        <div className="Field-LabelContainer">
                                            <label className="Field-Label">
                                                Add your photo
                                                {/*<span className="Field-Label Field-Label_isRequired"> *</span>*/}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <DropzoneArea
                                    onChange={this.handleFilesChange.bind(this)}
                                    // getPreviewIcon={handlePreviewIcon}
                                    Icon={UploadIcon}
                                    filesLimit={100}
                                    acceptedFiles={['image/jpg', 'image/jpeg', 'image/gif', 'image/png']}
                                    showPreviewsInDropzone={true}
                                    showFileNames={true}
                                    showAlerts={false}
                                    inputProps={{id:'attachment',name:'attachment'}}
                                />
                                {/*<Field*/}
                                {/*    type={FIELD_TYPE.file}*/}
                                {/*    attr={{*/}
                                {/*        id: `attachment`,*/}
                                {/*        name: `attachment`,*/}
                                {/*        // checked: this.state[option.id]*/}
                                {/*    }}*/}
                                {/*    label='I recommend this product'*/}
                                {/*    // isDisabled={ false }*/}
                                {/*    validateOn={['onChange']}*/}
                                {/*    validationRule={{*/}
                                {/*        match: (value) => {*/}
                                {/*            console.log(value);*/}
                                {/*            // this.setState({[option.id]: value})*/}
                                {/*            // this.setState({comment: !!(value)});*/}
                                {/*            // this.setState({comment: value});*/}
                                {/*            // console.log(this.state);*/}
                                {/*            // onSortChange();*/}
                                {/*            return true;*/}

                                {/*        },*/}
                                {/*        customErrorMessages: {*/}
                                {/*            onMatchFail: 'This field is required!'*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </>:''
                    }

                </div>
            </div>
        );
    }

    handleFilesChange(files) {
        // console.clear();
        // console.log(this.state);
        let preparedFiles = [];
        const {callBack} = this.props
        files.map((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                let dropZoneFile = {
                    name: file.name,
                    size: file.size,
                    type: !!(file.type) ? file.type : 'unknown',
                    data: reader.result,
                }
                preparedFiles.push(dropZoneFile);
            };

        })

        this.setState({
            files: files
        });
        this.setState({files: files, preparedFiles: preparedFiles}, () => callBack(this.state))
    }

    render() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        const {
            isLoading,
            onReviewSubmitSuccess,
            onReviewError
        } = this.props;

        return (
            <>
            <Form
              key="product-review"
              mix={ { block: 'ProductReviewForm' } }
              onSubmit={ onReviewSubmitSuccess }
              onError={ onReviewError }
            >
                <Loader isLoading={ isLoading } />
                { this.renderReviewFormContent() }
                { this.renderButton() }
            </Form>
            </>
        );
    }
}

export default ProductReviewForm;
