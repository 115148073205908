/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from '@scandipwa/scandipwa/src/util/Query';

export class HelpDeskQuery {
    getTickets(customerId = false) {
        return new Field('ticketsList')
            .addFieldList([
                'tickets_list'
            ])
            .addArgument('customerId', 'String', customerId)
    }

    processRate(uId, rate, comment, id, reply, action, attachments) {
        return new Field('customerTicket')
            .addFieldList([
                'tickets_list'
            ])
            .addArgument('uId', 'String', uId)
            .addArgument('rate', 'String', rate)
            .addArgument('comment', 'String', comment)
            .addArgument('id', 'Int', id)
            .addArgument('reply', 'String', reply)
            .addArgument('action', 'String', action)
            .addArgument('attachments', 'String', attachments)
    }
}

export default new HelpDeskQuery();
