/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-tdeme
 * @link https://gitdub.com/scandipwa/base-tdeme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { OrderType } from '@scandipwa/scandipwa/src/type/Order.type';
import { formatPrice } from '@scandipwa/scandipwa/src/util/Price';

import './MyAccountTicketTableRow.style';



/** @namespace Component/MyAccountTicketTableRow/Component */
export class MyAccountTicketTableRow extends PureComponent {
    static propTypes = {
        // order: OrderType.isRequired,
        onViewClick: PropTypes.func.isRequired
    };

    render() {
        // console.log(this.props);
        const {
            device: {
                isMobile
            },
            order: {
                ticket_id,
                subject,
                department,
                created_at,
                last_activity,
                status,
                status_color,
                verified_user
            },
            onViewClick
        } = this.props;

        let formattedOpenedOn = created_at.split('-');
        let formattedLastActivity = last_activity.split('-');

        let statusColor = 'hdmx__status hdmx-' + status_color;

        return (
            <tr onClick={ onViewClick } block="MyAccountTicketTableRow">
                <td data-th={__('Ticket ID: ')}>{ticket_id}</td>
                <td data-th={__('Subject: ')}>{subject}</td>
                <td data-th={__('Department: ')}>{department}</td>
                {/*<td data-th={__('Created At: ')}>{created_at}</td>*/}
                <td data-th={__('Created At: ')}>
                    <div className='data-wrapper'>
                    <span>{formattedOpenedOn[0]}</span>
                    <span>{formattedOpenedOn[1]}</span>
                    </div>
                </td>
                {/*<td data-th={__('Last Activity: ')}>{last_activity}</td>*/}
                <td data-th={__('Last Activity: ')}>
                    <div className='data-wrapper'>
                    {(verified_user) ?
                        <div className='verified-wrapper'>
                        <span className='verified-icon'>{formattedLastActivity[0]}</span>
                        {/*<span className='verified-icon'></span>*/}
                        </div>
                        :
                        <span>{formattedLastActivity[0]}</span>}
                    <span>{formattedLastActivity[1]}</span>
                    </div>
                </td>
                <td className='ticket-status' data-th={__('Status: ')}>
                    <div className='data-wrapper'>
                    <span className={statusColor}>{status}</span>
                    <span className={statusColor}>{status}</span>
                    </div>
                </td>

                {
                    (isMobile) ? <td className='ticket-view-details'><span>View Details</span></td> : ''
                }
            </tr>
        );
    }
}

export default MyAccountTicketTableRow;
