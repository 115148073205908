import {
    MyAccountOverlayComponent as SourceMyAccountOverlayComponent,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.component';

import './MyAccountOverlay.style';
import {ReactElement} from "Type/Common.type";
import {MyAccountPageState} from "Component/MyAccountOverlay/MyAccountOverlay.config";
import {MyAccountOverlayRenderMap} from "Component/MyAccountOverlay/MyAccountOverlay.type";
import {noopFn} from "Util/Common";
import AccountPrivacyPolicyPopup from "Component/AccountPrivacyPolicyPopup";
import AccountTermsAndConditionsPopup
    from "Component/AccountTermsAndConditionsPopup";

export class MyAccountOverlayComponent extends SourceMyAccountOverlayComponent {

    renderMap: Record<MyAccountPageState, MyAccountOverlayRenderMap> = {
        [MyAccountPageState.STATE_SIGN_IN]: {
            render: () => this.renderSignIn(),
            title: __('Sign In to Your Account'),
        },
        [MyAccountPageState.STATE_FORGOT_PASSWORD]: {
            render: () => this.renderForgotPassword(),
            title: __('Get Password Link'),
        },
        [MyAccountPageState.STATE_FORGOT_PASSWORD_SUCCESS]: {
            render: () => this.renderForgotPasswordSuccess(),
            title: __('Password Reset Link Sent'),
        },
        [MyAccountPageState.STATE_CREATE_ACCOUNT]: {
            render: () => this.renderCreateAccount(),
            title: __('Create New Account'),
        },
        [MyAccountPageState.STATE_LOGGED_IN]: {
            render: noopFn,
        },
        [MyAccountPageState.STATE_CONFIRM_EMAIL]: {
            render: () => this.renderConfirmEmail(),
            title: __('Confirm the Email'),
        },
    };

    renderMyAccount(): ReactElement {
        const { state } = this.props;
        const { render, title } = this.renderMap[state];

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <div block="MyAccountOverlay" elem="Action" mods={ { state } }>
                <p block="MyAccountOverlay" elem="Heading">{ title }</p>
                { render() }
                <AccountPrivacyPolicyPopup/>
                <AccountTermsAndConditionsPopup/>
            </div>
        );
    }
};

export default MyAccountOverlayComponent;
