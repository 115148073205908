import {
    MyAccountInformationComponent as SourceMyAccountInformationComponent,
} from 'SourceComponent/MyAccountInformation/MyAccountInformation.component';

import './MyAccountInformation.style';
import {ReactElement} from "Type/Common.type";
import MyAccountCustomerForm from "Component/MyAccountCustomerForm";
import CustomPopUp from './../CustomPopUp/CustomPopUp.component';
import Loader from "Component/Loader";

export class MyAccountInformationComponent extends SourceMyAccountInformationComponent {
    renderCustomerForm(): ReactElement {
        const {
            customer,
            onCustomerSave,
            showEmailChangeField,
            showPasswordChangeField,
            handleChangeEmailCheckbox,
            handleChangePasswordCheckbox,
            //@ts-ignore
            handleChangeEmailCheckboxHide,
            //@ts-ignore
            handleChangePasswordCheckboxHide,
            //@ts-ignore
            handleChangeEmailCheckboxShow,
            //@ts-ignore
            handleChangePasswordCheckboxShow,
        } = this.props;

        // return (
        //     <>
        //         <CustomPopUp heading="Edit Account Information" buttonTitle="Edit" source="edit" content={
        //             <MyAccountCustomerForm
        //                 customer={ customer }
        //                 onSave={ onCustomerSave }
        //                 showEmailChangeField={ showEmailChangeField }
        //                 showPasswordChangeField={ showPasswordChangeField }
        //                 handleChangeEmailCheckbox={ handleChangeEmailCheckbox }
        //                 handleChangePasswordCheckbox={ handleChangePasswordCheckbox }
        //                 //@ts-ignore
        //                 handleChangeEmailCheckboxShow={ handleChangeEmailCheckboxShow }
        //                 //@ts-ignore
        //                 handleChangePasswordCheckboxHide={ handleChangePasswordCheckboxHide }
        //             />
        //         }/>
        //         <CustomPopUp heading="Edit Account Information" buttonTitle="Change password" source="password" content={
        //             <MyAccountCustomerForm
        //                 customer={ customer }
        //                 onSave={ onCustomerSave }
        //                 showEmailChangeField={ showEmailChangeField }
        //                 showPasswordChangeField={ showPasswordChangeField }
        //                 handleChangeEmailCheckbox={ handleChangeEmailCheckbox }
        //                 handleChangePasswordCheckbox={ handleChangePasswordCheckbox }
        //                 //@ts-ignore
        //                 handleChangeEmailCheckboxHide={ handleChangeEmailCheckboxHide }
        //                 //@ts-ignore
        //                 handleChangePasswordCheckboxShow={ handleChangePasswordCheckboxShow }
        //             />
        //         }/>
        //     </>
        // )
        return (
            <MyAccountCustomerForm
                customer={ customer }
                onSave={ onCustomerSave }
                showEmailChangeField={ showEmailChangeField }
                showPasswordChangeField={ showPasswordChangeField }
                handleChangeEmailCheckbox={ handleChangeEmailCheckbox }
                handleChangePasswordCheckbox={ handleChangePasswordCheckbox }
            />
        );
    }


    renderCustomerFormEdit(): ReactElement {
        const {
            customer,
            onCustomerSave,
            showEmailChangeField,
            showPasswordChangeField,
            handleChangeEmailCheckbox,
            handleChangePasswordCheckbox,
            //@ts-ignore
            handleChangeEmailCheckboxHide,
            //@ts-ignore
            handleChangePasswordCheckboxHide,
            //@ts-ignore
            handleChangeEmailCheckboxShow,
            //@ts-ignore
            handleChangePasswordCheckboxShow,
        } = this.props;

        return (
                <CustomPopUp startIcon={
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.2594 3.60022L5.04936 12.2902C4.73936 12.6202 4.43936 13.2702 4.37936 13.7202L4.00936 16.9602C3.87936 18.1302 4.71936 18.9302 5.87936 18.7302L9.09936 18.1802C9.54936 18.1002 10.1794 17.7702 10.4894 17.4302L18.6994 8.74022C20.1194 7.24022 20.7594 5.53022 18.5494 3.44022C16.3494 1.37022 14.6794 2.10022 13.2594 3.60022Z" stroke="#A10101" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.8906 5.0498C12.3206 7.8098 14.5606 9.9198 17.3406 10.1998" stroke="#A10101" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 22H21" stroke="#A10101" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                } heading="Edit Account Information" buttonTitle="Edit" source="edit-account-information" content={
                    <MyAccountCustomerForm
                        customer={ customer }
                        onSave={ onCustomerSave }
                        showEmailChangeField={ showEmailChangeField }
                        showPasswordChangeField={ showPasswordChangeField }
                        handleChangeEmailCheckbox={ handleChangeEmailCheckbox }
                        handleChangePasswordCheckbox={ handleChangePasswordCheckbox }
                        //@ts-ignore
                        handleChangeEmailCheckboxShow={ handleChangeEmailCheckboxShow }
                        //@ts-ignore
                        handleChangePasswordCheckboxHide={ handleChangePasswordCheckboxHide }
                    />
                }/>
        )
    }

    renderCustomerFormPassword(): ReactElement {
        const {
            customer,
            onCustomerSave,
            showEmailChangeField,
            showPasswordChangeField,
            handleChangeEmailCheckbox,
            handleChangePasswordCheckbox,
            //@ts-ignore
            handleChangeEmailCheckboxHide,
            //@ts-ignore
            handleChangePasswordCheckboxHide,
            //@ts-ignore
            handleChangeEmailCheckboxShow,
            //@ts-ignore
            handleChangePasswordCheckboxShow,
        } = this.props;

        return (
                <CustomPopUp buttonClass="Button" variant="contained" heading="Change Password" buttonTitle="Change password" source="change-account-password" content={
                    <MyAccountCustomerForm
                        customer={ customer }
                        onSave={ onCustomerSave }
                        showEmailChangeField={ showEmailChangeField }
                        showPasswordChangeField={ showPasswordChangeField }
                        handleChangeEmailCheckbox={ handleChangeEmailCheckbox }
                        handleChangePasswordCheckbox={ handleChangePasswordCheckbox }
                        //@ts-ignore
                        handleChangeEmailCheckboxHide={ handleChangeEmailCheckboxHide }
                        //@ts-ignore
                        handleChangePasswordCheckboxShow={ handleChangePasswordCheckboxShow }
                    />
                }/>
        )
    }

    render(): ReactElement {
        const { isLoading } = this.props;

        return (
           <>
               <div
                   block="MyAccountInformation"
                   elem="Wrapper"
                   mix={{block: "AccountInformationEdit"}}
               >
                   <Loader isLoading={ isLoading } />
                   { this.renderCustomerFormEdit() }
               </div>
               <div
                   block="MyAccountInformation"
                   elem="Wrapper"
                   mix={{block: "ChangeAccountPassword"}}
               >
                   <Loader isLoading={ isLoading } />
                   <div block="MyAccountDashboard" elem="ChangePassword">
                       <span>Change Password</span>
                       <span>Set a unique password to protect your account.</span>
                   </div>
                   { this.renderCustomerFormPassword() }
               </div>

           </>
        );
    }
};

export default MyAccountInformationComponent;
