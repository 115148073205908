import { ChevronIconComponent as SourceChevronIconComponent } from "SourceComponent/ChevronIcon/ChevronIcon.component";

import "./ChevronIcon.override.style";
import { ReactElement } from "Type/Common.type";

export class ChevronIconComponent extends SourceChevronIconComponent {
    render(): ReactElement {
        const { direction } = this.props;

        return (
            <svg block="ChevronIcon"
                 mods={{direction}}
                 width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 11.25L9 6.75L4.5 11.25" stroke="black" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        );
    }
}

export default ChevronIconComponent;
