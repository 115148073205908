/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace Util/MiddleHeading/MiddleHeading */
// export const MiddleHeading = (data: string): string => btoa(data);
export const MiddleHeading = (data: string, html = false) => {
    const HeaderWrapper =
        document.getElementById("header-before-breadcrumbs") || null;

    if (window.location.pathname.includes('sales/order/view/order_id')) {
        data = 'Order Details';
    }

    if (!HeaderWrapper) {
        window.onload = () => {
            const HeaderWrapper = document.getElementById(
                "header-before-breadcrumbs"
            );
            // console.clear();
            // console.log(HeaderWrapper);
            injectMiddleHeader(HeaderWrapper, data, html);
        };
        return null;
    }

    injectMiddleHeader(HeaderWrapper, data, html);
    // const newDiv = document.createElement("h1");

    // const newContent = document.createTextNode(data);

    // newDiv.appendChild(newContent);

    // HeaderWrapper?.append(newDiv);
    return null;
    return data;
};

function injectMiddleHeader(element: any, data: any, html:any): void {
    // console.clear();
    // console.log(element);
    // console.log(data);
    if (!element) {
        element = document.createElement("h1");
    }

    element.innerHTML = "";
    const newDiv = document.createElement("h1");

    const newContent = document.createTextNode(data);

    newDiv.appendChild(newContent);

    // element?.append(newDiv);
    setTimeout(() => {
        console.log("Delayed for 1 second.");
        if (element.lastChild) {
            console.log(element.lastChild.outerText);
            console.log(newDiv);
            console.log(newDiv.outerText);
            if (element.lastChild.outerText === newDiv.outerText) {
                console.log('return');
                return;
            }
        }


        if (html) {
            element?.insertAdjacentHTML('beforeend', data);
        }else {
            element?.append(newDiv);
        }

    }, 1);
}
