export const _getStoreConfigFields = (args, callback) => {
    const originalFields = callback(...args);
    return [
        ...originalFields,
        'helpdesk_contact_us_is_active',
        'helpdesk_frontend_is_active'
    ];
};
export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
