export const _getOrderItemTotalFields = (args, callback) => {
    const originalFields = callback(...args);
    return [
        ...originalFields,
        'packing_cost'
    ];
};
export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemTotalFields
        }
    }
};
