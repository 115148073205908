/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {Component} from 'react';

import Loader from '@scandipwa/scandipwa/src/component/Loader';
import MyAccountTicketTableRow from '../MyAccountTicketTableRow';
import Pagination from './../../../../../src/component/Pagination';
import {DeviceType} from '@scandipwa/scandipwa/src/type/Device.type';
import {OrdersListType} from '@scandipwa/scandipwa/src/type/Order.type';

import './MyAccountMyTickets.style';
import './../HelpDesk/HelpDesk.style.scss';
import Popup from "./../../../../../src/component/Popup";

import Field from "./../../../../../src/component/Field";
import {FieldType} from './../../../../../src/component/Field/Field.config';

import {DropzoneArea} from "material-ui-dropzone";
import UploadIcon from './../../../../../src/component/UploadIcon';
import TicketQuery from "../../query/Ticket.query";
import {fetchMutation} from '@scandipwa/scandipwa/src/util/Request/Mutation';
import Html from "@scandipwa/scandipwa/src/component/Html";
import TimeCountDown from "../CountDown/TimeCountDown.component";
import Link from '@scandipwa/scandipwa/src/component/Link';

const handlePreviewIcon = (fileObject, classes) => {

    const {data, file: {name, type}, file} = fileObject;

    let iconType = name.split('.');
    if (iconType.length === 1) {
        iconType = 'blank';
    } else {
        iconType = name.split('.').pop();
    }

    let attachmentClass = 'fiv-cla fiv-icon-' + iconType;

    return <span className={attachmentClass}></span>;

}

let preparedTicketData = {};
let preparedTicketDataCopy = {};

/** @namespace Component/MyAccountMyTickets/Component */
export class MyAccountMyTickets extends Component {
    static propTypes = {
        ticketList: OrdersListType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        device: DeviceType.isRequired
    };

    state = {
        files: [],
        preparedFiles: []
    };
    closeCustomTicketDataPopup = this.closeCustomTicketDataPopup.bind(this);
    goToTickets = this.goToTickets.bind(this);

    shouldComponentUpdate(nextProps) {
        const {device, ticketList, isLoading} = this.props;
        const {
            device: nextDevice,
            ticketList: nextTicketList,
            isLoading: nextIsLoading
        } = nextProps;

        return device !== nextDevice || ticketList !== nextTicketList || isLoading !== nextIsLoading;
    }

    closeCustomTicketDataPopup() {
        const {showNewTicket} = this.props;
        showNewTicket();
        this.closeNewTicketPopup();
    }

    goToTickets() {
        const {showNewTicket} = this.props;
        showNewTicket();
    }

    componentDidMount() {
        this.closeNewTicketPopup();
    }


    renderNoTickets() {

        const helpdeskMyAccountMyTicketsNoTicketsNote = window.helpdeskMyAccountMyTicketsNoTicketsNote;

        if (typeof helpdeskMyAccountMyTicketsNoTicketsNote !== 'undefined') {
            // console.log(helpdeskMyAccountMyTicketsNoTicketsNote);
            return (
                <div block="MyAccountMyTickets" elem="NoTicketsFound">
                    <div block="NoTicketsNote">
                        {helpdeskMyAccountMyTicketsNoTicketsNote.svg}
                        <p>{helpdeskMyAccountMyTicketsNoTicketsNote.title}</p>
                        <p>{helpdeskMyAccountMyTicketsNoTicketsNote.note}</p>
                        {this.renderAddNewTicket()}
                    </div>
                </div>
            );
        }


        const {device} = this.props;
        delete window.helpdeskMyAccountMyTicketsNoTicketsNote;

        return (
            <tr block="MyAccountMyTickets" elem="NoTickets">
                { /* eslint-disable-next-line no-magic-numbers */}
                <td colSpan={device.isMobile ? 3 : 4}>{__('You have no tickets.')}</td>
            </tr>
        );
    }

    renderTicketHeadingRow() {
        return (
            <tr>
                <th>{__('Ticket ID')}</th>
                <th>{__('Subject')}</th>
                <th>{__('Department')}</th>
                <th>{__('Created At')}</th>
                <th>{__('Last Activity')}</th>
                <th>{__('Status')}</th>
            </tr>
        );
    }

    renderTicketsTable() {

        const {ticketList: {items = []}, isLoading} = this.props;

        if (isLoading) {
            return null;
        }

        if (!items.length) {
            return this.renderNoTickets();
        }

        return (
            <div block="MyAccountMyTickets" elem="Table">
            <table>
                <thead>
                {this.renderTicketHeadingRow()}
                </thead>
                <tbody>
                {this.renderTicketRows()}
                </tbody>
            </table>
            </div>
        );
    }

    renderTicketRow(order) {
        const {id, base_order_info: {id: defaultId} = {}} = order;
        return (
            <MyAccountTicketTableRow
                key={id || defaultId}
                order={order}
            />
        );
    }

    renderTicketRows() {
        const {ticketList: {items = []}, isLoading} = this.props;

        if (!isLoading && !items.length) {
            return this.renderNoTickets();
        }

        return items.reduce(
            (acc, e) => [...acc, this.renderTicketRow(e)],
            []
        );
    }

    renderPagination() {
        const {
            isLoading,
            ticketList: {
                items,
                pageInfo: {
                    total_pages = 0
                } = {}
            }
        } = this.props;

        if (typeof items === 'undefined' || !items.length) {
            return null;
        }

        return (
            <Pagination
                isLoading={isLoading}
                totalPages={total_pages}
                mix={{block: 'MyAccountMyTickets', elem: 'Pagination'}}
            />
        );
    }


    renderAddNewTicket() {
        const {onNewTicket, onlyAddNewTicket} = this.props;
        if (onlyAddNewTicket) {

            let mix = (typeof onlyAddNewTicket.mix !== 'undefined') ? onlyAddNewTicket.mix : {block: 'NewTicketPopup'};

            return (
                <>
                    <>
                        <span
                            block="RequestCancellation"
                            // mix={{block: 'NewTicket', elem: 'Button'}}
                            // mods={{isHollow: true}}
                            onClick={onNewTicket}
                        >
                            {onlyAddNewTicket.buttonTitle}
                        </span>
                        <Popup
                            id="NewTicketPopup"
                            mix={mix}
                            onClose={this.closeNewTicketPopup.bind(this)}
                            clickOutside={false}
                            hasFooter={true}
                        >
                            <Loader isLoading={true}/>
                            <div>
                                {this.ticketNote()}
                                {this.newTicket()}
                            </div>
                            {this.renderPopupSchedule()}
                        </Popup>
                    </>
                </>
            )
        }

        return (
            <>
                <button
                    block="Button"
                    mix={{block: 'MyAccountMyTickets', elem: 'Button'}}
                    // mods={{isHollow: true}}
                    onClick={onNewTicket}
                >
                    {__('Create New Ticket')}
                </button>
                {/*{this.renderWorkingHours()}*/}
                <Popup
                    id="NewTicketPopup"
                    mix={{block: 'NewTicketPopup'}}
                    onClose={this.closeNewTicketPopup.bind(this)}
                    clickOutside={false}
                    hasFooter={true}
                >
                    <Loader isLoading={true}/>
                    <div>
                        {this.newTicket()}
                    </div>
                    {this.renderPopupSchedule()}
                </Popup>
            </>
        );
    }

    renderPopupSchedule() {
        const {ticketList: {schedule}} = this.props;

        if (typeof schedule === 'undefined') {
            return null;
        }

        let scheduleData = JSON.parse(schedule);

        if (!scheduleData.open_message || !scheduleData.closed_message) {
            return null;
        }

        if (scheduleData.schedule_is_open) {
            return (
                <>
                    <div className="hdmx__schedule-status status-open">
                        <TimeCountDown messages={scheduleData.open_message.split('|')} source='opened'
                                       countDown={scheduleData.closed_in}/>
                        <div className='status-content response-time'>
                            Avg response time: 1 hr
                        </div>
                    </div>

                </>
            )
        }

        return (
            <>
                <div className="hdmx__schedule-status status-close">
                    <TimeCountDown messages={scheduleData.closed_message.split('|')} source='closed'
                                   countDown={scheduleData.open_in}/>
                </div>
            </>
        )
    }

    renderSchedule(responseTime = false) {

        const {ticketList: {schedule}} = this.props;
        let items = JSON.parse(schedule);
        const {schedule_is_open, open_message, open_in, closed_message, closed_in} = items;

        if (schedule_is_open) {
            return (
                <div className="hdmx__schedule-status status-open">
                    {/*{open_message}*/}
                    <TimeCountDown messages={open_message.split('|')} source='opened' countDown={closed_in}/>
                    {
                        (responseTime) ?
                            <div className='status-content response-time'>
                                Avg response time: 1 hr
                            </div>
                            : ''
                    }

                </div>
            )
        }

        if (closed_message) {
            return (
                <div className="hdmx__schedule-status status-close">
                    {/*{closed_message}*/}
                    <TimeCountDown messages={closed_message.split('|')} source='closed' countDown={open_in}/>
                </div>
            )
        }

        return null;

    }


    renderScheduleHeadingRow(days) {
        return (
            <tr block="Heading">
                <th><span>{__('Days')}</span></th>
                {
                    days.map((item, i) => {
                        return (
                            <th>{item.day}</th>
                        )
                    })
                }
            </tr>
        );
    }

    renderScheduleTimeRow(schedule) {

        if (schedule.is_holiday) {
            return;
        }

        return (
            <tr block="Heading">
                <th>{schedule.title}</th>
                {
                    schedule.days.map((item, i) => {
                        return (
                            <th block={item.time.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")}>{item.time.replaceAll(':00', '')}</th>
                        )
                    })
                }
            </tr>
        );
    }

    renderScheduleTable(schedule) {

        return (
            <div block="ContactPage" elem="WorkingHoursColumnWrapper">
            <table>
                <thead>
                {this.renderScheduleHeadingRow(schedule.current_schedule.days)}
                </thead>
                <tbody>
                {this.renderScheduleTimeRow(schedule.current_schedule)}
                {
                    (schedule.upcoming_schedule) ? this.renderUpcomingSchedule(schedule.upcoming_schedule) : ''
                }
                </tbody>
            </table>
            </div>
        );
    }

    renderUpcomingSchedule(schedule) {
        return schedule.map((item, i) => {
            return this.renderScheduleTimeRow(item)
        })
    }

    renderWorkingHours() {
        const {ticketList: {schedule}} = this.props;
        let items = JSON.parse(schedule);
        const {working_hours, schedule_in_my_tickets} = items;

        if (!working_hours || !schedule_in_my_tickets) {
            return null;
        }

        return (
            <div block="ContactPage" elem="WorkingHSchedules">
                {this.contactPageHeading('Working Schedules')}
                {this.renderSchedule(true)}
                {this.renderScheduleTable(working_hours)}
                <span className="note"><u>Important</u>: For every public holiday, we’ll be off for both office and online support</span>

                {
                    (working_hours.upcoming_holidays) ? this.renderHolidays(working_hours.upcoming_holidays) : ''
                }

            </div>
        );
    }

    renderHolidays(holidays) {
        return (
            <span className="note">Upcoming public holidays: {holidays}</span>
        )
    }

    closeNewTicketPopup() {
        let {onlyAddNewTicket} = this.props;

        preparedTicketData = {};
        preparedTicketDataCopy = {};

        if (onlyAddNewTicket) {
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
        }

    }

    contactPageHeading(heading) {
        return (
            <h1 block="Contact" elem="Heading">
                {heading}
            </h1>
        )
    }

    ticketNote() {
        const {onlyAddNewTicket} = this.props;
        if (onlyAddNewTicket) {
            return (
                <div block="NewTicketPopup" elem="Note">
                    <span>Note:</span>&nbsp;
                    <Html content={onlyAddNewTicket.ticketNote}/>
                </div>
            )
        }
    }


    newTicket() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);

        let {ticketList: {newTicketData, schedule}, onlyAddNewTicket} = this.props;
        if (typeof newTicketData === 'undefined') {
            return null;
        }
        // console.log(JSON.parse(newTicketData));

        let newTicket = JSON.parse(newTicketData);
        schedule = JSON.parse(schedule);
        // console.log(newTicket);
        // console.log(schedule);

        let messageTitle = 'Message';
        let messagePlaceholder = 'Your reply here';

        let ticketSubjectAttr = {
            id: `new-ticket-subject`,
            name: `subject`,
            placeholder: __('Your Ticket Subject'),
            isRequired: true,
        }

        let submitTitle = __('Submit Ticket');
        let showCancel = false;

        if (onlyAddNewTicket) {
            submitTitle = onlyAddNewTicket.submitTitle;
            showCancel = onlyAddNewTicket.showCancel;
            messageTitle = onlyAddNewTicket.messageTitle;
            messagePlaceholder = onlyAddNewTicket.messagePlaceholder;
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
            ticketSubjectAttr.value = onlyAddNewTicket.ticketSubject;
            ticketSubjectAttr.disabled = !!(onlyAddNewTicket && typeof onlyAddNewTicket.ticketSubject !== 'undefined');
        }

        return (
            <>
                <Field
                    addRequiredTag={true}
                    label={__('Subject')}
                    type={FieldType.TEXT}
                    attr={ticketSubjectAttr}
                    events={{
                        onChange: this.handleFieldChange.bind(this)
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            if (!(value)) {
                                delete preparedTicketData['subject'];
                                document.getElementById('submit-new-ticket').classList.add('disabled');
                            }
                            return !!(value);
                        },
                        customErrorMessages: {
                            onMatchFail: 'This field is required!'
                        }
                    }}
                />
                <Field
                    addRequiredTag={true}
                    label={messageTitle}
                    type={FieldType.TEXTAREA}
                    attr={{
                        id: `helpdesk-new-message`,
                        name: `message`,
                        placeholder: messagePlaceholder,
                        isRequired: true
                    }}
                    events={{
                        onChange: this.handleFieldChange.bind(this)
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            if (!(value)) {
                                delete preparedTicketData['message'];
                                document.getElementById('submit-new-ticket').classList.add('disabled');
                            }
                            return !!(value);
                        },
                        customErrorMessages: {
                            onMatchFail: 'This field is required!'
                        }
                    }}
                />

                <div block="OptionsWrapper">
                    {this.renderDropDowns('Order', 'order_id', newTicket.show_orders)}
                    {this.renderDropDowns('Department', 'department_id', newTicket.department)}
                    {this.renderDropDowns('Priority', 'priority_id', newTicket.priority)}

                    {!newTicket.is_custom_fields ? '' :
                        newTicket.is_custom_fields.map((item, i) => {
                            let fieldType;
                            if (item.type === 'text') {
                                fieldType = FieldType.TEXT;
                            }
                            if (item.type === 'textarea') {
                                fieldType = FieldType.TEXTAREA;
                            }
                            if (item.type === 'select') {
                                fieldType = FieldType.SELECT;
                            }
                            if (item.type === 'checkbox') {
                                fieldType = FieldType.CHECKBOX;
                            }
                            if (item.type === 'date') {
                                fieldType = FieldType.DATE;
                            }
                            let options;
                            if (item.values) {
                                options = item.values.map(({id, name}) => ({id, label: name, value: id}));
                            } else {
                                options = false;
                            }
                            return (
                                <Field
                                    addRequiredTag={item.is_required}
                                    label={item.name}
                                    type={fieldType}
                                    options={options}
                                    attr={{
                                        id: `helpdesk-new-message-${item.code}`,
                                        name: item.code,
                                        placeholder: __('Your Ticket') + ' ' + item.name,
                                        isRequired: item.is_required,
                                        helpdeskCustomField: true
                                    }}
                                    events={{
                                        onChange: this.handleFieldChange.bind(this)
                                    }}
                                    validateOn={['onChange']}
                                    validationRule={{
                                        match: (value) => {
                                            if (!item.is_required) {
                                                delete preparedTicketData[item.code];
                                                return true;
                                            }
                                            if (!(value)) {
                                                delete preparedTicketData[item.code];
                                                document.getElementById('submit-new-ticket').classList.add('disabled');
                                            }
                                            return !!(value);
                                        },
                                        customErrorMessages: {
                                            onMatchFail: 'This field is required!'
                                        }
                                    }}
                                />
                            )
                        })
                    }
                </div>
                {this.renderAttachments()}
                {
                    (showCancel) ?
                        <div block="SubmitNewTicket" elem="Actions">
                            <button
                                id="submitnewticket-cancel"
                                className="SubmitNewTicket-Cancel"
                                type="button"
                                block="Button"
                                mix={{block: 'CancelButton', elem: 'Cancel'}}
                                onClick={this.closeCustomTicketDataPopup}
                            >
                                {__('Cancel')}
                            </button>
                            <button
                                // disabled
                                id="submit-new-ticket"
                                block="Button disabled"
                                onClick={this.submitNewTicket.bind(this)}
                            >
                                {submitTitle}
                            </button>
                        </div>
                        :
                        <div block="SubmitNewTicket" elem="Actions">
                            <button
                                // disabled
                                id="submit-new-ticket"
                                block="Button disabled"
                                onClick={this.submitNewTicket.bind(this)}
                            >
                                {submitTitle}
                            </button>
                        </div>
                }

            </>
        )
    }

    renderDropDowns(option, optionCode, source) {
        if (!source) {
            return null;
        }

        return (
            <Field
                addRequiredTag={(option !== 'Order')}
                label={option}
                type={FieldType.SELECT}
                options={source.map(({id, name}) => ({id, label: name, value: id}))}
                attr={{
                    id: `helpdesk-new-message-${option}`,
                    name: optionCode,
                    isRequired: (option !== 'Order')
                }}
                events={{
                    onChange: this.handleFieldChange.bind(this)
                }}
                validateOn={['onChange']}
                validationRule={{
                    match: (value) => {
                        if (option === 'Order') {
                            delete preparedTicketData[`option-helpdesk-new-message-${option}`];
                            return true;
                        }
                        if (!(value)) {
                            delete preparedTicketData[`option-helpdesk-new-message-${option}`];
                            document.getElementById('submit-new-ticket').classList.add('disabled');
                        }
                        return !!(value);
                    },
                    customErrorMessages: {
                        onMatchFail: 'This field is required!'
                    }
                }}
            />
        )
    }

    renderAttachments() {

        const {ticketList: {newTicketData}} = this.props;
        if (typeof newTicketData === 'undefined') {
            return null;
        }

        let newTicket = JSON.parse(newTicketData);

        if (!newTicket.attachment_is_allowed) {
            return null;
        }

        return (
            <>
                <div className="max-attachment-size">
                    <label className="Field-Label">Attach Files</label>
                </div>
                <DropzoneArea
                    onChange={this.handleChange.bind(this)}
                    getPreviewIcon={handlePreviewIcon}
                    Icon={UploadIcon}
                    filesLimit={100}
                    showFileNames={true}
                    showAlerts={false}
                    dropzoneText={"Upload file (max file " + newTicket.attachment_size + " Mb)"}
                />
            </>
        )

        // return (
        //     <>
        //         <div className="max-attachment-size">
        //             <label className="Field-Label">Max file size: {newTicket.attachment_size} Mb</label>
        //         </div>
        //         <DropzoneArea
        //             onChange={this.handleChange.bind(this)}
        //             getPreviewIcon={handlePreviewIcon}
        //             Icon={UploadIcon}
        //             filesLimit={100}
        //             showFileNames={true}
        //             showAlerts={false}
        //         />
        //     </>
        // )

    }

    handleChange(files) {
        let preparedFiles = [];
        const {callBack} = this.props
        files.map((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                let dropZoneFile = {
                    name: file.name,
                    size: file.size,
                    type: !!(file.type) ? file.type : 'unknown',
                    data: reader.result,
                }
                preparedFiles.push(dropZoneFile);
            };

        })

        this.setState({files: files, preparedFiles: preparedFiles});
    }

    async submitNewTicket() {

        // console.clear();
        // console.log(preparedTicketData);
        // console.log(preparedTicketDataCopy);
        // console.log(this.state);
        // console.log(this.state);
        // console.log(this.props);


        const {getTicketList, showNewTicket, showNotification} = this.props;
        const {preparedFiles} = this.state;
        let ticketData = {
            'ticketFields': preparedTicketDataCopy,
            'attachments': preparedFiles,
        }

        document.querySelectorAll('.NewTicketPopup .Loader').forEach(n => n.style.display = "block");

        const newTicketResponse = await fetchMutation(TicketQuery.createNewTicket(JSON.stringify(ticketData)));
        showNewTicket();
        const {createNewTicket: {tickets_list}} = newTicketResponse;
        let result = JSON.parse(tickets_list);
        // console.log(result);
        if (result.success) {
            // showNotification('success', __('Your ticket has been created.'));
            showNewTicket({
                title: 'Submission Received',
                successPopupContent: this.submissionRecieved(result.ticket_id)
            });
        } else {
            showNotification('error', __('Your ticket has not been created.'));
        }
        // console.log(tickets_list);
        // console.log(newTicketResponse);

        getTicketList(1);
        this.closeNewTicketPopup();


    }

    submissionRecieved(ticketId) {
        return (
            <div className='SubmissionRecieved'>
                <h3>Ticket ID: #{ticketId}</h3>
                <p>Save this Ticket ID for reference. Our support team will be in contact with you shortly through
                    email.</p>
                <Link
                    onClick={this.goToTickets}
                    to='/helpdesk/tickets'
                >
                    My Support Tickets
                </Link>
            </div>
        )
    }

    handleFieldChange(event, field) {

        const {ticketList: {newTicketData}} = this.props;
        let newTicket = JSON.parse(newTicketData);
        let totalRequired = newTicket.total_required;

        let {isRequired, value = ''} = field;
        // console.clear();
        // console.log(value);
        // console.log(field);
        // console.log(this.state);
        // console.log(this.props);

        if (field.name.includes('checkbox') && typeof preparedTicketDataCopy[field.name] !== 'undefined') {
            value = preparedTicketDataCopy[field.name] = !preparedTicketDataCopy[field.name];
        }

        if (isRequired) {
            preparedTicketData[field.name] = value;
        }

        preparedTicketDataCopy[field.name] = value;

        // console.log(preparedTicketData);
        // console.log(preparedTicketDataCopy);

        let filedFields = Object.keys(preparedTicketData).length;
        // console.log(totalRequired);
        // console.log(filedFields);
        if (totalRequired === filedFields) {
            document.getElementById('submit-new-ticket').classList.remove('disabled');
        }
    }

    render() {
        const {isLoading, onlyAddNewTicket = false, ticketList: {items}} = this.props;

        if (isLoading) {
            return;
        }

        if (onlyAddNewTicket) {
            return this.renderAddNewTicket();
        }
        return (
            <div block="MyAccountMyTickets">
                <Loader isLoading={isLoading}/>
                {(items.length) ? this.renderAddNewTicket() : ''}
                {this.renderWorkingHours()}
                {this.renderTicketsTable()}
                {this.renderPagination()}
            </div>
        );
    }

}

export default MyAccountMyTickets;
