/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from '@scandipwa/scandipwa/src/util/Query';

/** @namespace Query/ProductAlerts/Query */
export class ShippingSystem {
    getShippingSystem(items, zip, courier, pinCodeChecker = false) {
        return new Field('shippingSystem').addFieldList([
            'shippingsystem_data'
        ]).addArgument('items', 'String!', items).addArgument('zip', 'Int!', zip).addArgument('courier', 'String', courier).addArgument('pinCodeChecker', 'Boolean', pinCodeChecker);
    }
    searchPinCode(zip) {
        return new Field('searchPinCode').addFieldList([
            'shippingsystem_data'
        ]).addArgument('zip', 'String!', zip);
    }
}

export default new ShippingSystem();
