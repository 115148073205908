/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import xNotifyQuery from './../../query/xNotify.query';
import {showNotification} from 'Store/Notification/Notification.action';
import {fetchQuery} from 'Util/Request/Query';
import { getErrorMessage } from 'Util/Request/Error';

import StockAlert from './StockAlert.component';

/** @namespace Component/StockAlert/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Component/StockAlert/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('info', message))
});

/** @namespace Component/StockAlert/Container */
export class StockAlertContainer extends PureComponent {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
    };

    state = {
        items: [],
        isLoading: false,
    };

    containerFunctions = {
        onOrderIdClick: this.onOrderIdClick.bind(this)
    };

    componentDidMount() {
        this.requestDownloadable();
    }

    containerProps = () => {
        const {isLoading} = this.state;

        const {updatedData = false} = this.props;

        return {
            updatedData,
            isLoading,
            items: this._prepareDownloadableProps()
        };
    };

    _prepareDownloadableProps() {
        const {items} = this.state;

        if (!items?.length) {
            return [];
        }

        return items.reduce((acc, item, index) => {
            acc.push({
                id: index,
                image: item.image,
                title: item.title,
                subscribedOn: item.subscribedOn,
                stockStatus: item.stockStatus,
                price: item.price,
                notificationStatus: item.notificationStatus,
                productUrl: item.productUrl,
                productId: item.productId
            });

            return acc;
        }, []);
    }

    requestDownloadable(action = false, productId = 0) {
        const {showSuccessNotification, showErrorNotification} = this.props;

        this.setState({isLoading: true});

        fetchQuery(
            xNotifyQuery.getSubscriptions('stock', action, productId)
        ).then(
            /** @namespace Component/StockAlert/Container/requestDownloadable/success */
            (
                /** @namespace Component/StockAlert/Container/StockAlertContainer/requestDownloadable/fetchQuery/then */
                (data) => {
                    const items = data.amastyXnotify;
                    this.setState({items, isLoading: false});
                    if (action === 1) {
                        showSuccessNotification('You will no longer receive stock alerts for this product.');
                    }
                }),
            /** @namespace Component/StockAlert/Container/StockAlertContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                showErrorNotification(getErrorMessage(err));
                this.setState({isLoading: false});
            }
        );
    }

    onOrderIdClick(e) {
        const productId = e.currentTarget.attributes.getNamedItem('data-productId').value;
        this.requestDownloadable(1, productId);
    }

    render() {
        return (
            <StockAlert
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockAlertContainer);
