import {PureComponent} from 'react';
import Loader from "Component/Loader/Loader.component";
import Html from "Component/Html";
import Link from "Component/Link/Link.component";

// import './PriceAlert.style';
import './../../plugin/Xnotify.style.scss';
import TrashIcon from "Component/TrashIcon";

export class PriceAlert extends PureComponent {

    renderNoOrders() {
        const {updatedData: {noSubscriptionAlert = false}} = this.props;

        if (noSubscriptionAlert) {
            return (
                <div block="MyAccountMySubscriptions" elem="NoSubscriptionFound">
                    <div block="NoSubscriptionNote">
                        { noSubscriptionAlert.svg }
                        <p>{ noSubscriptionAlert.title }</p>
                        <p>{ noSubscriptionAlert.note }</p>
                    </div>
                </div>
            );
        }

        return (
            <div block="MyAccountMySubscriptions" elem="NoOrders">
                <span>{__('You do not have any subscriptions yet.')}</span>
            </div>
        );
    }

    renderOrderHeadingRow() {
        let {items, isLoading, updatedData: {imageAndTitle, stockStatusMessage = false}} = this.props;

        // if (!isLoading && !items.length) {
        //     return this.renderNoOrders();
        // }

        if (!stockStatusMessage) {
            stockStatusMessage = __('Stock Status');
        }

        return (
            <tr>
                {
                    (imageAndTitle) ?
                        <>
                            <th colspan="2">{__('Product')}</th>
                        </>:
                        <>
                            <th>{__('Image')}</th>
                            <th>{__('Title')}</th>
                        </>
                }

                <th>{__('Subscribed On')}</th>
                <th>{__('Notification Status')}</th>
                <th>{stockStatusMessage}</th>
                <th>{__('Price')}</th>
                <th>{__('Action')}</th>
            </tr>
        );
    }

    renderTable() {

        const {isLoading, items} = this.props;

        if (isLoading) {
            return null;
        }

        if (!items.length) {
            return this.renderNoOrders();
        }

        return (
            <table
                block="MyAccountMySubscriptions"
                elem="Table"
            >
                <thead>
                {this.renderOrderHeadingRow()}
                </thead>
                <tbody>
                {this.renderPriceAlertRows()}
                </tbody>
            </table>
        );
    }

    renderPriceAlertRow = (order) => {
        const {
            id,
            image,
            price,
            stockStatus,
            subscribedOn,
            notificationStatus,
            productUrl,
            title,
            productId
        } = order;

        let {onOrderIdClick, updatedData: {imageAndTitle, stockStatusMessage = false}} = this.props;

        if (!price) {
            return null;
        }

        if (!stockStatusMessage) {
            stockStatusMessage = __('Stock Status: ');
        }else {
            stockStatusMessage = stockStatusMessage + ': ';
        }

        return (
            <tr block="SubscriptionTableRow" key={ id }>
                <td>
                    <Link
                        to={productUrl}
                        block="SubscriptionTableRow"
                        elem="ProductUrl"
                    >
                        <img
                            width={120}
                            height={120}
                            src={image}
                            alt={title}
                        />
                    </Link>
                </td>
                <td data-th={__('Title: ')}>
                    <Link
                        to={productUrl}
                        block="SubscriptionTableRow"
                        elem="ProductUrl"
                    >
                        {title}
                    </Link>
                </td>
                <td data-th={__('Subscribed On: ')}>{subscribedOn}</td>
                <td data-th={__('Notification Status: ')}>{notificationStatus}</td>
                <td data-th={stockStatusMessage}>{stockStatus}</td>
                <td data-th={__('Price: ')}><Html content={price}/></td>
                <td data-th={__('Remove')} data-productId={productId} block="remove" onClick={onOrderIdClick}><TrashIcon/></td>
            </tr>
        );
    };

    renderPriceAlertRows() {
        const {items, isLoading} = this.props;
        if (!isLoading && !items.length) {
            return null;
        }
        const orders = items.length
            ? items
            : Array.from({length: 10}, (_, id) => ({id}));
        return orders.reduceRight(
            (acc, e) => [...acc, this.renderPriceAlertRow(e)],
            []
        );
    }

    render() {
        const {isLoading, items} = this.props;
        console.log(items);
        return (
            <div
                block="MyAccountMySubscriptions"
                mods={{isEmpty: !(items.length)}}
            >
                <Loader isLoading={isLoading}/>
                {this.renderTable()}
            </div>
        );
    }
}

export default PriceAlert;
