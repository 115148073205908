/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';

import './CommentsIcon.style';

export class CommentsIcon extends PureComponent {
    render() {
        return (
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.625 14.25H6.25C3.25 14.25 1.75 13.5 1.75 9.75V6C1.75 3 3.25 1.5 6.25 1.5H12.25C15.25 1.5 16.75 3 16.75 6V9.75C16.75 12.75 15.25 14.25 12.25 14.25H11.875C11.6425 14.25 11.4175 14.3625 11.275 14.55L10.15 16.05C9.655 16.71 8.845 16.71 8.35 16.05L7.225 14.55C7.105 14.385 6.8275 14.25 6.625 14.25Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.5 6H13" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.5 9.75H10" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        );
    }
}

export default CommentsIcon;
