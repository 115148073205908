/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from "react";
import { DecorationComponentProps } from "./Decoration.type";

import "./Decoration.style";

/** @namespace Component/Decoration/Component */
export class Decoration extends PureComponent<DecorationComponentProps> {
    static defaultProps: Partial<DecorationComponentProps> = {
        source: "",
    };

    render() {
        const { direction, angle, type, source } = this.props;

        return (
            <div
                block="Decoration"
                mods={{ direction, angle, type }}
                mix={{ block: source }}
            />
        );
    }
}

export default Decoration;
