import {withStoreRegex} from "Component/Router/Router.component";
import MyAccount from "Route/MyAccount";
import {Route} from 'react-router-dom';
import {MY_TICKETS, MY_TICKET} from "./RouteMyAccount.plugin";
import ExternalTicket from "../component/ExternalTicket";
import {_getStoreConfigFields} from "./ConfigQuery.plugin";

export const INCREASED_POSITION = 1;

const SWITCH_ITEMS_TYPE = (originalMember) => {
    const maxPosition = Math.max.apply(
        Math, originalMember.filter(route => route.name !== 'URL_REWRITES'
        ).map(function (route) {
            return route.position;
        }));

    return [
        ...originalMember,
        {
            component: <Route path={withStoreRegex('/helpdesk/tickets')}
                              render={(props) => <MyAccount {...props} selectedTab={ MY_TICKETS }/>}/>,
            position: maxPosition + INCREASED_POSITION + INCREASED_POSITION,
            name: 'MY_ACCOUNT_TICKETS'
        },
        {
            component: <Route path={withStoreRegex('/helpdesk/tickets/view/:orderId?')}
                              render={(props) => <MyAccount {...props} selectedTab={ MY_TICKETS }/>}/>,
            position: maxPosition + INCREASED_POSITION,
            name: 'MY_ACCOUNT_TICKET'
        },
        {
            component: <Route path={ withStoreRegex('/helpdesk/ticket/external/id/:orderId?') } render={ (props) => <ExternalTicket { ...props } /> } />,
            position: maxPosition + INCREASED_POSITION,
            name: 'EXTERNAL_TICKET'
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: SWITCH_ITEMS_TYPE
        }
    }
};
