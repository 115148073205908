import {ImageState} from "Component/Image/Image.config";
import '../plugin/Webp.scss'

const renderStyledImage = (args, callback, instance) => {
    const {
        alt,
        src,
        style,
        title
    } = instance.props;

    const {imageStatus} = instance.state;

    let isWebpImage = src.toLowerCase().includes('.webp');

    let ProductImage = <img
        block="Image"
        elem="Image"
        src={src || ''}
        alt={alt}
        mods={{isLoading: imageStatus === ImageState.IMAGE_LOADING}}
        style={style}
        title={title}
        onLoad={instance.onLoad}
        onError={instance.onError}
        loading="lazy"
    />;

    if (!isWebpImage) {
        return ProductImage;
    }
    let originalSrc = src.replace(".webp", "");

    ProductImage = <img
        block="Image"
        elem="Image"
        src={originalSrc || ''}
        alt={alt}
        mods={{isLoading: imageStatus === ImageState.IMAGE_LOADING}}
        style={style}
        title={alt}
        onLoad={instance.onLoad}
        onError={instance.onError}
        loading="lazy"
    />;

    return (
        <picture>
            <source srcSet={src} type="image/webp"/>
            {ProductImage}
        </picture>
    );

};

const renderPlainImage = (args, callback, instance) => {

    const {
        alt,
        src,
        style,
        title,
        className
    } = instance.props;

    let isWebpImage = src.toLowerCase().includes('.mst.webp');

    let ProductImage = <img
        block={ className }
        src={ src || '' }
        alt={ alt }
        style={ style }
        title={ title }
        onLoad={ instance.onLoad }
        onError={ instance.onError }
        loading="lazy"
    />

    if (!isWebpImage) {
        return ProductImage;
    }

    let originalSrc = src.replace(".mst.webp", "");

    ProductImage = <img
        block={ className }
        src={ originalSrc || '' }
        alt={ alt }
        style={ style }
        title={ title }
        onLoad={ instance.onLoad }
        onError={ instance.onError }
        loading="lazy"
    />

    return (
        <picture>
            <source srcSet={src} type="image/webp"/>
            {ProductImage}
        </picture>
    );

};
export default {
    'Component/Image/Component': {
        'member-function': {
            renderStyledImage,
            renderPlainImage
        }
    },
};
