export const PRICE_ALERT = 'price-alert';
export const STOCK_ALERT = 'stock-alert';

const tabMap = (member) => {

    member[PRICE_ALERT] = {
        url: '/price-alert',
        tabName: __('Price Subscriptions')
    };
    member[STOCK_ALERT] = {
        url: '/stock-alert',
        tabName: __('Back in Stock Subscriptions')
    };
    return member;
};


export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: tabMap
        }
    }
};
