Mosaic.addPlugins([require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/mirasvit-helpdesk/src/plugin/ComponentMyAccountTabList.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import MyAccountTabListItem from 'Component/MyAccountTabListItem';
import { MyAccountTab } from 'Route/MyAccount/MyAccount.type';
import { ObjectEntries, ReactElement } from 'Type/Common.type';

import { MyAccountTabListComponentProps } from './MyAccountTabList.type';

import './MyAccountTabList.style';

/** @namespace Component/MyAccountTabList/Component */
export class MyAccountTabListComponent extends PureComponent<MyAccountTabListComponentProps> {
    renderTabListItem(
        tabEntry: ObjectEntries<Record<string, MyAccountTab>>,
        index: number,
        tabArray: ObjectEntries<Record<string, MyAccountTab>>[],
    ): ReactElement {
        const { activeTab, onTabClick } = this.props;
        const [key, tab] = tabEntry;
        const { section } = tab;
        const nextTab = tabArray[index + 1] || [];
        const { section: nextTabSection = section } = nextTab[1] || {};

        return (
            <MyAccountTabListItem
              key={ key }
              isActive={ activeTab === key }
              changeActiveTab={ onTabClick }
              tabEntry={ tabEntry }
            >
                { nextTabSection !== section ? this.renderLine() : null }
            </MyAccountTabListItem>
        );
    }

    renderLine(): ReactElement {
        return null;
        // return (
        //     <div block="MyAccountTabList" elem="Separator" />
        // );
    }

    renderLogoutTab(): ReactElement {
        const { handleLogout } = this.props;

        return (
            <li
              key="logout"
              block="MyAccountTabListItem"
              mix={{block: 'logout'}}
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ handleLogout }
                  role="link"
                >
                    <div className="item-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.666 11.3332L13.9993 7.99984L10.666 4.6665" stroke="#E31620" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14 8H6" stroke="#E31620" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6" stroke="#E31620" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        { __('Logout') }
                    </div>
                </button>
            </li>
        );
    }

    render(): ReactElement {
        const {
            activeTab,
            isContentExpanded,
            tabMap,
            toggleExpandableContent,
        } = this.props;
        const { tabName } = tabMap[activeTab];

        const tabs = [
            ...Object.entries(tabMap).map(this.renderTabListItem.bind(this)),
            this.renderLogoutTab(),
        ];

        return (
            <ExpandableContent
              heading={ tabName }
              isContentExpanded={ isContentExpanded }
              mix={ { block: 'MyAccountTabList' } }
              onClick={ toggleExpandableContent }
              mods={ { isWithoutBorder: true } }
            >
                <ul>
                    { tabs }
                </ul>
            </ExpandableContent>
        );
    }
}

export default MyAccountTabListComponent;
