import {
    MyAccountNewsletterSubscriptionComponent as SourceMyAccountNewsletterSubscriptionComponent,
} from 'SourceComponent/MyAccountNewsletterSubscription/MyAccountNewsletterSubscription.component';

import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';
import FieldForm from 'Component/FieldForm';
import { FormContainerProps } from 'Component/Form/Form.type';
import { ReactElement } from 'Type/Common.type';

import './MyAccountNewsletterSubscription.style';

export class MyAccountNewsletterSubscriptionComponent extends SourceMyAccountNewsletterSubscriptionComponent {
    fieldMap(): Partial<FieldContainerProps>[] {
        const { setSubscriptionStatus, isSubscriptionSelected } = this.props;

        return [
            {
                type: FieldType.CHECKBOX,
                attr: {
                    name: 'isSubscribed',
                    defaultChecked: isSubscriptionSelected,
                },
                events: {
                    onChange: setSubscriptionStatus,
                },
                // label: __('General subscription'),
            },
        ];
    }

    renderFormBody(): ReactElement {
        return (
            <div block="MyAccountNewsletterSubscription" elem="Wrapper">
                <div block="MyAccountNewsletterSubscription" elem="Note">
                    <span>Subscribe me to the newsletter</span>
                    <p>Subscribe to get exclusive weekly deals, discounts, new launches, straight from our shop to your inboxes.</p>
                </div>
                <div
                    block="FieldForm"
                    elem="Fields"
                    mix={ { block: 'MyAccountNewsletterSubscription' } }
                >

                    { super.renderFormBody() }
                    {/*<div className="asd">{ super.renderFormBody() }</div>*/}
                    {/*<input type="checkbox" id="switch"/><label htmlFor="switch">Toggle</label>*/}

                </div>
            </div>
        );
    }

    getFormProps(): Partial<FormContainerProps> {
        const { onCustomerSave, onError } = this.props;

        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit: onCustomerSave,
            onError,
            returnAsObject: true,
        };
    }

    renderActions(): ReactElement {
        return (
            <button
                id="updateSubscription"
                type={ FieldType.SUBMIT }
                block="Button"
                mix={ { block: 'MyAccountNewsletterSubscription', elem: 'Button' } }
                aria-label={ __('Submit') }
            >
                { __('Save changes') }
            </button>
        );
    }
};

export default MyAccountNewsletterSubscriptionComponent;
