/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';
import {connect} from 'react-redux';
import AccountTermsAndConditionsPopup from './AccountTermsAndConditionsPopup.component';

/** @namespace Component/AccountTermsAndConditionsPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Component/AccountTermsAndConditionsPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Component/AccountTermsAndConditionsPopup/Container */
export class AccountTermsAndConditionsPopupContainer extends PureComponent {

    render() {
        return (
            <AccountTermsAndConditionsPopup/>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountTermsAndConditionsPopupContainer);
