/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {PureComponent} from "react";
import getStore from "Util/Store";
import GoogleMapReact from 'google-map-react';
import Link from 'Component/Link';

/** @namespace Component/MagePlazaGoogleMaps/Component */
const apiIsLoaded = (map, maps) => {
    if (map) {
        // map.setOptions({ gestureHandling: 'greedy', mapTypeControl: true, minZoom: 2, draggable:true});
        const {
            secure_base_media_url,
            // mpgooglemaps_map_setting_api_key,
            mpgooglemaps_map_setting_map_type,
            mpgooglemaps_map_setting_map_style,
            mpgooglemaps_map_setting_allow_drag,
            mpgooglemaps_map_setting_zoom_default,
            mpgooglemaps_map_setting_marker_icon,
            mpgooglemaps_map_setting_location_setting_latitude,
            mpgooglemaps_map_setting_location_setting_longitude
        } = getStore().getState().ConfigReducer;

        const mapLatLng = new maps.LatLng(mpgooglemaps_map_setting_location_setting_latitude, mpgooglemaps_map_setting_location_setting_longitude);

        const mapOptions = {
            draggable: !!(parseInt(mpgooglemaps_map_setting_allow_drag)),
            zoom: parseInt(mpgooglemaps_map_setting_zoom_default),
            center: mapLatLng,
            mapTypeId: mpgooglemaps_map_setting_map_type,
            styles: mpgooglemaps_map_setting_map_style
        };

        map.setOptions(mapOptions);

        const icon = {
            url: secure_base_media_url + 'mageplaza/google_maps/marker_icon/' + mpgooglemaps_map_setting_marker_icon,
            scaledSize: new maps.Size(40, 40),
            origin: new maps.Point(0, 0),
            anchor: new maps.Point(0, 0)
        };

        const marker = new maps.Marker({
            draggable: !!(parseInt(mpgooglemaps_map_setting_allow_drag)),
            position: mapLatLng,
            icon: icon,
            animation: maps.Animation.BOUNCE
        });
        marker.setMap(map);
    }
};


export class MagePlazaGoogleMaps extends PureComponent {

    render() {

        const {
            // secure_base_media_url,
            mpgooglemaps_map_setting_api_key,
            // mpgooglemaps_map_setting_map_type,
            mpgooglemaps_map_setting_map_style,
            // mpgooglemaps_map_setting_allow_drag,
            mpgooglemaps_map_setting_zoom_default,
            // mpgooglemaps_map_setting_marker_icon,
            mpgooglemaps_map_setting_location_setting_latitude,
            mpgooglemaps_map_setting_location_setting_longitude
        } = getStore().getState().ConfigReducer;

        return (
            <>
                <Link isOpenInNewTab={true} to={
                    'https://www.google.com/maps?saddr=My+Location&daddr=' + parseInt(mpgooglemaps_map_setting_location_setting_latitude) + ',' + parseInt(mpgooglemaps_map_setting_location_setting_longitude)
                } block="GetDirections" elem="Link">
                    Get Directions
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.25 12.75L12.75 5.25M12.75 5.25H5.25M12.75 5.25V12.75" stroke="#A10101" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </Link>
                <div block="GoogleMap" elem="Wrapper" style={{ height: '100%', width: '100%' }}>
                <GoogleMapReact
                    defaultCenter={{
                        lat: parseInt(mpgooglemaps_map_setting_location_setting_latitude),
                        lng: parseInt(mpgooglemaps_map_setting_location_setting_longitude)
                    }}
                    defaultZoom={parseInt(mpgooglemaps_map_setting_zoom_default)}
                    center={{
                        lat: parseInt(mpgooglemaps_map_setting_location_setting_latitude),
                        lng: parseInt(mpgooglemaps_map_setting_location_setting_longitude)
                    }}
                    zoom={parseInt(mpgooglemaps_map_setting_zoom_default)}
                    styles={mpgooglemaps_map_setting_map_style}
                    yesIWantToUseGoogleMapApiInternals={true}
                    bootstrapURLKeys={{key: mpgooglemaps_map_setting_api_key}}
                    onGoogleApiLoaded={({map, maps}) => apiIsLoaded(map, maps)}
                >
                </GoogleMapReact>
                </div>
            </>
        )
    }
}

export default MagePlazaGoogleMaps;
