import {
    MyAccountCreateAccountComponent as SourceMyAccountCreateAccountComponent,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { ReactElement } from 'Type/Common.type';
import history from 'Util/History';
import { validatePassword } from 'Util/Validator';
import { ValidationInputType } from 'Util/Validator/Config';

import './MyAccountCreateAccount.style';
import AccountPrivacyPolicyPopup from "Component/AccountPrivacyPolicyPopup";
import AccountTermsAndConditionsPopup
    from "Component/AccountTermsAndConditionsPopup";

export class MyAccountCreateAccountComponent extends SourceMyAccountCreateAccountComponent {

    state = {
        isPrivacyPolicyAccepted: false,
        isTermsAndConditionsAccepted: false
    };

    setPPAccepted = () => {
        //@ts-ignore
        this.setState(({isPrivacyPolicyAccepted: oldIsPPAccepted}) => ({
            isPrivacyPolicyAccepted: !oldIsPPAccepted
        }));
    };

    setTACAccepted = () => {
        //@ts-ignore
        this.setState(({isTermsAndConditionsAccepted: oldIsTACAccepted}) => ({
            isTermsAndConditionsAccepted: !oldIsTACAccepted
        }));
    };

    handleShowPrivacyPolicyPopup = (e: { preventDefault: () => void; }) => {
        //@ts-ignore
        const {showPrivacyPolicyPopup} = this.props;
        e.preventDefault();
        showPrivacyPolicyPopup();
    };

    handleShowTermsAndConditionsPopup = (e: { preventDefault: () => void; }) => {
        //@ts-ignore
        const {showTermsAndConditionsPopup} = this.props;
        e.preventDefault();
        showTermsAndConditionsPopup();
    };

    renderPrivacyPolicy() {
        const {isPrivacyPolicyAccepted} = this.state;

        return (
            <div
                block="MyAccountOverlay"
                elem="PrivacyPolicy"
            >
                <Field
                    type={FieldType.CHECKBOX}
                    attr={{
                        id: 'privacyPolicy',
                        name: 'privacyPolicy',
                        value: 'privacyPolicy',
                        checked: isPrivacyPolicyAccepted
                    }}
                    events={{
                        onChange: this.setPPAccepted
                    }}
                    mix={{block: 'MyAccountOverlay', elem: 'PrivacyPolicy-Checkbox'}}
                />
                <label
                    block="MyAccountOverlay"
                    elem="PPLabel"
                    htmlFor="privacyPolicy"
                >
                    <span>I have read and accept the &nbsp;</span>
                    <button
                        block="Button"
                        elem="Button_likeLink"
                        onClick={this.handleShowPrivacyPolicyPopup}
                    >
                        {__('Privacy Policy')}
                        <span className="Field-Label Field-Label_isRequired">&nbsp;*</span>
                    </button>
                </label>
            </div>
        );
    }

    renderTermsAndConditions() {

        const {isTermsAndConditionsAccepted} = this.state;

        return (
            <div
                block="MyAccountOverlay"
                elem="TermsAndConditions"
            >
                <Field
                    type={FieldType.CHECKBOX}
                    attr={{
                        id: 'termsAndConditions',
                        name: 'termsAndConditions',
                        value: 'termsAndConditions',
                        checked: isTermsAndConditionsAccepted
                    }}
                    events={{
                        onChange: this.setTACAccepted
                    }}
                    mix={{block: 'MyAccountOverlay', elem: 'TermsAndConditions-Checkbox'}}
                />
                <label
                    block="MyAccountOverlay"
                    elem="TACLabel"
                    htmlFor="termsAndConditions"
                >
                    <span>I agree with the &nbsp;</span>
                    <button
                        block="Button"
                        elem="Button_likeLink"
                        onClick={this.handleShowTermsAndConditionsPopup}
                    >
                        {__('Terms & Conditions')}
                        <span className="Field-Label Field-Label_isRequired">&nbsp;*</span>
                    </button>
                </label>
            </div>
        );
    }

    renderAccountPrivacyPolicyPopup() {
        return <AccountPrivacyPolicyPopup/>;
    }

    renderAccountTermsAndConditionsPopup() {
        return <AccountTermsAndConditionsPopup/>;
    }

    renderCreateAccountPersonalInfoFields(): ReactElement {
        const { newsletterActive } = this.props;
        const { location: { state: { firstName = '', lastName = '' } = {} } } = history;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                {/*<legend block="MyAccountOverlay" elem="PersonalInfoLegend">{ __('Personal Information') }</legend>*/}
                <div className='fullname'>
                <Field
                    type={ FieldType.TEXT }
                    label={ __('First Name') }
                    attr={ {
                        id: 'firstname',
                        name: 'firstname',
                        defaultValue: firstName,
                        placeholder: __('Your first name'),
                        autoComplete: 'given-name',
                    } }
                    validateOn={ ['onChange'] }
                    validationRule={ {
                        inputType: ValidationInputType.ALPHASPACE,
                        isRequired: true,
                    } }
                    addRequiredTag
                />
                <Field
                    type={ FieldType.TEXT }
                    label={ __('Last Name') }
                    attr={ {
                        id: 'lastname',
                        name: 'lastname',
                        defaultValue: lastName,
                        placeholder: __('Your last name'),
                        autoComplete: 'family-name',
                    } }
                    validateOn={ ['onChange'] }
                    validationRule={ {
                        inputType: ValidationInputType.ALPHASPACE,
                        isRequired: true,
                    } }
                    addRequiredTag
                />
                </div>
                { this.renderVatNumberField() }
            </fieldset>
        );
    }

    renderCreateAccountSignUpInfoFields(): ReactElement {
        const { location: { state: { email = '' } = {} } } = history;
        const { newsletterActive, range, minimunPasswordCharacter } = this.props;

        //@ts-ignore
        const { gstIsEnabled, gstIsVisible, gstIsRequired } = this.props;
        // console.clear();
        // console.log(this.props);

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                {/*<legend block="MyAccountOverlay" elem="SignUpLegend">{ __('Sign-Up Information') }</legend>*/}
                <Field
                    type={ FieldType.EMAIL }
                    label={ __('Email') }
                    attr={ {
                        id: 'email',
                        name: 'email',
                        defaultValue: email,
                        placeholder: __('Your email'),
                        autoComplete: 'email',
                    } }
                    validateOn={ ['onChange'] }
                    validationRule={ {
                        isRequired: true,
                        inputType: ValidationInputType.EMAIL,
                    } }
                    addRequiredTag
                />
                <div block="MyAccountOverlay" elem="PasswordBlock">
                    <Field
                        type={ FieldType.PASSWORD }
                        label={ __('Password') }
                        attr={ {
                            id: 'password',
                            name: 'password',
                            placeholder: __('Enter your password'),
                            autoComplete: 'new-password',
                        } }
                        validateOn={ ['onChange'] }
                        validationRule={ {
                            isRequired: true,
                            inputType: ValidationInputType.PASSWORD,
                            match: (value: string) => {
                                const email = document.getElementById('email') as HTMLInputElement;

                                if (value && email.value === value) {
                                    return __('Passwords can\'t be the same as email!');
                                }

                                return validatePassword(value, range, minimunPasswordCharacter);
                            },
                        } }
                        addRequiredTag
                    />
                    <Field
                        type={ FieldType.PASSWORD }
                        label={ __('Confirm password') }
                        attr={ {
                            id: 'confirm_password',
                            name: 'confirm_password',
                            placeholder: __('Retype your password'),
                            autoComplete: 'new-password',
                        } }
                        validateOn={ ['onChange'] }
                        validationRule={ {
                            isRequired: true,
                            inputType: ValidationInputType.PASSWORD,
                            match: (value: string) => {
                                const password = document.getElementById('password') as HTMLInputElement;

                                return value && password.value === value;
                            },
                            customErrorMessages: {
                                onMatchFail: __('Passwords do not match!'),
                            },
                        } }
                        addRequiredTag
                    />
                </div>

                {
                    (gstIsEnabled) ?
                        (gstIsVisible) ?
                            (gstIsRequired) ?
                                <Field
                                    type={FieldType.TEXT}
                                    label={__('Buyer GST')}
                                    attr={{
                                        id: 'buyergst',
                                        name: 'buyergst',
                                        // defaultValue: lastName,
                                        placeholder: __('Your GST number'),
                                        // autocomplete: 'family-name'
                                    }}
                                    validationRule={ {
                                        isRequired: true,
                                        inputType: ValidationInputType.ALPHANUMERIC,
                                    } }
                                    addRequiredTag
                                />
                                :
                                <Field
                                    type={FieldType.TEXT}
                                    label={__('Buyer GST')}
                                    attr={{
                                        id: 'buyergst',
                                        name: 'buyergst',
                                        // defaultValue: lastName,
                                        placeholder: __('Your GST number'),
                                        // autocomplete: 'family-name'
                                    }}
                                    validationRule={ {
                                        inputType: ValidationInputType.ALPHANUMERIC,
                                    } }
                                />
                            : '' : ''
                }
                { newsletterActive ? this.renderSubscribeToNewsletter() : null }
            </fieldset>
        );
    }

    renderSubmitButton(): ReactElement {
        const {isTermsAndConditionsAccepted, isPrivacyPolicyAccepted} = this.state;
        if (!isTermsAndConditionsAccepted || !isPrivacyPolicyAccepted) {
            return (
                <div style={{fontSize: 12, paddingTop: 20}}>
                    <span>You must accept the required conditions to continue.</span></div>
            );
        }

        return (
            <div block="MyAccountOverlay" elem="Buttons">
                <button
                    block="Button"
                    type="submit"
                    mix={{block: 'MyAccountOverlay', elem: 'SignUpButton'}}
                >
                    {__('Create Account')}
                </button>
            </div>
        );
    }

    renderAdditionalField(): ReactElement {
        const { state, handleSignIn } = this.props;

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                <section className="dont-have-an-account">
                    <span>{ __('Already have an account?') }</span>
                    <button
                        block="Button"
                        mods={ { likeLink: true } }
                        mix={ { block: 'MyAccountOverlay', elem: 'SignInLink' } }
                        onClick={ handleSignIn }
                    >
                        { __('Sign In') }
                    </button>
                </section>
            </article>
        );
    }

    renderSubscribeToNewsletter(): ReactElement {
        return (
            <Field
                type={ FieldType.CHECKBOX }
                label={ __('Subscribe to newsletter') }
                attr={ {
                    id: 'is_subscribed',
                    name: 'is_subscribed',
                    placeholder: __('Your Tax/VAT Number'),
                } }
                mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
            />
        );
    }

    renderCreateAccountForm(): ReactElement {
        const { onError, onSuccess } = this.props;
// console.clear();
// console.log(this.props);
        return (
            <Form
                key="create-account"
                onSubmit={ onSuccess }
                onError={ onError }
            >
                { this.renderCreateAccountPersonalInfoFields() }
                { this.renderCreateAccountSignUpInfoFields() }
                {this.renderPrivacyPolicy()}
                {this.renderTermsAndConditions()}
                {this.renderSubmitButton()}
                {this.renderAccountPrivacyPolicyPopup()}
                {this.renderAccountTermsAndConditionsPopup()}
            </Form>
        );
    }
};

export default MyAccountCreateAccountComponent;
