/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const GET_TICKET_LIST = 'GET_TICKET_LIST';
export const SET_TICKET_LOADING_STATUS = 'SET_TICKET_LOADING_STATUS';

// /** @namespace Store/Ticket/Action/getTicketList */
export const getTicketList = (ticketList, status) => ({
    type: GET_TICKET_LIST,
    ticketList,
    status
});

// /** @namespace Store/Ticket/Action/setLoadingStatus */
export const setLoadingStatus = (status) => ({
    type: SET_TICKET_LOADING_STATUS,
    status
});
