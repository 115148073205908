import CommonSubscription from "./../component/CommonSubscription/CommonSubscription.component";

export const COMMON_SUBSCRIPTION = 'my-subscriptions';

const renderMap = (member) => {
    member[COMMON_SUBSCRIPTION] = CommonSubscription
    return member;
};

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: renderMap
        }
    }
};
