/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from 'Util/Query';

export class TrackOrder {
    getOrder(telephone, email, orderNumber) {
        return new Field('magenearTrackOrder').addFieldList([
            'magenear_trackorder'
        ])
            .addArgument('telephone', 'String', telephone)
            .addArgument('email', 'String', email)
            .addArgument('orderNumber', 'String', orderNumber)
    }
    getTracking(orderId) {
        return new Field('magenearTrackOrder').addFieldList([
            'magenear_trackorder'
        ])
            .addArgument('orderId', 'String', orderId)
    }
}

export default new TrackOrder();
