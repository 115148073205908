/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {PureComponent} from 'react';
import Loader from 'Component/Loader';
import {OrderType} from 'Type/Order.type';

import parser from 'html-react-parser';
import './MyAccountTicket.style';
import './../../style/file-type-icon.css';
import Bad from './../../style/smile/1.svg';
import Ok from './../../style/smile/2.svg';
import Great from './../../style/smile/3.svg';
import Popup from "Component/Popup";
import Field from "Component/Field";
import { FieldType } from 'Component/Field/Field.config';
import {DropzoneArea} from "material-ui-dropzone";
import UploadIcon from 'Component/UploadIcon';
import ExclamationMarkIcon from "Component/ExclamationMarkIcon";

const rateMap = {Bad, Ok, Great};
const rateText = {
    Bad: 'What went wrong? We are open to your feedback',
    Ok: 'What can we improve upon?',
    Great: 'How\'s your experience with our support?'
};

const handlePreviewIcon = (fileObject, classes) => {

    const {data, file: {name, type}, file} = fileObject;

    let iconType = name.split('.');
    if (iconType.length === 1) {
        iconType = 'blank';
    } else {
        iconType = name.split('.').pop();
    }

    let attachmentClass = 'fiv-cla fiv-icon-' + iconType;

    return <span className={attachmentClass}></span>;

}

/** @namespace Component/MyAccountTicket/Component */
export class MyAccountTicket extends PureComponent {
    static propTypes = {
        order: OrderType.isRequired,
        isLoading: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isLoading: true
    };

    state = {
        reply: false,
        files: [],
        preparedFiles: []
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidUpdate() {
        this.handleResize();
    }

    handleResize() {
        let xxx = document.getElementsByClassName("attachment");
        let bottom;
        for (let i = 0; i < xxx.length; i++) {
            if (xxx[i].firstChild.nodeName === 'IMG') {
                bottom = xxx[i].clientHeight + 10;
                xxx[i].firstChild.style.bottom = bottom + 'px';
            }
        }
    }


    renderContent() {

        const {order} = this.props;

        if (!order || typeof order.subject === 'undefined') {
            return null;
        }
        // console.clear();
        console.log(this.props);
        let xxx = document.getElementsByClassName("MyAccount-Heading");
        for (let i = 0; i < xxx.length; i++) {
            xxx[i].innerText = order.subject;    // Change the content
        }
        let statusColor = 'hdmx__status hdmx-' + order.status_color;
        let messageClass;
        let headerClass;
        let bodyClass;
        const history = Object.entries(order.history).map(([key, value]) => {

            messageClass = 'hdmx__message ' + value.body_triggered_by;
            headerClass = 'hdmx-message__header ' + value.body_triggered_by;
            bodyClass = 'hdmx-message__body ' + value.body_triggered_by;

            return (
                <>
                    <div className={messageClass}>
                        {(order.survey_results) ? this.renderRate(value.rate) : ''}
                        <div className={headerClass}><strong>{parser(value.header)}</strong></div>
                        {/*{Math.random()}*/}
                        <div className={bodyClass}>
                            {parser(value.body)}
                            {!value.attachments ? '' :
                                <>
                                    <div className='hdmx-message__attachments'>
                                        {
                                            value.attachments.map((item, i) => {
                                                let attachment = 'data:' + item.type + ';base64,' + item.url;
                                                let attachmentClass = 'fiv-cla fiv-icon-' + item.icon;
                                                return (
                                                    <>
                                                        <a download={item.name} href={attachment}
                                                           className='attachment'>
                                                            {!item.type.includes('image') ? '' :
                                                                <img className='attachment-image' src={attachment}
                                                                     alt={item.name}/>
                                                            }
                                                            <span className={attachmentClass}></span>
                                                            <span className='attachment-name'>{item.name}</span>

                                                        </a>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            }

                        </div>

                    </div>
                </>
            );
        })

        try {
            document.querySelectorAll('.hdmx__ticket-message-rate-satisfaction .comment').forEach( n => (n.offsetWidth > 225) ? n.parentElement.classList.add('hidden') : false );
        }catch (e) {
            console.log(e);
        }


        // console.log(history);
        return (
            <div block='ticket' elem='wrapper'>
                <div className='ticket-info'>
                    <span className='Ticket-Subject'>{order.subject}</span>
                    <span className='Ticket-Heading'>Ticket ID</span>
                    <span>{order.code}</span>
                    <span className='Ticket-Heading'>Department</span>
                    <span>{order.department}</span>
                    {
                        order.assignee ?
                            <>
                                <span className='Ticket-Heading'>Assigned Agent</span>
                                <span className='verified-icon'>{order.assignee}</span>
                            </>
                            : ''
                    }
                    <span className='Ticket-Heading'>Priority</span>
                    <span>{order.priority}</span>
                    <span className='Ticket-Heading'>Status</span>
                    <div className='data-wrapper'>
                        <span className={statusColor}>{order.status}</span>
                        <span className={statusColor}>{order.status}</span>
                    </div>
                    {!order.order ? '' :
                        <>
                            <span className='Ticket-Heading'>Order</span>
                            <span>{order.order}</span>
                        </>
                    }
                    {!order.custom_fields ? '' :
                        order.custom_fields.map((item, i) => {
                            if (!(item.value)) {
                                return null;
                            }
                            return (
                                <>
                                    <span className='Ticket-Heading'>{item.name}</span>
                                    <span>{item.value}</span>
                                </>
                            )
                        })
                    }

                    {order.is_locked ?
                        <div className="hdmx__ticket-locked">
                            <p>
                                <ExclamationMarkIcon/>You can’t reply because this ticket is locked by the agent. Contact support team or create a new ticket.
                            </p>
                        </div>
                        :
                        ''
                    }

                </div>

                {order.is_locked ?
                    ''
                    :
                    <div className='hdmx__ticket-reply'>
                        <div className='helpdesk-legend'>Post a Reply</div>
                        {this.renderPostReply()}
                    </div>
                }
                <div className='hdmx__ticket-history'>
                    <div className='helpdesk-legend'>History</div>
                    {history}
                </div>
            </div>
        );
    }

    renderPostReply() {
        const {closeTicket, reopenTicket, submitMessage, order: {is_closed}} = this.props;
        const {reply} = this.state;
        return (
            <>
                <Field
                    addRequiredTag={true}
                    label= 'Message'
                    type={FieldType.TEXTAREA}
                    attr={{
                        id: `helpdesk-reply`,
                        name: `option-reply`,
                        placeholder: __('Your reply here'),
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            let closeTicketButton = document.getElementById('close-ticket');
                            let submitMessageButton = document.getElementById('submit-message');
                            if (!!(value)) {
                                if (!is_closed) {
                                    closeTicketButton.classList.remove('disabled')
                                }
                                submitMessageButton.classList.remove('disabled');
                            } else {
                                if (!is_closed) {
                                    closeTicketButton.classList.add('disabled');
                                }
                                submitMessageButton.classList.add('disabled');
                            }
                            this.setState({reply: !!(value)});
                            // console.log(this.state);
                            return !!(value);

                        },
                        customErrorMessages: {
                            onMatchFail: 'This field is required!'
                        }
                    }}
                />
                {this.renderAttachments()}
                <div className='helpdesk-reply-actions '>
                    {is_closed ? '' :
                        <button
                            // disabled={reply ? '' : 'disabled'}
                            id="close-ticket"
                            block="Button"
                            className={reply ? '' : 'disabled'}
                            onClick={submitMessage}
                        >
                            {__('Close Ticket')}
                        </button>
                    }
                    <button
                        // disabled
                        id="submit-message"
                        block="Button disabled"
                        onClick={submitMessage}
                    >
                        {(is_closed) ? 'Reopen Ticket' : 'Submit Message'}
                    </button>
                </div>
            </>

        )
    }

    renderRate(rate) {

        if (!rate) {
            return null;
        }

        if (rate.submitted) {
            return (
                <>
                    <div className="hdmx__ticket-message-rate-satisfaction">
                        {!rate.comment ? '' :
                            <div className="comment">
                                {rate.comment}
                            </div>
                        }
                        <div className="rate">
                            <img src={rateMap[rate.rate]} alt={rate.rate}/>
                        </div>
                    </div>
                </>
            )
        }

        const {onRateClick} = this.props;

        return (
            <>
                <div className="hdmx__ticket-message-rate">
                    {
                        rate.rate.map((item, i) => {
                            return (
                                <>
                                    <div onClick={onRateClick} className='rate-wrapper' rate={item} uid={rate.uid}>
                                        <img src={rateMap[item]} alt={item}/>
                                        <span>{item}</span>
                                    </div>
                                </>
                            )
                        })
                    }

                </div>
            </>
        )
    }

    renderRatePopup() {

        const {rate, processRate} = this.props;

        return (
            <Popup
                id="RatePopup"
                mix={{block: 'RatePopup'}}
            >
                <div mix={{block: 'RatePopupContentWrapper'}}>
                    <img src={rateMap[rate]} alt={rate}/>
                    <span>{rateText[rate]}</span>
                </div>
                <Field
                    type={FieldType.TEXTAREA}
                    attr={{
                        id: `rate-comment`,
                        name: `option-${rate}`,
                        // maxlength: 60,
                        rows: 4,
                        placeholder: "Write here (max 60 words)"
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            document.getElementById('rate-comment').removeAttribute('maxlength');
                            let ProcessCommentButton = document.getElementById('ProcessComment');
                            if (!!(value)) {
                                ProcessCommentButton.classList.remove('disabled');
                            } else {
                                ProcessCommentButton.classList.add('disabled');
                            }

                            let words = value.length ? value.match(/\S+/g).length : 0;

                            if (words > 60) {
                                document.getElementById('rate-comment').setAttribute('maxlength', value.length);
                                ProcessCommentButton.classList.add('disabled');
                                return __('You are reached words limit!');
                            }
                            return !!(value);
                        },
                        customErrorMessages: {
                            onMatchFail: 'Your feedback is important for us!'
                        }
                    }}
                />
                <div className='process-comment-wrapper'>
                    <button
                        // disabled
                        id="ProcessComment"
                        block="Button disabled"
                        onClick={processRate}
                    >
                        {__('Submit')}
                    </button>
                </div>
            </Popup>
        )
    }


    renderAttachments() {
        const {order: {attachment_is_allowed, attachment_size}} = this.props;

        if (!attachment_is_allowed) {
            return null;
        }

        return (
            <>
                <div className="max-attachment-size">
                    {/*<label className="Field-Label">Max file size: {attachment_size} Mb</label>*/}
                    <label className="Field-Label">Attach Files</label>
                </div>
                <DropzoneArea
                    onChange={this.handleChange.bind(this)}
                    getPreviewIcon={handlePreviewIcon}
                    Icon={UploadIcon}
                    filesLimit={100}
                    showFileNames={true}
                    showAlerts={false}
                    // maxFileSize={attachment_size * 100000}
                    dropzoneText={"Upload file (max file " + attachment_size + " Mb)"}
                />
            </>
            )


    }

    handleChange(files) {
        let preparedFiles = [];
        const {callBack} = this.props
        files.map((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                let dropZoneFile = {
                    name: file.name,
                    size: file.size,
                    type: !!(file.type) ? file.type : 'unknown',
                    data: reader.result,
                }
                preparedFiles.push(dropZoneFile);
            };

        })
        this.setState({
            files: files
        });
        this.setState({files: files, preparedFiles: preparedFiles}, () => callBack(this.state))
    }

    render() {
        const {isLoading} = this.props;

        return (
            <>
                <Loader mix={{block: 'MyAccountTicket', elem: 'Loader '}} isLoading={isLoading}/>
                {this.renderContent()}
                {this.renderRatePopup()}
            </>
        );
    }


}

export default MyAccountTicket;
