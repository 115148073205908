const render = (args, callback, instance) => {

    const { tabEntry } = instance.props;

    if (tabEntry[0] === 'stock-alert' || tabEntry[0] === 'price-alert' || tabEntry[0] === 'newsletter-subscription') {
        return null;
    }

    return callback(...args);
};


export default {
    'Component/MyAccountTabListItem/Component': {
        'member-function': {
            render: render
        }
    }
};
