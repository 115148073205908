/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const _getOrderItemsFields = (args, callback) => {
    return [
        ...callback(...args),
        'order_comment'
    ]
};


export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemsFields
        }
    }
};
