import {
    MyAccountAddressTableComponent as SourceMyAccountAddressTableComponent,
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import './MyAccountAddressTable.style';
import {ReactElement} from "Type/Common.type";
import Loader from "Component/Loader";
import TrashIcon from "Component/TrashIcon";
import RadioButton from 'Component/RadioButtonIcon';

export class MyAccountAddressTableComponent extends SourceMyAccountAddressTableComponent {

    renderActions(): ReactElement {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            address,
        } = this.props;

        if (!('default_shipping' in address)) {
            return null;
        }

        const { default_billing, default_shipping } = address;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <>
                <span
                    block="Action"
                    onClick={ onEditClick }
                    mods={ { isHollow: true } }
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.2594 3.60022L5.04936 12.2902C4.73936 12.6202 4.43936 13.2702 4.37936 13.7202L4.00936 16.9602C3.87936 18.1302 4.71936 18.9302 5.87936 18.7302L9.09936 18.1802C9.54936 18.1002 10.1794 17.7702 10.4894 17.4302L18.6994 8.74022C20.1194 7.24022 20.7594 5.53022 18.5494 3.44022C16.3494 1.37022 14.6794 2.10022 13.2594 3.60022Z" stroke="#A10101" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.8906 5.0498C12.3206 7.8098 14.5606 9.9198 17.3406 10.1998" stroke="#A10101" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 22H21" stroke="#A10101" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    { __('Edit') }
                </span>
                <button
                    block="Delete"
                    mods={ { isHollow: true, isWithoutBorder: true, disabled:isDeleteAllowed } }
                    onClick={ onDeleteClick }
                    disabled={ isDeleteAllowed }
                    title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address' }
                >
                    <TrashIcon />
                    { __('Delete') }
                </button>
            </>
        );
    }

    capitalize(string: string) {
        const arr = string.split(" ");
        // console.log(arr);
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join(" ");
    }

    renderHeading() {
        //@ts-ignore
        let { title, isSelected, isMobile } = this.props;
// console.log(this.props);
        if (!title) {
            return null;
        }

        let addressType = title.split('(');
        let subHeading = false;
        if (addressType.length === 2) {
            title = addressType[0];
            //@ts-ignore
            subHeading = '(' + this.capitalize(addressType[1]);
        }else {
            // console.log(title);
            // console.log(addressType);
            // title = addressType[0] + '(' + this.capitalize(addressType[1]);
        }

        if (subHeading && !isMobile) {
            title = title + subHeading;
            subHeading = false;
        }
// console.log(title);
// console.log(subHeading);
        return (
            <>
                <tr>
                    <th
                        block="KeyValueTable"
                        elem="Heading"
                        colSpan={ 2 }
                    >
                        { title }
                        <RadioButton isActive={ isSelected } />
                    </th>
                </tr>
                {
                    (subHeading) ? <tr>
                        <th
                            block="KeyValueTable"
                            elem="SubHeading"
                            colSpan={ 2 }
                        >
                            { subHeading }
                        </th>
                    </tr> : ''
                }
            </>
        );
    }

    render(): ReactElement {
        const { countries, mix } = this.props;

        return (
            <div block="MyAccountAddressTable" mix={ mix }>
                <Loader isLoading={ !countries.length } />
                <div block="MyAccountAddressTable" elem="Actions">
                    { this.renderActions() }
                </div>
                { this.renderTable() }
            </div>
        );

    }
};

export default MyAccountAddressTableComponent;
