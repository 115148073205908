import Link from "Component/Link";
import ChevronIcon from "../../../../src/component/ChevronIcon/ChevronIcon.component";
import {LEFT, RIGHT} from "../../../../src/component/ChevronIcon/ChevronIcon.config";

const render = (args, callback, instance) => {

    const {mix: {block}} = instance.props;

    if (block !== 'AdvancedReview') {
        return callback(...args);
    }


    const {
        isLoading,
        totalPages,
        id,
        mix
    } = instance.props;

    if (totalPages === 1) { // do not show pagination, if there are less then one page
        return <ul block="Pagination"/>;
    }

    if (isLoading) {
        return instance.renderPlaceholder();
    }

    function renderFirstPageLink() {
        const {
            isLoading,
            pathname,
            currentPage,
            paginationFrame,
            totalPages
        } = instance.props;

        if (isLoading) {
            return instance.renderPlaceholder();
        }

        if (currentPage === 1) {
            return <li className="Pagination-ListItem"></li>;
        }

        if (totalPages < paginationFrame) {
            return <li className="Pagination-ListItem"></li>;
        }

        return (
            <li className="Pagination-ListItem">
                <Link to={pathname} className="PaginationLink isFirst PaginationLink_isArrow">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0742 17L6.07422 12L11.0742 7" stroke="black" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.0742 17L13.0742 12L18.0742 7" stroke="black" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </Link>

            </li>
        )
    }

    function renderLastPageLink() {
        const {
            isLoading,
            pathname,
            currentPage,
            paginationFrame,
            totalPages,
        } = instance.props;

        if (isLoading) {
            return instance.renderPlaceholder();
        }

        if (currentPage === totalPages) {
            return <li className="Pagination-ListItem"></li>;
        }

        if (totalPages < paginationFrame) {
            return <li className="Pagination-ListItem"></li>;
        }

        return (
            <li className="Pagination-ListItem">
                <Link to={`${pathname}?page=${ totalPages }`} className="PaginationLink isLast PaginationLink_isArrow">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.0742 17L18.0742 12L13.0742 7" stroke="black" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M6.07422 17L11.0742 12L6.07422 7" stroke="black" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </Link>

            </li>
        )
    }

    return (
        <nav aria-label={__('List navigation')}>
            <ul block="Pagination" id={id} mix={mix}>
                {renderFirstPageLink()}
                {/*{instance.renderPreviousPageLink()}*/}
                { instance.renderPreviousJump() }
                {/*<div className='PagesWrapper'>*/}
                    {instance.renderPageLinks()}
                {/*</div>*/}
                { instance.renderNextJump() }
                {/*{instance.renderNextPageLink()}*/}
                {renderLastPageLink()}
            </ul>
        </nav>
    );

};

const renderPageIcon = (args, callback, instance) => {

    const {
        isLoading,
    } = instance.props;

    if (isLoading) {
        return instance.renderPlaceholder();
    }

    let isNext = args[0];

    if (isNext) {
        return (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.07422 18L15.0742 12L9.07422 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        )
    }

    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0742 18L9.07422 12L15.0742 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )

}

export default {
    'Component/Pagination/Component': {
        'member-function': {
            render,
            renderPageIcon
        }
    }
};
