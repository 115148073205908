import { TicketReducer } from '../store/Ticket/Ticket.reducer';
import {changeTabName, getTabContent} from "./RouteMyAccount.plugin";

export const getStaticReducers = (args, callback) => {
    let reducers = callback(...args);
    return {
        ...reducers,
        TicketReducer
    }

};
export default {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};
