/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from 'Util/Query';

export class SearchQuery {
    doSearch(searchedText) {
        return new Field('searchResult')
            .addFieldList([
                'search_result'
            ])
            .addArgument('searchedText', 'String', searchedText)
    }

    logSearch(searchedText, source) {
        return new Field('searchResult')
            .addFieldList([
                'search_result'
            ])
            .addArgument('searchedText', 'String', searchedText)
            .addArgument('logQuery', 'String', source)
    }

    mirasSearch(
        query
    ) {
        console.log(query);

        return new Field('search')
            .addField(this._getCmsPages())
            .addField(this._getProducts())
            .addField(this._getCategories())
            .addArgument('query', 'String', query)
    }

    _getProducts() {
        const sort = {
            price: 'ASC',
            // sortDirection: 'DESC',
        };
        const filter = {
            category_id: {
                in: [0]
            },
        };

        return new Field('magento_catalog_product').addArgument('pageSize', 'Int', 100)
            .addArgument('sort', 'ProductAttributeSortInput', sort)
            .addArgument('filter', 'ProductAttributeFilterInput', filter)
            .addFieldList(this._getProductsList());
    }

    _getProductsList() {
        return [
            'identifier',
            'title',
            'position',
            this._getProductsItemsList(),
            'total_count',
            this._getPageInfo(),
            this._getAggregations(),
        ];
    }

    _getProductsItemsList() {
        return new Field('items')
            .addFieldList(
                [
                    'id',
                    'uid',
                    'name',
                    'sku',
                    this._getProductDescription(),
                ]
            );
    }

    _getProductDescription() {
        return new Field('description')
            .addFieldList(
                [
                    'html',
                ]
            );
    }

    _getAggregations() {
        return new Field('aggregations')
            .addFieldList(
                [
                    'count',
                    'label',
                    'attribute_code',
                    // 'options',
                    'position',
                ]
            );
    }

    _getCategories() {
        return new Field('magento_catalog_category').addArgument('pageSize', 'Int', 100)
            .addFieldList(this._getCategoriesList());
    }

    _getCategoriesList() {
        return [
            'identifier',
            'title',
            'position',
            this._getCategoriesItemsList(),
            'total_count',
            this._getPageInfo(),
        ];
    }

    _getCategoriesItemsList() {
        return new Field('items')
            .addFieldList(
                [
                    'id',
                    'uid',
                    'description',
                    'name',
                ]
            );
    }

    _getCmsPages() {
        return new Field('magento_cms_page').addArgument('pageSize', 'Int', 1)
            .addFieldList(this._getCmsPagesList());
    }

    _getCmsPagesList() {
        return [
            'identifier',
            'title',
            'position',
            this._getCmsPagesItemsList(),
            'total_count',
            this._getPageInfo(),
        ];
    }

    _getCmsPagesItemsList() {
        return new Field('items')
            .addFieldList(
                [
                    'identifier',
                    'url_key',
                    'title',
                    'content',
                    'content_heading',
                    'page_layout',
                    'meta_title',
                    'meta_description',
                    'meta_keywords',
                ]
            );
    }

    _getPageInfo() {
        return new Field('page_info')
            .addFieldList(
                [
                    'page_size',
                    'current_page',
                    'total_pages',
                ]
            );
    }



}

export default new SearchQuery();
