import {PureComponent} from 'react';
import Loader from "Component/Loader/Loader.component";

import './FeedBackTab.style';
import Popup from "Component/Popup";
import Field from "Component/Field";
import { FieldType } from 'Component/Field/Field.config';
import { ValidationInputType } from 'Util/Validator/Config';
import { fetchMutation } from 'Util/Request/Mutation';
import TicketQuery from "../../query/Ticket.query";
import {DropzoneArea} from "material-ui-dropzone";
import UploadIcon from 'Component/UploadIcon';
import TimeCountDown from "../CountDown/TimeCountDown.component";
import Html from "Component/Html";
import Link from 'Component/Link';
import SearchIcon from "../../../../../src/component/SearchIcon/SearchIcon.component";
import CloseIcon from "../../../../../src/component/CloseIcon/CloseIcon.component";
import Decoration from "Component/Decoration/Decoration.component";
import { Wrapper as GoogleMapsWrapper, Status as GoogleMapsStatus } from "@googlemaps/react-wrapper";
import GoogleMapReact from 'google-map-react';
import MyAccountTicketTableRow from "../MyAccountTicketTableRow/MyAccountTicketTableRow.container";
import CmsBlock from 'Component/CmsBlock';
import { appendWithStoreCode } from 'Util/Url';
import MagePlazaGoogleMaps from "../../../../../src/component/MagePlazaGoogleMaps/MagePlazaGoogleMaps.component";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const handlePreviewIcon = (fileObject, classes) => {

    const {data, file: {name, type}, file} = fileObject;

    let iconType = name.split('.');
    if (iconType.length === 1) {
        iconType = 'blank';
    } else {
        iconType = name.split('.').pop();
    }

    let attachmentClass = 'fiv-cla fiv-icon-' + iconType;

    return <span className={attachmentClass}></span>;

}

const defaultProps = {
    center: {
        lat: 26.8938045,
        lng: 75.7472084
    },
    zoom: 20
};

let preparedTicketData = {};
let preparedTicketDataCopy = {};

export class FeedBackTab extends PureComponent {

    state = {
        files: [],
        preparedFiles: []
    };

    closeCustomTicketDataPopup = this._closeCustomTicketDataPopup.bind(this);
    closeKbSearchResults = this._closeKbSearchResults.bind(this);

    _closeCustomTicketDataPopup() {
        console.clear();
        console.log(this.props);
        const { showCustomRequest } = this.props;
        showCustomRequest();
        this.closeNewTicketPopup();
    }

    _closeKbSearchResults() {
        console.clear();
        console.log(this.props);
        document.getElementById("what-is-your-question").value = '';
        document.getElementById("FeedBackTabActiveKbResult").innerHTML = '';
        document.getElementById('CloseKbSearchResults').classList.remove('active');
    }

    closeNewTicketPopup() {
        let {onlyAddNewTicket} = this.props;

        preparedTicketData = {};
        preparedTicketDataCopy = {};

        if (onlyAddNewTicket) {
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
        }

    }

    goToTickets = this._goToTickets.bind(this);

    _goToTickets() {
        const {contactUs, getTicketList, showContactUs, showCustomRequest, showNotification, onlyAddNewTicket} = this.props;

        if (onlyAddNewTicket) {
            showCustomRequest();
        }else {
            showContactUs();
        }
    }

    componentDidMount() {
        this.closeNewTicketPopup();
    }

    renderHeading() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        const {items:{form_title, help_text}} = this.props;
        // console.log(form_title);
        // console.log(help_text);
        const node = document.createElement("h1");
        // node.setAttribute("class", 'CmsPage-Heading');
        node.setAttribute("class", 'HelpdeskContact-Heading');
        // const textnode = document.createTextNode("How Can We Help You?");
        const textnode = document.createTextNode(form_title);
        const node2 = document.createElement("p");
        node2.setAttribute("class", "cms-content-important");
        // const textnode2 = document.createTextNode("Welcome to our Support Centre. Please check our FAQs page to find quick answers. Want to contact us directly? Check out the form at the bottom of the page.");
        const textnode2 = document.createTextNode(help_text);
        node.appendChild(textnode);
        node2.appendChild(textnode2);
        const contactUsHeading = document.getElementById('header-before-breadcrumbs');
        contactUsHeading.innerHTML = '';
        contactUsHeading.appendChild(node);
        contactUsHeading.appendChild(node2);
    }
    renderContent() {
        const {items, contactUs, onlyAddNewTicket} = this.props;

        let messageTitle = items.description_title;
        let messagePlaceholder = items.description_placeholder;

        let subjectTitle = items.subject_title;

        let ticketSubjectAttr = {
            id: `new-ticket-subject`,
            name: `subject`,
            placeholder: items.subject_placeholder,
            isRequired: true,
        }

        let submitTitle = __('Submit Ticket');
        let showCancel = false;

        if (onlyAddNewTicket) {
            subjectTitle = 'Subject';
            submitTitle = onlyAddNewTicket.submitTitle;
            showCancel = onlyAddNewTicket.showCancel;
            messageTitle = onlyAddNewTicket.messageTitle;
            messagePlaceholder = onlyAddNewTicket.messagePlaceholder;
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
            ticketSubjectAttr.value = onlyAddNewTicket.ticketSubject;
            ticketSubjectAttr.disabled = !!(onlyAddNewTicket && typeof onlyAddNewTicket.ticketSubject !== 'undefined');
        }

        // console.log(this.props);
        return (
            <>
                {/*{(contactUs && !onlyAddNewTicket) ?*/}
                {/*    <h3 block="Popup" elem="Heading">*/}
                {/*        {items.form_title}*/}
                {/*    </h3>*/}
                {/*    : ''*/}
                {/*}*/}


                {(items.help_text && !onlyAddNewTicket && !contactUs) ?
                    <div>
                        <p className="cms-content-important">{items.help_text}</p>
                    </div>
                    : ''}

                {(items.help_text && !onlyAddNewTicket && contactUs) ?
                    this.renderHeading()
                    : ''}


                {
                    (contactUs) ?
                        <>
                            <div id="FeedBackTabActiveKb">
                                <SearchIcon/>
                                <Field
                                    // addRequiredTag={true}
                                    // label="What is your question?"
                                    type={FieldType.INPUT}
                                    attr={{
                                        id: `what-is-your-question`,
                                        name: `whatisyourquestion`,
                                        placeholder: 'Search your questions',
                                        isRequired: true
                                    }}
                                    events={{
                                        onChange: this.handleFieldChange.bind(this)
                                    }}
                                    validateOn={['onChange']}
                                    validationRule={{
                                        match: (value) => {
                                            console.clear();
                                            console.log(value);
                                            if (!(value)) {
                                                // delete preparedTicketData['subject'];
                                                // document.getElementById('submit-new-ticket').classList.add('disabled');
                                                document.getElementById('CloseKbSearchResults').classList.remove('active');
                                            }else {
                                                document.getElementById('CloseKbSearchResults').classList.add('active');
                                            }
                                            return true;
                                            // return !!(value);

                                        },
                                        customErrorMessages: {
                                            onMatchFail: 'This field is required!'
                                        }
                                    }}
                                />
                                <div id="CloseKbSearchResults" onClick={this.closeKbSearchResults}><CloseIcon/></div>
                                <div id="FeedBackTabActiveKbResult"></div>
                            </div>
                        </>
                        : ''
                }



                {(contactUs && items.schedule_in_contact_us && !onlyAddNewTicket) ? this.renderSchedule(true) : ''}
                {/*{(!contactUs && items.schedule_in_popup && !onlyAddNewTicket) ? this.renderSchedule() : ''}*/}


                { (contactUs) ? this.renderContactUsTwoBoxes() : ''}
                { (contactUs) ? this.renderOurOffice() : ''}


                {(contactUs && !onlyAddNewTicket) ? this.renderOurEmails() : ''}
                {(contactUs && !onlyAddNewTicket) ? this.renderWorkingHours() : ''}


                {/*{this.renderContactForm()}*/}
                { (contactUs) ? '' : this.renderContactForm()}

                { (contactUs) ? this.renderContactUsFaq() : ''}

            </>

        )
    }

    renderContactForm() {
        const {items, contactUs, onlyAddNewTicket} = this.props;
        let messageTitle = items.description_title;
        let messagePlaceholder = items.description_placeholder;

        let subjectTitle = items.subject_title;

        let ticketSubjectAttr = {
            id: `new-ticket-subject`,
            name: `subject`,
            placeholder: items.subject_placeholder,
            isRequired: true,
        }

        let submitTitle = __('Submit Ticket');
        let showCancel = false;

        if (onlyAddNewTicket) {
            subjectTitle = 'Subject';
            submitTitle = onlyAddNewTicket.submitTitle;
            showCancel = onlyAddNewTicket.showCancel;
            messageTitle = onlyAddNewTicket.messageTitle;
            messagePlaceholder = onlyAddNewTicket.messagePlaceholder;
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
            ticketSubjectAttr.value = onlyAddNewTicket.ticketSubject;
            ticketSubjectAttr.disabled = !!(onlyAddNewTicket && typeof onlyAddNewTicket.ticketSubject !== 'undefined');
        }
        return (
            <div id="ContactFormWrapper" block="ContactForm" elem="Wrapper">
                {/*<Decoration direction="left" angle="centerRight" type="type1"/>*/}

                <div block="ContactForm" elem="Heading">Contact Form</div>
                {/*{ (contactUs) ? this.contactPageHeading('Contact Form') : ''}*/}



                <Field
                    addRequiredTag={true}
                    label={subjectTitle}
                    type={FieldType.TEXT}
                    attr={ticketSubjectAttr}
                    events={{
                        onChange: this.handleFieldChange.bind(this)
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            if (!(value)) {
                                delete preparedTicketData['subject'];
                                document.getElementById('submit-new-ticket').classList.add('disabled');
                            }
                            return !!(value);

                        },
                        customErrorMessages: {
                            onMatchFail: 'This field is required!'
                        }
                    }}
                />
                <Field
                    addRequiredTag={true}
                    label={messageTitle}
                    type={FieldType.TEXTAREA}
                    attr={{
                        id: `helpdesk-new-message`,
                        name: `message`,
                        placeholder: messagePlaceholder,
                        isRequired: true
                    }}
                    events={{
                        onChange: this.handleFieldChange.bind(this)
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            if (!(value)) {
                                delete preparedTicketData['message'];
                                document.getElementById('submit-new-ticket').classList.add('disabled');
                            }
                            return !!(value);

                        },
                        customErrorMessages: {
                            onMatchFail: 'This field is required!'
                        }
                    }}
                />

                {!items.customer ?
                    <>
                        <Field
                            addRequiredTag={true}
                            label={__('Name')}
                            type={FieldType.TEXT}
                            attr={{
                                id: `new-ticket-customer-name`,
                                name: `customer_name`,
                                placeholder: __('Your Name'),
                                isRequired: true
                            }}
                            events={{
                                onChange: this.handleFieldChange.bind(this)
                            }}
                            validateOn={['onChange']}
                            validationRule={{
                                match: (value) => {
                                    if (!(value)) {
                                        delete preparedTicketData['customer-name'];
                                        document.getElementById('submit-new-ticket').classList.add('disabled');
                                    }
                                    return !!(value);

                                },
                                customErrorMessages: {
                                    onMatchFail: 'This field is required!'
                                }
                            }}
                        />
                        <Field
                            addRequiredTag={true}
                            label={__('Email')}
                            type={FieldType.TEXT}
                            attr={{
                                id: `new-ticket-customer-email`,
                                name: `customer_email`,
                                placeholder: __('Your Email'),
                                isRequired: true
                            }}
                            events={{
                                onChange: this.handleFieldChange.bind(this)
                            }}
                            validateOn={['onChange']}
                            validationRule={{
                                inputType: ValidationInputType.EMAIL,
                                match: (value) => {
                                    let valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    if (!valid.test(value)) {
                                        delete preparedTicketData['customer-email'];
                                        document.getElementById('submit-new-ticket').classList.add('disabled');
                                        return true;
                                    }
                                    return !!(value);
                                },
                                customErrorMessages: {
                                    onMatchFail: 'This field is required!'
                                }
                            }}
                        />
                    </>
                    : ''}


                {(items.is_custom_fields && !onlyAddNewTicket) ?
                    items.is_custom_fields.map((item, i) => {
                        let fieldType;
                        if (item.type === 'text') {
                            fieldType = FieldType.TEXT;
                        }
                        if (item.type === 'textarea') {
                            fieldType = FieldType.TEXTAREA;
                        }
                        if (item.type === 'select') {
                            fieldType = FieldType.SELECT;
                        }
                        if (item.type === 'checkbox') {
                            fieldType = FieldType.CHECKBOX;
                        }
                        if (item.type === 'date') {
                            fieldType = FieldType.DATE;
                        }
                        let options;
                        if (item.values) {
                            options = item.values.map(({id, name}) => ({id, label: name, value: id}));
                        } else {
                            options = false;
                        }
                        return (
                            <Field
                                addRequiredTag={item.is_required}
                                label={item.name}
                                type={fieldType}
                                options={options}
                                attr={{
                                    id: `helpdesk-new-message-${item.code}`,
                                    name: item.code,
                                    placeholder: __('Your Ticket') + ' ' + item.name,
                                    isRequired: item.is_required,
                                    helpdeskCustomField: true
                                }}
                                events={{
                                    onChange: this.handleFieldChange.bind(this)
                                }}
                                validateOn={['onChange']}
                                validationRule={{
                                    match: (value) => {
                                        if (!item.is_required) {
                                            delete preparedTicketData[item.code];
                                            return true;
                                        }
                                        if (!(value)) {
                                            delete preparedTicketData[item.code];
                                            document.getElementById('submit-new-ticket').classList.add('disabled');
                                        }
                                        return !!(value);
                                    },
                                    customErrorMessages: {
                                        onMatchFail: 'This field is required!'
                                    }
                                }}
                            />
                        )
                    }) : ''
                }
                <div block="OptionsWrapper">
                    {(!onlyAddNewTicket) ? this.renderDropDowns('Priority', 'priority_id', items.priority) : ''}
                    {(!onlyAddNewTicket) ? this.renderDropDowns('Department', 'department_id', items.department) : ''}
                </div>
                <div className='helpdesk-popup-bottom'>
                    {this.renderAttachments()}
                    <div block="SubmitNewTicket" elem="Actions">
                        {
                            (showCancel) ?
                                <button
                                    id="submitnewticket-cancel"
                                    className="SubmitNewTicket-Cancel"
                                    type="button"
                                    block="Button"
                                    mix={ { block: 'CancelButton', elem: 'Cancel' } }
                                    onClick={this.closeCustomTicketDataPopup}
                                >
                                    { __('Cancel') }
                                </button>
                                : ''
                        }
                        <button
                            // disabled
                            id="submit-new-ticket"
                            block="Button disabled"
                            onClick={this.submitNewTicket.bind(this)}
                        >
                            {(contactUs && !onlyAddNewTicket) ? __('Submit') : submitTitle }
                        </button>
                    </div>
                </div>
                <Loader isLoading={true}/>
                {/*{(contactUs) ? '' :*/}
                {/*    <Loader isLoading={true}/>*/}
                {/*}*/}

            </div>
        )
    }

    renderContactUsFaq() {

        const {kb_general_base_url, items:{kb_mostviewed}} = this.props;

        if (typeof kb_mostviewed === 'undefined') {
            return;
        }

        return (
            <div block="ContactPage" elem="Faq">
                {this.contactPageHeading('Looking For Any Of These Questions?')}
                <div block="ContactPage" elem="FaqWrapper">
                    <Decoration direction="left" angle="centerLeft" type="type1"/>
                {
                    kb_mostviewed.map((item, i) => {
                        return (
                            // <span block="KB" elem="MostViewed">{item.name}</span>
                            <Link
                                block="KB"
                                elem="MostViewed"
                                to={ appendWithStoreCode(`${kb_general_base_url}/${item.url_key}`) }
                                // isOpenInNewTab
                            >
                                { item.name }
                            </Link>
                        )
                    })
                }
            </div>
            </div>
        )
    }
    renderContactUsTwoBoxes() {
        const { goToContactUsForm, items: {store_information} } = this.props;
        console.log(store_information);
        if (typeof store_information === 'undefined') {
            return;
        }
        return (
            <div block="contactUsBoxes" elem="Wrapper">
            <div block="contactUsBoxes">
                <div block="contactUsBox">
                    <span block="contactUsBox" elem="Top" mix={{block:'call'}}>Call Us</span>
                    <span block="contactUsBox" elem="Middle">Give us a call and quickly talk with our customer support</span>
                   <a block="contactUsBox" elem="Bottom" href={'tel:+' + store_information.phone.replace(/\D/g, "")} target="_blank">Call {store_information.phone}</a>
                </div>
                <div block="contactUsBox">
                    <span block="contactUsBox" elem="Top" mix={{block:'email'}}>Email Us</span>
                    <span block="contactUsBox" elem="Middle">Fill the contact form and one of our support member will contact you through email</span>
                    {/*<span block="contactUsBox" elem="Bottom">Get Contact Form</span>*/}
                    <a id="goToContactUsForm" block="contactUsBox" elem="Bottom" onClick={goToContactUsForm}>Get Contact Form</a>
                </div>
            </div>
                <Decoration direction="right" angle="bottomLeft" type="type1"/>
                </div>
        )
    }

    contactPageHeading(heading) {
        return (
            <h1 block="Contact" elem="Heading">
                {heading}
            </h1>
        )
    }
    renderOurOffice() {

        const {items: {store_information}} = this.props;

        let hoursLine1 = '', hoursLine2 = '';
        try {
            hoursLine1 = store_information.hours.split(',')[0].trim();
        }catch (e) {}
        try {
            hoursLine2 = store_information.hours.split(',')[1].trim();
        }catch (e) {}

        return (
                <div block="Contact" elem="OurOffice">
                    {/*<Decoration direction="left" angle="topLeft" type="type1"/>*/}
                    <h1 block="Contact" elem="Heading">
                        Our Headquarters
                    </h1>
                    <div block="Contact" elem="Wrapper">
                        <div block="Contact" elem="Box" mix={{block: 'info'}}>

                            <div block="Contact" elem="BoxesWrapper">
                                {
                                    (typeof store_information !== 'undefined') ?
                                        <div block="Contact" elem="BoxWrapper">
                                            {/*<Html content={decodeURIComponent(escape(store_information))} />*/}
                                            {/*{store_information}*/}
                                            <div>{!!(store_information.name) ? store_information.name : ''}</div>
                                            <span>{!!(store_information.street_line1) ? store_information.street_line1 + ", ": ''}</span>
                                            <span>{!!(store_information.street_line2) ? store_information.street_line2 + ", ": ''}</span>
                                            <span>{!!(store_information.region) ? store_information.region + ", ": ''}</span>
                                            <span>{!!(store_information.city) ? store_information.city + ", ": ''}</span>
                                            <span>{!!(store_information.postcode) ? store_information.postcode + ", " : ''}</span>
                                            <span>{!!(store_information.country) ? store_information.country : ''}</span>
                                        </div>
                                        : ''
                                }
                                <div block="Contact" elem="BoxSpacer"></div>
                                {
                                    (typeof store_information !== 'undefined') ?
                                        <div block="Contact" elem="BoxWrapper">
                                            {/*<Html content={decodeURIComponent(escape(store_information))} />*/}
                                            {/*{store_information}*/}
                                            <div>Opens</div>
                                            <span>{!!(hoursLine1) ? hoursLine1 : ''}</span>
                                            <div className="days-closed">{!!(hoursLine2) ? hoursLine2 : ''}</div>
                                        </div>
                                        : ''
                                }
                            </div>
                            <div block="Contact" elem="Box" mix={{block:'map'}}>
                                <div block="Contact-Box" elem="MapWrapper" style={{ height: '100%', width: '100%' }}>
                                    <MagePlazaGoogleMaps/>
                                </div>
                            </div>
                        </div>
                        <div block="Contact" elem="Box" mix={{block:'map'}}>
                            {this.renderContactForm()}
                        </div>

                    </div>
                </div>
        )
    }
    renderFeedBackButton() {

        const {isLoading, items, onButtonClick, onlyAddNewTicket} = this.props;

        if (isLoading) {
            return null;
        }

        if (onlyAddNewTicket) {

            let mix = (typeof onlyAddNewTicket.mix !== 'undefined') ? onlyAddNewTicket.mix : {block: 'NewTicketPopup'};

            return (
                <>
                    <>
                        <span
                            block="RequestCancellation"
                            // mix={{block: 'NewTicket', elem: 'Button'}}
                            // mods={{isHollow: true}}
                            onClick={onButtonClick}
                        >
                            {onlyAddNewTicket.buttonTitle}
                        </span>
                        <Popup
                            id="NewCustomRequestPopup"
                            mix={mix}
                            onClose={this.closeNewTicketPopup.bind(this)}
                            clickOutside={false}
                            hasFooter={true}
                        >
                            <Loader isLoading={true}/>
                            <div>
                                {this.ticketNote()}
                                {this.renderContent()}
                            </div>
                            {this.renderSchedule(true)}

                        </Popup>
                    </>
                </>
            )
        }

        if (!items.is_enabled) {
            return null;
        }

        return (
            <>
                <div id="hdmx__contact-button"
                     className={'hdmx__contact-button hdmx__contact-button-' + items.position}
                     style={{backgroundColor: items.button_color}}
                     onClick={onButtonClick}
                >
                    <span>{items.title}</span>
                </div>
                <Popup
                    id="NewContactUsPopup"
                    mix={{block: 'NewContactUsPopup'}}
                    onClose={this.closeNewTicketPopup.bind(this)}
                    clickOutside={false}
                    hasFooter={!!(items.schedule_in_popup)}
                >
                    {
                        (items.is_active_kb) ?
                            <>
                                <div id="FeedBackTabActiveKb">
                                    <SearchIcon/>
                                    <Field
                                        // addRequiredTag={true}
                                        label="What is your question?"
                                        type={FieldType.INPUT}
                                        attr={{
                                            id: `what-is-your-question`,
                                            name: `whatisyourquestion`,
                                            placeholder: 'Briefly describe your question',
                                            isRequired: true
                                        }}
                                        events={{
                                            onChange: this.handleFieldChange.bind(this)
                                        }}
                                        validateOn={['onChange']}
                                        validationRule={{
                                            match: (value) => {
                                                if (!(value)) {
                                                    // delete preparedTicketData['subject'];
                                                    // document.getElementById('submit-new-ticket').classList.add('disabled');
                                                    document.getElementById('CloseKbSearchResults').classList.remove('active');
                                                }else {
                                                    document.getElementById('CloseKbSearchResults').classList.add('active');
                                                }
                                                return true;
                                                // return !!(value);

                                            },
                                            customErrorMessages: {
                                                onMatchFail: 'This field is required!'
                                            }
                                        }}
                                    />
                                    <div id="CloseKbSearchResults" onClick={this.closeKbSearchResults}><CloseIcon/></div>
                                    <div id="FeedBackTabActiveKbResult"></div>
                                </div>


                                <div id="FeedBackTabForm" block="hidden">
                                    {this.renderContent()}
                                </div>
                            </>

                            : this.renderContent()
                    }

                    {(items.schedule_in_popup) ? this.renderSchedule(true) : ''}

                    {/*{this.newTicket()}*/}
                </Popup>
            </>

        );
    }

    ticketNote() {
        const {onlyAddNewTicket} = this.props;
        if (onlyAddNewTicket) {
            return (
                <div block="NewTicketPopup" elem="Note">
                    <span>Note:</span>&nbsp;
                    <Html content={onlyAddNewTicket.ticketNote} />
                </div>
            )
        }
    }

    renderSchedule(responseTime = false) {

        const {items: {schedule_is_open, open_message, open_in, closed_message, closed_in}} = this.props;

        if (schedule_is_open) {
            return (
                <div className="hdmx__schedule-status status-open">
                    {/*{open_message}*/}
                    <TimeCountDown messages={open_message.split('|')} source='opened' countDown={closed_in} />
                    {
                        (responseTime) ?
                            <div className='status-content response-time'>
                                Avg response time: 1 hr
                            </div>
                            : ''
                    }

                </div>
            )
        }

        if (closed_message) {
            return (
                <div className="hdmx__schedule-status status-close">
                    {/*{closed_message}*/}
                    <TimeCountDown messages={closed_message.split('|')} source='closed' countDown={open_in} />
                </div>
            )
        }

        return null;

    }


    renderScheduleHeadingRow(days) {
        return (
            <tr block="Heading">
                <th><span>{__('Days')}</span></th>
                {
                    days.map((item, i) => {
                        return (
                            <th>{item.day}</th>
                        )
                    })
                }
            </tr>
        );
    }

    renderScheduleTimeRow(schedule) {

        if (schedule.is_holiday) {
            return;
        }

        return (
            <tr block="Heading">
                <th>{schedule.title}</th>
                {
                    schedule.days.map((item, i) => {
                        return (
                            <th block={item.time.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")}>{item.time.replaceAll(':00', '')}</th>
                        )
                    })
                }
            </tr>
        );
    }

    renderScheduleTable(schedule) {

        return (
            <div block="ContactPage" elem="WorkingHoursColumnWrapper">
            <table>
                <thead>
                {this.renderScheduleHeadingRow(schedule.current_schedule.days)}
                </thead>
                <tbody>
                {this.renderScheduleTimeRow(schedule.current_schedule)}
                {
                    (schedule.upcoming_schedule) ? this.renderUpcomingSchedule(schedule.upcoming_schedule) : ''
                }
                </tbody>
            </table>
            </div>
        );
    }

    renderUpcomingSchedule(schedule) {
        return schedule.map((item, i) => {
            return this.renderScheduleTimeRow(item)
        })
    }
    renderWorkingHours() {
        const {items: {working_hours}} = this.props;

        if (!working_hours) {
            return null;
        }

        return (
            <div block="ContactPage" elem="WorkingHSchedules">
                <Decoration direction="left" angle="centerLeft" type="type1"/>
                {this.contactPageHeading('Working Schedules')}
                {this.renderScheduleTable(working_hours)}
                <Decoration direction="right" angle="centerRight" type="type1"/>
                <span className="note"><u>Important</u>: For every public holiday, we’ll be off for both office and online support</span>

                {
                    (working_hours.upcoming_holidays) ? this.renderHolidays(working_hours.upcoming_holidays) : ''
                }

            </div>
        );
    }

    renderHolidays(holidays) {
        return (
            <span className="note">Upcoming public holidays: {holidays}</span>
        )
    }

    renderOurEmails() {
        return (
            <div block="Contact" elem="OurEmails">
                {this.contactPageHeading('Our Emails')}
                <Decoration direction="left" angle="centerLeft" type="type1"/>
                <CmsBlock identifier="contact_us_page_our_emails" />
                <Decoration direction="right" angle="centerRight" type="type1"/>
            </div>
        );
    }

    closeNewTicketPopup() {
        preparedTicketData = {};
        preparedTicketDataCopy = {};
    }

    handleFieldChange(event, field) {

        let {items: {total_required, customer, is_active_kb}, onlyAddNewTicket, onQuestionChange, contactUs} = this.props;

        if (is_active_kb && field.name === 'whatisyourquestion') {
            onQuestionChange();
            field.name = 'subject';
            // return;
        }


        if (!customer) {
            total_required++;
            total_required++;
        }

        let {isRequired, value = ''} = field;

        if (onlyAddNewTicket) {
            preparedTicketData = {};
            preparedTicketDataCopy = {};
            preparedTicketData['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketData['subject'] = onlyAddNewTicket.ticketSubject;
            preparedTicketDataCopy['order_id'] = onlyAddNewTicket.orderId;
            preparedTicketDataCopy['subject'] = onlyAddNewTicket.ticketSubject;
        }

        if (field.name.includes('checkbox') && typeof preparedTicketDataCopy[field.name] !== 'undefined') {
            value = preparedTicketDataCopy[field.name] = !preparedTicketDataCopy[field.name];
        }


        if (isRequired) {
            preparedTicketData[field.name] = value;
        }

        preparedTicketDataCopy[field.name] = value;


        if (!value) {
            delete preparedTicketData[field.name];
            delete preparedTicketDataCopy[field.name];
        }



        let filedFields = Object.keys(preparedTicketData).length;
        // console.clear();
        // console.log(!!value);
        // console.log(this.state);
        // console.log(this.props);
        // console.log(total_required);
        // console.log(filedFields);
        // console.log(preparedTicketData);
        // console.log(preparedTicketDataCopy);

        if (total_required === filedFields) {
            document.getElementById('submit-new-ticket').classList.remove('disabled');
        }
    }

    renderAttachments() {

        const {items: {attachment_is_allowed, attachment_size}} = this.props;

        if (!attachment_is_allowed) {
            return null;
        }

        return (
            <>
                <div className="max-attachment-size">
                    {/*<label className="Field-Label">Max file size: {attachment_size} Mb</label>*/}
                    <label className="Field-Label">Attach Files</label>
                </div>
                <DropzoneArea
                    onChange={this.handleChange.bind(this)}
                    getPreviewIcon={handlePreviewIcon}
                    Icon={UploadIcon}
                    filesLimit={100}
                    showFileNames={true}
                    showAlerts={false}
                    dropzoneText={"Upload file (max file " + attachment_size + " Mb)"}
                />
            </>
        )


    }

    handleChange(files) {
        let preparedFiles = [];
        const {callBack} = this.props
        files.map((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                let dropZoneFile = {
                    name: file.name,
                    size: file.size,
                    type: !!(file.type) ? file.type : 'unknown',
                    data: reader.result,
                }
                preparedFiles.push(dropZoneFile);
            };

        })

        this.setState({files: files, preparedFiles: preparedFiles});
    }

    renderDropDowns(option, optionCode, source) {
        if (!source) {
            return null;
        }

        return (
            <Field
                addRequiredTag={(option !== 'Order')}
                label={option}
                type={FieldType.SELECT}
                options={source.map(({id, name}) => ({id, label: name, value: id}))}
                attr={{
                    id: `helpdesk-new-message-${option}`,
                    name: optionCode,
                    isRequired: (option !== 'Order')
                }}
                events={{
                    onChange: this.handleFieldChange.bind(this)
                }}
                validateOn={['onChange']}
                validationRule={{
                    match: (value) => {
                        if (option === 'Order') {
                            delete preparedTicketData[`option-helpdesk-new-message-${option}`];
                            return true;
                        }
                        if (!(value)) {
                            delete preparedTicketData[`option-helpdesk-new-message-${option}`];
                            document.getElementById('submit-new-ticket').classList.add('disabled');
                        }
                        return !!(value);
                    },
                    customErrorMessages: {
                        onMatchFail: 'This field is required!'
                    }
                }}
            />
        )
    }

    async submitNewTicket() {

        // console.clear();
        // console.log(preparedTicketData);
        // console.log(preparedTicketDataCopy);
        // console.log(this.state);
        // console.log(this.state);
        // console.log(this.props);


        const {contactUs, getTicketList, showContactUs, showCustomRequest, showNotification, onlyAddNewTicket} = this.props;
        const {preparedFiles} = this.state;
        let ticketData = {
            'ticketFields': preparedTicketDataCopy,
            'attachments': preparedFiles
        }

        // console.log(ticketData);
        // showContactUs();
        document.querySelectorAll(
            '.NewContactUsPopup .Loader, .ContactPage .Loader, .NewTicketPopup .Loader'
        ).forEach(n => n.style.display = "block");
        // return null;
        const newTicketResponse = await fetchMutation(TicketQuery.createNewTicket(JSON.stringify(ticketData)));
        // console.log(newTicketResponse);
        if (!contactUs) {
            if (onlyAddNewTicket) {
                showCustomRequest();
            }else {
                showContactUs();
            }

        } else {
            document.querySelectorAll(
                '.ContactPage .Loader'
            ).forEach(n => n.style.display = "none");
        }

        const {createNewTicket: {tickets_list}} = newTicketResponse;
        let result = JSON.parse(tickets_list);
        // console.log(result);
        if (result.wrong) {
            showNotification('error', __('Something went wrong. Please refresh the page.'));
        } else {
            if (result.success) {
                // showNotification('success', __('Your ticket has been created.'));
                if (contactUs) {
                    showNotification('success', __('Your ticket has been created.'));
                }
                if (onlyAddNewTicket) {
                    showCustomRequest({
                        title: 'Submission Received',
                        successPopupContent: this.submissionRecieved(result.ticket_id)
                    });
                }else {
                    showContactUs({
                        title: 'Submission Received',
                        successPopupContent: this.submissionRecieved(result.ticket_id)
                    });
                }

            } else {
                showNotification('error', __('Your ticket has not been created.'));
            }
            if (result.customer) {
                getTicketList(1);
            }
        }

        this.closeNewTicketPopup();

    }

    submissionRecieved(ticketId) {
        return (
            <div className='SubmissionRecieved'>
                <h3>Ticket ID: #{ticketId}</h3>
                <p>Save this Ticket ID for reference. Our support team will be in contact with you shortly through email.</p>
                <Link
                    onClick={this.goToTickets}
                    to='/helpdesk/tickets'
                >
                    My Support Tickets
                </Link>
            </div>
        )
    }

    render() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        // return 'asd';


        const {isLoading, contactUs} = this.props;

        if (isLoading) {
            return null;
        }

        if (contactUs) {
            return this.renderContent();
        }

        return (
            <div
                block="MyAccountFeedBackTab"
            >
                {/*<Loader isLoading={isLoading}/>*/}
                {this.renderFeedBackButton()}
            </div>
        );
    }
}

export default FeedBackTab;
