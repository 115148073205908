import AdvancedReviewWidget from './../component/AdvancedReviewWidget';
import RenderWhenVisible from "Component/RenderWhenVisible";
import { lazy, PureComponent, Suspense } from 'react';

const renderContent = (args, callback, instance) => {

    const {
        type
    } = instance.props;

    // console.log(type);

    if (type !== 'AmastyAdvancedReviews') {
        return callback.apply(instance, args);
    }


    instance.renderMap['AdvancedReview'] = {
        component: AdvancedReviewWidget
    }

    // return callback.apply(instance, args);

    // console.clear();
    // console.log(instance.props);

    const {
        reviewType,
        reviewsCount,
        title
    } = instance.props;

    return <AdvancedReviewWidget reviewType={reviewType} reviewsCount={reviewsCount} title={title}/>
    // return 'AdvancedReview';
    // return callback.apply(instance, args);
};


export default {
    'Component/WidgetFactory/Component': {
        'member-function': {
            renderContent
        }
    }
};
