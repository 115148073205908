const containerProps = (args, callback, instance) => {

    const {
        id,
    } = instance.props;

    if (id !== 'REVIEW_POPUP_ID') {
        return callback(...args);
    }

    const {
        activeOverlay,
        areOtherOverlaysOpen,
        changeHeaderState,
        children,
        isMobile,
        isStatic,
        mix,
        contentMix,
        onClose,
        onHide,
        onVisible,
        shouldPopupClose,
        hideActiveOverlay,
        resetHideActivePopup,
        goToPreviousNavigationState,
        productName
    } = instance.props;

    return {
        activeOverlay,
        areOtherOverlaysOpen,
        changeHeaderState,
        children,
        id,
        isMobile,
        isStatic,
        mix,
        contentMix,
        shouldPopupClose,
        onClose,
        onHide,
        onVisible,
        hideActiveOverlay,
        resetHideActivePopup,
        goToPreviousNavigationState,
        productName,
        title: instance._getPopupTitle()
    };
};

export default {
    'Component/Popup/Container': {
        'member-function': {
            containerProps
        }
    }
};
