/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from "react";

import { ReactElement } from "Type/Common.type";

import "./TrashIcon.style";

/** @namespace Component/SearchIconDesktop/Component */
export class TrashIconComponent extends PureComponent {
    render(): ReactElement {
        return (
            <svg block="TrashIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_363_37868)">
                    <path
                        d="M13.3333 5.00007V4.3334C13.3333 3.39998 13.3333 2.93327 13.1517 2.57675C12.9919 2.26315 12.7369 2.00818 12.4233 1.84839C12.0668 1.66673 11.6001 1.66673 10.6667 1.66673H9.33333C8.39991 1.66673 7.9332 1.66673 7.57668 1.84839C7.26308 2.00818 7.00811 2.26315 6.84832 2.57675C6.66667 2.93327 6.66667 3.39998 6.66667 4.3334V5.00007M8.33333 9.5834V13.7501M11.6667 9.5834V13.7501M2.5 5.00007H17.5M15.8333 5.00007V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76653 18.3334 6.06647 18.3334 5.53169 18.0609C5.06128 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00007"
                        stroke="#E1202A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_363_37868">
                        <rect width="20" height="20" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
    );
    }
}

export default TrashIconComponent;
