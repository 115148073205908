import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';

import { RootState } from 'Util/Store/Store.type'
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    //@ts-ignore
    gstIsEnabled: !!parseInt(state.ConfigReducer.gst_is_enabled),
    //@ts-ignore
    gstIsVisible: !!parseInt(state.ConfigReducer.gst_is_visible),
    //@ts-ignore
    gstIsRequired: !!parseInt(state.ConfigReducer.gst_is_required),
    // TODO extend mapStateToProps
});

import { Dispatch } from 'redux'
import {showPopup} from "Store/Popup/Popup.action";
import {
    MyAccountCreateAccountComponentProps,
    MyAccountCreateAccountContainerFunctions, MyAccountCreateAccountContainerPropsKeys
} from "Component/MyAccountCreateAccount/MyAccountCreateAccount.type";
import {FieldData} from "Util/Form/Form.type";
import transformToNameValuePair from "Util/Form/Transform";
import {ORDER_ID} from "Component/MyAccountOrder/MyAccountOrder.config";
import {CreateAccountOptions} from "Query/MyAccount.type";
import {CONFIRMATION_REQUIRED} from "Component/MyAccountCreateAccount/MyAccountCreateAccount.config";
import {MyAccountPageState} from "Component/MyAccountOverlay/MyAccountOverlay.config";
import {NotificationType} from "Store/Notification/Notification.type";
import history from "Util/History";
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showTermsAndConditionsPopup: (payload: any) => dispatch(showPopup('AccountTermsAndConditionsPopup', payload)),
    showPrivacyPolicyPopup: (payload: any) => dispatch(showPopup('AccountPrivacyPolicyPopup', payload))
    // TODO extend mapDispatchToProps
});

export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    containerFunctions: MyAccountCreateAccountContainerFunctions = {
        onSuccess: this.onSuccess.bind(this),
        onError: this.onError.bind(this),
        //@ts-ignore
        showTermsAndConditionsPopup: this.showTermsAndConditionsPopup.bind(this),
        showPrivacyPolicyPopup: this.showPrivacyPolicyPopup.bind(this)
    };

    containerProps(): Pick<MyAccountCreateAccountComponentProps, MyAccountCreateAccountContainerPropsKeys> {
        const {
            state,
            handleSignIn,
            showTaxVatNumber,
            newsletterActive,
            minimunPasswordLength,
            minimunPasswordCharacter,
            //@ts-ignore
            gstIsEnabled,
            //@ts-ignore
            gstIsVisible,
            //@ts-ignore
            gstIsRequired
        } = this.props;

        const range = { min: minimunPasswordLength, max: 64 };

        return {
            state,
            handleSignIn,
            showTaxVatNumber: !!showTaxVatNumber,
            newsletterActive,
            vatNumberRequired: this.getVatNumberRequired(),
            range,
            minimunPasswordCharacter,
            //@ts-ignore
            gstIsEnabled,
            //@ts-ignore
            gstIsVisible,
            //@ts-ignore
            gstIsRequired
        };
    }

    showTermsAndConditionsPopup() {
        //@ts-ignore
        const {showTermsAndConditionsPopup} = this.props;
        return showTermsAndConditionsPopup();
    }

    showPrivacyPolicyPopup() {
        //@ts-ignore
        const {showPrivacyPolicyPopup} = this.props;
        return showPrivacyPolicyPopup();
    }

    async onSuccess(form: HTMLFormElement, fields: FieldData[]): Promise<void> {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile,
        } = this.props;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat,
            buyergst
        } = transformToNameValuePair<Record<string, string | number | boolean>>(fields);

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                taxvat,
                buyergst
            },
            password,
            orderID: sessionStorage.getItem(ORDER_ID),
        } as CreateAccountOptions;

        if (is_subscribed) {
            customerData.customer.is_subscribed = !!is_subscribed;
        }

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData).catch(
                /** @namespace Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/code/createAccount/catch */
                () => process.exit(1),
            );

            // if user needs confirmation
            if (code === CONFIRMATION_REQUIRED) {
                setSignInState(MyAccountPageState.STATE_CONFIRM_EMAIL);

                if (isLandingPage || isMobile) {
                    showNotification(
                        NotificationType.SUCCESS,
                        // eslint-disable-next-line max-len
                        __('The email confirmation link has been sent to your email. Please confirm your account to proceed.'),
                    );
                    history.push('/default/customer/account/login');
                }
            } else if (code !== false) {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
