/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from '@scandipwa/scandipwa/src/util/Query';

export class TicketQuery {
    // getTickets(customerId = false) {
    getTickets(options) {
        const { customerId, page = 1 } = options || {};
        console.log(options);
        return new Field('ticketsList')
            .addFieldList([
                'tickets_list'
            ])
            .addArgument('customerId', 'String', customerId)
    }

    getTicketInfo(options) {
        let { orderId, externalId } = options || {};
        console.log(options);
        if (externalId) {
            orderId = 0;
        }
        return new Field('customerTicket')
            .addFieldList([
                'tickets_list'
            ])
            .addArgument('ticketId', 'Int', orderId)
            .addArgument('externalId', 'String', externalId)
    }

    getTicketListQuery(options) {
        return new Field('customer')
            .addFieldList(this._getTicketListFields(options));
    }
    _getTicketListFields(options) {
        return [
            this._getOrdersField(options)
        ];
    }
    _getOrdersField(options) {
        const { orderId, page = 1 } = options || {};
        const ordersField = new Field('tickets')
            .addField('new_ticket_data')
            .addField('schedule');

        if (orderId) {
            return ordersField
                .addArgument('filter', 'CustomerOrdersFilterInput', { entity_id: { eq: orderId } })
                .addFieldList(this._getOrdersFields(true));
        }

        return ordersField
            .addArgument('currentPage', 'Int', page)
            .addFieldList(this._getOrdersFields());
    }
    _getOrdersFields(isSingleOrder = false) {
        return [
            'total_count',
            this._getOrderItemsField(isSingleOrder),
            this._getSearchResultPageInfoField()
        ];
    }
    _getSearchResultPageInfoField() {
        return new Field('page_info')
            .addFieldList(this._getSearchResultPageInfoFields());
    }

    _getSearchResultPageInfoFields() {
        return [
            'current_page',
            'page_size',
            'total_pages'
        ];
    }

    _getOrderItemsField(isSingleOrder) {
        return new Field('items')
            .addFieldList(this._getOrderItemsFields(isSingleOrder));
    }
    _getOrderItemsFields(isSingleOrder) {
        const basicFields = [
            'id',
            'ticket_id',
            'subject',
            'department',
            'created_at',
            'last_activity',
            'status',
            'status_color',
            'verified_user'
        ];

        if (isSingleOrder) {
            return [...basicFields, ...this._getSingleOrderFields()];
        }

        return basicFields;
    }

    _getSingleOrderFields() {
        return [
            'carrier'
        ];
    }

    createNewTicket(ticketData) {
        return new Field('createNewTicket')
            .addFieldList([
                'tickets_list'
            ])
            .addArgument('createNewTicketData', 'String', ticketData);
    }
}

export default new TicketQuery();
