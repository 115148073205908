import {PureComponent} from 'react';
import './TimeCountDown.style';
import Countdown from 'react-countdown';
import FeedBackTabQuery from "../../query/FeedBackTab.query";
import {fetchQuery} from '@scandipwa/scandipwa/src/util/Request/Query';

let countDownChanged = false;

const requestDownloadable = () => {
    fetchQuery(
        FeedBackTabQuery.getFeedBackTab(1)
    ).then(
        /** @namespace Component/FeedBackTab/Container/requestDownloadable/success */
        (
            /** @namespace Component/FeedBackTab/Container/FeedBackTabContainer/requestDownloadable/fetchQuery/then */
            (data) => {
                // const items = data.ticketsList;
                // console.log(data);
                countDownChanged = true;
                let items = JSON.parse(data.ticketsList.tickets_list);
                console.log(items);
                console.log('countDownChanged ' + countDownChanged);
                const {schedule_is_open, closed_in, closed_message, open_in, open_message } = items;

                // this.setState({items, isLoading: false});
                // if (action === 1) {
                //     showSuccessNotification('You will no longer receive price alerts for this product.');
                // }
            }),
        /** @namespace Component/FeedBackTab/Container/FeedBackTabContainer/requestDownloadable/fetchQuery/then/catch */
        (err) => {
            console.log(err);
            // showErrorNotification(getErrorMessage(err));
            // this.setState({isLoading: false});
        }
    );
}

// const WeAreClosed = (data) => console.log(data);
const WeAreClosed = () => <div className="hdmx__schedule-status status-content status-close">We are just closed.</div>;
const WeAreOpened = () => <div className="hdmx__schedule-status status-content status-open">We are just opened.</div>;
const ClosedCountDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // document.body.classList.add('helpdesk-opened');
        // requestDownloadable();
        // return <WeAreOpened />;
    } else {
        // requestDownloadable();
        // if (countDownChanged) {
        //     // countDownChanged = true;
        //     return <WeAreOpened />;
        // }

        if (!days && !hours && !minutes) {
            return (
                <span>
                    {(seconds === 1 ? seconds + ' sec' : seconds + ' secs')}
                </span>
            )
        }
        return (
            <span>
                {
                    (days) ? (days === 1) ? days + ' day ' : days + ' days ': ''
                }
                {
                    // (hours) ? (hours === 1 ? hours + ' hr ' : hours + ' hrs ') : ''
                    (hours === 1) ? hours + ' hr ' : hours + ' hrs '
                }
                {
                    // (minutes) ? (minutes === 1 ? minutes + ' min ' : minutes + ' mins ') : ''
                    (minutes === 1) ? minutes + ' min ' : minutes + ' mins '
                }
                {
                    // (!days && !hours && !minutes) ? (seconds === 1 ? seconds + ' sec' : seconds + ' secs') : ''
                }
                {/*{(seconds === 1 ? seconds + ' sec' : seconds + ' secs')}*/}
            </span>
        );
    }
};
const OpenedCountDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // requestDownloadable();
        // document.body.classList.add('helpdesk-closed');
        // return <WeAreClosed />;
    } else {
        // requestDownloadable();
        // if (countDownChanged) {
        //     document.body.classList.add('helpdesk-closed');
        //     return <WeAreClosed />;
        // }
        // return <WeAreClosed />;
        if (!days && !hours && !minutes) {
            return (
                <span>
                    {(seconds === 1 ? seconds + ' sec' : seconds + ' secs')}
                </span>
            )
        }
        return (
            <span>
                {
                    (days) ? (days === 1) ? days + ' day ' : days + ' days ': ''
                }
                {
                    // (hours) ? (hours === 1 ? hours + ' hr ' : hours + ' hrs ') : ''
                    (hours === 1) ? hours + ' hr ' : hours + ' hrs '
                }
                {
                    // (minutes) ? (minutes === 1 ? minutes + ' min ' : minutes + ' mins ') : ''
                    (minutes === 1) ? minutes + ' min ' : minutes + ' mins '
                }
                {
                    // (!days && !hours && !minutes) ? (seconds === 1 ? seconds + ' sec' : seconds + ' secs') : ''
                }
                {/*{(seconds === 1 ? seconds + ' sec' : seconds + ' secs')}*/}
            </span>
        );
    }
};
export class TimeCountDown extends PureComponent {



    render() {
        const {messages, source, countDown} = this.props;
        let sourceRenderer;
        if (source === 'opened') {
            sourceRenderer = OpenedCountDownRenderer;
        }else {
            sourceRenderer = ClosedCountDownRenderer;
        }

        return messages.map((message, index) => {
            let findTime = message.replace(/%.*%/,'time_here').split('time_here');
            if (findTime.length === 1) {
                return (
                    <div key={index} className='status-content status'>
                        {message}
                    </div>
                )
            }else {
                return (
                    <div key={index} className='status-content countdown'>
                        <span>{findTime[0]}</span>
                        <Countdown renderer={sourceRenderer} date={Date.now() + countDown} />
                        <span>{findTime[1]}</span>
                    </div>
                )
            }
        })
    }

}

export default TimeCountDown;
