import './_mst-search.scss';
import parser from 'html-react-parser';
import TextPlaceholder from "@scandipwa/scandipwa/src/component/TextPlaceholder";
import {AMOUNT_OF_PLACEHOLDERS} from "../../../../src/component/SearchOverlay/SearchOverlay.config";
import getStore from 'Util/Store';
import ProductCard from 'Component/ProductCard';

function renderProductCard (product, index) {
    return (
        <ProductCard
            block="ProductLinks"
            elem="Card"
            product={product}
            key={index}
            siblingsHaveBrands={true}
            siblingsHavePriceBadge={true}
            siblingsHaveTierPrice={true}
            siblingsHaveConfigurableOptions={true}
            setSiblingsHaveBrands={true}
            setSiblingsHavePriceBadge={true}
            setSiblingsHaveTierPrice={true}
            setSiblingsHaveConfigurableOptions={true}
        />
    );
}
function Highlight(searchQuery, nameHighlight) {

    let arQuery = searchQuery.split(' ');
    let arSpecialChars = [
        {'key': 'a', 'value': '(à|â|ą|a)'},
        {'key': 'c', 'value': '(ç|č|c)'},
        {'key': 'e', 'value': '(è|é|ė|ê|ë|ę|e)'},
        {'key': 'i', 'value': '(î|ï|į|i)'},
        {'key': 'o', 'value': '(ô|o)'},
        {'key': 's', 'value': '(š|s)'},
        {'key': 'u', 'value': '(ù|ü|û|ū|ų|u)'}
    ];
    let cssClass = 'mst-search__highlight';

    arQuery.forEach(function (word, key) {
        if (word.trim()) {
            word = word.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
            arSpecialChars.forEach(function (match, idx) {
                word = word.replace(new RegExp(match.key, 'g'), match.value);
            });

            if ("span".indexOf(word.toLowerCase()) === -1) {
                nameHighlight = nameHighlight.replace(new RegExp('(' + word + '(?![^<>]*>))', 'ig'), function ($1, match) {
                    return '<span class="' + cssClass + '">' + match + '</span>';
                });
            }
        }
    });
    return parser(nameHighlight);
}

const renderSearchResults = (args, callback, instance) => {

    const state = getStore().getState();

    const {device} = state.ConfigReducer;

    const {searchResults, isLoading} = instance.props;

    if (!searchResults.length && !isLoading && !instance.timeout) {
        return instance.renderNoResults();
    }

    const resultsToRender = (isLoading || instance.timeout) ? Array(AMOUNT_OF_PLACEHOLDERS).fill({}) : searchResults;

    if (device.isMobile) {
        return (
            <ul
                block="SearchOverlay"
                elem="ItemsHolder"
            >
                {resultsToRender.map((item, i) => renderProductCard(item, i))}
            </ul>
        );
    }

    return (
        <ul
            block="SearchOverlay"
            elem="ItemsHolder"
        >
            {resultsToRender.map((item, i) => instance.renderSearchItem(item, i))}
        </ul>
    );
};

const renderContent = (args, callback, instance) => {

    const {product: {name}} = instance.props;

    if (typeof name === 'undefined') {
        return callback(...args);
    }

    let searchQuery = document.getElementById('search-field');
    if (searchQuery) {
        window.mirasSearchQuery = searchQuery.value;
        searchQuery = searchQuery.value;
    } else {
        searchQuery = window.mirasSearchQuery;
    }
    // searchQuery = searchQuery.value;
    // console.clear();
    // console.log(searchQuery);
    // console.log(window.mirasSearchQuery);
    if (typeof searchQuery === 'undefined') {
        searchQuery = '';
    }

    return (
        <figcaption block="SearchItem" elem="Content">
            {instance.renderCustomAttribute()}
            <h4 block="SearchItem" elem="Title" mods={{isLoaded: !!name}}>
                <TextPlaceholder content={Highlight(searchQuery, name)} length="long"/>
            </h4>
        </figcaption>
    );

};

const renderProductName = (args, callback, instance) => {

    const {product: {title_h1}} = instance.props;
    if (window.location.pathname.indexOf('search') === -1) {
        if (title_h1) {
            return <h1 itemProp="name" className="CpPp-Title">{title_h1}</h1>
        }
        return callback.apply(instance, args);
    }
    let name = callback(...args);
    let nameHighlight;
    if (title_h1) {
        nameHighlight = title_h1;
    } else {
        nameHighlight = name.props.children.props.content;
    }
    let searchQuery = window.location.pathname.split('/').pop();
    searchQuery = decodeURI(searchQuery);

    if (title_h1) {
        return <h1 itemProp="name" className="CpPp-Title">{Highlight(searchQuery, nameHighlight)}</h1>
    }
    return <p className="ProductCard-Name">{Highlight(searchQuery, nameHighlight)}</p>;

};


export default {
    'Component/Product/Component': {
        'member-function': {
            renderName: renderProductName
        }
    },
    'Component/SearchItem/Component': {
        'member-function': {
            renderContent: [
                {
                    position: 1,
                    implementation: renderContent
                }
            ],
            // renderContent: renderContent
        }
    },
    'Component/SearchOverlay/Component': {
        'member-function': {
            renderSearchResults: renderSearchResults,
        }
    },
};

