/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace Util/DecorateHeading/DecorateHeading */

export const DecorateHeading = () => {

    const getPageWidth = () => {
        const bodyMax = document.body
            ? Math.max(document.body.scrollWidth, document.body.offsetWidth)
            : 0;

        const docElementMax = document.documentElement
            ? Math.max(
                document.documentElement.scrollWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            )
            : 0;

        return Math.max(bodyMax, docElementMax);
    };

    const elements = document.querySelectorAll('[class*="CmsPage-Heading"], .CategoryDetails-Heading, .Contact-Heading, #header-before-breadcrumbs h1, .not-match');
    if (elements.length) {
        for(var i=0; i<elements.length; i++) {
            // console.log(elements[i]);

            let element = elements[i];
            let parentElement = element.parentElement;

            let diff = 0;
            //@ts-ignore
            if (parseInt(window.getComputedStyle(parentElement).paddingRight)) {
                diff = 64;
            };

            //@ts-ignore
            let parentElementWidth = parseInt( window.getComputedStyle(parentElement).width) - diff;

            //@ts-ignore
            const words = element.textContent.split(' ');
            const wordsLength = words.length;

            let fixedWordsLength = 0;
            let fixedWords = [];

            while (fixedWordsLength < wordsLength) {
                let newH1ForCalculate = document.createElement('h1');
                newH1ForCalculate.setAttribute("class", 'CmsPage-Heading no-wrap');
                let newContent = document.createTextNode(words.join(' '));
                newH1ForCalculate.appendChild(newContent);
                parentElement?.appendChild(newH1ForCalculate);
                fixedWords.push(parseFloat(window.getComputedStyle(newH1ForCalculate).width));
                newH1ForCalculate.remove();
                fixedWordsLength++;
                words.pop();

            }
            let filtered = fixedWords.filter((width) => {
                if (width > parentElementWidth) {
                    return false;
                }
                return width;
            });
            if (!filtered.length) {
                filtered = [parentElementWidth - 104];
            }
            element.setAttribute("style", "width:" + filtered[0] + "px;");
        }

    } else {
        // console.log(elements);
    }
    return null;
};

