import MyAccountTabListItem from "Component/MyAccountTabListItem/MyAccountTabListItem.component";

export class MyAccountTabListContainerNew {
    mapStateToPropsNew = (state) => ({
        helpdesk_frontend_is_active: state[0].ConfigReducer.helpdesk_frontend_is_active
    });

    containerProps = (args, callback, instance) => {

        const {
            helpdesk_frontend_is_active,
        } = instance.props;

        return {
            ...callback.apply(instance, args),
            helpdesk_frontend_is_active,
        };
    };

    renderTabListItem = (args, callback, instance) => {

        const {helpdesk_frontend_is_active} = instance.props;

        if (!helpdesk_frontend_is_active) {
            args[2].map((item, i) => {
                if (item[0] === 'my-tickets') {
                    args[2].splice(i, 1);
                }
            })
        }

        const {activeTab, onTabClick} = instance.props;
        const [key, tab] = args[0];
        const {section} = tab;
        const nextTab = args[2][args[1] + 1] || [];
        const {section: nextTabSection = section} = nextTab[1] || {};

        return (
            <MyAccountTabListItem
                key={key}
                isActive={activeTab === key}
                changeActiveTab={onTabClick}
                tabEntry={args[0]}
            >
                {nextTabSection !== section ? instance.renderLine() : null}
            </MyAccountTabListItem>
        );

    };

}

const {
    mapStateToPropsNew,
    containerProps,
    renderTabListItem
} = new MyAccountTabListContainerNew();

export default {
    'Component/MyAccountTabList/Container/mapStateToProps': {
        'function': mapStateToPropsNew
    },
    'Component/MyAccountTabList/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/MyAccountTabList/Component': {
        'member-function': {
            renderTabListItem
        }
    }
};
