import MostViewedWidget from "./../component/Mostviewed";
import RenderWhenVisible from "Component/RenderWhenVisible";
import { Suspense } from 'react';

const renderContent = (args, callback, instance) => {

    const {
        type,
        ruleType = 0,
        inFrame = false
    } = instance.props;

    if (type !== 'AmastyMostviewed') {
        return callback.apply(instance, args);
    }

    instance.renderMap['AmastyMostviewed'] = {
        component: MostViewedWidget
    }

    const {
        sliderId = 0,
        blockId = 0,
        displayType,
        productsCount,
        showPager,
        storeId,
        title,
        conditionsEncoded,
        onLoad,
    } = instance.props;

    const {
        component: Widget,
        fallback = () => <div />,
    } = instance.renderMap[type] || {};

    if (Widget !== undefined) {
        return (
            <Suspense fallback={ fallback() }>
                <RenderWhenVisible>
                    <Widget
                        inFrame={ inFrame === 'true' }
                        ruleType={ ruleType }
                        sliderId={ sliderId }
                        blockId={ blockId }
                        displayType={ displayType }
                        productsCount={ productsCount }
                        showPager={ !!showPager }
                        storeId={ storeId }
                        title={ title }
                        conditionsEncoded={ conditionsEncoded }
                        onLoad={ onLoad }
                    />
                </RenderWhenVisible>
            </Suspense>
        );
    }

    return callback.apply(instance, args);

    console.log(args);
    console.log(instance);

    return callback.apply(instance, args);

};

export default {
    'Component/WidgetFactory/Component': {
        'member-function': {
            renderContent
        }
    }
};
