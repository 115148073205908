import {withStoreRegex} from "Component/Router/Router.component";
import MyAccount from "Route/MyAccount";
import {Route} from 'react-router-dom';
import {MY_REVIEWS} from "./RouteMyAccount.plugin";
import {lazy} from "react";
import getStore from 'Util/Store';

export const AllReviewsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ '../route/AllReviewsPage'));


export const INCREASED_POSITION = 1;

const SWITCH_ITEMS_TYPE = (originalMember) => {

    const state = getStore().getState();
    let {
        am_advanced_review_base_url
    } = state.ConfigReducer;

    if (typeof am_advanced_review_base_url === 'undefined') {
        am_advanced_review_base_url = 'all-reviews';
    }

    const maxPosition = Math.max.apply(
        Math, originalMember.filter(route => route.name !== 'URL_REWRITES'
        ).map(function (route) {
            return route.position;
        }));

    return [
        ...originalMember,
        {
            component: <Route path={withStoreRegex('/customer/reviews')}
                              render={(props) => <MyAccount {...props} selectedTab={ MY_REVIEWS }/>}/>,
            position: maxPosition + INCREASED_POSITION + INCREASED_POSITION,
            name: 'MY_ACCOUNT_REVIEWS'
        },
        {
            component: <Route path={ withStoreRegex('/' + am_advanced_review_base_url) } render={ (props) => <AllReviewsPage { ...props } /> } />,
            position: maxPosition + INCREASED_POSITION,
            name: 'ALL_REVIEWS'
        }
    ];
};
const stateMap = (member) => {

    member['ALL_REVIEWS'] = {
        title: true,
        back: true
    };
    return member;
};

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: SWITCH_ITEMS_TYPE
        }
    },
    'Component/Header/Component': {
        'member-property': {
            stateMap: stateMap
        }
    }
};
