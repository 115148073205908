import {COLUMN_MAP} from "Component/Footer/Footer.config";
import ContentWrapper from "Component/ContentWrapper";
import getStore from 'Util/Store';

export const renderColumns = (args, callback, instance) => {

    //inject kb link to footer, NUMBER means column
    const state = getStore().getState();
    let {
        kb_general_link_output,
        kb_general_base_url,
        kb_footer_column
    } = state.ConfigReducer;

    if (kb_general_link_output === '0') {
        return callback(...args);
    }
    if (typeof kb_general_base_url === 'undefined') {
        kb_general_base_url = 'knowledge-base';
    }

    // const columnNumber = parseInt(kb_footer_column) - 1;
    // const targetColumn = COLUMN_MAP[columnNumber].items.filter(i => !(i.href && i.href === '/' + kb_general_base_url));
    // COLUMN_MAP[columnNumber].items = targetColumn;

    const {categories = {}} = instance.props;
    const footerCategories = JSON.parse(categories);
    let footerColumnsCategories = footerCategories.concat(COLUMN_MAP);
    let columnNumber;
    columnNumber = Object.keys(footerColumnsCategories).filter(function(key) {return footerColumnsCategories[key]['columnIdentify'] === 'account'})[0];
    if (typeof columnNumber === 'undefined') {
        columnNumber = parseInt(kb_footer_column) - 1;
    }
    const targetColumn = footerColumnsCategories[columnNumber].items.filter(i => !(i.href && i.href === '/' + 'kb_general_base_url'));

    footerColumnsCategories[columnNumber].items = targetColumn;

    targetColumn.push({href:'/' + kb_general_base_url, title: __('Knowledge Base')});

    return callback(...args);
};
export default {
    'Component/Footer/Component': {
        'member-function': {
            renderColumns
        }
    }
};
