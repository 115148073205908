/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from 'Util/Query';

export class CategoriesQuery {
    getCategories(location = false, comment = '', vote = '', homepage = false, sort='position') {
        return new Field('kbCategories')
            .addFieldList([
                'kb_categories'
            ])
            .addArgument('location', 'String', location)
            .addArgument('comment', 'String', comment)
            .addArgument('vote', 'String', vote)
            .addArgument('homepage', 'Boolean', homepage)
            .addArgument('sort', 'String', sort)
    }

}

export default new CategoriesQuery();
