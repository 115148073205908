/** @namespace Util/WordEnding/wordEnding */
export const wordEnding = (num, form_for_1, form_for_2, form_for_5) => {
    num = Math.abs(num) % 100; // take the number modulo and reset hundreds (divide by 100, and assign the remainder to the num variable)
    let num_x = num % 10; // reset tens and write to a new variable
    if (num > 10 && num < 20) // if the number belongs to the segment [11; 19]
        return form_for_5;
    if (num_x > 1 && num_x < 5) // otherwise if the number ends in 2,3,4
        return form_for_2;
    if (num_x === 1) // otherwise if it ends in 1
        return form_for_1;
    return form_for_5;
};
