/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// import { formatTickets } from 'Util/Tickets';

import { GET_TICKET_LIST, SET_TICKET_LOADING_STATUS } from './Ticket.action';

/** @namespace Store/Ticket/Reducer/getInitialState */
export const getInitialState = () => ({
    ticketList: {},
    isLoading: true
});

/** @namespace Store/Ticket/Reducer/TicketReducer */
export const TicketReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        ticketList,
        status
    } = action;
    // console.log(type);
    // console.log(status);
    // console.log(ticketList);
    switch (type) {
    case GET_TICKET_LIST:
        const { items = [], page_info, new_ticket_data, schedule } = ticketList;
        // const formattedTickets = formatTickets(items);
        // console.clear();
        // console.log(ticketList);
        // console.log(items);
        // console.log(type);
        // console.log(status);
        // console.log(ticketList);
        return {
            ...state,
            isLoading: status,
            ticketList: { items: items, pageInfo: page_info, newTicketData:new_ticket_data, schedule:schedule }
        };
    case SET_TICKET_LOADING_STATUS:
        return {
            ...state,
            isLoading: status
        };
    default:
        return state;
    }
};

export default TicketReducer;
