/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import twitterIcon from 'Style/icons/social/twitter.svg';
import facebookIcon from 'Style/icons/social/facebook.svg';
import instagramIcon from 'Style/icons/social/instagram.svg';
import pinterestIcon from 'Style/icons/social/pinterest.svg';
import youtubeIcon from 'Style/icons/social/youtube.svg';

export const RENDER_NEWSLETTER = 'render_newsletter';

export const NEWSLETTER_COLUMN = {
    title: __('Sign up for our newsletter & get the new stuff first!'),
    columnActiveKey: 'newsletterActive',
    items: [
        {
            render: RENDER_NEWSLETTER,
        },
    ],
    columnIdentify: 'newsletter',
    expandableContent: false
};

export const COLUMN_MAP = [
    // {
    //     title: __('Shop'),
    //     expandableContent: true,
    //     columnIdentify: 'shop',
    //     items: [
    //         {
    //             href: '/assorted-sweets',
    //             title: __('Assorted sweets')
    //         },
    //         {
    //             href: '/namkeens',
    //             title: __('Namkeens')
    //         },
    //         {
    //             href: '/gift-boxes',
    //             title: __('Gift boxes')
    //         },
    //         {
    //             href: '/bhaji',
    //             title: __('Bhaji')
    //         },
    //         {
    //             href: '/milk-sweets',
    //             title: __('Milk sweets')
    //         },
    //         {
    //             href: '/biscuits',
    //             title: __('Biscuits')
    //         }
    //     ]
    // },
    // {
    //     title: __('Dry Fruits'),
    //     expandableContent: true,
    //     columnIdentify: 'dryfruits',
    //     items: [
    //         {
    //             href: '/dry-fruit-sweets',
    //             title: __('Dry fruit sweets')
    //         },
    //         {
    //             href: '/dry-fruit-gift-box',
    //             title: __('Dry fruit gift box')
    //         },
    //         {
    //             href: '/flavoured-dry-fruits',
    //             title: __('Flavoured dry fruits')
    //         }
    //     ]
    // },
    // {
    //     title: __('Corporate'),
    //     expandableContent: true,
    //     columnIdentify: 'corporate',
    //     items: [
    //         {
    //             href: '/sweet-boxes',
    //             title: __('Sweet boxes')
    //         },
    //         {
    //             href: '/dry-fruit-gift-box',
    //             title: __('Dry fruit gift box')
    //         },
    //         {
    //             href: '/flavoured-dry-fruits',
    //             title: __('Flavoured dry fruits')
    //         }
    //     ]
    // },
    {
        title: __('Account'),
        expandableContent: true,
        columnIdentify: 'account',
        items: [
            {
                href: '/customer/account/create',
                title: __('Sign Up')
            },
            {
                href: '/wishlist',
                title: __('My Wishlist')
            }
        ]
    },
    {
        title: __('Legal'),
        expandableContent: true,
        columnIdentify: 'legal',
        items: [
            {
                href: '/terms-and-conditions',
                title: __('Terms & Conditions')
            },
            {
                href: '/shipping-policy',
                title: __('Shipping Policy')
            },
            {
                href: '/cancellation-and-returns',
                title: __('Refunds, Cancellation & Returns')
            },
            {
                href: '/privacy-policy',
                title: __('Privacy Policy')
            },
            {
                href: '/contact',
                title: __('Contact Us')
            },
        ]
    },
    {
        title: __('Follow Us'),
        isItemsHorizontal: true,
        expandableContent: false,
        columnIdentify: 'follow',
        items: [
            {
                href: 'https://www.instagram.com/themodernvillage.store/',
                src: instagramIcon,
                title: __('Instagram')
            },
            {
                href: 'https://www.facebook.com/profile.php?id=61564346623912',
                src: facebookIcon,
                title: __('Facebook')
            },
            {
                href: 'https://x.com/tmv_store',
                src: twitterIcon,
                title: __('Twitter')
            },
            {
                href: 'https://www.youtube.com/@themodernvillage',
                src: youtubeIcon,
                title: __('YouTube')
            },
            // {
            //     href: 'https://pinterest.com/',
            //     src: pinterestIcon,
            //     title: __('Pinterest')
            // }
        ]
    },
    // NEWSLETTER_COLUMN
];
