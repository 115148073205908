/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {connect} from 'react-redux';
import ProductListQuery from 'Query/ProductList.query';
import {updateNoMatch} from 'Store/NoMatch/NoMatch.action';
import {showNotification} from 'Store/Notification/Notification.action';
import DataContainer from 'Util/Request/DataContainer';
import { fetchQuery } from 'Util/Request/Query';
import UniquesQuery from "./../../query/Uniques.query";
import KbCategoriesQuery from './../../../../mirasvit-kb/src/query/Categories';
import Loader from "Component/Loader/Loader.component";
import {Tab, TabList, Tabs} from 'react-web-tabs';
import ProductCard from 'Component/ProductCard';
import Field from "Component/Field";
import { FieldType } from 'Component/Field/Field.config';
import ChevronIcon from "Component/ChevronIcon/ChevronIcon.component";
import 'Component/ProductListPage/ProductListPage.style';
import './UniquesCategoryProductsWidget.style';
import Decoration from "Component/Decoration/Decoration.component";
import history from 'Util/History';
import Link from 'Component/Link';

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, FreeMode} from "swiper";
// Import Swiper styles
// import 'swiper/swiper-bundle.css';
import PropTypes from "prop-types";
import Html from "Component/Html";
import wave from './../../../../../src/style/icons/hp/wave.svg';
import './../../../../../src/component/ProductListWidget/ProductListWidget.style.scss';
// import './../../../../magenear-faq/src/plugin/_faq.scss'
// import CategoriesQuery from "./../../../../mirasvit-kb/src/query/Categories";

import './../../../../../../divah-pwa/src/component/ProductList/ProductList.style.scss';
import './_faq.scss';
// import calculateSize from 'calculate-size';
import { getIndexedProduct } from 'Util/Product';

import { DecorateHeading }  from '../../../../../src/util/DecorateHeading';

export const mapStateToProps = (state) => ({
    kb_general_base_url: state.ConfigReducer.kb_general_base_url,
    device: state.ConfigReducer.device,
});


export const mapDispatchToProps = () => ({
    updateNoMatch,
    showNotification
});

let currentTab = null;

const getPageWidth = () => {
    const bodyMax = document.body
        ? Math.max(document.body.scrollWidth, document.body.offsetWidth)
        : 0;

    const docElementMax = document.documentElement
        ? Math.max(
            document.documentElement.scrollWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        )
        : 0;

    return Math.max(bodyMax, docElementMax);
};

export class UniquesCategoryProductsWidgetContainer extends DataContainer {

    static propTypes = {
        blockId: PropTypes.number
    };

    state = {
        tabsHeader: [],
        items: [],
        isLoading: true,
        siblingsHaveBrands: false,
        siblingsHavePriceBadge: false,
        siblingsHaveTierPrice: false,
        siblingsHaveConfigurableOptions: false
    };
    containerFunctions = {
        setSiblingsHaveBrands: () => this.setState({siblingsHaveBrands: true}),
        setSiblingsHavePriceBadge: () => this.setState({siblingsHavePriceBadge: true}),
        setSiblingsHaveTierPrice: () => this.setState({siblingsHaveTierPrice: true}),
        setSiblingsHaveConfigurableOptions: () => this.setState({siblingsHaveConfigurableOptions: true})
    };
    renderProductCard = this._renderProductCard.bind(this);

    componentDidMount() {
        // console.clear();
        // console.log(this.props);

        const {blockId} = this.props;
        // console.log(blockId);
        if (blockId === 21) { //means faq block to get miras kb articles
            this._getKbArticles(blockId);
        }else if (blockId) {
            this._getCmsBlock(blockId);
        } else {
            this.requestDownloadable();
        }

        // this.setState({isLoading: false});


    }

    // __construct(props) {
    //     // console.clear();
    //     console.log(props);
    //     const { blockId } = props;
    //
    //     super.__construct(props, `UniquesCategoryProductsWidgetContainer-${blockId}`);
    // }

    componentDidUpdate() {
        try {
            document.getElementById(currentTab + '-tab').click();
        } catch (e) {
        }
    }

    requestDownloadable() {

        if (window.uniqueCategoriesData) {
            this.uniqueCategoriesData(window.uniqueCategoriesData);
            return;
        }

        fetchQuery(
            UniquesQuery.getCategories()
        ).then(
            (
                async (data) => {
                    window.uniqueCategoriesData = data;
                    this.uniqueCategoriesData(data);


                }),
            (err) => {
                // console.log(err);
            }
        );
    }

    async uniqueCategoriesData(data) {

        let tabProducts = [];
        if (this.state[currentTab]) {
            tabProducts = this.state[currentTab];
            this.setState({isLoading: false, items: tabProducts});
            const tabsLoader = document.getElementById('tabsLoader');
            tabsLoader.style.display = "none";
        }
        let firstTabProducts = [];
        const {uniques: {uniques}} = data;
        let response = JSON.parse(uniques);

        console.log(response);

        if (!response.categories.length) {
            return;
        }
        let tabsHeader = [];
        if (!this.state[currentTab]) {
            let firstTimeTab = false;
            response.categories.map(async (item, index) => {
                if (index === 0 || currentTab === item.title) {
                    firstTimeTab = item.title;
                    firstTabProducts = item.skus;
                }
                tabsHeader.push(
                    {
                        title: item.title,
                        description: item.description,
                        uniq_pattern_background_color: item.uniq_pattern_background_color,
                        uniq_pattern_stop_color_one: item.uniq_pattern_stop_color_one,
                        uniq_pattern_stop_opacity_one: item.uniq_pattern_stop_opacity_one,
                        uniq_pattern_stop_color_two: item.uniq_pattern_stop_color_two,
                        uniq_pattern_stop_opacity_two: item.uniq_pattern_stop_opacity_two,
                        uniq_pattern_size: item.uniq_pattern_size,
                        uniq_pattern_size_step_down: item.uniq_pattern_size_step_down,
                        uniq_pattern_columns: item.uniq_pattern_columns,
                        uniq_pattern_rows: item.uniq_pattern_rows,
                        url: item.url
                    }
                    );

                let query = ProductListQuery.getQuery({
                    args: {
                        filter: {
                            productsSkuArray: item.skus
                        }
                    },
                    notRequireInfo: false,
                    noVariants: false,
                    noVariantAttributes: false
                })

                if (window['uniqueCategoriesFirstTabProducts' + item.title]) {
                    tabProducts = window['uniqueCategoriesFirstTabProducts' + item.title];
                }else {
                    tabProducts = await fetchQuery(query);
                    window['uniqueCategoriesFirstTabProducts' + item.title] = tabProducts;
                    if (!window['uniqueCategoriesFirstTabProducts' + response.categories[0].title]) {
                        window['uniqueCategoriesFirstTabProducts' + response.categories[0].title] = tabProducts;
                    }
                }

                tabProducts = tabProducts.products.items;

                // if (firstTimeTab) {
                    this.setState({[item.title]: tabProducts, [currentTab]: tabProducts});
                // }

            })

            // if (firstTabProducts.length) {
            //     let query = ProductListQuery.getQuery({
            //         args: {
            //             filter: {
            //                 productsSkuArray: firstTabProducts
            //             }
            //         },
            //         notRequireInfo: false,
            //         noVariants: false,
            //         noVariantAttributes: false
            //     })
            //
            //     if (window['uniqueCategoriesFirstTabProducts' + currentTab]) {
            //         tabProducts = window['uniqueCategoriesFirstTabProducts' + currentTab];
            //     }else {
            //         tabProducts = await fetchQuery(query);
            //
            //         window['uniqueCategoriesFirstTabProducts' + currentTab] = tabProducts;
            //         if (!window['uniqueCategoriesFirstTabProducts' + response.categories[0].title]) {
            //             window['uniqueCategoriesFirstTabProducts' + response.categories[0].title] = tabProducts;
            //         }
            //     }
            //
            //     tabProducts = tabProducts.products.items;
            //
            //     if (firstTimeTab) {
            //         this.setState({[firstTimeTab]: tabProducts, [currentTab]: tabProducts});
            //     }
            //
            //
            // }

            if (currentTab) {
                this.setState({[currentTab]: tabProducts});
            }

            this.setState({isLoading: false, tabsHeader: tabsHeader, items: tabProducts});

        } else {
            tabProducts = this.state[currentTab];
            this.setState({isLoading: false, items: tabProducts});
        }

        try {
            const tabsLoader = document.getElementById('tabsLoader');
            tabsLoader.style.display = "none";
        } catch (e) {

        }
        this.setSelectWidth();

    }
    renderNoProducts() {
        return (
            <div block="ProductList">
                <div
                    block="ProductList"
                    elem="ProductsMissing"
                >
                    <svg width="90" height="70" viewBox="0 0 90 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.305 -0.0195312H4.19375C2.33389 -0.0195312 0.845703 1.46864 0.845703 3.32851V24.4398C0.845703 26.2996 2.33387 27.7878 4.19375 27.7878H25.305C27.1649 27.7878 28.6531 26.2997 28.6531 24.4398V3.42151C28.7458 1.56099 27.1649 -0.0195312 25.305 -0.0195312ZM21.957 21.0917H7.63446V6.76989H21.9563L21.957 21.0917Z" fill="#6B2A2A"/>
                        <path d="M43.2542 6.76972H85.8487C87.7086 6.76972 89.1968 5.28155 89.1968 3.42167C89.1968 1.56181 87.7086 0.0736304 85.8487 0.0736304H43.2542C41.3943 0.0736304 39.9062 1.5618 39.9062 3.42167C39.9062 5.28153 41.3943 6.76972 43.2542 6.76972Z" fill="#6B2A2A"/>
                        <path d="M43.2542 27.881H74.1306C75.9904 27.881 77.4786 26.3928 77.4786 24.5329C77.4786 22.6731 75.9905 21.0921 74.1306 21.0921H43.2542C41.3943 21.0921 39.9062 22.5802 39.9062 24.4401C39.9062 26.3 41.3943 27.881 43.2542 27.881Z" fill="#6B2A2A"/>
                        <path d="M25.305 42.2032H4.19375C2.33389 42.2032 0.845703 43.6913 0.845703 45.5512V66.6625C0.845703 68.5223 2.33387 70.0105 4.19375 70.0105H25.305C27.1649 70.0105 28.6531 68.5224 28.6531 66.6625V45.5512C28.7458 43.6907 27.1649 42.2032 25.305 42.2032ZM21.957 63.3144H7.63446V48.9926H21.9563L21.957 63.3144Z" fill="#6B2A2A"/>
                        <path d="M85.8487 42.2032H43.2542C41.3943 42.2032 39.9062 43.6913 39.9062 45.5512C39.9062 47.4111 41.3943 48.8993 43.2542 48.8993H85.8487C87.7086 48.8993 89.1968 47.4111 89.1968 45.5512C89.1968 43.6907 87.7086 42.2032 85.8487 42.2032Z" fill="#6B2A2A"/>
                        <path d="M74.1306 63.3144H43.2542C41.3943 63.3144 39.9062 64.8026 39.9062 66.6625C39.9062 68.5223 41.3943 70.0105 43.2542 70.0105H74.1306C75.9904 70.0105 77.4786 68.5224 77.4786 66.6625C77.4786 64.8026 75.9905 63.3144 74.1306 63.3144Z" fill="#6B2A2A"/>
                        <path d="M25.305 -0.0195312H4.19375C2.33389 -0.0195312 0.845703 1.46864 0.845703 3.32851V24.4398C0.845703 26.2996 2.33387 27.7878 4.19375 27.7878H25.305C27.1649 27.7878 28.6531 26.2997 28.6531 24.4398V3.42151C28.7458 1.56099 27.1649 -0.0195312 25.305 -0.0195312ZM21.957 21.0917H7.63446V6.76989H21.9563L21.957 21.0917Z" stroke="#FEFAF8" stroke-width="2"/>
                        <path d="M43.2542 6.76972H85.8487C87.7086 6.76972 89.1968 5.28155 89.1968 3.42167C89.1968 1.56181 87.7086 0.0736304 85.8487 0.0736304H43.2542C41.3943 0.0736304 39.9062 1.5618 39.9062 3.42167C39.9062 5.28153 41.3943 6.76972 43.2542 6.76972Z" stroke="#FEFAF8" stroke-width="2"/>
                        <path d="M43.2542 27.881H74.1306C75.9904 27.881 77.4786 26.3928 77.4786 24.5329C77.4786 22.6731 75.9905 21.0921 74.1306 21.0921H43.2542C41.3943 21.0921 39.9062 22.5802 39.9062 24.4401C39.9062 26.3 41.3943 27.881 43.2542 27.881Z" stroke="#FEFAF8" stroke-width="2"/>
                        <path d="M25.305 42.2032H4.19375C2.33389 42.2032 0.845703 43.6913 0.845703 45.5512V66.6625C0.845703 68.5223 2.33387 70.0105 4.19375 70.0105H25.305C27.1649 70.0105 28.6531 68.5224 28.6531 66.6625V45.5512C28.7458 43.6907 27.1649 42.2032 25.305 42.2032ZM21.957 63.3144H7.63446V48.9926H21.9563L21.957 63.3144Z" stroke="#FEFAF8" stroke-width="2"/>
                        <path d="M85.8487 42.2032H43.2542C41.3943 42.2032 39.9062 43.6913 39.9062 45.5512C39.9062 47.4111 41.3943 48.8993 43.2542 48.8993H85.8487C87.7086 48.8993 89.1968 47.4111 89.1968 45.5512C89.1968 43.6907 87.7086 42.2032 85.8487 42.2032Z" stroke="#FEFAF8" stroke-width="2"/>
                        <path d="M74.1306 63.3144H43.2542C41.3943 63.3144 39.9062 64.8026 39.9062 66.6625C39.9062 68.5223 41.3943 70.0105 43.2542 70.0105H74.1306C75.9904 70.0105 77.4786 68.5224 77.4786 66.6625C77.4786 64.8026 75.9905 63.3144 74.1306 63.3144Z" stroke="#FEFAF8" stroke-width="2"/>
                    </svg>

                    <p>{ __('No Results Found') }</p>
                    <p>{ __('Sorry, there are no products.') }</p>
                </div>
            </div>
        );
    }

    renderTabHandler(tabId) {

        if (this.state[tabId]) {
            this.setSelectWidth();
        }
        currentTab = tabId;

        const tabsLoader = document.getElementById('tabsLoader');
        tabsLoader.style.display = "block";
        this.requestDownloadable();
    }

    setSelectWidth() {

        const {
            device: {isMobile},
        } = this.props;

        if (!isMobile) {
            return;
        }

        // try {
        //     const selectId = document.getElementById('unique-tabs');
        //     const selectedText = selectId.options[selectId.selectedIndex].text;
        //     const size = calculateSize(selectedText, {
        //         font: 'Inter',
        //         fontSize: '11px'
        //     })
        //     selectId.style.width = size.width + 16 + "px";
        // }catch (e) {
        //
        // }

    }

    renderTabsHeader() {
        const {tabsHeader} = this.state;
        const {device: {isMobile}} = this.props;

        // if (isMobile) {
        //     return (
        //         <Field
        //             type={FieldType.SELECT}
        //             attr={{
        //                 id: `unique-tabs`,
        //                 name: `unique-tabs`,
        //                 noPlaceholder: true,
        //                 value: currentTab
        //             }}
        //             options={
        //                 tabsHeader.map((item, index) => {
        //                     return {
        //                         label: item.title,
        //                         value: item.title
        //                     }
        //                 })
        //             }
        //             events={{
        //                 onChange: this.renderTabHandler.bind(this)
        //             }}
        //         />
        //     )
        // }

        return (
            <TabList>
                {
                    tabsHeader.map((item, index) => {
                        return <Tab key={index} tabFor={item.title}>{item.title}</Tab>
                    })
                }
            </TabList>
        )
    }

    renderTabsSwitcher() {
        // console.log('render tabsSwitcher');
        const {
            isLoading,
            tabsHeader,
            items,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;
        // console.log(items);
        const {
            device: {isMobile},
            setSiblingsHaveBrands,
            setSiblingsHavePriceBadge,
            setSiblingsHaveTierPrice,
            setSiblingsHaveConfigurableOptions,
            title
        } = this.props;

        let arrowRight = <Html content='<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 12H19" stroke="#6B2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 5L19 12L12 19" stroke="#6B2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' />;

        let blockTitle;

        if (typeof title === 'undefined') {
            blockTitle = "Divah Uniques";
        }else {
            blockTitle = title;
        }

        // console.clear();
        // console.log(this.state);
        // console.log(this.props);



        return (
            <>
                <h1 className="CmsPage-Heading">{blockTitle}</h1>
                {
                    tabsHeader.map((item, index) => {

                        // let hex = 'FFF9ED';
                        // let hex = 'E1F4F4';
                        let rgb = this.hexToRgb(item.uniq_pattern_background_color);


                        return (
                            <>
                                <div key={index} block="ProductList" elem="ProductListWidget">
                                    <div block="ProductList" elem="Info" style={ { background: `linear-gradient(180deg, rgba(${rgb},1) 0%, rgba(255,255,255,1) 100%)` } }>
                                        <h1>{item.title}</h1>
                                        {/*{item.description ? <Html content={item.description.replace('</p><p>',' ')} /> : '2'}*/}
                                        {item.description ? <Html content={'<p>' + item.description + '</p>'}/> : ''}

                                        <div block="ProductList" elem="Marks">

                                            {this.renderMarks(item)}
                                        </div>
                                        <div block="ProductList" elem="Control">
                                                <span className="uniqButton" id={'uniqLeft-' + item.url}>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#F0EFEF"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#F0EFEF"/>
<path d="M22 24L18 20L22 16" stroke="#2F2D2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                                </span>
                                            <Link
                                                to={item.url}
                                                className="Unique-Link Button AddToCart"
                                                // id={ item_id }
                                                // onMouseEnter={ handleCategoryHover }
                                                // onMouseLeave={ handleLinkLeave }
                                                // mods={ { isHovered } }
                                                onClick={this.onItemClick.bind(this)}
                                            >
                                                View all {item.title}
                                            </Link>
                                            <span className="uniqButton" id={'uniqRight-' + item.url}>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#F0EFEF"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#F0EFEF"/>
<path d="M18 24L22 20L18 16" stroke="#2F2D2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                                </span>
                                            {/*<div className="swiper-button-prev"*/}
                                            {/*     id={'uniqLeft-' + item.url}></div>*/}
                                            {/*<div className="swiper-button-next"*/}
                                            {/*     id={'uniqRight-' + item.url}></div>*/}
                                        </div>

                                    </div>
                                    <div block="ProductList" elem="Items">{this.renderMobileSlider(item.url, item.title)}</div>
                                </div>
                            </>
                        )

                    })
                }
            </>
        )
    }

    hexToRgb(hex) {
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;

        return r + "," + g + "," + b;
    }

    renderMarks(item) {

        const {device: {isMobile}} = this.props;

        let rows;
        let size;
        if (isMobile) {
            rows = 3;
            size = 16;
        }else {
            rows = item.uniq_pattern_rows;
            size = item.uniq_pattern_size;
        }
        const columns = item.uniq_pattern_columns;
        const stepDown = item.uniq_pattern_size_step_down;

        // console.log(this.hexToRgb('FFF9ED'));

        let grid = [];
        for (let i = 0; i < rows; i++) {
            let row = [];
            for (let j = 0; j < columns; j++) {
                row.push(<td key={j} style={{height:size}}>
                    <svg width={size} height={size} viewBox={size} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d={`M${size/2} 0L${size} ${size/2}L${size/2} ${size}L0 ${size/2}L${size/2} 0Z`} fill={`url(#category-${item.title.replace(/\s/g, "")})`}/>
                        <defs>
                            <linearGradient id={`category-${item.title.replace(/\s/g, "")}`} x1={size / 2} y1="0" x2={size / 2} y2={size}
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color={item.uniq_pattern_stop_color_one} stop-opacity={item.uniq_pattern_stop_opacity_one}/>
                                <stop offset="1" stop-color={item.uniq_pattern_stop_color_two} stop-opacity={item.uniq_pattern_stop_opacity_two}/>
                            </linearGradient>
                        </defs>
                    </svg>
                </td>);
            }
            grid.push(<tr key={i}>{row}</tr>);
            size = size - stepDown;
        }
        return grid;
    }

    _renderProductCard(product, i) {
        // product.options = (product.options) ? product.options : [];
        // product.configurable_options = {};
        const fullProduct = getIndexedProduct(product);

        const {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;
        const {
            setSiblingsHaveBrands,
            setSiblingsHavePriceBadge,
            setSiblingsHaveTierPrice,
            setSiblingsHaveConfigurableOptions
        } = this.props;

        const {id = i} = fullProduct;

        return (
            <ProductCard
                block="ProductLinks"
                elem="Card"
                product={fullProduct}
                key={id}
                siblingsHaveBrands={siblingsHaveBrands}
                siblingsHavePriceBadge={siblingsHavePriceBadge}
                siblingsHaveTierPrice={siblingsHaveTierPrice}
                siblingsHaveConfigurableOptions={siblingsHaveConfigurableOptions}
                setSiblingsHaveBrands={setSiblingsHaveBrands}
                setSiblingsHavePriceBadge={setSiblingsHavePriceBadge}
                setSiblingsHaveTierPrice={setSiblingsHaveTierPrice}
                setSiblingsHaveConfigurableOptions={setSiblingsHaveConfigurableOptions}
            />
        );
    }

    renderMobileSlider(uniqSwiper, title) {

        if (typeof this.state[title] === 'undefined') {
            return <Loader isLoading={true}/>;
        }
        const items = this.state[title];
        // const {items} = this.state;
        const { device: {isMobile}} = this.props;
        return (
            <Swiper
                className={(items.length > 4) ? 'WithPager' : ''}
                // effect={"fade"}
                // navigation={true}
                pagination={{
                    clickable: true,
                }}
                pagination={false}
                // loop={true}
                // loopFillGroupWithBlank={true}
                navigation={{
                    prevEl: '#uniqLeft-' + uniqSwiper,
                    nextEl: '#uniqRight-' + uniqSwiper
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                modules={[Autoplay, Navigation, Pagination]}
                // className="mySwiper"
                spaceBetween={(isMobile) ? 16:24}
                slidesPerView={4}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    375: {
                        slidesPerView: 2,
                    },
                    580: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    811: {
                        slidesPerView: 2,
                    },
                    1180: {
                        slidesPerView: 3,
                    },
                    // 1440: {
                    //     slidesPerView: 4,
                    // },
                }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
            >

                {
                    // this.renderMobileSlider()
                    items.map((product, index) => {
                        // product.options = (product.options) ? product.options : [];
                        return <SwiperSlide>{this.renderProductCard(product, index)}</SwiperSlide>
                    })
                }
            </Swiper>

        );

    }

    onItemClick() {
        const {currentUrl} = this.state;
        history.push(currentUrl);
        history.replace(currentUrl);
    }

    _getKbArticles(blockId) {

        fetchQuery(
            KbCategoriesQuery.getCategories(false, false, false, true)
        ).then(
            (
                async (data) => {

                    const {kbCategories: {kb_categories} = {}} = data;

                    let kbCategories = JSON.parse(kb_categories);
                    // console.clear();
                    // console.log(blockId);
                    // console.log(kbCategories);
                    this.setState({
                        isLoading: false,
                        cmsBlock: {
                            identifier:'frequently-asked-questions',
                            title:'Frequently Asked Questions',
                            articles: kbCategories
                        }
                    });



                }),
            (err) => {
                // console.log(err);
                // this.setState({isLoading: false});
            }
        );

    }

    _getCmsBlock(blockId) {

        if (window['uniquesCategory' + blockId]) {
            this.setState({isLoading: false, cmsBlock: window['uniquesCategory' + blockId]});
            return;
        }
        fetchQuery(
            UniquesQuery.getStaticBlock({identifiers: [blockId]})
        ).then(
            (
                async (data) => {
                    const {cmsBlocks: {items}} = data;
                    if (items.length) {
                        // console.log(items[0]);
                        this.setState({isLoading: false, cmsBlock: items[0]});
                        window['uniquesCategory' + blockId] = items[0];
                    }
                }),
            (err) => {
                // console.log(err);
            }
        );

    }

    renderItemsSliderLoadingSkeleton(mobileSlidesPerView = 1, wrapper = '') {
        let items = ['1', '2', '3', '4', '5'];
        const { device: {isMobile}} = this.props;

        let pageWidth = getPageWidth();
        let pagerClass;
        if (pageWidth > 1280) {
            pagerClass = ' ';
        }else {
            pagerClass = ' WithPager';
        }


        // console.clear();
        // console.log(pageWidth);
        // console.log(this.state);
        // console.log(this.props);
        // console.log(wrapper);

        let removeParagraph = false;
        let desktopSlidesPerView = 4;

        if (isMobile && wrapper === 'veg_fresh_fast' || 'worry-free-delivery-and-returns') {
            removeParagraph = true;
        }

        if (!isMobile && wrapper === 'worry-free-delivery-and-returns') {
            desktopSlidesPerView = 4;
        }

        if (!isMobile && wrapper === 'world_of_culinary_delights') {
            desktopSlidesPerView = 4;
        }

        return (
            <div block="Uniques" elem="Wrapper" mix={{block:'dynamic-widget-wrapper '}}>
                <div block="CmsPage" elem="SectionPlaceholder" mix={ { block: 'Title' } }>title</div>
            <Swiper
                // className={(items.length > mobileSlidesPerView) ? wrapper + ' WithPager' : wrapper}
                className={wrapper + pagerClass}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                modules={[Autoplay, Navigation, Pagination]}
                spaceBetween={(isMobile) ? 16:24}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    320: {
                        slidesPerView: mobileSlidesPerView,
                    },
                    375: {
                        slidesPerView: 2,
                    },
                    580: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    811: {
                        slidesPerView: 3,
                    },
                    // 1024: {
                    //     slidesPerView: 3,
                    // },
                    1160: {
                        slidesPerView: 4,
                    },
                    1280: {
                        slidesPerView: desktopSlidesPerView,
                    },
                }}
            >

                {
                    items.map((item, index) => {
                        return <SwiperSlide>
                            <div block="SectionPlaceholder" elem="Wrapper">
                            <div mix={ { block: 'dynamic-widget-item-icon' } }>
                                <img block="CmsPage" elem="SectionPlaceholder" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="/>
                            </div>
                            <div block="CmsPage" elem="SectionPlaceholder" mix={ { block: 'dynamic-widget-item-title' } }>title</div>
                            <div block="CmsPage" elem="SectionPlaceholder" mix={ { block: 'dynamic-widget-item-text' } }><p>text</p></div>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
            </div>
        )
    }

    renderItemsSlider(items, mobileSlidesPerView = 5, wrapper = '') {
        const { device: {isMobile}} = this.props;

        let pageWidth = getPageWidth();
        let pagerClass;
        if (pageWidth > 1280) {
            pagerClass = ' ';
        }else {
            pagerClass = ' WithPager';
        }

        // console.log(wrapper);
        let removeParagraph = false;
        let desktopSlidesPerView = 5;

        if (isMobile && wrapper === 'veg_fresh_fast' || 'worry-free-delivery-and-returns') {
            removeParagraph = true;
        }

        if (!isMobile && wrapper === 'worry-free-delivery-and-returns') {
            desktopSlidesPerView = 4;
        }
// console.clear();
//         const detectPagination = document.querySelectorAll('.HomePage .dynamic-widget-wrapper.veg_fresh_fast .swiper-pagination-bullet').length;
//         console.log(detectPagination);
        try {
            const qualityItsDivah = document.querySelector('.HomePage .dynamic-widget-wrapper.veg_fresh_fast .swiper-wrapper').value;
            console.log(qualityItsDivah);
        }catch (e) {

        }

//         console.log(items);
//         console.log(mobileSlidesPerView);

        return (
            <Swiper
                // className={(items.length > mobileSlidesPerView) ? wrapper + ' WithPager' : wrapper}
                className={wrapper + pagerClass}
                pagination={{
                    clickable: true,
                }}
                pagination={false}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                modules={[Autoplay, Navigation, Pagination]}
                spaceBetween={(isMobile) ? 12:18}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    320: {
                        slidesPerView: mobileSlidesPerView,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                    811: {
                        slidesPerView: 3,
                    },
                    // 1024: {
                    //     slidesPerView: 3,
                    // },
                    1160: {
                        slidesPerView: 4,
                    },
                    1280: {
                        slidesPerView: desktopSlidesPerView,
                    },
                }}
            >

                {
                    items.map((item, index) => {
                        return <SwiperSlide><Html content={(removeParagraph) ? item.replace('</p><p>',' ') : item}/></SwiperSlide>
                    })
                }
            </Swiper>
        )
    }

    renderDynamicWidget(cmsBlock){
        const newDiv = document.createElement("div");
        newDiv.innerHTML = cmsBlock.content;
        const elements = newDiv.querySelectorAll('.dynamic-widget-item');
        const values = [].map.call(elements, function (e) {
            return e.innerHTML.replace(/(\r\n|\n|\r)/gm, "");
        });

        let widgetName = 'dynamic-widget-wrapper ' + cmsBlock.identifier;
        let style = {}
        if (cmsBlock.identifier === 'worry-free-delivery-and-returns') {
            style = {
                margin: '96px 0 60px',
                padding: '60px 0 60px',
                backgroundImage: 'url(' + wave +')',
                backgroundSize: 'cover',
                backgroundPositionY: 'center'
            };
        }

        return (
            <>
                <div className={widgetName} style={style}>
                    {/*{*/}
                    {/*    (cmsBlock.identifier === 'worry-free-delivery-and-returns') ? <img src={wave}/> : ''*/}
                    {/*}*/}
                    {/*<div className="wrapper">*/}
                    <h1 className="CmsPage-Heading">{cmsBlock.title}</h1>
                    {/*{cmsBlock.identifier === 'world_of_culinary_delights' ?*/}
                    {/*    <h1 className="CmsPage-Heading">{cmsBlock.title}</h1>*/}
                    {/*    :*/}
                    {/*    <h1>{cmsBlock.title}</h1>*/}
                    {/*}*/}

                    {this.renderItemsSlider(values, 1, cmsBlock.identifier)}
                    {/*</div>*/}
                </div>
            </>
        )
    }

    renderHomePageFaq(cmsBlock){
        // return 'faq data'
        // console.clear();
        const {articles} = cmsBlock;
        const {kb_general_base_url} = this.props;
        // console.log(this.state);
        // console.log(this.props);
        console.log(articles);
        console.log(kb_general_base_url);
        // const newDiv = document.createElement("div");
        // newDiv.innerHTML = cmsBlock.content;
        // const elements = newDiv.querySelectorAll('[data-content-type="faq-item"]');
        // // console.log(elements);
        // const values = [].map.call(elements, function (e) {
        //     return '<li data-content-type="faq-item">' + e.innerHTML.replace(/(\r\n|\n|\r)/gm, "") + '</li>';
        // });


        // console.log(cmsBlock);
        // console.log(values);
      //   values.map((item, index) => {
      //       return (
      //           console.log(item)
      //       )
      //   })
      // return 'asd'
      //   return 'faq data'
        return (
            <div id="home-page-faq-wrapper">
                <h1 className="CmsPage-Heading">{cmsBlock.title}</h1>
                <ul data-content-type="faq" id="home-page-faq">
                    {
                        articles.map((item, index) => {
                            // console.log(item);
                            return (
                                <>
                                    <li data-content-type="faq-item">
                                        <h3 data-collapsible="true" data-element="question">{item.name}</h3>
                                        <div data-element="answer"><Html content={item.text} /></div>
                                    </li>
                                </>
                            )
                                // return (
                                //     <Html content={item} />
                                // )
                        })
                    }
                </ul>
                {/*<ul data-content-type="faq">*/}
                {/*    {*/}
                {/*        values.map((item, index) => {*/}
                {/*            if (index < 5) { //limit to 5 faq's on home page*/}
                {/*                return (*/}
                {/*                    <Html content={item} />*/}
                {/*                )*/}
                {/*            }*/}
                {/*        })*/}
                {/*    }*/}
                {/*</ul>*/}
                <div style={{display:'none'}}>
                {
                    setTimeout(function(){
                        const acc = document.querySelectorAll('h3[data-element="question"]');
                        let i;
                        for (i = 0; i < acc.length; i++) {
                            // console.log(acc[i]);
                            if (acc[i].getAttribute('listener') !== 'true') {
                                acc[i].addEventListener("click", function () {
                                    this.classList.toggle("active");
                                    const panel = this.nextElementSibling;
                                    if (panel.style.maxHeight) {
                                        panel.style.maxHeight = null;
                                    } else {
                                        panel.style.maxHeight = panel.scrollHeight + "px";
                                    }
                                });
                                acc[i].setAttribute('listener', 'true');
                            }
                        }
                    }, 1)
                }
                </div>
                <Link
                    to={kb_general_base_url}
                    // to='faq'
                    className="All-Faq-Link"
                    // id={ item_id }
                    // onMouseEnter={ handleCategoryHover }
                    // onMouseLeave={ handleLinkLeave }
                    // mods={ { isHovered } }
                    onClick={this.onItemClick.bind(this)}
                >
                    All FAQs <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19" stroke="#6B2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 5L19 12L12 19" stroke="#6B2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                </Link>
            </div>
        )
    }

    renderFaqLoadingSkeleton() {
        return (
            <>
            <div block="Uniques" elem="Wrapper" mix={{block:'dynamic-widget-wrapper '}}>
                <div block="CmsPage" elem="SectionPlaceholder" mix={ { block: 'Title' } }>title</div>
                <ul data-content-type="faq" id="home-page-faq">
                    {Array.apply(0, Array(5)).map(function (x, i) {
                        return (
                            <li key={i} data-content-type="faq-item">
                                <span block="CmsPage" elem="SectionPlaceholder loading" >question</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
            </>
        );
    }

    categoryUniqLoadingSkeleton() {
        const {
            device: {isMobile},
        } = this.props;

        return (
            <div id="UniquesCategory" block="HomePage" elem="Content" mix={{block: 'loading'}}>
            {/*<div id="UniquesCategory" mix={{block: 'loading'}}>*/}
                <h1 block="CmsPage" elem="SectionPlaceholder loading" className="CmsPage-Heading">Divah Uniques</h1>
                <div block="CmsPage" elem="SectionPlaceholder tabs-header" role="tablist" className="rwt__tablist "/>
                <div id="uniq-loading">
                    {Array.apply(0, Array((isMobile) ? 2:4)).map(function (x, i) {
                        return (
                            <div key={i} block="UniquesCategory" elem="LoadingWrapper">
                                <div block="CmsPage" elem="SectionPlaceholder "/>
                                <span block="CmsPage" elem="SectionPlaceholder loading name"></span>
                                <span block="CmsPage" elem="SectionPlaceholder loading price"></span>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    render() {
        const {blockId} = this.props;
        const {isLoading, cmsBlock, items} = this.state;
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        // console.log(cmsBlock);
        // return <div block="CmsPage" elem="SectionPlaceholder"/>;
        // return this.renderFaqLoadingSkeleton();
        // return this.categoryUniqLoadingSkeleton();
        // return this.renderItemsSliderLoadingSkeleton(1, 'veg_fresh_fast');
        // return (
        //     <>
        //         { this.renderItemsSliderLoadingSkeleton(1, 'veg_fresh_fast')}
        //         {this.renderFaqLoadingSkeleton()}
        //     </>
        // )
        if (isLoading) {
            if (blockId === 8) {
                return this.renderItemsSliderLoadingSkeleton(1, 'world_of_culinary_delights');
            }
            if (blockId === 19) {
                return this.renderItemsSliderLoadingSkeleton(1, 'veg_fresh_fast');
            }
            if (blockId === 20) {
                return this.renderItemsSliderLoadingSkeleton(1, 'worry-free-delivery-and-returns');
            }
            if (blockId === 21) {
                return this.renderFaqLoadingSkeleton();
            }
            if (!items.length) {
                return this.categoryUniqLoadingSkeleton();
            }
            // 'veg_fresh_fast' || 'worry-free-delivery-and-returns'
            return <div block="CmsPage" elem="SectionPlaceholder"/>;
            // return <div block="CmsPage" elem="SectionPlaceholder"/>;
            // return <Loader isLoading={isLoading}/>;
        }
        // return this.renderItemsSliderLoadingSkeleton();
        // return <div block="CmsPage" elem="SectionPlaceholder"/>;

        // console.log(this.state);
        // console.log(cmsBlock);

        if (typeof cmsBlock !== 'undefined') {

            if (cmsBlock.identifier === 'world_of_culinary_delights' || cmsBlock.identifier === 'veg_fresh_fast' || cmsBlock.identifier === 'worry-free-delivery-and-returns') {
                    return this.renderDynamicWidget(cmsBlock);

            }

            if (cmsBlock.identifier === 'frequently-asked-questions') {
                // console.log(cmsBlock);
                // return 'miras kb'
                return this.renderHomePageFaq(cmsBlock);
            }


            return <Html content={cmsBlock.content}/>;


        }

        setTimeout(() => {
            DecorateHeading();
        }, 10);

        return (
            <>
                {/*<div id='UniquesCategory' mix={{block: 'HomePage', elem: 'Content'}}>*/}
                <div id='UniquesCategory'>
                    {this.renderTabsSwitcher()}
                </div>
                {}
            </>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UniquesCategoryProductsWidgetContainer);
