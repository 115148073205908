/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';
import {connect} from 'react-redux';
import CustomRequestPopupQuery from './../../query/FeedBackTab.query';
import {showNotification} from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import {fetchQuery} from '@scandipwa/scandipwa/src/util/Request/Query';
import {showPopup} from "@scandipwa/scandipwa/src/store/Popup/Popup.action";
import CustomRequestPopup from './CustomRequestPopup.component';
import {TicketDispatcher} from "../MyAccountMyTickets/MyAccountMyTickets.container";
import browserHistory from '@scandipwa/scandipwa/src/util/History';

/** @namespace Component/CustomRequestPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Component/CustomRequestPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getTicketList: (page) => TicketDispatcher.then(
        ({default: dispatcher}) => dispatcher.requestTickets(dispatch, page)
    ),
    showContactUs: (payload) => dispatch(showPopup('NewContactUsPopup', payload)),
    // showCustomRequest: (payload) => (!payload.hasOwnProperty('customPopUpId')) ? dispatch(showPopup('NewCustomRequestPopup', payload)) : dispatch(showPopup(payload.customPopUpId, payload))
    // showCustomRequest: (payload) => dispatch(showPopup(payload.customPopUpId, payload)),
    // showCustomRequest: (payload) => dispatch(showPopup('NewCustomRequestPopup', payload)),
    showCustomRequest: (payload) => {
        if (typeof payload === 'undefined') {
            dispatch(showPopup('NewCustomRequestPopup', payload))
        }else {
            dispatch(showPopup(payload.customPopUpId, payload))
        }
    },
});

/** @namespace Component/CustomRequestPopup/Container */
export class CustomRequestPopupContainer extends PureComponent {

    state = {
        items: [],
        isLoading: false,
    };

    containerFunctions = {
        onButtonClick: this.onButtonClick.bind(this)
    };

    componentDidMount() {
        this.requestDownloadable();
    }

    containerProps = () => {
        const {isLoading} = this.state;
        const {showContactUs, showCustomRequest, getTicketList, showNotification, contactUs, onlyAddNewTicket = false} = this.props;
        let items = this._prepareDownloadableProps();

        if (onlyAddNewTicket) {
            items.total_required = onlyAddNewTicket.total_required;
        }
        return {
            isLoading,
            items:items,
            showContactUs,
            showCustomRequest,
            getTicketList,
            showNotification,
            contactUs,
            onlyAddNewTicket
        };
    };

    _prepareDownloadableProps() {
        const {items} = this.state;
        return items;
    }

    async requestDownloadable(action = false, productId = 0) {
        const {showSuccessNotification, showErrorNotification} = this.props;
        this.setState({isLoading: true});
        await fetchQuery(
            CustomRequestPopupQuery.getFeedBackTab('showFeedbackTabIfEnabled')
        ).then(
            /** @namespace Component/CustomRequestPopup/Container/requestDownloadable/success */
            (
                /** @namespace Component/CustomRequestPopup/Container/CustomRequestPopupContainer/requestDownloadable/fetchQuery/then */
                (data) => {
                    let items = JSON.parse(data.ticketsList.tickets_list);
                    this.setState({items, isLoading: false});
                }),
            /** @namespace Component/CustomRequestPopup/Container/CustomRequestPopupContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                this.setState({isLoading: false});
            }
        );
    }

    onButtonClick(e) {

        const { showContactUs, showCustomRequest, onlyAddNewTicket } = this.props;
        const { items } = this.state;

        let customPopUpId = 'NewCustomRequestPopup';

        if (typeof onlyAddNewTicket.customPopUpId !== 'undefined') {
            customPopUpId = customPopUpId + '-' + onlyAddNewTicket.customPopUpId;
        }

        if (onlyAddNewTicket) {
            showCustomRequest({
                customPopUpId: customPopUpId,
                title: onlyAddNewTicket.ticketTitle
            });
        }else {
            showContactUs({
                title: items.form_title,
            });
        }
    }

    render() {
        return (
            <CustomRequestPopup
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomRequestPopupContainer);
