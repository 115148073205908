/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from 'Util/Query';

export class AdvancedReviewQuery {
    getMyReviews(
        myReviews = false,
        page = false,
        action = '',
    ) {
        return new Field('amastyReview')
            .addFieldList([
                'amasty_review'
            ])
            .addArgument('myReviews', 'Boolean', myReviews)
            .addArgument('page', 'Int', page)
            .addArgument('action', 'String', action)
    }

}

export default new AdvancedReviewQuery();
