/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from 'Util/Query';

export class Uniques {
    getCategories() {
        return new Field('uniques').addFieldList([
            'uniques'
        ]);
    }

    getStaticBlock({ identifiers }) {
        if (!identifiers) {
            throw new Error('Missing argument `options`');
        }

        return new Field('cmsBlocks')
            .addArgument('identifiers', '[String]', identifiers)
            .addField(this._getItemsField())
            .setAlias('cmsBlocks');
    }

    _getItemFields() {
        return [
            'title',
            'content',
            'identifier',
            'disabled'
        ];
    }

    _getItemsField() {
        return new Field('items')
            .addFieldList(this._getItemFields());
    }
}

export default new Uniques();
