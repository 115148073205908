export const COMMON_SUBSCRIPTION = 'my-subscriptions';

const tabMap = (member) => {

    member[COMMON_SUBSCRIPTION] = {
        url: '/my-subscriptions',
        tabName: __('My Subscriptions')
    };

    return member;
};


export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: tabMap
        }
    }
};
