/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';
import {connect} from 'react-redux';
import AccountPrivacyPolicyPopup from './AccountPrivacyPolicyPopup.component';

/** @namespace Component/AccountPrivacyPolicyPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Component/AccountPrivacyPolicyPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Component/AccountPrivacyPolicyPopup/Container */
export class AccountPrivacyPolicyPopupContainer extends PureComponent {

    render() {
        return (
            <AccountPrivacyPolicyPopup/>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPrivacyPolicyPopupContainer);
