import {
    MyAccountOrderInformation as SourceMyAccountOrderInformation,
} from 'SourceComponent/MyAccountOrderInformation/MyAccountOrderInformation.component';

import './MyAccountOrderInformation.style';

export class MyAccountOrderInformation extends SourceMyAccountOrderInformation {
    // TODO implement logic
};

export default MyAccountOrderInformation;
