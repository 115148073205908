import {PureComponent} from 'react';
import Loader from "Component/Loader/Loader.component";

import './Mostviewed.style';
import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from 'Component/ProductCard';

import Decoration from "Component/Decoration/Decoration.component";
import '@scandipwa/scandipwa/src/route/CmsPage/CmsPage.style.scss';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, FreeMode } from "swiper";
// Import Swiper styles
// import 'swiper/swiper-bundle.css';
import { getIndexedProduct } from 'Util/Product';
import Image from 'Component/Image';
import {ReactElement} from "@scandipwa/scandipwa/src/type/Common.type";
import Link from "@scandipwa/scandipwa/src/component/Link";
import {ImageRatio} from "@scandipwa/scandipwa/src/component/Image/Image.type";

export class Mostviewed extends PureComponent {

    renderProductCard = this._renderProductCard.bind(this);

    _renderProductCard(product, i, inFrame = false) {

        // product.options = (product.options) ? product.options : [];
        // product.configurable_options = {};
        const fullProduct = getIndexedProduct(product);

        const {
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            productCardFunctions: {
                setSiblingsHaveBrands,
                setSiblingsHavePriceBadge,
                setSiblingsHaveTierPrice,
                setSiblingsHaveConfigurableOptions
            }
        } = this.props;
        const {id = i} = fullProduct;

        return (
            <ProductCard
                inFrame = {inFrame}
                block="ProductLinks"
                elem="Card"
                product={fullProduct}
                key={id}
                siblingsHaveBrands={siblingsHaveBrands}
                siblingsHavePriceBadge={siblingsHavePriceBadge}
                siblingsHaveTierPrice={siblingsHaveTierPrice}
                siblingsHaveConfigurableOptions={siblingsHaveConfigurableOptions}
                setSiblingsHaveBrands={setSiblingsHaveBrands}
                setSiblingsHavePriceBadge={setSiblingsHavePriceBadge}
                setSiblingsHaveTierPrice={setSiblingsHaveTierPrice}
                setSiblingsHaveConfigurableOptions={setSiblingsHaveConfigurableOptions}
            />
        );
    }

    renderHeading(activeRule) {
        const {title, isLoading, linkType, activeRules} = this.props;
        if (isLoading || typeof activeRules === "undefined") {
            return (
                <h2 block="ProductLinks" elem="Title">
                </h2>
            );
        }
        // const renderItems = activeRules.filter(s => s.includes(linkType))[0];
        return (
            <>
                {/*<Decoration direction="left" angle="topLeft" type="type1"/>*/}
            <h1 block="CmsPage" elem="Heading">
                {(title) ? title : this.props.items[activeRule].title}
            </h1>
            </>
        );
    }

    renderProductCardInFrame(product) {
        const {
            id,
            name,
            image,
            url
        } = product;

        // `https://api.zippopotam.us/${countryId}/${value}`
        return (
            <div className="dynamic-widget-item">
                <Link
                    to={url}
                    // className="Unique-Link Button AddToCart"
                    // id={ item_id }
                    // onMouseEnter={ handleCategoryHover }
                    // onMouseLeave={ handleLinkLeave }
                    // mods={ { isHovered } }
                    // onClick={this.onItemClick.bind(this)}
                >
                    <svg width="100%" height="100%" viewBox="0 0 311 257" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <clipPath id={`inFrame${id}`}>
                            <path
                                d="M294.054 250.559C300.319 250.559 305.399 245.501 305.399 239.263V148.256C305.399 135.528 300.628 123.372 291.956 114.03C284.493 105.992 274.728 100.639 264.056 98.6599C261.074 87.8925 254.568 78.2436 245.618 71.4033V59.5297C245.618 52.6833 240.044 47.1337 233.168 47.1337H226.885V45.0257C226.885 38.591 222.484 33.0721 216.175 31.6033C178.949 22.9746 179.739 24.5418 165.641 10.1484C163.023 7.47499 159.524 6 155.795 6C152.067 6 148.246 7.62248 145.623 10.4495C129.889 27.3873 130.969 25.7894 96.0204 31.8061C89.3541 32.9554 84.5086 38.6648 84.5086 45.3883V47.1337H78.2311C71.3611 47.1337 65.7811 52.6956 65.7811 59.5297V71.4033C56.8309 78.2436 50.3189 87.8925 47.3437 98.6599C36.6714 100.639 26.9002 105.992 19.4438 114.03C10.7714 123.372 6 135.528 6 148.256V239.263C6 245.501 11.08 250.559 17.3451 250.559H294.048H294.054Z"
                                stroke="url(#paint2_linear_0_1)" stroke-width="2" stroke-miterlimit="10"/>
                        </clipPath>
                        <path
                            d="M295.578 256.416C303.831 256.416 310.516 249.733 310.516 241.497V148.245C310.516 134.077 305.219 120.545 295.596 110.145C288.078 102.019 278.584 96.3965 267.961 93.7579C264.616 83.9925 258.616 75.1888 250.733 68.4997V59.2398C250.733 49.308 242.672 41.2564 232.728 41.2564H231.703C230.364 33.7289 224.691 27.6132 216.994 25.8253C180.847 17.4162 182.501 19.4754 169.174 5.83213C165.501 2.07145 160.594 0 155.347 0C150.101 0 144.774 2.2749 141.095 6.24518C126.231 22.2928 128.311 20.1473 94.6269 25.9671C86.5654 27.3604 80.4174 33.5255 78.8804 41.2626H77.7817C67.8375 41.2626 59.776 49.3141 59.776 59.246V68.5059C51.8874 75.195 45.8875 83.9986 42.5481 93.7641C31.9312 96.4027 22.4314 102.025 14.9131 110.151C5.29614 120.545 0 134.077 0 148.245V241.497C0 249.74 6.69116 256.416 14.9378 256.416H295.578Z"
                            stroke="url(#paint0_linear_0_1)" stroke-miterlimit="10"/>

                        <path
                            d="M294.054 250.559C300.319 250.559 305.399 245.501 305.399 239.263V148.256C305.399 135.528 300.628 123.372 291.956 114.03C284.493 105.992 274.728 100.639 264.056 98.6599C261.074 87.8925 254.568 78.2436 245.618 71.4033V59.5297C245.618 52.6833 240.044 47.1337 233.168 47.1337H226.885V45.0257C226.885 38.591 222.484 33.0721 216.175 31.6033C178.949 22.9746 179.739 24.5418 165.641 10.1484C163.023 7.47499 159.524 6 155.795 6C152.067 6 148.246 7.62248 145.623 10.4495C129.889 27.3873 130.969 25.7894 96.0204 31.8061C89.3541 32.9554 84.5086 38.6648 84.5086 45.3883V47.1337H78.2311C71.3611 47.1337 65.7811 52.6956 65.7811 59.5297V71.4033C56.8309 78.2436 50.3189 87.8925 47.3437 98.6599C36.6714 100.639 26.9002 105.992 19.4438 114.03C10.7714 123.372 6 135.528 6 148.256V239.263C6 245.501 11.08 250.559 17.3451 250.559H294.048H294.054Z"
                            stroke="url(#paint2_linear_0_1)" stroke-width="2" stroke-miterlimit="10"/>
                        <image
                            width="100%"
                            height="116%"
                            href={image.url}
                            clip-path={`url(#inFrame${id})`}
                        />
                        <defs>
                            <linearGradient id="paint0_linear_0_1" x1="155.261" y1="257.033" x2="155.261" y2="-0.622668"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#D7BC6C"/>
                                <stop offset="0.26" stop-color="#B2994D"/>
                                <stop offset="0.54" stop-color="#B2994D"/>
                                <stop offset="0.77" stop-color="#F9E094"/>
                                <stop offset="1" stop-color="#B2994D"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_0_1" x1="155.703" y1="251.788" x2="155.703" y2="4.77085"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#D76C76"/>
                                <stop offset="0.26" stop-color="#B26D4D"/>
                                <stop offset="0.54" stop-color="#B24D53"/>
                                <stop offset="0.77" stop-color="#F9A694"/>
                                <stop offset="1" stop-color="#B24D53"/>
                            </linearGradient>
                        </defs>


                        <path
                            d="M294.054 250.559C300.319 250.559 305.399 245.501 305.399 239.263V148.256C305.399 135.528 300.628 123.372 291.956 114.03C284.493 105.992 274.728 100.639 264.056 98.6599C261.074 87.8925 254.568 78.2436 245.618 71.4033V59.5297C245.618 52.6833 240.044 47.1337 233.168 47.1337H226.885V45.0257C226.885 38.591 222.484 33.0721 216.175 31.6033C178.949 22.9746 179.739 24.5418 165.641 10.1484C163.023 7.47499 159.524 6 155.795 6C152.067 6 148.246 7.62248 145.623 10.4495C129.889 27.3873 130.969 25.7894 96.0204 31.8061C89.3541 32.9554 84.5086 38.6648 84.5086 45.3883V47.1337H78.2311C71.3611 47.1337 65.7811 52.6956 65.7811 59.5297V71.4033C56.8309 78.2436 50.3189 87.8925 47.3437 98.6599C36.6714 100.639 26.9002 105.992 19.4438 114.03C10.7714 123.372 6 135.528 6 148.256V239.263C6 245.501 11.08 250.559 17.3451 250.559H294.048H294.054Z"
                            fill="url(#paint0_linear_1967_26312)" stroke="url(#paint1_linear_1967_26312)"
                            stroke-width="2" stroke-miterlimit="10"/>
                        <defs>
                            <linearGradient id="paint0_linear_1967_26312" x1="155.7" y1="6" x2="155.7" y2="277.33"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0.595234" stop-opacity="0"/>
                                <stop offset="0.93643" stop-opacity="0.92"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_1967_26312" x1="155.703" y1="251.788" x2="155.703"
                                            y2="4.77085" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#D76C76"/>
                                <stop offset="0.26" stop-color="#B26D4D"/>
                                <stop offset="0.54" stop-color="#B24D53"/>
                                <stop offset="0.77" stop-color="#F9A694"/>
                                <stop offset="1" stop-color="#B24D53"/>
                            </linearGradient>
                        </defs>
                    </svg>


                    <span>{name}</span>
                </Link>
                {/*<a href={url}>*/}

                {/*<Image*/}
                {/*    src={image.url}*/}
                {/*    mix={{*/}
                {/*        // block: 'CartItem',*/}
                {/*        // elem: 'Picture',*/}
                {/*        // mods: {*/}
                {/*        //     isNotAvailable, isMobileLayout,*/}
                {/*        // },*/}
                {/*    }}*/}
                {/*    ratio={ImageRatio.IMG_CUSTOM}*/}
                {/*    alt={`Product ${name} thumbnail.`}*/}
                {/*/>*/}
                {/*<img*/}
                {/*    // style={ { display: 'none' } }*/}
                {/*    alt={name}*/}
                {/*    src={image.url}*/}
                {/*/>*/}
                {/*</a>*/}
            </div>
        )

    }

    renderMostViewed(activeRule) {

        const {items, isLoading, linkType, activeRules, isMobile, inFrame} = this.props;

        if (isLoading || !activeRules) {
            return null;
        }

        // if (inFrame) {
        //     return (
        //         <Swiper
        //             // className={(items.length > mobileSlidesPerView) ? wrapper + ' WithPager' : wrapper}
        //             className='world_of_culinary_delights'
        //             pagination={{
        //                 clickable: true,
        //             }}
        //             pagination={false}
        //             autoplay={{
        //                 delay: 2500,
        //                 disableOnInteraction: false,
        //                 pauseOnMouseEnter: true,
        //             }}
        //             modules={[Autoplay, Navigation, Pagination]}
        //             spaceBetween={(isMobile) ? 12 : 18}
        //             breakpoints={{
        //                 0: {
        //                     slidesPerView: 1,
        //                 },
        //                 320: {
        //                     slidesPerView: 2,
        //                 },
        //                 480: {
        //                     slidesPerView: 2,
        //                 },
        //                 811: {
        //                     slidesPerView: 3,
        //                 },
        //                 // 1024: {
        //                 //     slidesPerView: 3,
        //                 // },
        //                 1160: {
        //                     slidesPerView: 4,
        //                 },
        //                 1280: {
        //                     slidesPerView: 4,
        //                 },
        //             }}
        //         >
        //
        //             {
        //                 items[activeRule].items.map((product, index) => {
        //                     return <SwiperSlide>{this.renderProductCard(product, index, true)}</SwiperSlide>;
        //                 })
        //             }
        //         </Swiper>
        //     )
        // }


        return (
            <Swiper
                className={(items[activeRule].items.length > 4) ? 'WithPager' : ''}
                // effect={"fade"}
                navigation={false}
                // pagination={{
                //     clickable: true
                // }}
                pagination={false}
                // loop={true}
                // loopFillGroupWithBlank={true}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                //     pauseOnMouseEnter: true,
                // }}
                // centeredSlides={isMobile}
                // initialSlide={2}
                // modules={[Autoplay, Navigation, Pagination, FreeMode]}
                // className="mySwiper"
                spaceBetween={(isMobile) ? 16 : 24}
                slidesPerView={4}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                freeMode={true}
                breakpoints={
                    {
                        0: {
                            slidesPerView: 1,
                            pagination: {
                                enabled: false,
                            },
                            navigation: {
                                enabled: false
                            }
                        },
                        375: {
                            slidesPerView: 2,
                            pagination: {
                                enabled: false,
                            },
                            navigation: {
                                enabled: false
                            }
                        },
                        580: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        811: {
                            slidesPerView: 3,
                        },
                        1180: {
                            slidesPerView: 4,
                        },
                        1440: {
                            slidesPerView: 4,
                        },
                }
                }
            >

                {
                    items[activeRule].items.map((product, index) => {
                        // product.options = (product.options) ? product.options : [];
                        // console.log(product);
                        if (typeof product === 'undefined') {
                            return null;
                        }

                        return <SwiperSlide>{this.renderProductCard(product, index, inFrame)}</SwiperSlide>;


                    })
                }
            </Swiper>
        )

    }

    renderMostviewedLoadingSkeleton() {
        const {isMobile, ruleType, inFrame} = this.props;

        if (inFrame) {
            let items = ['1', '2', '3', '4', '5'];
            return (
                <section aria-label="Linked products" className="ProductLinks-MostViewed">
                    <div className="Uniques-Wrapper ContentWrapper">
                        <div block="CmsPage" elem="SectionPlaceholder" mix={{block: 'Title loading'}}>heading</div>
                        <Swiper
                            // className={(items.length > mobileSlidesPerView) ? wrapper + ' WithPager' : wrapper}
                            // className='world_of_culinary_delights'
                            // pagination={{
                            //     clickable: true,
                            // }}
                            pagination={false}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }}
                            modules={[Autoplay, Navigation, Pagination]}
                            spaceBetween={(isMobile) ? 12 : 18}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                320: {
                                    slidesPerView: 2,
                                },
                                480: {
                                    slidesPerView: 2,
                                },
                                811: {
                                    slidesPerView: 3,
                                },
                                // 1024: {
                                //     slidesPerView: 3,
                                // },
                                1160: {
                                    slidesPerView: 4,
                                },
                                1280: {
                                    slidesPerView: 4,
                                },
                            }}
                        >

                            {
                                items.map((product, index) => {
                                    return <SwiperSlide>
                                        <div className="skeletonWrapper inFrame">
                                            {Array.apply(0, Array(1)).map(function (x, i) {
                                                return (
                                                    <span key={index} block="CmsPage" elem="SectionPlaceholder"></span>
                                                )
                                            })}
                                        </div>
                                    </SwiperSlide>;
                                })
                            }
                        </Swiper>
                    </div>
                </section>
            )

        }


        return (
            <>
                <section aria-label="Linked products" className="ProductLinks-MostViewed">
                    <div className="Uniques-Wrapper ContentWrapper">
                        <div block="CmsPage" elem="SectionPlaceholder" mix={ { block: 'Title loading' } }>heading</div>
                        <div className="skeletonWrapper">
                            {Array.apply(0, Array(4)).map(function (x, i) {
                                return (
                                    <span key={i} block="CmsPage" elem="SectionPlaceholder">
                            </span>
                                )
                            })}
                        </div>
                    </div>
                </section>
            </>
        );
    }

    render() {
        // console.clear();
        // console.log(this.props);
        const {isLoading, items, activeRules, linkType, ruleType, inFrame} = this.props;

        if (isLoading) {
            return this.renderMostviewedLoadingSkeleton();
        }

        if (items.length === 0) {
            return null;
        }

        if (typeof activeRules === 'undefined') {
            return this.renderMostviewedLoadingSkeleton();
            return (
                <ContentWrapper
                    // mix={ { block: 'ProductLinks' } }
                    wrapperMix={ { block: 'Amasty', elem: 'Mostviewed' } }
                    label={ __('Amasty Mostviewed') }
                    // className="amasty-mostviewed"
                >
                    <Loader isLoading={isLoading}/>
                </ContentWrapper>
            )
        }

        return (
            <>
                {activeRules.map((activeRule, index) => {
                    // console.log(activeRule);
                    // console.log(ruleType);

                    if (inFrame) {
                        return (
                            <div
                                className='dynamic-widget-wrapper world_of_culinary_delights'
                                key={index}
                                // mix={{block: 'ProductLinks', elem: 'MostViewed'}}
                                // wrapperMix={{block: 'ProductLinks', elem: 'Wrapper'}}
                                // label={__('Linked products')}
                            >
                                {this.renderHeading(activeRule)}
                                <Loader isLoading={isLoading}/>
                                {this.renderMostViewed(activeRule)}
                                {/*{this.renderDecoration()}*/}
                            </div>
                        )
                    }

                    return (
                        <ContentWrapper
                            className=''
                            key={index}
                            mix={{block: 'ProductLinks', elem: 'MostViewed'}}
                            wrapperMix={{block: 'ProductLinks', elem: 'Wrapper'}}
                            label={__('Linked products')}
                        >
                            {this.renderHeading(activeRule)}
                            <Loader isLoading={isLoading}/>
                            {this.renderMostViewed(activeRule)}
                            {/*{this.renderDecoration()}*/}
                        </ContentWrapper>
                    )
                })
                }


            </>
        )
    }

    renderDecoration() {
        return null;
        return (
            <div className="most-viewed-deco">
                <Decoration direction="left" angle="bottomLeft" type="type1"/>
                <Decoration direction="center" angle="custom" type="type1"/>
                <Decoration direction="right" angle="custom" type="type1"/>
            </div>
        )
    }
}

export default Mostviewed;
