/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from '@scandipwa/scandipwa/src/util/Query';

export class HelpDeskQuery {

    getFeedBackTab(feedBackTab = '') {
        return new Field('ticketsList')
            .addFieldList([
                'tickets_list'
            ])
            .addArgument('feedBackTab', 'String', feedBackTab)
    }
}

export default new HelpDeskQuery();
