Mosaic.addPlugins([require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/amasty-advancedreview/src/plugin/PopUpComponent.plugin.js'),require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/mirasvit-helpdesk/src/plugin/PopupComponent.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { createPortal } from 'react-dom';

import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import NotificationList from 'Component/NotificationList';
import Overlay from 'Component/Overlay/Overlay.component';
import { ReactElement } from 'Type/Common.type';
import history from 'Util/History';

import { ESCAPE_KEY } from './Popup.config';
import { PopupComponentProps } from './Popup.type';

import './Popup.style';

/** @namespace Component/Popup/Component */
export class PopupComponent extends Overlay<PopupComponentProps> {
    static defaultProps: Partial<PopupComponentProps> = {
        ...Overlay.defaultProps,
        isCloseOnOutsideClick: true,
        title: '',
        successPopupContent: false
    };

    __construct(props: PopupComponentProps): void {
        super.__construct?.(props);

        this.hidePopUp = this.hidePopUp.bind(this);
        this.hidePopupAndGoBack = this.hidePopupAndGoBack.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('keydown', this.handleKeyDown.bind(this));
    }

    componentDidUpdate(prevProps: PopupComponentProps): void {
        const { shouldPopupClose, resetHideActivePopup } = this.props;
        const { shouldPopupClose: prevShouldPopupClose } = prevProps;

        if (shouldPopupClose && shouldPopupClose !== prevShouldPopupClose) {
            this.hidePopUp();
            resetHideActivePopup();
        }

        super.componentDidUpdate(prevProps);
    }

    componentWillUnmount(): void {
        document.removeEventListener('keydown', this.handleKeyDown.bind(this));
    }

    onVisible(): void {
        const { onVisible } = this.props;

        this.freezeScroll();
        this.overlayRef.current?.focus();

        window.addEventListener('popstate', this.hidePopUp);

        history.push(
            `${location.pathname}${location.search}${location.hash}`,
            { popupOpen: true },
        );

        onVisible?.();
    }

    onHide(): void {
        const { onHide } = this.props;

        window.removeEventListener('popstate', this.hidePopUp);

        this.unfreezeScroll();

        onHide?.();
    }

    hidePopUp(): void {
        const { hideActiveOverlay, goToPreviousNavigationState, onClose } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            this.unfreezeScroll();
            hideActiveOverlay();
            goToPreviousNavigationState();
            onClose?.();
        }
    }

    hidePopupAndGoBack(): void {
        const { isHistoryBackHandle } = this.props;

        this.hidePopUp();

        if (isHistoryBackHandle) {
            history.goBack();
        }
    }

    // Same with click outside
    handleClickOutside(): void {
        const { isCloseOnOutsideClick, isMobile } = this.props;

        if (!isCloseOnOutsideClick) {
            return;
        }

        if (isMobile) {
            return;
        }

        this.hidePopupAndGoBack();
    }

    handleKeyDown(e: KeyboardEvent): void {
        switch (e.keyCode) {
        case ESCAPE_KEY:
            this.hidePopupAndGoBack();
            break;
        default:
            break;
        }
    }

    renderTitle(): ReactElement {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h3 block="Popup" elem="Heading">
                { title }
            </h3>
        );
    }

    renderCloseButton(): ReactElement {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ this.hidePopupAndGoBack }
            >
                <CloseIcon />
            </button>
        );
    }

    renderNotifications(): ReactElement {
        const { isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return <NotificationList />;
    }

    renderContent(): ReactElement {
        const { children, contentMix , hasFooter, successPopupContent} = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        if (!!(successPopupContent)) {
            return (
                <ClickOutside onClick={this.handleClickOutside}>
                    <div block="Popup" elem="Content" mix={contentMix}>
                        <header block="Popup" elem="Header">
                            <div block="Popup" elem="SubmissionRecieved">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_382_17222)">
                                        <path
                                            d="M14.0004 28.0007C21.7326 28.0007 28.0007 21.7326 28.0007 14.0004C28.0007 6.26818 21.7326 0 14.0004 0C6.26818 0 0 6.26818 0 14.0004C0 21.7326 6.26818 28.0007 14.0004 28.0007Z"
                                            fill="#059E00"/>
                                        <path
                                            d="M11.9062 16.7395C12.1347 16.5227 12.2949 16.378 12.4469 16.2252C14.6584 14.0162 16.8688 11.8065 19.0783 9.596C19.2069 9.4667 19.3318 9.33151 19.4699 9.21323C19.9401 8.80841 20.4529 8.80106 20.8467 9.18604C21.2405 9.57103 21.2457 10.1639 20.8063 10.6055C18.0982 13.3263 15.3833 16.0395 12.6615 18.7452C12.1898 19.2139 11.6917 19.2066 11.2075 18.7312C9.86693 17.4142 8.53541 16.0836 7.21296 14.7396C6.75304 14.2709 6.75304 13.7265 7.14757 13.3239C7.5421 12.9212 8.14529 12.9506 8.60815 13.4113C9.69697 14.4935 10.7689 15.5904 11.9062 16.7395Z"
                                            fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_382_17222">
                                            <rect width="28" height="28" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                {this.renderTitle()}
                            </div>
                            {this.renderCloseButton()}
                        </header>
                        {successPopupContent}
                    </div>
                    {/*<div className="Popup-Back"></div>*/}
                </ClickOutside>
            );
        }

        let childrenContent;
        let childrenFooterContent = false;

        if (!hasFooter) {
            childrenContent = children;
        } else {
            // @ts-ignore
            childrenContent = [...children];
            childrenFooterContent = childrenContent.pop();
        }

        return (
            <ClickOutside onClick={this.handleClickOutside}>
                <div block="Popup" elem="Content" mix={contentMix} mods={{hasFooter:hasFooter}}>
                    <header block="Popup" elem="Header">
                        {this.renderTitle()}
                        {this.renderCloseButton()}
                    </header>
                    {this.renderNotifications()}
                    {childrenContent}
                </div>
                <div block="Popup" elem="Footer">
                    {childrenFooterContent}
                </div>
                {/*<div className="Popup-Back"></div>*/}
            </ClickOutside>
        );

        // return (
        //     <ClickOutside onClick={ this.handleClickOutside }>
        //         <div block="Popup" elem="Content" mix={ contentMix }>
        //             <header block="Popup" elem="Header">
        //                 { this.renderTitle() }
        //                 { this.renderCloseButton() }
        //             </header>
        //             { this.renderNotifications() }
        //             { children }
        //         </div>
        //     </ClickOutside>
        // );
    }

    render(): ReactElement {
        const { mix, areOtherOverlaysOpen } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix?.mods, isVisible } } }
            >
                { this.renderContent() }
            </div>,
            document.body,
        );
    }
}

export default PopupComponent;
