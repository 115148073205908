/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const _getOrderListFields = (args, callback) => {
    const fields = callback(...args);
    const field = fields[0];
    field.addArgument('sort', 'CustomerOrderSortInput', { sort_field: 'CREATED_AT', sort_direction: 'DESC' } );
    return fields;

};
export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderListFields
        }
    }
};
