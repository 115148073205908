/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// const defaultProps = (original) => ({
//     ...original,
//     clickOutside: false
// });
const defaultProps = (original) => {

    console.log(original);
    return original;
};
const handleClickOutside = (args, callback, instance) => {

    const {id} = instance.props;

    if (id === 'NewTicketPopup' || id === 'NewContactUsPopup' || id === 'RatePopup') {
        return;
    }

    return callback(...args);

    // if (!clickOutside) {
    //     return;
    // }
    // instance.hidePopupAndGoBack();
};

export default {
    'Component/Popup/Component': {
        'static-member': {
            // defaultProps
        },
        'member-function': {
            handleClickOutside
        }
    }
};
