import {PureComponent} from 'react';
import './MyReviews.style';
import {showNewReviewPopup} from 'Util/Product';
import ProductReviewRating from "./../ProductReviewRating";
import PopUpWithImages from "./../PopUpWithImages";
import Html from "Component/Html";
import Popup from "Component/Popup";
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination as SwiperPagination} from "swiper";
// Import Swiper styles
// import 'swiper/swiper-bundle.css';

import Pagination from 'Component/Pagination';
import Field from "Component/Field";
import {FieldType} from 'Component/Field/Field.config';
import CategorySort from 'Component/CategorySort';
import history from 'Util/History';

import ThumbsUpIcon from "../ThumbsUpIcon";
import ThumbsDownIcon from "../ThumbsDownIcon";
import CommentsIcon from "../CommentsIcon";
import AmExpandableContent from "../AmExpandableContent";
import {ValidationInputType} from "Util/Validator/Config";
import {wordEnding} from '../../../../../src/util/WordEnding';
import ChevronIcon from "Component/ChevronIcon";
import {Directions} from 'Component/ChevronIcon/ChevronIcon.config';
import Image from 'Component/Image';
import Loader from "Component/Loader/Loader.component";
import {ReactElement} from "@scandipwa/scandipwa/src/type/Common.type";
import Link from "@scandipwa/scandipwa/src/component/Link";
import {REVIEW_POPUP_ID} from "@scandipwa/scandipwa/src/component/ProductReviews/ProductReviews.config";
import ProductReviewForm from "../ProductReviewForm";

let reviewPopupData = {};
let totalReplyFields = 1;
let replyToValidate = {};
let reviewToReply = null;

export class MyReviews extends PureComponent {

    // static propTypes = {
    //     onFormError: PropTypes.func.isRequired
    // };

    state = {
        with_images: false,
        verified_buyer: false,
        is_recommended: false,
        reply_to_validate: 1
    }

    // handlePaginationLinkClick = this.handleClick.bind(this);
    //
    // handleClick({target}) {
    //     // console.clear();
    //     console.log(target);
    //     // const {history: {location}} = this.props;
    //     // history.push({pathname: window.location.pathname});
    //     // this.requestDownloadable();
    // }
    handleCheckboxChange = this._handleCheckboxChange.bind(this);
    handlePrevReview = this._handlePrevReview.bind(this);
    handleNextReview = this._handleNextReview.bind(this);

    componentDidMount() {
        // document.addEventListener('click', this.handleClick);
        // browserHistory.listen( location =>  {
        //     // console.clear();
        //     console.log('advanced review changes');
        // });
        // const Anchors = document.getElementsByClassName('Pagination-ListItem')
        //
        // for (let i = 0; i < Anchors.length; i++) {
        //     console.log(Anchors[i]);
        //     Anchors[i].addEventListener("click",
        //         this.handlePaginationLinkClick.bind(this),
        //         false);
        // }
    }

    _handleCheckboxChange(event, option) {

        const {onSortChange} = this.props;
        this.setState({[option.id]: false})
        // console.clear();
        onSortChange();
        // console.clear();
        // console.log(option);
        // console.log(this.state);
        // console.log(this.props);
        // this.renderHorizontalLogic2();

    }

    _handlePrevReview(event) {
        document.getElementById('swiper-button-prev-review-popup-placeholder').click();
        // console.clear();
        // console.log(event);
        // console.log(this.state);
        // console.log(this.props);
        // this.forceUpdate();

    }

    _handleNextReview(event) {
        document.getElementById('swiper-button-next-review-popup-placeholder').click();
        // console.clear();
        // console.log(event);
        // console.log(this.state);
        // console.log(this.props);
        // this.forceUpdate();

    }

    componentWillMount() {
        const {handlePaginationLinkClick} = this.props;
        document.addEventListener('click', handlePaginationLinkClick);
    }

    componentWillUnmount() {
        const {handlePaginationLinkClick} = this.props;
        document.removeEventListener('click', handlePaginationLinkClick);
    }

    componentDidUpdate() {
        // console.log('update');

    }


    // handleClick({target}) {
    //     // console.clear();
    //     console.log(target);
    //     console.log(target.getAttribute('href'));
    //     console.log(window.location.search);
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const page = urlParams.get('page') ? urlParams.get('page') : 1;
    //     console.log(page);
    //     // const {history: {location}} = this.props;
    //     // history.push({pathname: window.location.pathname});
    //     // this.requestDownloadable();
    // }

    // trueWordForm(num, form_for_1, form_for_2, form_for_5) {
    //     num = Math.abs(num) % 100; // take the number modulo and reset hundreds (divide by 100, and assign the remainder to the num variable)
    //     let num_x = num % 10; // reset tens and write to a new variable
    //     if (num > 10 && num < 20) // if the number belongs to the segment [11; 19]
    //         return form_for_5;
    //     if (num_x > 1 && num_x < 5) // otherwise if the number ends in 2,3,4
    //         return form_for_2;
    //     if (num_x === 1) // otherwise if it ends in 1
    //         return form_for_1;
    //     return form_for_5;
    // }

    reviewMyItem(name, sku) {
        window.myreviewsName = name;
        window.myreviewsSku = sku;
        this.forceUpdate();
        showNewReviewPopup();
    }
    renderButton(name, sku) {
        return (
            <a
                block="ProductReviews"
                elem="Button"
                // mix={{block: 'Button', mods: {amReview: true}}}
                // onClick={showNewReviewPopup}
                onClick={this.reviewMyItem.bind(this, name, sku)}
            >
                {__('Write a Review')}
            </a>
        );
    }

    renderRatingSchema(percent, reviewCount, singleReview = false) {

        if (singleReview) {
            return (
                <>
                    <meta itemProp="ratingValue" content={percent}/>
                    <meta itemProp="worstRating" content={0}/>
                    <meta itemProp="bestRating" content={100}/>
                </>
            );
        }

        return (
            <>
                <meta itemProp="ratingValue" content={percent}/>
                <meta itemProp="worstRating" content={0}/>
                <meta itemProp="bestRating" content={100}/>
                <meta itemProp="reviewCount" content={reviewCount}/>
            </>
        );
    }

    renderRatingData() {
        const {
            product: {
                review_summary: {
                    rating_summary,
                    review_count
                } = {}
            }
        } = this.props;

        const STARS_COUNT = 5;
        const PERCENT = 100;

        // eslint-disable-next-line no-mixed-operators
        const percent = parseFloat(STARS_COUNT * (rating_summary || 0) / PERCENT).toFixed(2);

        // if (!review_count) {
        //     return this.renderNoRating();
        // }

        return (
            <>
                {this.renderRatingSchema(rating_summary, review_count)}
                <ProductReviewRating
                    mix={{block: 'ProductReviews', elem: 'SummaryRating'}}
                    summary={rating_summary}
                />
            </>
        );
    }

    renderNoRating() {
        const {device: {isMobile}} = this.props;

        if (isMobile) {
            return (
                <span className='ampercent-no-rating-yet'>
                    {__('There are no reviews yet! Click button below to submit one!')}
                </span>
            );
        }

        return (
            <span className='ampercent-no-rating-yet'>
                {__('There are no reviews yet! Click button on the right to submit one!')}
            </span>
        );
    }

    renderSummary() {
        const {
            MyReviews,
            // product: {
            //     review_summary: {
            //         review_count
            //     } = {}
            // }
        } = this.props;

        const {
            rating_summary_value,
            recomended_percent,
            review_count,
        } = MyReviews;

        return (
            <>
                <div
                    block="ProductReviews"
                    elem="Summary"
                    itemType={review_count ? 'http://schema.org/AggregateRating' : null}
                    itemProp={review_count ? 'aggregateRating' : null}
                    itemScope={review_count ? true : null}
                >
                    {this.renderRatingData()}
                    {/*{ this.renderButton() }*/}
                </div>
                <span className='amreview-count'>
                    {/*{review_count + ' ' + this.trueWordForm(review_count, 'Review', 'Reviews', 'Reviews')}*/}
                    {review_count + ' ' + wordEnding(review_count, 'Review', 'Reviews', 'Reviews')}
                </span>
            </>
        );
    }

    detailedSummary() {

        const {isLoading, MyReviews} = this.props;

        if (isLoading) {
            return null;
        }

        const {
            review_count,
            detailed_summary = {}
        } = MyReviews;
        // console.log(detailed_summary);

        let bar;
        let barNumber;
        return Object.entries(detailed_summary).map((key) => {
            // console.log(key);
            barNumber = Math.round((key[1] / review_count * 100));
            bar = (review_count) ? barNumber + '%' : 0 + '%';
            return (
                <>
                    <div className='summary-details-wrapper'>
                    <span className="amreview-stars">
                        {key[0]}
                        {(key[0]) === '1' ? __(' star') : __(' stars')}
                    </span>
                        <div className='amreview-bar-wrapper'>
                            <div className="amreview-bar">
                                <div className="amreview-bar -active"
                                     style={{width: bar}}></div>
                            </div>

                        </div>
                        <span className="amreview-text">
                        {bar} ({key[1]})
                    </span>
                    </div>
                </>
            )
        })
    }

    renderNoPendingReviews() {
        // const { device } = this.props;
        //
        // return (
        //     <tr block="MyAccountMyOrders" elem="NoOrders">
        //         { /* eslint-disable-next-line no-magic-numbers */ }
        //         <td colSpan={ device.isMobile ? 3 : 4 }>{ __('You have no orders.') }</td>
        //     </tr>
        // );
        return (
            <div block="noPendingReviews">
                <div block="NoOrders" elem="Wrapper">
                    <svg width="80" height="62" viewBox="0 0 80 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M50.7128 45.6888L44.3264 44.7608L41.4705 38.9738C41.335 38.6993 41.1254 38.4682 40.8654 38.3066C40.6054 38.145 40.3054 38.0593 39.9992 38.0593C39.6931 38.0593 39.3931 38.145 39.1331 38.3066C38.8731 38.4682 38.6635 38.6993 38.528 38.9738L35.672 44.7608L29.2856 45.6888C28.9827 45.7328 28.6981 45.8607 28.464 46.058C28.23 46.2553 28.0558 46.5142 27.9611 46.8053C27.8665 47.0965 27.8553 47.4083 27.9287 47.7055C28.002 48.0027 28.1571 48.2735 28.3763 48.4872L32.9975 52.9919L31.9067 59.3525C31.855 59.6542 31.8887 59.9644 32.004 60.248C32.1194 60.5316 32.3117 60.7772 32.5594 60.9572C32.8071 61.1371 33.1001 61.2442 33.4055 61.2662C33.7108 61.2883 34.0162 61.2245 34.2872 61.082L39.9992 58.0786L45.7116 61.0817C45.9825 61.2243 46.288 61.2881 46.5933 61.2661C46.8987 61.244 47.1918 61.1369 47.4394 60.9569C47.6871 60.7769 47.8794 60.5313 47.9948 60.2477C48.1101 59.9641 48.1438 59.6539 48.092 59.3522L47.0011 52.9916L51.6224 48.487C51.8415 48.2733 51.9966 48.0026 52.0699 47.7054C52.1432 47.4081 52.1319 47.0963 52.0373 46.8052C51.9427 46.5141 51.7685 46.2552 51.5344 46.0579C51.3004 45.8606 51.0158 45.7327 50.7128 45.6888Z"
                            fill="#F4A509"/>
                        <path
                            d="M39.9992 38.0591C39.6931 38.0591 39.3931 38.1447 39.1331 38.3064C38.8731 38.468 38.6635 38.6991 38.528 38.9736L35.672 44.7606L29.2856 45.6886C28.9827 45.7327 28.6981 45.8606 28.464 46.0579C28.23 46.2552 28.0558 46.514 27.9611 46.8052C27.8665 47.0963 27.8553 47.4081 27.9287 47.7054C28.002 48.0026 28.1571 48.2733 28.3763 48.487L32.9975 52.9917L31.9067 59.3524C31.855 59.6541 31.8887 59.9643 32.004 60.2479C32.1194 60.5314 32.3117 60.7771 32.5594 60.957C32.8071 61.137 33.1001 61.244 33.4055 61.2661C33.7108 61.2882 34.0162 61.2243 34.2872 61.0819L39.9992 58.0786V38.0591Z"
                            fill="#F8BE4A"/>
                        <path
                            d="M24.1816 45.6888L17.7952 44.7608L14.9392 38.9738C14.8037 38.6993 14.5941 38.4682 14.3341 38.3066C14.0741 38.145 13.7741 38.0593 13.468 38.0593C13.1619 38.0593 12.8618 38.145 12.6018 38.3066C12.3418 38.4682 12.1322 38.6993 11.9967 38.9738L9.14079 44.7608L2.75439 45.6888C2.45144 45.7328 2.16685 45.8607 1.93279 46.058C1.69872 46.2553 1.52452 46.5142 1.42986 46.8053C1.3353 47.0965 1.32407 47.4083 1.39743 47.7055C1.4708 48.0027 1.62583 48.2735 1.84501 48.4872L6.46626 52.9919L5.37548 59.3525C5.32372 59.6542 5.35742 59.9644 5.47276 60.248C5.5881 60.5316 5.78048 60.7772 6.02815 60.9572C6.27581 61.1371 6.56889 61.2442 6.87422 61.2662C7.17956 61.2883 7.48498 61.2245 7.75595 61.082L13.4681 58.0789L19.1805 61.082C19.4514 61.2246 19.7569 61.2884 20.0622 61.2664C20.3676 61.2443 20.6607 61.1372 20.9083 60.9572C21.156 60.7772 21.3483 60.5316 21.4637 60.248C21.579 59.9644 21.6127 59.6542 21.5609 59.3525L20.47 52.9919L25.0913 48.4873C25.3105 48.2736 25.4656 48.0028 25.539 47.7056C25.6124 47.4083 25.6011 47.0964 25.5065 46.8052C25.4118 46.514 25.2376 46.2551 25.0034 46.0578C24.7693 45.8605 24.4846 45.7327 24.1816 45.6888Z"
                            fill="#F4A509"/>
                        <path
                            d="M13.468 38.0591C13.1618 38.0591 12.8618 38.1447 12.6018 38.3064C12.3418 38.468 12.1322 38.6991 11.9967 38.9736L9.14079 44.7606L2.75439 45.6886C2.45144 45.7327 2.16685 45.8606 1.93279 46.0579C1.69872 46.2552 1.52452 46.514 1.42986 46.8052C1.3353 47.0963 1.32407 47.4081 1.39743 47.7054C1.4708 48.0026 1.62583 48.2733 1.84501 48.487L6.46626 52.9917L5.37548 59.3524C5.32372 59.6541 5.35742 59.9643 5.47276 60.2479C5.5881 60.5314 5.78048 60.7771 6.02815 60.957C6.27581 61.137 6.56889 61.244 6.87422 61.2661C7.17956 61.2882 7.48498 61.2243 7.75595 61.0819L13.4681 58.0788L13.468 38.0591Z"
                            fill="#F8BE4A"/>
                        <path
                            d="M77.246 45.6888L70.8596 44.7608L68.0037 38.9738C67.8682 38.6993 67.6586 38.4682 67.3986 38.3066C67.1386 38.145 66.8386 38.0593 66.5324 38.0593C66.2263 38.0593 65.9263 38.145 65.6663 38.3066C65.4063 38.4682 65.1967 38.6993 65.0612 38.9738L62.2052 44.7608L55.8188 45.6888C55.5159 45.7328 55.2313 45.8607 54.9972 46.058C54.7632 46.2553 54.589 46.5142 54.4943 46.8053C54.3998 47.0965 54.3885 47.4083 54.4619 47.7055C54.5352 48.0027 54.6903 48.2735 54.9095 48.4872L59.5307 52.9919L58.4399 59.3525C58.3882 59.6542 58.4219 59.9644 58.5372 60.248C58.6526 60.5316 58.8449 60.7772 59.0926 60.9572C59.3403 61.1371 59.6333 61.2442 59.9387 61.2662C60.244 61.2883 60.5494 61.2245 60.8204 61.082L66.5326 58.0789L72.2449 61.082C72.5159 61.2246 72.8213 61.2884 73.1267 61.2664C73.432 61.2443 73.7251 61.1372 73.9727 60.9572C74.2204 60.7772 74.4128 60.5316 74.5281 60.248C74.6435 59.9644 74.6772 59.6542 74.6254 59.3525L73.5345 52.9919L78.1557 48.4873C78.375 48.2736 78.5301 48.0028 78.6035 47.7056C78.6768 47.4083 78.6656 47.0964 78.5709 46.8052C78.4763 46.514 78.302 46.2551 78.0679 46.0578C77.8337 45.8605 77.549 45.7327 77.246 45.6888Z"
                            fill="#F4A509"/>
                        <path
                            d="M66.5324 38.0591C66.2263 38.0591 65.9263 38.1447 65.6663 38.3064C65.4063 38.468 65.1967 38.6991 65.0612 38.9736L62.2052 44.7606L55.8188 45.6886C55.5159 45.7327 55.2313 45.8606 54.9972 46.0579C54.7632 46.2552 54.589 46.514 54.4943 46.8052C54.3998 47.0963 54.3885 47.4081 54.4619 47.7054C54.5352 48.0026 54.6903 48.2733 54.9095 48.487L59.5307 52.9917L58.4399 59.3524C58.3882 59.6541 58.4219 59.9643 58.5372 60.2479C58.6526 60.5314 58.8449 60.7771 59.0926 60.957C59.3403 61.137 59.6333 61.244 59.9387 61.2661C60.244 61.2882 60.5494 61.2243 60.8204 61.0819L66.5326 58.0788V38.0591H66.5324Z"
                            fill="#F8BE4A"/>
                        <path
                            d="M68.7325 0.729736H11.2675C5.05469 0.729736 0 5.78427 0 11.9972V19.6002C0 25.401 4.40563 30.1919 10.047 30.8019L12.4833 34.0477C13.2864 35.1177 14.5141 35.7433 15.8516 35.7641L15.9205 35.7646C17.2319 35.7646 18.45 35.18 19.2716 34.1538L21.903 30.8675H68.7325C74.9455 30.8675 80 25.813 80 19.6V11.9971C80 5.78427 74.9453 0.729736 68.7325 0.729736Z"
                            fill="#FE5252"/>
                        <path
                            d="M40 0.729736H11.2675C5.05469 0.729736 0 5.78427 0 11.9972V19.6002C0 25.401 4.40563 30.1919 10.047 30.8019L12.4833 34.0477C13.2864 35.1177 14.5141 35.7433 15.8516 35.7641L15.9205 35.7646C17.2319 35.7646 18.45 35.18 19.2716 34.1538L21.903 30.8675H40V0.729736Z"
                            fill="#FE8484"/>
                        <path
                            d="M66.3606 8.64771H13.6406C12.3462 8.64771 11.2969 9.69708 11.2969 10.9915C11.2969 12.2858 12.3462 13.3352 13.6406 13.3352H66.3606C67.655 13.3352 68.7044 12.2858 68.7044 10.9915C68.7044 9.69708 67.655 8.64771 66.3606 8.64771Z"
                            fill="#FE0909"/>
                        <path
                            d="M40.0006 8.64771H13.6406C12.3462 8.64771 11.2969 9.69708 11.2969 10.9915C11.2969 12.2858 12.3462 13.3352 13.6406 13.3352H40.0006V8.64771ZM34.9744 18.0715H13.6406C12.3462 18.0715 11.2969 19.1208 11.2969 20.4152C11.2969 21.7096 12.3462 22.759 13.6406 22.759H34.9744C36.2688 22.759 37.3181 21.7096 37.3181 20.4152C37.3181 19.1208 36.2688 18.0715 34.9744 18.0715Z"
                            fill="#FE5252"/>
                    </svg>


                    <p>{__('No Orders to Review')}</p>
                    <p>{__('Items you have purchased will appear here for you to review!')}</p>
                    <Link
                        to="/"
                        block="StartShopping"
                        elem="Button"
                        mix={{block: 'Button'}}
                    >
                        {__('Start Shopping')}

                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 12L10.5 8L6.5 4" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>


                    </Link>
                </div>
            </div>
        )
    }

    pendingReviews() {
        const {device: {isMobile}, MyReviews: {pending_reviews}} = this.props;

        // console.clear();
        // console.log(this.props);
        // console.log(pending_reviews);

        if (!pending_reviews) {
            return null;
        }

        return (
            <div className="pending-reviews">
                <span block="myReviews" elem="pending">Review your purchased items</span>
                <div block="ProductsInThisOrderWrapper">
                    {
                        Object.values(pending_reviews).map((review) => {
                            return (
                                <div block="ProductsInThisOrderWrapperItem">
                                    <div block="ProductsInThisOrderWrapperItemImage">
                                        <Image
                                            block="myReviews"
                                            elem="Image"
                                            src={review.product_image}
                                            alt={__('Product %s thumbnail.', review.product_name)}
                                            isPlaceholder={!review.product_name}
                                        />
                                    </div>
                                    <div block="ProductsInThisOrderWrapperItemContent">
                                        <div>{review.product_name}</div>
                                        <Html content={review.product_options}/>
                                        {this.renderButton(review.product_name, review.product_sku)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <Popup
                    id={REVIEW_POPUP_ID}
                    productName={window.myreviewsName}
                    mix={{block: 'ProductReviews', elem: 'Popup'}}
                >
                    <ProductReviewForm product={{sku: window.myreviewsSku}}/>
                </Popup>
            </div>
        )

    }


    reviewsList() {
        // console.clear();
        // console.log(this.props);
        // console.log(this.state);
        const {device: {isMobile}, MyReviews: {reviews_list}} = this.props;
        // console.log(reviews_list);
        if (typeof reviews_list === 'undefined') {
            return null;
            return 'reviews_list'
        }


        return (
            <>
                <div className="my-reviews">
                    {/*<Loader isLoading={true}/>*/}
                    {
                        Object.values(reviews_list).map((review) => {
                            return (
                                <>
                                    <div block="myReviews" elem="wrapper">
                                        <div block="myReviews" elem="image">
                                            {/*<img*/}
                                            {/*    alt={ review.product_name }*/}
                                            {/*    src={ review.product_image }*/}
                                            {/*/>*/}
                                            <Image
                                                block="myReviews"
                                                elem="Image"
                                                src={review.product_image}
                                                alt={__('Product %s thumbnail.', review.product_name)}
                                                isPlaceholder={!review.product_name}
                                            />
                                        </div>
                                        <div block="myReviews" elem="content">
                                            <span block="myReviews" elem="title">{review.product_name}</span>
                                            <div block="myReviews" elem="subject">
                                                <span className="amreview-title-review">{review.title}</span>
                                                <time className='amreview-date' dateTime={review.created_at}>
                                                    {
                                                        (isMobile) ? review.created_at : "(Reviewed on " + review.created_at + ")"
                                                    }
                                                    {/*(Reviewed on {review.created_at})*/}
                                                </time>
                                            </div>
                                            {Object.values(review.rating_votes).map((vote) => {
                                                return (
                                                    <div className='amstars-rating-container'>
                                                        {this.renderRatingSchema(vote.percent, 1)}
                                                        <div className='rating-code'>{vote.rating_code}</div>
                                                        <ProductReviewRating
                                                            mix={{block: 'ProductReviews', elem: 'SummaryRating'}}
                                                            summary={vote.percent}
                                                        />
                                                    </div>
                                                )
                                            })}
                                            <span className='amreview-text'>{review.detail}</span>

                                            {
                                                (review.like_about || review.not_like_about) ?
                                                    <div className='pros-cons-wrapper'>{this.renderProsAndCons(review)}</div> : ''
                                        }

                                        {(review.review_images.length) ? this.renderReviewImages(review.review_images) : ''}
                                    </div>
                                    </div>
                                </>
                            )
                        })
                    }
            </div>
            </>
        )
        const {
            MyReviews:
                {
                    config: {
                        is_admin_answer_account_only,
                        guest_email,
                        is_guest_can_comment,
                        is_comments_enabled,
                        gdpr_enabled,
                        gdpr_text
                    }, customer_id
                }
        } = this.props;

        if (!customer_id && guest_email) {
            this.setState({reply_to_validate: 3})
            totalReplyFields = 3;
        } else if (!customer_id && !guest_email) {
            this.setState({reply_to_validate: 2})
            totalReplyFields = 2;
        }

        let gdpr = (gdpr_enabled) ? gdpr_text : false;
        // console.log(customer_id);
        // console.log(is_guest_can_comment);
        return Object.values(reviews_list).map((review) => {
            return (
                <>
                    <div className='item review-item amreview-review-wrapper'>
                        {
                            (isMobile) ?
                                <>
                                    <div className="review-details amreview-author-details">
                                        <div className='amreview-mobile-author'>
                                            <p className="amreview-author">
                                                {review.nickname}
                                            </p>
                                            <Html content={review.verified_buyer_html}/>
                                        </div>
                                        <time className='amreview-date' dateTime={review.created_at}>
                                            {review.created_at}
                                            <br/>
                                            {review.time_ago}
                                        </time>
                                    </div>
                                    <Html content={review.recommended_html}/>
                                </>
                                :
                                <div className="review-details amreview-author-details">
                                    <p className="amreview-author">
                                        {review.nickname}
                                    </p>
                                    <Html content={review.verified_buyer_html}/>
                                    <time className='amreview-date' dateTime={review.created_at}>
                                        {review.created_at}
                                        <br/>
                                        {review.time_ago}
                                    </time>
                                    <Html content={review.recommended_html}/>
                                </div>
                        }

                        <div className="amreview-description-wrap">
                            <div className="amrev-desc-title">
                            <span className="amreview-title-review">
                                {review.title}
                            </span>
                                <span className="amrev-voteqty">
                                {review.helpful_html}
                            </span>
                            </div>
                            {Object.values(review.rating_votes).map((vote) => {
                                return (
                                    <div className='amstars-rating-container'>
                                        {this.renderRatingSchema(vote.percent, 1)}
                                        <div className='rating-code'>{vote.rating_code}</div>
                                        <ProductReviewRating
                                            mix={{block: 'ProductReviews', elem: 'SummaryRating'}}
                                            summary={vote.percent}
                                        />
                                    </div>
                                )
                            })}
                            <span className='amreview-text'>{review.detail}</span>

                            {
                                (review.like_about || review.not_like_about) ?
                                    <div className='pros-cons-wrapper'>{this.renderProsAndCons(review)}</div> : ''
                            }


                            {(review.review_images.length) ? this.renderReviewImages(review.review_images) : ''}
                            {
                                (!is_admin_answer_account_only && review.answer) ?
                                    <>
                                        <div className='amreview-response-wrapper'>
                                            <div className='amreview-response'>
                                                <span className='amreview-text'>Response from Divah</span>
                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_138_5087)">
                                                        <path
                                                            d="M13.9627 3.28873C12.9555 3.01993 11.9716 2.67066 11.0205 2.24428C10.0846 1.83833 9.18127 1.36139 8.31823 0.817615L8.07378 0.666504L7.83378 0.822059C6.97074 1.36583 6.06737 1.84278 5.13156 2.24873C4.1788 2.67381 3.19341 3.0216 2.1849 3.28873L1.85156 3.37317V7.07984C1.85156 13.0309 7.8649 15.2576 7.92267 15.2798L8.07378 15.3332L8.2249 15.2798C8.28712 15.2798 14.296 13.0354 14.296 7.07984V3.37317L13.9627 3.28873ZM11.9316 6.24428L6.96712 11.0665L4.15823 8.25317C4.04035 8.1353 3.97413 7.97542 3.97413 7.80873C3.97413 7.64203 4.04035 7.48216 4.15823 7.36428C4.2761 7.24641 4.43597 7.18019 4.60267 7.18019C4.76937 7.18019 4.92924 7.24641 5.04712 7.36428L6.98045 9.33317L11.0649 5.33317C11.1233 5.27481 11.1926 5.22851 11.2688 5.19692C11.3451 5.16533 11.4268 5.14908 11.5093 5.14908C11.5919 5.14908 11.6736 5.16533 11.7499 5.19692C11.8261 5.22851 11.8954 5.27481 11.9538 5.33317C12.0121 5.39154 12.0584 5.46083 12.09 5.53708C12.1216 5.61334 12.1379 5.69507 12.1379 5.77761C12.1379 5.86016 12.1216 5.94189 12.09 6.01815C12.0584 6.0944 12.0121 6.16369 11.9538 6.22206L11.9316 6.24428Z"
                                                            fill="#6B2A2A"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_138_5087">
                                                            <rect width="16" height="16" fill="white"
                                                                  transform="translate(0.0742188)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </div>
                                            <span className='amreview-text'>{review.answer}</span>
                                        </div>

                                    </> : ''
                            }
                            {
                                (customer_id && is_comments_enabled || !customer_id && is_guest_can_comment && is_comments_enabled) ?
                                    this.renderCommentActions(review.review_id, customer_id, review.comments, review.vote_count, guest_email, gdpr)
                                    : ''
                            }
                        </div>
                    </div>

                </>
            )
        })
    }

    renderProsAndCons(review) {
        const {MyReviews: {config: {is_pros_cons_enabled}}} = this.props;
        // if (!is_pros_cons_enabled) {
        //     return null;
        // }
        // console.log(review);
        // console.log(review.like_about);
        return (
            <>
                {
                    (review.like_about) ? <div className='review-pros'>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_138_5103)">
                                <path
                                    d="M9.07422 16.5C13.2164 16.5 16.5742 13.1421 16.5742 9C16.5742 4.85786 13.2164 1.5 9.07422 1.5C4.93208 1.5 1.57422 4.85786 1.57422 9C1.57422 13.1421 4.93208 16.5 9.07422 16.5Z"
                                    stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.07422 6V12" stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M6.07422 9H12.0742" stroke="#0A9D10" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_138_5103">
                                    <rect width="18" height="18" fill="white" transform="translate(0.0742188)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span>{review.like_about}</span>
                    </div> : ''
                }

                {
                    (review.not_like_about) ? <div className='review-cons'>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_138_5106)">
                                <path
                                    d="M9.07422 16.5C13.2164 16.5 16.5742 13.1421 16.5742 9C16.5742 4.85786 13.2164 1.5 9.07422 1.5C4.93208 1.5 1.57422 4.85786 1.57422 9C1.57422 13.1421 4.93208 16.5 9.07422 16.5Z"
                                    stroke="#E31620" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.07422 9H12.0742" stroke="#E31620" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_138_5106">
                                    <rect width="18" height="18" fill="white" transform="translate(0.0742188)"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <span>{review.not_like_about}</span>
                    </div> : ''
                }

            </>
        )
    }

    renderReviewImages(reviewImages) {


        // const {items} = this.state;
        const {device: {isMobile}, MyReviews: {config: {slides_to_show}}} = this.props;
        // console.log(reviewImages);
        // console.log(slides_to_show);
        // console.log(reviewImages.length);

        if (!isMobile && reviewImages.length <= slides_to_show || slides_to_show === 0) {
            return (
                <>
                    <div className='review-images-wrapper'>
                        {
                            reviewImages.map((image, index) => {
                                let imageThumb = 'url(' + image.thumb + ')';
                                return (
                                    <div className='review-image-container' style={{backgroundImage: imageThumb}}>
                                        {this.renderImageInContainer(image)}
                                    </div>)
                            })
                        }
                    </div>
                </>
            )
        }


        return (
            <Swiper
                // className={(items.length > 4) ? 'WithPager' : ''}
                // effect={"fade"}

                navigation={true}
                // pagination={{
                //     clickable: true,
                // }}
                loop={false}
                // loopFillGroupWithBlank={true}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                modules={[Autoplay, Navigation, SwiperPagination]}
                // className="mySwiper"
                spaceBetween={(isMobile) ? 12 : 24}
                slidesPerView={(isMobile) ? 2 : 4}
                // breakpoints={{
                //     320: {
                //         slidesPerView: 2,
                //     },
                //     640: {
                //         slidesPerView: 3,
                //     },
                //     768: {
                //         slidesPerView: 4,
                //     },
                // }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
            >
                {/*<SwiperSlide>1</SwiperSlide>*/}
                {/*<SwiperSlide>2</SwiperSlide>*/}
                {/*<SwiperSlide>3</SwiperSlide>*/}
                {
                    // this.renderMobileSlider()
                    reviewImages.map((image, index) => {
                        let imageThumb = 'url(' + image.thumb + ')';
                        return (
                            <SwiperSlide className='review-image-container' style={{backgroundImage: imageThumb}}>
                                {this.renderImageInContainer(image)}
                            </SwiperSlide>)
                    })
                }
            </Swiper>

        );

    }

    renderImageInContainer(image) {
        const {onReviewImageClick} = this.props;
        return (
            <img className="amreview-image"
                 src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAMAAABcxfTLAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAAtJREFUeNpjGOQAAACgAAHz2+p5AAAAAElFTkSuQmCC'
                // title='Review image'
                // alt='Review image'
                 data-review-id={image.review_id}
                 data-image-id={image.image_id}
                 // onClick={this.ShowPopup.bind(this, image)}
            />
        )
    }

    renderPagination() {
        // console.clear();
        // console.log(this.props);
        const {
            isLoading,
            MyReviews: {total_pages}
        } = this.props;

        if (total_pages === 1) {
            return null;
        }

        return (
            <Pagination
                isLoading={isLoading}
                totalPages={total_pages}
                mix={{block: 'AdvancedReview', elem: 'Pagination'}}
            />
        );
    }

    ShowPopup(a) {
        // console.clear();
        const {onReviewImageClick} = this.props;
        this.forceUpdate();
        reviewPopupData = a;
        // console.log(a);
        onReviewImageClick(a);
        // console.log(this.props);
        // return 1
    }

    renderReviewsFilter() {
        const {
            device: {isMobile},
            onSortChange,
            MyReviews: {
                config: {
                    filtering_options,
                    sorting_options,
                } = {}
            }
        } = this.props;

        if (typeof sorting_options === 'undefined') {
            return null;
        }
        let sortFields = [];
        Object.entries(sorting_options).map((key) => {
            sortFields.push(
                {
                    id: key[0],
                    name: key[0],
                    value: key[0],
                    label: key[1]
                }
            )
        });

        setTimeout(function () {
            const isExpanded = document.querySelectorAll('#category-sort_wrapper .FieldSelect-Options');
            let i;
            for (i = 0; i < isExpanded.length; i++) {
                if (!isExpanded[i].classList.contains('FieldSelect-Options_isExpanded')) {
                    document.body.classList.remove('isExpanded');
                }

            }
        }, 1);
        // console.log(filtering_options);
        // console.log(this.state);
        // console.log(this.props);
        return (
            <div className="am-reviews-filter-wrapper">
                <span className="am-top-reviews">Top Reviews</span>

                {
                    (isMobile) ?
                        <>
                            <div className='am-reviews-sorter'>
                                {
                                    (sortFields.length) ?
                                        <>
                                            <CategorySort
                                                mobileHeader={true}
                                                source='MyReviews'
                                                isCurrentCategoryLoaded={true}
                                                isMatchingInfoFilter={true}
                                                onSortChange={onSortChange}
                                                sortFields={sortFields}
                                                sortKey={sortFields[0].id}
                                                sortDirection='DESC'
                                            />
                                            <div block="CustomPopup-Back"></div>
                                        </>
                                        : ''
                                }
                            </div>
                            <div className='am-reviews-filter'>
                                {
                                    filtering_options.map((option, i) => (
                                        <Field
                                            type={FieldType.CHECKBOX}
                                            attr={{
                                                id: option.id,
                                                name: option.name,
                                                checked: this.state[option.id]
                                            }}
                                            label={option.name}
                                            events={{
                                                onChange: this.handleCheckboxChange,
                                            }}
                                            validateOn={['onChange']}
                                            validationRule={{
                                                match: (value) => {
                                                    this.setState({[option.id]: value})
                                                    onSortChange();
                                                    return true;
                                                },
                                                customErrorMessages: {
                                                    onMatchFail: 'This field is required!'
                                                }
                                            }}
                                        />
                                    ))
                                }
                            </div>
                        </>
                        :
                        <div className='am-reviews-filter-wrapper-dk'>
                            <div className='am-reviews-filter'>
                                {
                                    filtering_options.map((option, i) => (
                                        <Field
                                            type={FieldType.CHECKBOX}
                                            attr={{
                                                id: option.id,
                                                name: option.name,
                                                checked: this.state[option.id]
                                            }}
                                            label={option.name}
                                            events={{
                                                onChange: this.handleCheckboxChange,
                                            }}
                                            validateOn={['onChange']}
                                            validationRule={{
                                                match: (value) => {
                                                    this.setState({[option.id]: value})
                                                    onSortChange();
                                                    return true;
                                                },
                                                customErrorMessages: {
                                                    onMatchFail: 'This field is required!'
                                                }
                                            }}
                                        />
                                    ))
                                }
                            </div>
                            <div className='am-reviews-sorter'>
                                {
                                    (sortFields.length) ?
                                        <>
                                            <CategorySort
                                                source='MyReviews'
                                                isCurrentCategoryLoaded={true}
                                                isMatchingInfoFilter={true}
                                                onSortChange={onSortChange}
                                                sortFields={sortFields}
                                                sortKey={sortFields[0].id}
                                                sortDirection='DESC'
                                            />
                                            <div block="CustomPopup-Back"></div>
                                        </>
                                        : ''
                                }
                            </div>
                        </div>
                }


            </div>
        )
    }

    renderNoReviews() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        return (
            <>
                {this.renderReviewsFilter()}
                <div id="am-no-reviews">
                    <svg width="90" height="70" viewBox="0 0 90 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M25.305 -0.0195312H4.19375C2.33389 -0.0195312 0.845703 1.46864 0.845703 3.32851V24.4398C0.845703 26.2996 2.33387 27.7878 4.19375 27.7878H25.305C27.1649 27.7878 28.6531 26.2997 28.6531 24.4398V3.42151C28.7458 1.56099 27.1649 -0.0195312 25.305 -0.0195312ZM21.957 21.0917H7.63446V6.76989H21.9563L21.957 21.0917Z"
                            fill="#6B2A2A"/>
                        <path
                            d="M43.2542 6.76972H85.8487C87.7086 6.76972 89.1968 5.28155 89.1968 3.42167C89.1968 1.56181 87.7086 0.0736304 85.8487 0.0736304H43.2542C41.3943 0.0736304 39.9062 1.5618 39.9062 3.42167C39.9062 5.28153 41.3943 6.76972 43.2542 6.76972Z"
                            fill="#6B2A2A"/>
                        <path
                            d="M43.2542 27.881H74.1306C75.9904 27.881 77.4786 26.3928 77.4786 24.5329C77.4786 22.6731 75.9905 21.0921 74.1306 21.0921H43.2542C41.3943 21.0921 39.9062 22.5802 39.9062 24.4401C39.9062 26.3 41.3943 27.881 43.2542 27.881Z"
                            fill="#6B2A2A"/>
                        <path
                            d="M25.305 42.2032H4.19375C2.33389 42.2032 0.845703 43.6913 0.845703 45.5512V66.6625C0.845703 68.5223 2.33387 70.0105 4.19375 70.0105H25.305C27.1649 70.0105 28.6531 68.5224 28.6531 66.6625V45.5512C28.7458 43.6907 27.1649 42.2032 25.305 42.2032ZM21.957 63.3144H7.63446V48.9926H21.9563L21.957 63.3144Z"
                            fill="#6B2A2A"/>
                        <path
                            d="M85.8487 42.2032H43.2542C41.3943 42.2032 39.9062 43.6913 39.9062 45.5512C39.9062 47.4111 41.3943 48.8993 43.2542 48.8993H85.8487C87.7086 48.8993 89.1968 47.4111 89.1968 45.5512C89.1968 43.6907 87.7086 42.2032 85.8487 42.2032Z"
                            fill="#6B2A2A"/>
                        <path
                            d="M74.1306 63.3144H43.2542C41.3943 63.3144 39.9062 64.8026 39.9062 66.6625C39.9062 68.5223 41.3943 70.0105 43.2542 70.0105H74.1306C75.9904 70.0105 77.4786 68.5224 77.4786 66.6625C77.4786 64.8026 75.9905 63.3144 74.1306 63.3144Z"
                            fill="#6B2A2A"/>
                        <path
                            d="M25.305 -0.0195312H4.19375C2.33389 -0.0195312 0.845703 1.46864 0.845703 3.32851V24.4398C0.845703 26.2996 2.33387 27.7878 4.19375 27.7878H25.305C27.1649 27.7878 28.6531 26.2997 28.6531 24.4398V3.42151C28.7458 1.56099 27.1649 -0.0195312 25.305 -0.0195312ZM21.957 21.0917H7.63446V6.76989H21.9563L21.957 21.0917Z"
                            stroke="#FEFAF8" stroke-width="2"/>
                        <path
                            d="M43.2542 6.76972H85.8487C87.7086 6.76972 89.1968 5.28155 89.1968 3.42167C89.1968 1.56181 87.7086 0.0736304 85.8487 0.0736304H43.2542C41.3943 0.0736304 39.9062 1.5618 39.9062 3.42167C39.9062 5.28153 41.3943 6.76972 43.2542 6.76972Z"
                            stroke="#FEFAF8" stroke-width="2"/>
                        <path
                            d="M43.2542 27.881H74.1306C75.9904 27.881 77.4786 26.3928 77.4786 24.5329C77.4786 22.6731 75.9905 21.0921 74.1306 21.0921H43.2542C41.3943 21.0921 39.9062 22.5802 39.9062 24.4401C39.9062 26.3 41.3943 27.881 43.2542 27.881Z"
                            stroke="#FEFAF8" stroke-width="2"/>
                        <path
                            d="M25.305 42.2032H4.19375C2.33389 42.2032 0.845703 43.6913 0.845703 45.5512V66.6625C0.845703 68.5223 2.33387 70.0105 4.19375 70.0105H25.305C27.1649 70.0105 28.6531 68.5224 28.6531 66.6625V45.5512C28.7458 43.6907 27.1649 42.2032 25.305 42.2032ZM21.957 63.3144H7.63446V48.9926H21.9563L21.957 63.3144Z"
                            stroke="#FEFAF8" stroke-width="2"/>
                        <path
                            d="M85.8487 42.2032H43.2542C41.3943 42.2032 39.9062 43.6913 39.9062 45.5512C39.9062 47.4111 41.3943 48.8993 43.2542 48.8993H85.8487C87.7086 48.8993 89.1968 47.4111 89.1968 45.5512C89.1968 43.6907 87.7086 42.2032 85.8487 42.2032Z"
                            stroke="#FEFAF8" stroke-width="2"/>
                        <path
                            d="M74.1306 63.3144H43.2542C41.3943 63.3144 39.9062 64.8026 39.9062 66.6625C39.9062 68.5223 41.3943 70.0105 43.2542 70.0105H74.1306C75.9904 70.0105 77.4786 68.5224 77.4786 66.6625C77.4786 64.8026 75.9905 63.3144 74.1306 63.3144Z"
                            stroke="#FEFAF8" stroke-width="2"/>
                    </svg>
                    <p>No Results Found</p>
                    <p>Sorry, there are no reviews. Try using different filters</p>
                </div>
            </>
        )
    }

    renderCommentActions(reviewId, customerId, comments, voteCount, guestEmail, gdpr) {
        const {handleVote} = this.props;
        // console.clear();
        // console.log(reviewId);
        // console.log(comments);
        const plusClass = 'amreview-plus ' + ((voteCount.plus) ? 'voted' : '');
        const minusClass = 'amreview-minus ' + ((voteCount.minus) ? 'voted' : '');
        return (
            <>
                <div className='amrev-comment-toolbar'>
                    <AmExpandableContent
                        // show placeholder if the details are not loaded
                        heading='Reply'
                        mix={{block: 'amrev', elem: 'reply'}}
                    >
                        {/*Reply Divah is a Fashion Jewellery E-commerce Brand based in India, that aims to provide affordable women’s jewellery of all kinds at its best.*/}
                        {this.renderReply(reviewId, customerId, guestEmail, gdpr)}
                    </AmExpandableContent>
                    {/*<div className='amrev-comment'>*/}
                    {/*    <CommentsIcon />*/}
                    {/*    Comments ({comments.length})*/}
                    {/*</div>*/}
                    <AmExpandableContent
                        // show placeholder if the details are not loaded
                        heading={
                            <>
                                <CommentsIcon/>
                                <span>Comments ({comments.length})</span>
                            </>
                        }
                        mix={{block: 'amrev', elem: 'comment'}}
                    >
                        {/*Comments Divah is a Fashion Jewellery E-commerce Brand based in India, that aims to provide affordable women’s jewellery of all kinds at its best.*/}
                        {this.renderComments(comments)}
                    </AmExpandableContent>
                    <div className='amreview-helpful-wrap'>
                        <span className="amreview-helpful">Was this review helpful?</span>
                        <div className="amreview-options">
                            <div review-id={reviewId} type='plus' count={voteCount.plus} className={plusClass}
                                 onClick={handleVote}>
                                <ThumbsUpIcon/>
                                <span className="amreview-count">{voteCount.plus}</span>
                            </div>
                            <div review-id={reviewId} type='minus' count={voteCount.minus} className={minusClass}
                                 onClick={handleVote}>
                                <ThumbsDownIcon/>
                                <span className="amreview-count">{voteCount.minus}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    renderReply(reviewId, customerId, guestEmail, gdpr) {

        const {submitComment} = this.props;
        // console.log(reviewId, customerId, guestEmail, gdpr);
        let submitNewComment = 'submit-' + reviewId + '-comment';
        let submitNewCommentButton = document.getElementById(submitNewComment);

        return (
            <>
                {/*<div className="reply-loader">*/}
                {/*    <Loader isLoading={false}/>*/}
                {/*</div>*/}
                <p id={`reply-comment-wrapper-${reviewId}`} className='am-showing-title'>Leave your comment</p>
                {
                    (!customerId) ?
                        <div className='am-comment-input'>
                            {
                                (!customerId) ?

                                    <Field
                                        addRequiredTag
                                        type={FieldType.TEXT}
                                        attr={{
                                            id: `option-${reviewId}-nickname`,
                                            name: `option-${reviewId}-nickname`,
                                            placeholder: 'Your name',
                                            class: submitNewComment
                                        }}
                                        label='Name'
                                        validateOn={['onChange']}
                                        validationRule={{
                                            match: (value) => {
                                                // console.clear();
                                                // console.log(value);
                                                if (!!(value)) {
                                                    try {
                                                        replyToValidate[reviewId]['nickname'] = value;
                                                    } catch (e) {
                                                        replyToValidate[reviewId] = {nickname: value};
                                                    }
                                                } else {
                                                    delete replyToValidate[reviewId]['nickname'];
                                                }

                                                this.approveComment(reviewId);

                                                return !!(value);

                                            },
                                            customErrorMessages: {
                                                onMatchFail: 'This field is required!'
                                            }
                                        }}
                                    /> : ''
                            }

                            {
                                (!customerId && guestEmail) ?
                                    <Field
                                        addRequiredTag
                                        type={FieldType.EMAIL}
                                        attr={{
                                            id: `option-${reviewId}-email`,
                                            name: `option-${reviewId}-email`,
                                            placeholder: 'Your email address',
                                            class: submitNewComment
                                        }}
                                        label='Email'
                                        validateOn={['onChange']}
                                        validationRule={{
                                            inputType: ValidationInputType.EMAIL,
                                            match: (value) => {
                                                // console.clear();
                                                let valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                if (valid.test(value)) {
                                                    try {
                                                        replyToValidate[reviewId]['email'] = value;
                                                    } catch (e) {
                                                        replyToValidate[reviewId] = {email: value};
                                                    }
                                                } else {
                                                    try {
                                                        delete replyToValidate[reviewId]['email'];
                                                    } catch (e) {

                                                    }
                                                }

                                                try {
                                                    this.approveComment(reviewId);
                                                } catch (e) {

                                                }

                                                return !!(value);

                                            },
                                            customErrorMessages: {
                                                onMatchFail: 'This field is required!'
                                            }
                                        }}
                                    /> : ''
                            }
                        </div> : ''
                }

                <Field
                    addRequiredTag
                    type={FieldType.TEXTAREA}
                    attr={{
                        id: `option-${reviewId}-comment`,
                        name: `option-${reviewId}-comment`,
                        placeholder: 'Talk about why you like this review, or ask a question.',
                        class: submitNewComment
                    }}
                    label='Comment'
                    validateOn={['onChange']}
                    validationRule={{
                        match: (value) => {
                            // console.log(value);
                            // console.clear();
                            if (!!(value)) {
                                try {
                                    replyToValidate[reviewId]['message'] = value;
                                } catch (e) {
                                    replyToValidate[reviewId] = {message: value};
                                }
                            } else {
                                delete replyToValidate[reviewId]['message'];
                            }

                            this.approveComment(reviewId);

                            return !!(value);
                        },
                        customErrorMessages: {
                            onMatchFail: 'This field is required!'
                        }
                    }}
                />
                {/*{*/}
                {/*    (gdpr) ? this.renderGdpr(gdpr):''*/}
                {/*}*/}

                <button
                    style={{pointerEvents: 'none', opacity: '.25'}}
                    id={submitNewComment}
                    block="Button"
                    className="SubmitNewComment"
                    // onClick={this.submitNewTicket.bind(this)}
                    onClick={submitComment}
                >
                    Submit Comment
                </button>
            </>
        )
    }

    approveComment(reviewId) {
        let submitNewCommentButton = document.getElementById(`submit-${reviewId}-comment`);
        let filedFields = Object.keys(replyToValidate[reviewId]).length;
        // console.log(replyToValidate);
        // console.log(filedFields);
        // console.log(totalReplyFields);
        if (filedFields === totalReplyFields) {
            // console.log('approved');
            submitNewCommentButton.style.pointerEvents = 'all';
            submitNewCommentButton.style.opacity = '1';
            submitNewCommentButton.setAttribute('review-id', reviewId);
            replyToValidate[reviewId]['review_id'] = reviewId;
            submitNewCommentButton.setAttribute('review-comment', JSON.stringify(replyToValidate[reviewId]));
            delete replyToValidate[reviewId]['review_id'];
        } else {
            submitNewCommentButton.style.pointerEvents = 'none';
            submitNewCommentButton.style.opacity = '0.25';
        }
    }

    renderComments(comments) {

        // console.log(comments);

        if (!comments.length) {
            return <span className='am-no-comments'>There no comments yet.</span>
        }


        return (
            <>
                <p className='am-showing-title'>Showing {comments.length} comments</p>
                {
                    comments.map((comment, index) => {
                        // let imageThumb = 'url(' + image.thumb + ')';
                        return (
                            <div className='am-comment-wrapper'>
                                <div className='am-comment-user'>
                                    <p className='am-comment-nickname'>{comment.nickname}</p>
                                    {
                                        (comment.customer_id) ?
                                            <p className='am-comment-verified'>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M0.5 6C0.5 4.54131 1.07946 3.14236 2.11091 2.11091C3.14236 1.07946 4.54131 0.5 6 0.5C7.45869 0.5 8.85764 1.07946 9.88909 2.11091C10.9205 3.14236 11.5 4.54131 11.5 6C11.5 7.45869 10.9205 8.85764 9.88909 9.88909C8.85764 10.9205 7.45869 11.5 6 11.5C4.54131 11.5 3.14236 10.9205 2.11091 9.88909C1.07946 8.85764 0.5 7.45869 0.5 6H0.5ZM5.68613 8.354L8.85267 4.39547L8.28067 3.93787L5.58053 7.31193L3.668 5.7184L3.19867 6.2816L5.68613 8.35473V8.354Z"
                                                          fill="#0A9D10"/>
                                                </svg>
                                                Verified User</p>
                                            : ''
                                    }
                                </div>

                                <time className='amreview-date' dateTime={comment.created_at}>
                                    {comment.created_at}
                                </time>
                                <time className='amreview-date' dateTime={comment.time_ago}>
                                    {comment.time_ago}
                                </time>
                                <p className='am-comment-text'>{comment.message}</p>
                            </div>
                        )
                    })
                }
            </>
        )
    }

    renderGdpr(gdpr) {
        return (
            <>
                <Field
                    type={FieldType.CHECKBOX}
                    attr={{
                        id: `option-gdpr`,
                        name: `option-gdpr`,
                        // checked: this.state[option.id]
                    }}
                    label=<Html content={gdpr}/>
                // isDisabled={false}
                validateOn={['onChange']}
                validationRule={{
                match: (value) => {
                    // console.log(value);
                    // this.setState({[option.id]:value})
                    // this.setState({comment: !!(value)});
                    // this.setState({comment: value});
                    // console.log(this.state);
                    // onSortChange();
                    return !!(value);

                },
                customErrorMessages: {
                    onMatchFail: 'This field is required!'
                }
            }}
                />
            </>
        )
    }

    render() {
        console.clear();
        console.log(this.state);
        console.log(this.props);
        console.log(window.location);
        // debugger;
        // return 'My Reviews';
        const {product, device: {isMobile}, isLoading, MyReviews, onSortChange, MyReviews: {pending_reviews}} = this.props;

        if (isLoading) {
            return <Loader isLoading={isLoading}/>
            return <div block="CmsPage" elem="SectionPlaceholder"/>;
            // return null;
            return 'loading';
        }
        console.log(MyReviews);
        const {
            rating_summary,
            rating_summary_value,
            recomended_percent,
            review_count
        } = MyReviews;

        // if (typeof review_count === 'undefined') {
        //     return null;
        // }

        let pendingReviews = false;
        try {
            pendingReviews = pending_reviews.length;
        }catch (e) {}
console.log(pending_reviews);
console.log(pendingReviews);
        if (typeof pendingReviews !== 'undefined') {
            console.log('here');
            return this.renderNoPendingReviews();
        }

        if (review_count === 0) {
            return (
                <>
                    <div id='my-reviews'>
                        <span className="reviews-count">No reviews yet</span>
                        {/*{this.reviewsList()}*/}
                        {/*{this.renderPagination()}*/}
                        {this.pendingReviews()}
                    </div>
                </>
            )
        }

        return (
            <>
                <div id='my-reviews'>
                    <span className="reviews-count">
                    {
                        (review_count === 1) ? review_count + ' review' : review_count + ' reviews'
                    }
                    </span>
                    {this.reviewsList()}
                    {this.renderPagination()}
                    {this.pendingReviews()}
                </div>
            </>
        )

        // console.clear();
        // console.log(MyReviews);
        const {current_page, total_pages} = MyReviews;
        // console.log(current_page);
        // console.log(total_pages);

        if (typeof current_page === 'undefined') {
            return null;
        }
        if (current_page > total_pages) {
            // console.log(window.location);
            history.replace({pathname: window.location.pathname});
            onSortChange();
        }
        // console.log(window.location.search);
        return (
            <>
                <div id='amreview-info-wrapper' className='amreview-info-wrapper'>
                    {
                        (isMobile) ? <span className='am-mobile-section'>Customer Reviews</span> : ''
                    }
                    <div className='amreview-summary-info'>
                        <div className='amreview-summary'>
                            <div className='amrating'><span className='amrating-number'>{rating_summary_value}</span>
                                <div className='amreview-stars'>
                                    {this.renderSummary()}
                                </div>
                            </div>
                            {
                                (review_count) ?
                                    <div className='ampercent'>
                                        <span>{recomended_percent}%</span>

                                        {
                                            (isMobile) ?
                                                <span
                                                    className='ampercent-recommend'> customers recommend this product</span>
                                                : <span className='ampercent-recommend'> customers<br/>recommend this product</span>
                                        }
                                    </div>
                                    : this.renderNoRating()
                            }
                        </div>

                        <div className='amreview-stars-detailed'>
                            {this.detailedSummary()}
                        </div>
                    </div>
                    {this.renderButton()}
                </div>
                {this.renderReviewsFilter()}
                {this.reviewsList()}
                {this.renderPagination()}
                <Popup
                    id="MyReviewsPopup"
                    mix={{block: 'MyReviewsPopup'}}
                >
                    <div id='amreview-popup'>
                        <PopUpWithImages product={product} reviewPopupData={reviewPopupData}/>
                    </div>
                    {
                        (isMobile) ? '' :
                            <div className="reviews-control-wrapper">
                                <div id="reviews-control">
                                    <div className="swiper-button-prev" id="swiper-button-prev-review-popup"
                                         onClick={this.handlePrevReview}>
                                        <ChevronIcon direction={Directions.LEFT}/>
                                    </div>
                                    <span id="reviews-control-current"></span>
                                    <div className="swiper-button-next" id="swiper-button-next-review-popup"
                                         onClick={this.handleNextReview}>
                                        <ChevronIcon direction={Directions.RIGHT}/>
                                    </div>
                                </div>
                            </div>
                    }
                </Popup>
            </>

        )
    }
}

export default MyReviews;
